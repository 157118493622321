import React from 'react';
import PropTypes from 'prop-types';
import { Box, HelpOutlineIcon, ToolTip, Typography } from '@esgian/esgianui';

function TextWithTooltipIcon({
  iconColor,
  labelVariant,
  labelBold,
  color,
  label,
  tooltipText,
  sx
}) {
  return (
    <Box sx={{ display: 'flex', gap: '0.1em', ...sx }}>
      {typeof label === 'string' || label instanceof String ? (
        <Typography component={'span'} color={color} variant={labelVariant} bold={labelBold}>
          {label}
        </Typography>
      ) : (
        label
      )}
      <ToolTip title={tooltipText}>
        <HelpOutlineIcon sx={{ fill: iconColor, fontSize: '1rem', mx: 0.5 }} />
      </ToolTip>
    </Box>
  );
}

TextWithTooltipIcon.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  labelVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  sx: PropTypes.object,
  labelBold: PropTypes.bool
};

TextWithTooltipIcon.defaultProps = {
  iconColor: 'text.primary',
  color: 'text.primary',
  labelVariant: 'subtitle2',
  sx: {},
  labelBold: false
};

export default TextWithTooltipIcon;
