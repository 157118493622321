import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Grid, NotFound, Paper, Stack, Typography } from '@esgian/esgianui';
import {
  ServiceFrequencyChart,
  ServiceLevelConsistencyChart,
  TransitTimeConsistencyChart,
  FrequencyAnalysisTable,
  TransitTimeAnalysisTable,
  SubSectionMenu,
  PortServiceLevelFilters,
  UnderlyingServicePortModal
} from '@components/Sections/ShipAnalytics/PortServiceLevelSection/index';
import { getPortFrequency } from '@api';
import { formatPeriodDisplay } from '@helpers';
import { getDateList } from '@helpers';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { MissingOperatorsDisplay } from '@components';
import CEUCapacityChart from '@components/Sections/ShipAnalytics/PortServiceLevelSection/CEU/CEUCapacityChart';
import CEUConsistencyChart from '@components/Sections/ShipAnalytics/PortServiceLevelSection/CEU/CEUConsistencyChart';
import CEUCapacityTable from '@components/Sections/ShipAnalytics/PortServiceLevelSection/CEU/CEUCapacityTable';
import { OPERATOR_SELECT_TYPES } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { useSegment } from '@hooks';
import {
  getCommercialCategoryType,
  getLookupLocations,
  getLookupOperators,
  getOperatorType
} from '@store/features';

import {
  getServiceLevelFilters,
  SERVICE_LEVEL_SLICE_KEYS,
  setServiceLevelSection,
  updateServiceLevelFilters
} from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import {
  getSelectedLocationFromUri,
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSelectedLocationToUrl,
  setSelectedOperatorsToUrl,
  setSliceToUri
} from '@helpers/uriHelpers';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';

const initUrlParams = (filters, lookupLocations, lookupOperators, searchParams) => {
  let newFilters = { ...filters };

  newFilters = getStandardValuesFromUri(newFilters, SERVICE_LEVEL_SLICE_KEYS, 3, searchParams);
  const operators = getSelectedOperatorsFromUri(
    'pslSelectedOperators',
    newFilters.selectType,
    lookupOperators,
    searchParams
  );

  const locationLoad = getSelectedLocationFromUri('pslLoad', lookupLocations, searchParams);
  const locationDischarge = getSelectedLocationFromUri(
    'pslDischarge',
    lookupLocations,
    searchParams
  );
  if (operators) {
    newFilters.selectedOperators = operators;
  }
  if (locationLoad) {
    newFilters.selectedLocationLoad = locationLoad;
  }
  if (locationDischarge) {
    newFilters.selectedLocationDischarge = locationDischarge;
  }

  return newFilters;
};

const setUrlParams = (filters, searchParams, setSearchParams) => {
  const { selectedOperators, selectType, selectedLocationLoad, selectedLocationDischarge } =
    filters;

  searchParams = setSliceToUri(filters, SERVICE_LEVEL_SLICE_KEYS, 'psl', searchParams);
  searchParams = setSelectedOperatorsToUrl(
    selectedOperators,
    selectType,
    'pslSelectedOperators',
    searchParams
  );
  searchParams = setSelectedLocationToUrl(selectedLocationLoad, 'pslLoad', searchParams);
  searchParams = setSelectedLocationToUrl(selectedLocationDischarge, 'pslDischarge', searchParams);

  setSearchParams(searchParams);
};
const getTableHeader = (sectionId) => {
  switch (sectionId) {
    case 1:
    case 2:
      return 'Frequency';
    case 3:
      return 'Transit Time';
    case 4:
    case 5:
      return 'Capacity';
  }
};

const getMissingOperators = (frequencyResults, selectedOperators) => {
  return selectedOperators?.filter((object1) => {
    return !frequencyResults?.some((object2) => {
      return object2.operatorId === object1.id;
    });
  });
};

function PortServiceLevelSection() {
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [noData, setNoData] = useState(true);
  const [inputErrors, setInputErrors] = useState({ minServices: null, maxTime: null });
  const [missingOperators, setMissingOperators] = useState([]);
  const [portServiceData, setPortServiceData] = useState(null);
  const [dateList, setDateList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalData, setModalData] = useState(null);

  const dispatch = useDispatch();
  const { segment, isRoRo } = useSegment();
  const lookupOperators = useSelector(getLookupOperators);
  const lookupLocations = useSelector(getLookupLocations);
  const serviceLevelFilters = useSelector(getServiceLevelFilters);
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const checkValidInput = useCallback(
    (minServices, maxTransitTime) => {
      let errors = { ...inputErrors };
      let errorMessage = 'Field must be greater than 0';

      errors.maxTime = parseInt(maxTransitTime) < 0 ? errorMessage : null;
      errors.minServices = parseInt(minServices) < 0 ? errorMessage : null;

      setInputErrors(errors);
      return !Object.values(errors).includes(errorMessage);
    },
    [inputErrors]
  );

  const handleCellClick = useCallback(
    (operatorId, columnIndex) => {
      if (!portServiceData) return;

      let date = dateList[columnIndex];

      let operatorData = portServiceData.frequencyResults.find(
        (operator) => operator.operatorId === operatorId
      );
      if (operatorData) {
        let monthData = operatorData.operatorMonthlyFrequencyResults.find(
          ({ month }) => moment(month).format('MMM yyyy') === date
        );
        setModalData({ ...monthData, operatorId: operatorId });
      }
    },
    [portServiceData]
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const {
      startDate,
      endDate,
      selectedLocationLoad,
      selectedLocationDischarge,
      selectedOperators,
      selectType,
      maxTransitTime,
      minServices
    } = serviceLevelFilters;
    const loadPortServiceData = async () => {
      try {
        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && !selectedOperators.length) {
          setNoData(true);
          setPortServiceData(null);
          return;
        }

        setLoading(true);
        setNoData(false);
        setMissingOperators([]);

        const dates = getDateList(startDate, endDate);
        setDateList(dates);

        await getPortFrequency(
          {
            ...serviceLevelFilters,
            operatorTypeId: operatorType,
            segmentTypeId: segment.id,
            commercialCategoryId: commercialCategoryType
          },
          signal
        ).then((response) => {
          const { frequencyResults } = response;
          if (!frequencyResults.length) {
            setNoData(true);
            setPortServiceData(null);
          } else {
            const missing = getMissingOperators(frequencyResults, selectedOperators);
            setMissingOperators(missing);
            setPortServiceData(response);
            setNoData(false);
          }
          setLoading(false);
        });
      } catch (error) {
        setNoData(true);
        setLoading(false);
        setPortServiceData(null);
      }
    };
    if (
      init &&
      selectedLocationDischarge &&
      selectedLocationLoad &&
      moment(startDate).isValid() &&
      moment(endDate).isValid() &&
      checkValidInput(minServices, maxTransitTime)
    ) {
      loadPortServiceData();
    }

    return () => {
      controller.abort();
    };
  }, [serviceLevelFilters, operatorType, segment, commercialCategoryType, init]);

  useEffect(() => {
    if (init) {
      setUrlParams(serviceLevelFilters, searchParams, setSearchParams);
    }
  }, [serviceLevelFilters, init]);

  useEffect(() => {
    if (lookupLocations?.length && lookupOperators?.length) {
      const newFilters = initUrlParams(
        serviceLevelFilters,
        lookupLocations,
        lookupOperators,
        searchParams
      );
      dispatch(updateServiceLevelFilters(newFilters));
      setInit(true);
    }
  }, [lookupLocations, lookupOperators]);

  const dateString = useMemo(() => {
    const { selectedLocationLoad, selectedLocationDischarge, startDate, endDate } =
      serviceLevelFilters;
    const CodePOL = selectedLocationLoad?.name.toLowerCase().replace(/\s/g, '-');
    const CodePOD = selectedLocationDischarge?.name.toLowerCase().replace(/\s/g, '-');
    return `${CodePOL}-${CodePOD}-${moment(startDate).format('MMYY')}-${moment(endDate).format(
      'MMYY'
    )}`;
  }, [serviceLevelFilters]);

  const { subSection, selectedOperators, startDate, endDate } = serviceLevelFilters;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h6'}>Service Level</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <PortServiceLevelFilters />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                borderRight: '1px solid #e0e0e0',
                boxShadow: '0 0 8px rgb(0 0 0 / 18%)',
                clipPath: 'inset(0px -27px 0px 0px)'
              }}>
              <SubSectionMenu
                setSubSection={(val) => {
                  dispatch(setServiceLevelSection(val));
                }}
                subSection={subSection}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <MissingOperatorsDisplay
                  noData={noData}
                  alertText={
                    'Some of the selected operators does not have any port activity between the selected dates and ports.'
                  }
                  setMissingOperators={setMissingOperators}
                  setSelectedOperators={(operators) => {
                    dispatch(updateServiceLevelFilters({ selectedOperators: operators }));
                  }}
                  selectedOperators={selectedOperators}
                  missingOperators={missingOperators}
                />
                {!loading && noData && (
                  <Grid item xs={12}>
                    <NotFound
                      show={true}
                      header={'No Matching Result'}
                      text={'Please change your search parameters'}
                    />
                  </Grid>
                )}
                {!noData && (
                  <Grid item xs={12}>
                    {subSection === 1 && (
                      <ServiceFrequencyChart
                        loading={loading}
                        portServiceData={portServiceData}
                        dateList={dateList}
                        fileName={`service-freq-${dateString}`}
                      />
                    )}
                    {subSection === 2 && (
                      <ServiceLevelConsistencyChart
                        loading={loading}
                        portServiceData={portServiceData}
                        fileName={`service-consistency-${dateString}`}
                      />
                    )}
                    {subSection === 3 && (
                      <TransitTimeConsistencyChart
                        loading={loading}
                        portServiceData={portServiceData}
                        fileName={`transit-consistency-${dateString}`}
                      />
                    )}
                    {subSection === 4 && (
                      <CEUCapacityChart
                        loading={loading}
                        portServiceData={portServiceData}
                        dateList={dateList}
                        fileName={`ceu-capacity-${dateString}`}
                      />
                    )}
                    {subSection === 5 && (
                      <CEUConsistencyChart
                        loading={loading}
                        portServiceData={portServiceData}
                        fileName={`ceu-consistency-${dateString}`}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {!noData && (
        <Grid item xs={12}>
          <Stack>
            <Paper sx={{ maxWidth: '100%' }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant={'h6'}>
                      Service Level - {getTableHeader(subSection)} Analysis
                    </Typography>
                    <Typography color={'text.secondary'} variant={'body2'}>
                      From {formatPeriodDisplay(startDate, endDate)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Box sx={{ p: 2 }}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {(subSection === 1 || subSection === 2) && (
                    <FrequencyAnalysisTable
                      handleCellClick={handleCellClick}
                      loading={loading}
                      portServiceData={portServiceData}
                      dateList={dateList}
                    />
                  )}
                  {subSection === 3 && (
                    <TransitTimeAnalysisTable
                      handleCellClick={handleCellClick}
                      loading={loading}
                      portServiceData={portServiceData}
                      dateList={dateList}
                    />
                  )}
                  {(subSection === 4 || subSection === 5) && (
                    <CEUCapacityTable
                      handleCellClick={handleCellClick}
                      loading={loading}
                      portServiceData={portServiceData}
                      dateList={dateList}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </Grid>
      )}
      {!!modalData && (
        <UnderlyingServicePortModal modalData={modalData} setModalData={setModalData} />
      )}
    </Grid>
  );
}

PortServiceLevelSection.propTypes = {};

PortServiceLevelSection.defaultProps = {};

export default PortServiceLevelSection;
