import React from 'react';
import { Box, Button, FormControlLabel, Paper, Switch, Typography } from '@esgian/esgianui';

import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';
import MultiSelectDropdown from '../../../Common/MultiSelectDropdown';

const VoyageGroupFilter = ({ multiSelectOptions, selectedVesselsId, onSelectVessels }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { themeMode } = useSelector(getGeneralSlice);

  const isColorCodeChecked = searchParams.get('showColorCode') === 'true';
  const isShowPortChecked = searchParams.get('showPorts') === 'true';
  const isShowVesselNameChecked = searchParams.get('showVesselName') === 'true';

  const updateSearchParams = (key, value) => {
    setSearchParams((p) => {
      p.set(key, value);
      return p;
    });
  };

  const exitFullScreenMode = () => {
    setSearchParams((p) => {
      p.set('view', 'list');
      p.delete('showColorCode');
      p.delete('showPorts');
      p.delete('showVesselName');
      return p;
    });
  };

  const selectedOptions = selectedVesselsId.map((id) =>
    multiSelectOptions.find((option) => option.id === id)
  );

  return (
    <Paper
      style={{
        position: 'absolute',
        zIndex: 1,
        left: '16px',
        top: '16px',
        right: '16px',
        backgroundColor: themeMode ? '#191919E5' : '#FFFFFFE5',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 2
        }}>
        <Button
          variant="contained"
          onClick={exitFullScreenMode}
          startIcon={<img src="/assets/images/full-screen-exit-icon.png" alt="Fullscreen Exit" />}>
          <Typography color="black">Exit</Typography>
        </Button>

        <MultiSelectDropdown
          handleChange={(selected) => {
            onSelectVessels(selected.map((v) => v.id));
          }}
          placeholder="Select Vessels"
          selectedOptions={selectedOptions}
          options={multiSelectOptions}
          label="Vessels"
        />

        <FormControlLabel
          control={
            <Switch
              defaultChecked
              checked={isColorCodeChecked}
              onChange={() => updateSearchParams('showColorCode', !isColorCodeChecked)}
            />
          }
          label="Show color code"
        />
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              checked={isShowPortChecked}
              onChange={() => updateSearchParams('showPorts', !isShowPortChecked)}
            />
          }
          label="Show Ports"
        />
        <FormControlLabel
          control={
            <Switch
              checked={isShowVesselNameChecked}
              onChange={(e) => updateSearchParams('showVesselName', e.target.checked)}
            />
          }
          label="Show Vessel Name"
        />
      </Box>
    </Paper>
  );
};

VoyageGroupFilter.propTypes = {
  multiSelectOptions: PropTypes.array,
  selectedVesselsId: PropTypes.array,
  onSelectVessels: PropTypes.func
};

export default VoyageGroupFilter;
