export const VoyageType = {
  COMPLETED: 'COMPLETED',
  ONGOING: 'ONGOING'
};

export const getVoyageLink = (uriExt, voyageDetails, voyageGroupType) => {
  return `${uriExt}/${voyageGroupType === VoyageType.ONGOING ? 'ongoing' : 'completed'}-voyages/${
    voyageDetails.imo
  }?departureDate=${voyageDetails.departureDate}&arrivalDate=${
    voyageDetails.arrivalDate
  }&fromPortId=${voyageDetails.fromPortId}&toPortId=${voyageDetails.toPortId}`;
};

export const generateVoyageIdFromSavedVoyage = (voyage) =>
  `${voyage.imo}-${voyage.fromPortId}-${voyage.departureDate}`;
