import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { TextWithTooltipIcon } from '@components';
import { EU_ALLOWANCE_FOR_MY_CARGO, ETS_COST_PER_TONNE } from '@constants/tooltips';

import {
  getValueUptoOneDecimalPlace,
  getValueUptoTwoDecimalPlaces
} from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';
import { formatNumberReadable } from '@helpers';

const CargoEstimatedEtsCostTable = ({
  myCargoEuEtsAllowanceSummary,
  myCargoEuEtsAllowanceCost,
  isLoading,
  setMyCargoWeightForCustomizedCalculation,
  setMyEtsCostForCustomizedCalculation,
  myCargoWeightForCustomizedCalculation,
  myEtsCostForCustomizedCalculation
}) => {
  const data = [];
  const columns = [
    { name: 'items', label: 'Items' },
    { name: 'amount', label: 'Amount' }
  ];
  const summary = [];

  const detailsColumns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'totalEstimatedCargo',
      label: 'Total Cargo (T)',
      tooltip: 'Estimated cargo weight as per Esgian estimation model'
    },
    {
      name: 'myCargo',
      label: 'My Cargo (MT)'
    },
    {
      name: 'shareOfMyCargo',
      label: 'Share of My Cargo',
      tooltip: 'My cargo weight/ Estimated total cargo weight onboard ( % )'
    },
    {
      name: 'estimatedEtsCostEuro',
      label: 'EU ETS Cost (€)'
    }
  ];
  const detailsData = [];
  const totalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    totalEstimatedCargo: { value: '-' },
    myCargo: { value: '-' },
    shareOfMyCargo: { value: '-' },
    estimatedEtsCostEuro: { value: '-' }
  };

  if (myCargoEuEtsAllowanceSummary) {
    data.push(
      ...[
        {
          items: { value: 'My Cargo (MT)' },
          amount: {
            value: formatNumberReadable(
              getValueUptoOneDecimalPlace(myCargoWeightForCustomizedCalculation)
            ),
            isEditable: true
          }
        },
        {
          items: {
            value: 'ETS cost per tonne CO₂(€)',
            tooltip: ETS_COST_PER_TONNE
          },
          amount: {
            value: getValueUptoTwoDecimalPlaces(myEtsCostForCustomizedCalculation),
            isEditable: true
          }
        },
        {
          items: { value: 'ETS cost for my cargo (€)' },
          amount: {
            value: formatNumberReadable(
              myCargoEuEtsAllowanceSummary?.totalMyCargoEstimatedEuEtsCost?.toFixed(0)
            )
          }
        },
        {
          items: { value: 'EU Allowances for my cargo', tooltip: EU_ALLOWANCE_FOR_MY_CARGO },
          amount: {
            value: formatNumberReadable(
              myCargoEuEtsAllowanceSummary?.totalMyCargoEuEtsAllowanceTn?.toFixed(0)
            )
          }
        }
      ]
    );
    summary.push(
      ...[
        {
          label: 'EU ETS Cost for my cargo (€)',
          value: formatNumberReadable(
            myCargoEuEtsAllowanceSummary.totalMyCargoEstimatedEuEtsCost?.toFixed(0)
          )
        },
        {
          label: (
            <TextWithTooltipIcon
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.1em'
              }}
              label="EU Allowances for my cargo"
              tooltipText={EU_ALLOWANCE_FOR_MY_CARGO}
            />
          ),
          value: formatNumberReadable(
            myCargoEuEtsAllowanceSummary.totalMyCargoEuEtsAllowanceTn?.toFixed(0)
          )
        }
      ]
    );
  }

  if (myCargoEuEtsAllowanceCost) {
    detailsData.push(
      ...myCargoEuEtsAllowanceCost.voyageLegMyCargoEuEtsAllowanceCosts.map((d, i) => ({
        voyageLeg: { value: `#${i + 1}` },
        origin: { value: d.fromPortName },
        destination: { value: d.toPortName },
        totalEstimatedCargo: { value: formatNumberReadable(d.cargoWeightTn?.toFixed(0)) },
        myCargo: { value: formatNumberReadable(d.myCargoWeightTn?.toFixed(1)) },
        shareOfMyCargo: { value: `${getValueUptoOneDecimalPlace(d.myCargoShare)}%` },
        estimatedEtsCostEuro: { value: formatNumberReadable(d.myCargoEstimatedEtsCost?.toFixed(0)) }
      }))
    );

    //totalRow.myCargo.value = myCargoEuEtsAllowanceCost?.totalMyCargoEuEtsAllowanceTn?.toFixed(1);
    totalRow.estimatedEtsCostEuro.value = formatNumberReadable(
      myCargoEuEtsAllowanceCost?.totalMyCargoEstimatedEuEtsCost?.toFixed(0)
    );
  }
  return (
    <TableWithSummary
      title="My cargo"
      columns={columns}
      data={data}
      summary={summary}
      detailsTableTitle="EU ETS Cost for My Cargo"
      detailsTableColumns={detailsColumns}
      detailsTableData={[...detailsData, totalRow]}
      isLoading={isLoading}
      onUpdate={(rowIndex, columnName, newValue) => {
        if (rowIndex === 0) {
          setMyCargoWeightForCustomizedCalculation(newValue);
        } else if (rowIndex === 1) {
          setMyEtsCostForCustomizedCalculation(newValue);
        }
      }}
    />
  );
};

CargoEstimatedEtsCostTable.propTypes = {
  myCargoEuEtsAllowanceSummary: PropTypes.object,
  myCargoEuEtsAllowanceCost: PropTypes.object,
  isLoading: PropTypes.bool,
  setMyCargoWeightForCustomizedCalculation: PropTypes.func,
  setMyEtsCostForCustomizedCalculation: PropTypes.func,
  myCargoWeightForCustomizedCalculation: PropTypes.number,
  myEtsCostForCustomizedCalculation: PropTypes.number
};

export default CargoEstimatedEtsCostTable;
