import { BaseApiRequest } from '@api/BaseApi';
import { objectToQuery } from '@api/helpers';

export const getCanalDepartures = (data, signal) => {
  return BaseApiRequest(signal, `/Canal/Departures`, 'ships', 'Post', data);
};
export const getCanalArrivals = (data, signal) => {
  return BaseApiRequest(signal, `/Canal/ExpectedArrivals`, 'ships', 'Post', data);
};

export const getCanalInTransit = (data, signal) => {
  return BaseApiRequest(signal, `/Canal/InTransit`, 'ships', 'Post', data);
};
