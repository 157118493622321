import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Typography } from '@esgian/esgianui';
import ValueDisplay from '@components/ValueDisplay/ValueDisplay';
import { ProfileLink } from '@components/Links';
import { formatNumberReadable } from '@helpers';

function VesselOverview({
  showTitle,
  vesselsData,
  loading,
  isRoRo,
  title,
  width,
  isShowInSingleColumn,
  backgroundColor
}) {
  const {
    imo,
    buildYear,
    deadweight,
    grossTonnage,
    operatorShortName,
    operatorLongName,
    ownerShortName,
    ownerLongName,
    class: vesselClass,
    flagState,
    capacity,
    operatorId,
    ownerId,
    maxLiftingCapacity,
    numCranes,
    gears
  } = vesselsData || {};

  const items = [
    { title: 'IMO', value: imo, className: 'vessel-details' },
    { title: 'Build Year', value: buildYear, className: 'vessel-details' },
    { title: 'DWT', value: `${formatNumberReadable(deadweight)} mt`, className: 'vessel-details' },
    {
      title: 'GT',
      value: grossTonnage !== null ? `${formatNumberReadable(grossTonnage)}` : '-',
      className: 'vessel-details'
    },
    {
      title: isRoRo ? 'Capacity' : 'Max Lifting Capacity',
      value: isRoRo
        ? capacity !== null
          ? `${formatNumberReadable(capacity)} CEU`
          : '-'
        : maxLiftingCapacity !== null
        ? formatNumberReadable(maxLiftingCapacity)
        : '-',
      className: 'vessel-details'
    },
    {
      title: 'Operator',
      value:
        operatorLongName && operatorShortName ? (
          <ProfileLink
            profile={'operator'}
            name={`${operatorLongName} (${operatorShortName})`}
            id={operatorId}
          />
        ) : (
          '--'
        ),
      className: 'vessel-details'
    },
    isRoRo && {
      title: 'Owner',
      value:
        ownerLongName && ownerShortName ? (
          <ProfileLink
            profile={'owner'}
            name={`${ownerLongName} (${ownerShortName})`}
            id={ownerId}
          />
        ) : (
          '--'
        ),
      className: 'vessel-owner'
    },
    { title: 'Flag', value: flagState, className: 'vessel-details' },
    {
      title: 'Class',
      value: vesselClass !== null ? vesselClass : '-',
      className: 'vessel-details'
    },
    !isRoRo && {
      title: 'No. Cranes',
      value: numCranes !== null ? numCranes : '-',
      className: 'vessel-details'
    },
    !isRoRo && {
      title: 'Gears in Overview Box',
      value: gears !== null ? gears : '-',
      className: 'vessel-class'
    }
  ].filter(Boolean); // Filter out any false values (e.g., items not applicable for RoRo)

  return (
    <Paper sx={{ p: 2, height: '100%', width, backgroundColor }}>
      <Grid container spacing={2}>
        {showTitle && (
          <>
            <Grid item container spacing={2} xs={12}>
              <Grid item alignSelf={'center'}>
                <Typography variant={'h6'} id={'vessel-overview-title'}>
                  {title ?? 'Overview'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        {isShowInSingleColumn ? (
          <Grid item xs={12}>
            <Grid container direction={'column'} spacing={1}>
              {items.map(({ title, value, className }) => (
                <ValueDisplay
                  key={title}
                  title={title}
                  value={value}
                  className={className}
                  loading={loading}
                />
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Grid container direction={'column'} spacing={1}>
                {items.slice(0, Math.ceil(items.length / 2)).map(({ title, value, className }) => (
                  <ValueDisplay
                    key={title}
                    title={title}
                    value={value}
                    className={className}
                    loading={loading}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction={'column'} spacing={1}>
                {items.slice(Math.ceil(items.length / 2)).map(({ title, value, className }) => (
                  <ValueDisplay
                    key={title}
                    title={title}
                    value={value}
                    className={className}
                    loading={loading}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

VesselOverview.propTypes = {
  vesselsData: PropTypes.object,
  loading: PropTypes.bool,
  isRoRo: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTitle: PropTypes.bool,
  isShowInSingleColumn: PropTypes.bool,
  backgroundColor: PropTypes.string
};

VesselOverview.defaultProps = {
  vesselsData: null,
  loading: false,
  isRoRo: false,
  showTitle: true
};

export default VesselOverview;
