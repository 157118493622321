import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Divider, Grid, NotFound, Paper, Tab, Tabs, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { getOperatorPerformance } from '@api';
import { useSearchParams } from 'react-router-dom';
import {
  OperatorPerformanceFilters,
  PerformanceIndicator,
  PerformanceVsSegment,
  SubSectionsMenus
} from '@components/Sections/ShipAnalytics/OperatorPerformanceSection/index';
import { getVesselsEmissions } from '@api/Emissions';
import { useSegment } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupOperators, getLookupPorts } from '@store/features';
import {
  getOperatorPerformanceFilters,
  OPERATOR_PERFORMANCE_SLICE_KEYS,
  setOperatorPerformanceSubSection,
  setOperatorPerformanceTabSection,
  updateOperatorPerformanceFilters
} from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';
import {
  getSelectedPortFromUri,
  getStandardValuesFromUri,
  setSelectedPortToUrl,
  setSliceToUri
} from '@helpers/uriHelpers';
import { toast } from 'react-toastify';

const initUrlParams = (filters, lookupPorts, lookupOperators, searchParams) => {
  let newFilters = { ...filters };

  newFilters = getStandardValuesFromUri(
    newFilters,
    OPERATOR_PERFORMANCE_SLICE_KEYS,
    2,
    searchParams
  );
  let selectedOperator = searchParams.get('opSelectedOperator');
  if (selectedOperator) {
    newFilters.selectedOperator =
      lookupOperators.find(({ id }) => id === parseInt(selectedOperator)) || null;
  }

  const portLoad = getSelectedPortFromUri('opPortLoad', lookupPorts, searchParams);
  const portDischarge = getSelectedPortFromUri('opPortDischarge', lookupPorts, searchParams);
  if (portLoad) {
    newFilters.selectedPortLoad = portLoad;
  }
  if (portDischarge) {
    newFilters.selectedPortDischarge = portDischarge;
  }
  return newFilters;
};

const setUrlParams = (filters, searchParams, setSearchParams) => {
  const { selectedOperator, selectedPortLoad, selectedPortDischarge } = filters;

  if (selectedOperator) {
    searchParams.set('opSelectedOperator', selectedOperator.id);
  }
  searchParams = setSliceToUri(filters, OPERATOR_PERFORMANCE_SLICE_KEYS, 'op', searchParams);

  searchParams = setSelectedPortToUrl(selectedPortLoad, 'opPortLoad', searchParams);
  searchParams = setSelectedPortToUrl(selectedPortDischarge, 'opPortDischarge', searchParams);

  setSearchParams(searchParams);
};

function OperatorPerformanceSection() {
  const [performanceData, setPerformanceData] = useState(null);
  const [vesselsEmissions, setVesselsEmissions] = useState(null);
  const [init, setInit] = useState(false);
  const [noData, setNoData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { segment } = useSegment();
  const lookupOperators = useSelector(getLookupOperators);
  const lookupPorts = useSelector(getLookupPorts);
  const operatorPerformanceFilters = useSelector(getOperatorPerformanceFilters);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const { selectedOperator, startDate, endDate, selectedPortLoad, selectedPortDischarge } =
      operatorPerformanceFilters;
    const loadOperatorPerformanceData = async () => {
      try {
        await getOperatorPerformance(operatorPerformanceFilters, segment.id, signal)
          .then(async (result) => {
            const {
              operatorPerformance: { operatorFrequencyResult, transitResult }
            } = result;

            if (!operatorFrequencyResult.length || !transitResult.length) {
              setNoData(true);
              setPerformanceData(null);
            } else {
              try {
                await getVesselsEmissions(result, signal)
                  .catch(() => {
                    toast.success('Unable to load emissions data', {
                      position: 'top-right'
                    });
                    setVesselsEmissions(null);
                  })
                  .then((result) => {
                    setVesselsEmissions(result);
                  });
              } catch (error) {
                setVesselsEmissions(null);
              }
              setPerformanceData(result);
              setNoData(false);
            }
          })
          .catch(() => {
            setNoData(true);
            setPerformanceData(null);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };

    if (
      init &&
      selectedOperator &&
      moment(startDate).isValid() &&
      moment(endDate).isValid() &&
      selectedPortLoad &&
      selectedPortDischarge
    ) {
      setLoading(true);
      setNoData(false);
      loadOperatorPerformanceData();
    }
    return () => {
      controller.abort();
    };
  }, [operatorPerformanceFilters, init]);

  useEffect(() => {
    if (init) {
      setUrlParams(operatorPerformanceFilters, searchParams, setSearchParams);
    }
  }, [operatorPerformanceFilters, init]);

  useEffect(() => {
    if (lookupPorts?.length && lookupOperators?.length) {
      const newFilters = initUrlParams(
        operatorPerformanceFilters,
        lookupPorts,
        lookupOperators,
        searchParams
      );
      dispatch(updateOperatorPerformanceFilters(newFilters));
      setInit(true);
    }
  }, [lookupPorts, lookupOperators]);

  const { segmentPerformance } = performanceData || {};
  const { numberOfOperatorsInSegment } = segmentPerformance || {};
  const { tabSection } = operatorPerformanceFilters;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h6'}>Operator Performance</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <OperatorPerformanceFilters />
            {numberOfOperatorsInSegment === 1 && (
              <Grid item xs={12}>
                <Alert severity={'info'}>
                  The response to the current filter gives only 1 operator in the segment
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Tabs
                value={tabSection}
                onChange={(_, val) => {
                  dispatch(setOperatorPerformanceTabSection(val));
                  dispatch(setOperatorPerformanceSubSection(1));
                }}
                variant="scrollable"
                aria-label="scrollable prevent tabs example">
                <Tab value={1} label="Performance Indicator" />
                <Tab value={2} label="Performance vs Segment" />
              </Tabs>
              <Divider />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                borderRight: '1px solid #e0e0e0',
                boxShadow: '0 0 8px rgb(0 0 0 / 18%)',
                clipPath: 'inset(0px -27px 0px 0px)'
              }}>
              <SubSectionsMenus />
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {!loading && noData && (
                  <Grid item xs={12}>
                    <NotFound
                      show={true}
                      header={'No Matching Result'}
                      text={'Please change your search parameters'}
                    />
                  </Grid>
                )}
                {!noData && (
                  <Grid item xs={12}>
                    {tabSection === 1 && (
                      <PerformanceIndicator loading={loading} performanceData={performanceData} />
                    )}
                    {tabSection === 2 && (
                      <PerformanceVsSegment loading={loading} performanceData={performanceData} />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {tabSection === 1 && !noData && !loading && (
        <PerformanceIndicator
          vesselsEmissions={vesselsEmissions}
          renderOverview
          loading={loading}
          performanceData={performanceData}
        />
      )}
      {tabSection === 2 && !noData && !loading && (
        <PerformanceVsSegment renderOverview loading={loading} performanceData={performanceData} />
      )}
    </Grid>
  );
}

OperatorPerformanceSection.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedPortLoad: PropTypes.object,
  selectedOperator: PropTypes.object,
  selectedPortDischarge: PropTypes.object
};

OperatorPerformanceSection.defaultProps = {
  startDate: null,
  endDate: null,
  selectedPortLoad: {},
  selectedOperator: {},
  selectedPortDischarge: {}
};

export default OperatorPerformanceSection;
