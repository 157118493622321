import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InfoBox,
  ShipsIcon,
  StorageIcon,
  TrendingUpIcon,
  Paper,
  Stack,
  Typography,
  Box,
  DateRangeMobi,
  Select,
  MenuItem,
  Divider,
  NotFound,
  Tabs,
  Tab,
  Slide
} from '@esgian/esgianui';
import { OperatorsTable } from '@components/Tables';
import { useSegment } from '@hooks';
import moment from 'moment';
import {
  OperatorCEUChart,
  OperatorFleetAgeChart
} from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections/OperatorOverviewSection';
import { fetchVesselStats } from '@api';
import { DATE_TIME_FORMAT } from '@constants';
import { OperatorMultiSelect } from '@components/Inputs';
import { getLookupOperators } from '@store/features';
import { useSelector } from 'react-redux';

function aggregateOperatorData(response) {
  const aggregatedData = {
    vesselsAdded: [],
    vesselsRemoved: [],
    totalVessels: [],
    operatorAgeInformation: [],
    ceuCapacity: [],
    numVesselsArray: []
  };
  response.forEach((operator) => {
    const operatorFleetData = operator.vesselTimeSeries || [];

    operatorFleetData.forEach((item, index) => {
      // Aggregate vesselsAdded
      if (!aggregatedData.vesselsAdded[index]) {
        aggregatedData.vesselsAdded[index] = [];
      }
      aggregatedData.vesselsAdded[index] = aggregatedData.vesselsAdded[index].concat(
        item.vesselsIn || []
      );

      // Aggregate vesselsRemoved
      if (!aggregatedData.vesselsRemoved[index]) {
        aggregatedData.vesselsRemoved[index] = [];
      }
      aggregatedData.vesselsRemoved[index] = aggregatedData.vesselsRemoved[index].concat(
        item.vesselsOut || []
      );

      if (!aggregatedData.numVesselsArray[index]) {
        aggregatedData.numVesselsArray[index] = [];
      }
      aggregatedData.numVesselsArray[index].push(item.numVessels || 0);

      // Aggregate totalVessels
      if (!aggregatedData.totalVessels[index]) {
        aggregatedData.totalVessels[index] = 0;
      }
      aggregatedData.totalVessels[index] += item.numVessels || 0;

      // Aggregate operatorAgeInformation
      if (!aggregatedData.operatorAgeInformation[index]) {
        aggregatedData.operatorAgeInformation[index] = {
          averageAge: 0,
          vesselsAgeRange0To5: 0,
          vesselsAgeRange6To10: 0,
          vesselsAgeRange11To15: 0,
          vesselsAgeRange16To20: 0,
          vesselsAgeRange21To25: 0,
          vesselsAgeRangeGreaterThan25: 0
        };
      }
      const ageInfo = item.ageInformation || {};
      Object.keys(ageInfo).forEach((key) => {
        if (key === 'averageAge') {
          aggregatedData.operatorAgeInformation[index][key] +=
            ageInfo[key] * (item.numVessels || 1);
        } else {
          aggregatedData.operatorAgeInformation[index][key] += ageInfo[key] || 0;
        }
      });

      // Aggregate ceuCapacity
      if (!aggregatedData.ceuCapacity[index]) {
        aggregatedData.ceuCapacity[index] = {
          averageCEU: 0,
          totalCEU: 0,
          averageDeadweightTonnage: 0,
          totalDeadweightTonnage: 0
        };
      }
      const capacity = item.capacity || {};
      Object.keys(capacity).forEach((key) => {
        if (key.startsWith('average')) {
          aggregatedData.ceuCapacity[index][key] += capacity[key] * (item.numVessels || 1);
        } else {
          aggregatedData.ceuCapacity[index][key] += capacity[key] || 0;
        }
      });
    });
  });

  // Calculate final averages
  aggregatedData.operatorAgeInformation.forEach((item, index) => {
    item.averageAge /= aggregatedData.totalVessels[index] || 1;
  });

  aggregatedData.ceuCapacity.forEach((item, index) => {
    item.averageCEU /= aggregatedData.totalVessels[index] || 1;
    item.averageDeadweightTonnage /= aggregatedData.totalVessels[index] || 1;
  });

  return aggregatedData;
}

function OperatorsOverviewSection({ operatorDetails }) {
  const [init, setInit] = useState(false);
  const { isRoRo, segment } = useSegment();
  const { operators, averageAge, averageCapacity, totalCapacity, fleetSize, operatorShortName } =
    operatorDetails || {};
  const lookupOperators = useSelector(getLookupOperators);
  const isProd = process.env.CURRENT_ENV === 'prod';
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [fleetAgeCeuData, setFleetAgeCeuData] = useState([]);
  const [CEUCapacityData, setCEUCapacityData] = useState([]);
  const [totalCountVessel, setTotalCountVessel] = useState([]);
  const [categories, setCategories] = useState([]);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vesselsAdded, setVesselsAdded] = useState([]);
  const [vesselsRemoved, setVesselsRemoved] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('ageProfile');
  const [fleetDates, setFleetDates] = useState({
    start: moment().subtract(12, 'months').startOf('month'),
    end: moment()
  });

  const [section, setSection] = useState(1);

  const filterOptions = [
    { value: 'ceuCapacity', label: isRoRo ? 'CEU Capacity' : 'Total Deadweight' },
    { value: 'ageProfile', label: 'Age Profile' }
  ];

  useEffect(() => {
    if (lookupOperators.length > 0 && !init) {
      setSelectedOperators(lookupOperators);
      setInit(true);
    }
  }, [lookupOperators, init]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchFleetAgeData = async () => {
      try {
        setLoading(true);
        setNoData(false);
        const operatorIds = selectedOperators.map((operator) => operator.id);

        const response = await fetchVesselStats(
          signal,
          [segment.id],
          [],
          operatorIds,
          [],
          moment(fleetDates.start).subtract(1, 'month').format(DATE_TIME_FORMAT),
          moment(fleetDates.end).format(DATE_TIME_FORMAT)
        );

        if (response.length === 0) {
          setNoData(true);
          setLoading(false);
          return;
        }

        const aggregatedData = aggregateOperatorData(response);
        console.log('numVesselsArray:', aggregatedData.numVesselsArray);

        const { vesselsAdded, vesselsRemoved, totalVessels, operatorAgeInformation, ceuCapacity } =
          aggregatedData;

        const categories = response[0].vesselTimeSeries.map((item) => {
          const date = new Date(item.date);
          return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        });

        setCategories(categories);
        setFleetAgeCeuData(operatorAgeInformation);
        setCEUCapacityData(ceuCapacity);
        setVesselsAdded(vesselsAdded);
        setVesselsRemoved(vesselsRemoved);
        setTotalCountVessel(totalVessels);
        setNoData(false);
        setLoading(false);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          setLoading(false);
          console.error('Error fetching fleet age data:', error);
          setNoData(true);
        }
      }
    };

    fetchFleetAgeData();

    return () => {
      controller.abort();
    };
  }, [fleetDates, selectedOperators, segment.id]);

  const handleFilterChange = useCallback(({ selectedOperators }) => {
    setSelectedOperators(selectedOperators);
  }, []);

  const handleSectionChange = useCallback((_, val) => {
    setSection(val);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} id={'operator-overview-fleet-size'}>
        <InfoBox
          loading={loading}
          mainValue={fleetSize ? fleetSize : ''}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          headerId={'operator-overview-fleet-size-label'}
          mainValueId={'operator-overview-fleet-size-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-age'}>
        <InfoBox
          loading={loading}
          mainValue={averageAge ? averageAge.toFixed(1) : ''}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          headerId={'operator-overview-avg-age-label'}
          mainValueId={'operator-overview-avg-age-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          headerId={'operator-overview-avg-capacity-label'}
          mainValueId={'operator-overview-avg-capacity-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-total-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={totalCapacity ? totalCapacity : ''}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
          headerId={'operator-overview-total-capacity-label'}
          mainValueId={'operator-overview-total-capacity-value'}
        />
      </Grid>
      {!isProd && (
        <Grid item xs={12} id={'operators-overview'}>
          <Paper>
            <Tabs variant="scrollable" onChange={handleSectionChange} value={section}>
              <Tab value={1} label="Operators Overview" />
              <Tab value={2} label="Fleet Development" />
            </Tabs>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
          <div>
            <Paper sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Stack>
                  <Typography variant={'h6'}>Fleet Development</Typography>
                </Stack>
                <Stack direction="row" spacing={4} justifyContent={'space-between'}>
                  <Box sx={{ width: '15em' }}>
                    <DateRangeMobi
                      sx={{
                        inputProps: {
                          InputProps: {
                            sx: { fontSize: '0.875rem' }
                          },
                          defaultValue: undefined,
                          value:
                            fleetDates.start || fleetDates.end
                              ? `${
                                  fleetDates.start
                                    ? moment(fleetDates.start).format('MMM YYYY')
                                    : ''
                                } - ${
                                  fleetDates.end ? moment(fleetDates.end).format('MMM YYYY') : ''
                                }`
                              : null
                        }
                      }}
                      onClose={({ value }) => {
                        if (value[0].isValid()) {
                          setFleetDates((prevState) => ({
                            ...prevState,
                            start: value[0].startOf('month')
                          }));
                        }
                        if (value[1].isValid()) {
                          setFleetDates((prevState) => ({
                            ...prevState,
                            end: value[1].isSameOrAfter(moment(), 'month')
                              ? moment()
                              : value[1].endOf('month')
                          }));
                        }
                      }}
                      dateFormat={'MMM YYYY'}
                      minStartDate={
                        isRoRo ? moment('07-2022', 'MM-YYYY') : moment('07-2023', 'MM-YYYY')
                      }
                      disableFuture
                      startDate={fleetDates.start}
                      endDate={fleetDates.end}
                      placeholder={'Period'}
                      dateWheels={'MMM YYYY'}
                    />
                  </Box>
                  <Grid item xs={12} className={'section-filter'}>
                    <Box sx={{ width: '300px' }}>
                      <OperatorMultiSelect
                        helperText={
                          init && !selectedOperators.length ? 'No operators selected' : ''
                        }
                        error={init && !selectedOperators.length}
                        placeholder={'Select Operators...'}
                        selectedOperators={selectedOperators}
                        operatorsList={lookupOperators || []}
                        sx={{ width: '100%' }}
                        handleChange={(_, values) => {
                          handleFilterChange({ selectedOperators: values });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ width: '10em' }}>
                      <Select
                        value={selectedFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                        displayEmpty
                        variant={'standard'}
                        labelId="operator-overview-filter"
                        inputProps={{ 'aria-label': 'Filter' }}
                        MenuProps={{ PaperProps: { style: { width: '10em' } } }}
                        sx={{ width: '10em' }}>
                        {filterOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                </Stack>
                <Divider />
                {noData ? (
                  <NotFound
                    show={true}
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                ) : (
                  <>
                    {selectedFilter === 'ageProfile' && (
                      <OperatorFleetAgeChart
                        operatorName={operatorShortName}
                        categories={categories}
                        loading={loading}
                        fleetAgeCeuData={fleetAgeCeuData}
                        totalCountVessel={totalCountVessel}
                        vesselsAdded={vesselsAdded}
                        vesselsRemoved={vesselsRemoved}
                        fileName={`${operatorShortName}-fleet-age`}
                        showForSingleSeries={false}
                      />
                    )}
                    {selectedFilter === 'ceuCapacity' && (
                      <OperatorCEUChart
                        loading={loading}
                        CEUCapacityData={CEUCapacityData}
                        fileName={`${operatorShortName}-CEU-capacity`}
                        categories={categories}
                        vesselsAdded={vesselsAdded}
                        vesselsRemoved={vesselsRemoved}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Paper>
          </div>
        </Slide>
      </Grid>

      <Grid item xs={12}>
        <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
          <div>
            <OperatorsTable
              operators={operators}
              fileName={'operators-overview'}
              loading={loading}
            />
          </div>
        </Slide>
      </Grid>
    </Grid>
  );
}

OperatorsOverviewSection.propTypes = {
  loading: PropTypes.bool,
  operatorDetails: PropTypes.object
};

OperatorsOverviewSection.defaultProps = {};

export default OperatorsOverviewSection;
