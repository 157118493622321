import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Typography, Stack } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable } from '@helpers';
import moment from 'moment';
import { useSegment, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getOptions = (categories, theme, user, fileName, measureUnit) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast, twoColorsNeutral, threeColors }
    }
  } = theme;

  let colors = [...threeColors, twoColorsNeutral[0], twoColorsContrast[0]];
  return {
    chart: {
      type: 'bar',
      height: 350,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: background,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName,
            headerCategory: 'Month'
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: false
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [0, 1, 2]
    },
    colors: colors,
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      min: 0,
      title: {
        text: `${measureUnit}`
      },
      decimalsInFloat: measureUnit === 'Anchor events' || measureUnit === 'Days' ? 1 : 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: {
        formatter: function (value) {
          if (!value) return '-';
          const formattedValue = formatNumberReadable(value?.toFixed(2));
          return `${
            measureUnit === 'CEU' || measureUnit === 'DWT' || measureUnit === 'Anchor events'
              ? formatNumberReadable(value)
              : formattedValue
          } ${measureUnit}`;
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  };
};
function MonthlyPortWaitingChart({ portCongestionData, selectedMeasure, loading, fileName }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const { isRoRo } = useSegment();

  const series = useMemo(() => {
    if (!portCongestionData) return [];
    let tempSeries = [];
    const { years } = portCongestionData;
    const maxDataLength = Math.max(...years.map(({ months }) => months?.length || 0));

    years.forEach(({ year, months }) => {
      let tempData = months?.map(
        ({
          averageWaitingTimeDays,
          totalWaitingTimeDays,
          ceuCapacityInWaiting,
          numWaitingEvents,
          dwtInWaiting
        }) => {
          if (selectedMeasure.id === 1) {
            return averageWaitingTimeDays * 24;
          }
          if (selectedMeasure.id === 2) {
            return totalWaitingTimeDays;
          }
          if (selectedMeasure.id === 3) {
            return isRoRo ? ceuCapacityInWaiting : dwtInWaiting;
          }
          if (selectedMeasure.id === 4) {
            return numWaitingEvents;
          }
        }
      );
      tempData = [...tempData, ...Array(maxDataLength - tempData.length).fill(null)];
      tempSeries.push({ name: year, data: tempData });
    });
    return tempSeries;
  }, [portCongestionData, theme, selectedMeasure]);

  const options = useMemo(() => {
    let months = [];
    for (let i = 0; i <= 11; i++) {
      months.push(moment(`01/01/2020`).add(i, 'months').format('MMM'));
    }

    return getOptions(months, theme, user, fileName, selectedMeasure?.unit);
  }, [selectedMeasure]);

  return (
    <>
      <Stack>
        <Typography variant={'h6'}>Monthly {selectedMeasure?.type}</Typography>
      </Stack>
      <ApexChart
        loading={loading || !options}
        height={480}
        options={options || {}}
        type={'bar'}
        data={series.reverse()}
      />
    </>
  );
}
MonthlyPortWaitingChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  portCongestionData: PropTypes.object,
  selectedMeasure: PropTypes.object
};
MonthlyPortWaitingChart.defaultProps = {
  portCongestionData: {},
  loading: false,
  selectedMeasure: {}
};
export default MonthlyPortWaitingChart;
