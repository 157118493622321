import React from 'react';
import PropTypes from 'prop-types';
import { CountryFlag, Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import ValueDisplay from '@components/ValueDisplay';
import CanalVesselPortCallsTable from '@components/Tables/CanalVesselPortCallsTable';
import { mockCanalPortCalls } from '../../../../../Sections/ShipAnalytics/CanalsOverviewSection/mock';
import CanalsOverviewMap from '@components/Maps/CanalsOverviewMap';

function CanalOverviewSection({
  loading,
  canalDeparturesData,
  canalArrivalsData,
  canalIntransitData
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item container spacing={2} xs={12}>
              <Grid item alignSelf={'center'}>
                <Typography variant={'h6'}>Overview</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Grid container direction={'column'} spacing={2}>
                <ValueDisplay title={'Name'} value={'portName'} loading={loading} />
                <ValueDisplay title={'Country'} loading={loading}>
                  <Stack spacing={1} direction={'row'}>
                    <CountryFlag size={20} countryCode={'isoAlpha2'} />
                    <Typography variant={'body2'}>{'country?.name'}</Typography>
                  </Stack>
                </ValueDisplay>
                <ValueDisplay
                  title={'Location(lat/lon)'}
                  //value={`${cpointlat}/${cpointlon}`}
                  loading={loading}
                />
                <ValueDisplay title={'UNLOCODE'} value={'unlocode'} loading={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <CanalsOverviewMap height={'40vh'} loading={loading} hideCanalList={true} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CanalVesselPortCallsTable
          assetLevel={'canal'}
          canalDeparturesData={canalDeparturesData}
          hideColumns={[
            'Speed',
            'Heading',
            'Estimated Time of Arrival',
            'Destination port name',
            'Time',
            'ETA',
            'Destination LOCODE',
            'Canal Arrival'
          ]}
          title={'Recent Departures'}
          loading={loading}
          name={'portName'}
        />
      </Grid>
      <Grid item xs={12}>
        <CanalVesselPortCallsTable
          assetLevel={'canal'}
          canalDeparturesData={canalArrivalsData}
          hideColumns={[
            'Estimated Time of Arrival',
            'Origin',
            'Origin ATD',
            'Destination',
            'Arrival',
            'Departure',
            'Time in transit',
            'Destination LOCODE',
            'Canal arrival',
            'Direction to',
            'Destination ETA',
            'Destination ATA',
            'Canal Arrival'
          ]}
          title={'Expected Arrivals'}
          loading={loading}
          name={'portName'}
        />
      </Grid>
      <Grid item xs={12}>
        <CanalVesselPortCallsTable
          assetLevel={'canal'}
          mockCanalPortCalls={mockCanalPortCalls}
          hideColumns={[
            'Estimated Time of Arrival',
            'Arrival',
            'Departure',
            'Time in transit',
            'Direction to',
            'Origin',
            'Origin ATD',
            'Destination',
            'Destination ETA',
            'Destination ATA'
          ]}
          title={'In Transit'}
          loading={loading}
          name={'portName'}
        />
      </Grid>
    </Grid>
  );
}

CanalOverviewSection.propTypes = {
  lastPortCalls: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  upcomingPortCalls: PropTypes.arrayOf(PropTypes.object),
  maxDraughtLastPortCalls: PropTypes.object
};

CanalOverviewSection.defaultProps = {
  loading: false,
  upcomingPortCalls: [],
  maxDraughtLastPortCalls: {}
};

export default CanalOverviewSection;
