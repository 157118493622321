import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  CloseIcon,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography
} from '@esgian/esgianui';
import { OPERATOR_SELECT_TYPES } from '@constants';
import debounce from 'lodash.debounce';
import { useTheme } from '@hooks/useTheme';

function OperatorAdvancedMultiSelect({
  selected,
  placeholder,
  loading,
  label,
  operators,
  handleClose,
  autoEnabledText,
  selectType,
  autoButtonTitle,
  showAdvanceButton
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [tempSelectType, setTempSelectType] = useState(OPERATOR_SELECT_TYPES.AUTO);
  const [tempSelectedOpr, setTempSelectedOpr] = useState([]);
  const [queriedOperators, setQueriedOperators] = useState([]);
  const open = Boolean(anchorEl);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    setTempSelectType(selectType);
    setTempSelectedOpr(selected?.map(({ id }) => id) || []);
  }, [selected, selectType]);

  const selectedChips = useMemo(() => {
    if (!operators.length) return '';
    if (tempSelectType === OPERATOR_SELECT_TYPES.AUTO) {
      return <Chip disabled={loading} label={` ${autoButtonTitle}`} />;
    }
    if (!tempSelectedOpr?.length) return;
    const numItems = tempSelectedOpr.length;
    const notDisplayed = numItems - 1;
    const operator = operators.find(({ id }) => tempSelectedOpr[0] === id) || {};
    if (numItems > 1) {
      return (
        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
          {tempSelectedOpr.slice(0, 1).map((item, i) => (
            <Chip
              disabled={loading}
              size={'small'}
              key={`${operator.id}-${i}`}
              label={operator.operatorLongName}
            />
          ))}
          <Typography>+{notDisplayed}</Typography>
        </Stack>
      );
    }
    return <Chip disabled={loading} key={`${operator.id}`} label={operator.operatorLongName} />;
  }, [tempSelectedOpr, tempSelectType, operators]);

  const handleItemClick = useCallback(
    (val) => {
      let newArray = [...tempSelectedOpr];
      if (tempSelectedOpr.includes(val)) {
        newArray = newArray.filter((item) => item !== val);
      } else {
        newArray.push(val);
      }
      setTempSelectedOpr(newArray);
    },
    [tempSelectedOpr]
  );

  const handleSelectAll = () => {
    const allIds = operators.map(({ id }) => id);
    setTempSelectedOpr(allIds);
  };

  const handleClearAll = () => {
    setTempSelectedOpr([]);
  };

  const debouncedFetchData = debounce((cb) => {
    let opr = operators;
    if (searchQuery !== '') {
      opr = operators.filter(({ operatorLongName }) => {
        return operatorLongName.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    cb(opr);
  }, 200);

  useEffect(() => {
    debouncedFetchData((res) => {
      setQueriedOperators(res);
    });
  }, [searchQuery, operators]);

  return (
    <div id={'operator-select'}>
      <TextField
        disabled={loading}
        label={!loading ? label : <CircularProgress size={25} />}
        autoComplete={'off'}
        type={'search'}
        sx={{ minWidth: '20em', maxWidth: '20em' }}
        placeholder={placeholder}
        onClick={({ currentTarget }) => {
          if (!loading) {
            setAnchorEl(currentTarget);
          }
        }}
        value={''}
        InputProps={{
          startAdornment: selectedChips,
          endAdornment: (
            <IconButton
              disabled={loading}
              onClick={() => {
                setTempSelectedOpr([]);
              }}
              sx={{ visibility: tempSelectedOpr.length ? 'visible' : 'hidden' }}>
              <CloseIcon />
            </IconButton>
          )
        }}
      />
      <Popover
        onClose={() => {
          setAnchorEl(undefined);
          const oprs = [...operators]?.filter(({ id }) => tempSelectedOpr?.includes(id));
          handleClose({
            operatorType: tempSelectType,
            selectedOperators: oprs
          });
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        anchorEl={anchorEl}>
        <Paper elevation={5} sx={{ minWidth: '20em', maxWidth: '20em' }}>
          <Stack>
            <ButtonGroup disableElevation size={'large'} fullWidth sx={{ width: '100%' }}>
              <Button
                size={'small'}
                onClick={() => {
                  setTempSelectType(OPERATOR_SELECT_TYPES.AUTO);
                  setTempSelectedOpr([]);
                }}
                variant={tempSelectType === OPERATOR_SELECT_TYPES.AUTO ? 'contained' : 'outlined'}
                sx={{ borderRadius: 0 }}
                fullWidth>
                {autoButtonTitle}
              </Button>
              <Button
                size={'small'}
                onClick={() => {
                  setTempSelectType(OPERATOR_SELECT_TYPES.MANUAL);
                }}
                variant={tempSelectType === OPERATOR_SELECT_TYPES.MANUAL ? 'contained' : 'outlined'}
                sx={{ borderRadius: 0 }}
                fullWidth>
                Manual
              </Button>
            </ButtonGroup>
            {tempSelectType === OPERATOR_SELECT_TYPES.AUTO ? (
              <Typography variant={'body2'} sx={{ p: 2 }} alignSelf={'center'}>
                {autoEnabledText}
              </Typography>
            ) : (
              <Stack>
                {showAdvanceButton && (
                  <>
                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ p: 2 }}>
                      <Grid item xs={6}>
                        <Button fullWidth onClick={handleSelectAll}>
                          Check all
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button fullWidth onClick={handleClearAll}>
                          Clear
                        </Button>
                      </Grid>
                    </Stack>
                  </>
                )}

                <Divider />
                <TextField
                  value={searchQuery}
                  onChange={({ target }) => setSearchQuery(target.value)}
                  sx={{ p: 2 }}
                  size={'small'}
                  placeholder={'Search...'}
                />
                <Divider />
                <List
                  sx={{
                    maxHeight: '40vh',
                    overflowX: 'auto',
                    minWidth: '20em',
                    maxWidth: '20em',
                    ...customScrollbar
                  }}>
                  {queriedOperators?.map(({ id, operatorLongName }) => (
                    <ListItemButton key={`opr-${id}-filter`} onClick={() => handleItemClick(id)}>
                      <ListItemIcon sx={{ minWidth: '20px' }}>
                        <Checkbox
                          size={'small'}
                          onChange={() => handleItemClick(id)}
                          checked={tempSelectedOpr.includes(id)}
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body2' }}
                        primary={operatorLongName}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Popover>
    </div>
  );
}

OperatorAdvancedMultiSelect.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoEnabledText: PropTypes.string,
  autoButtonTitle: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.object),
  operators: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  selectType: PropTypes.oneOf(Object.values(OPERATOR_SELECT_TYPES)),
  showAdvanceButton: bool
};

OperatorAdvancedMultiSelect.defaultProps = {
  autoButtonTitle: 'Top 10',
  selectType: OPERATOR_SELECT_TYPES.AUTO,
  label: '',
  autoEnabledText: 'Top 10 enabled',
  selected: [],
  operators: [],
  loading: false,
  placeholder: undefined,
  showAdvanceButton: false
};

export default OperatorAdvancedMultiSelect;
