import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  NotFound,
  Paper,
  Stack,
  Typography,
  Box,
  DateRangeMobi
} from '@esgian/esgianui';
import { UpcomingVesselPortCallsTable, VesselPortCallsTable } from '@components/Tables';
import { OperatorActivityProfileChart, OperatorAvgSpeedChart } from './index';
import { TextWithTooltipIcon } from '@components';
import moment from 'moment';
import { useSegment } from '@hooks/useSegment';
import { useParams } from 'react-router-dom';
import { getAverageMonthlySpeed, getMonthlyPercentActivityStatus } from '@api';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { DATE_FORMAT } from '@constants';

function OperatorActivitySection({
  setPortCallData,
  lastPortCalls,
  operatorData,
  loading,
  oprUpcomingPortCallsData
}) {
  const { operatorShortName } = operatorData || {};
  const { segment, segmentMinDate } = useSegment();
  const params = useParams();

  const [activityDates, setActivityDates] = useState({
    start: moment().subtract(1, 'year').startOf('month'),
    end: moment()
  });
  const [speedDates, setSpeedDates] = useState({
    start: moment().subtract(1, 'year').startOf('month'),
    end: moment()
  });
  const [loadingActivityData, setLoadingActivityData] = useState(true);
  const [loadingSpeedData, setLoadingSpeedData] = useState(true);
  const [avgSpeedData, setAvgSpeedData] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getActivityData = async () => {
      setLoadingActivityData(true);
      try {
        getMonthlyPercentActivityStatus(
          {
            OperatorId: params.id,
            SegmentTypeId: segment.id,
            StartDate: moment(activityDates.start).format(DATE_FORMAT),
            EndDate: moment(activityDates.end).format(DATE_FORMAT),
            OperatorTypeId: operatorType,
            CommercialCategoryId: commercialCategoryType
          },
          signal
        )
          .then((result) => {
            setActivityData(result);
            setLoadingActivityData(false);
          })
          .catch(() => {
            setLoadingActivityData(false);
            setActivityData([]);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (activityDates.start.isValid() && activityDates.end.isValid()) {
      getActivityData();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [activityDates, commercialCategoryType]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getSpeedData = async () => {
      setLoadingSpeedData(true);
      try {
        getAverageMonthlySpeed(
          {
            OperatorId: params.id,
            SegmentTypeId: segment.id,
            StartDate: moment(speedDates.start).format(DATE_FORMAT),
            EndDate: moment(speedDates.end).format(DATE_FORMAT),
            OperatorTypeId: operatorType,
            CommercialCategoryId: commercialCategoryType
          },
          signal
        )
          .then((result) => {
            setLoadingSpeedData(false);
            setAvgSpeedData(result);
          })
          .catch(() => {
            setAvgSpeedData([]);
            setLoadingSpeedData(false);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (speedDates.start.isValid() && speedDates.end.isValid()) {
      getSpeedData();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [speedDates, commercialCategoryType]);

  const handleDateSelect = useCallback((val, chart) => {
    if (val[0].isValid()) {
      if (chart === 'activity') {
        setActivityDates((prevState) => ({ ...prevState, start: val[0].startOf('month') }));
      } else {
        setSpeedDates((prevState) => ({ ...prevState, start: val[0].startOf('month') }));
      }
    }
    if (val[1].isValid()) {
      if (val[1].isSameOrAfter(moment(), 'month', '[]')) {
        if (chart === 'activity') {
          setActivityDates((prevState) => ({ ...prevState, end: moment() }));
        } else {
          setSpeedDates((prevState) => ({ ...prevState, end: moment() }));
        }
      } else {
        if (chart === 'activity') {
          setActivityDates((prevState) => ({ ...prevState, end: val[1].endOf('month') }));
        } else {
          setSpeedDates((prevState) => ({ ...prevState, end: val[1].endOf('month') }));
        }
      }
    }
  }, []);

  const noData = useMemo(() => {
    return !avgSpeedData?.length && !activityData?.length && !operatorData?.length;
  }, [avgSpeedData, activityData, operatorData]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!loading && noData && (
            <Grid item xs={12}>
              <NotFound
                show={true}
                header={'No Data to load'}
                text={'Please try refreshing the page'}
              />
            </Grid>
          )}
          {(!noData || loading) && (
            <>
              <Grid item xs={6}>
                <Paper sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <Stack>
                      <Typography variant={'h6'}>Average speed</Typography>
                      <Box sx={{ width: '10em' }}>
                        <DateRangeMobi
                          sx={{
                            inputProps: {
                              InputProps: {
                                sx: { fontSize: '0.875rem' }
                              },
                              variant: 'standard',
                              defaultValue: undefined,
                              value:
                                speedDates.start || speedDates.end
                                  ? `${
                                      speedDates.start
                                        ? moment(speedDates.start).format('MMM YYYY')
                                        : ''
                                    } - ${
                                      speedDates.end
                                        ? moment(speedDates.end).format('MMM YYYY')
                                        : ''
                                    }`
                                  : null
                            }
                          }}
                          onClose={({ value }) => {
                            handleDateSelect(value, 'speed');
                          }}
                          dateFormat={'MMM YYYY'}
                          minStartDate={segmentMinDate}
                          disableFuture
                          startDate={speedDates.start}
                          endDate={speedDates.end}
                          placeholder={'Period'}
                          dateWheels={'MMM YYYY'}
                        />
                      </Box>
                    </Stack>
                    <Divider />
                    <OperatorAvgSpeedChart
                      loading={loadingSpeedData}
                      avgSpeedData={avgSpeedData}
                      fileName={`${operatorShortName}-average-speed`}
                    />
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <Stack>
                      <TextWithTooltipIcon
                        label={<Typography variant={'h6'}>Activity profile</Typography>}
                        tooltipText={
                          <Stack>
                            <Typography variant={'caption'}>
                              Transit: Vessel Speed &gt; 3 kn
                            </Typography>
                            <Typography variant={'caption'}>
                              Standby: 0 kn &lt; Vessel Speed &le; 3 kn
                            </Typography>
                            <Typography variant={'caption'}>At Port: Vessel at port</Typography>
                          </Stack>
                        }
                      />
                      <Box sx={{ width: '10em' }}>
                        <DateRangeMobi
                          sx={{
                            inputProps: {
                              InputProps: {
                                sx: { fontSize: '0.875rem' }
                              },
                              variant: 'standard',
                              defaultValue: undefined,
                              value:
                                activityDates.start || activityDates.end
                                  ? `${
                                      activityDates.start
                                        ? moment(activityDates.start).format('MMM YYYY')
                                        : ''
                                    } - ${
                                      activityDates.end
                                        ? moment(activityDates.end).format('MMM YYYY')
                                        : ''
                                    }`
                                  : null
                            }
                          }}
                          onClose={({ value }) => {
                            handleDateSelect(value, 'activity');
                          }}
                          dateFormat={'MMM YYYY'}
                          minStartDate={segmentMinDate}
                          disableFuture
                          startDate={activityDates.start}
                          endDate={activityDates.end}
                          placeholder={'Period'}
                          dateWheels={'MMM YYYY'}
                        />
                      </Box>
                    </Stack>
                    <Divider />
                    <OperatorActivityProfileChart
                      activityStatusData={activityData}
                      loading={loadingActivityData}
                      fileName={`${operatorShortName}-activity-profile`}
                    />
                  </Stack>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <VesselPortCallsTable
          assetLevel={'operator'}
          name={operatorShortName}
          hideColumns={['operatorName', 'countryName']}
          setPortCallData={setPortCallData}
          portCalls={lastPortCalls}
          loading={loading.lastPortCall}
          title={'Most Recent Vessels Port Calls'}
        />
      </Grid>
      <Grid item xs={12}>
        <UpcomingVesselPortCallsTable
          assetLevel={'operator'}
          title={'Upcoming Vessels Port Calls'}
          upcomingPortCalls={oprUpcomingPortCallsData}
          name={operatorShortName}
        />
      </Grid>
    </>
  );
}

OperatorActivitySection.propTypes = {
  loading: PropTypes.object.isRequired,
  operatorData: PropTypes.object,
  fleetAgeData: PropTypes.arrayOf(PropTypes.object),
  lastPortCalls: PropTypes.arrayOf(PropTypes.object),
  setPortCallData: PropTypes.func.isRequired,
  oprUpcomingPortCallsData: PropTypes.arrayOf(PropTypes.object)
};

OperatorActivitySection.defaultProps = {
  loading: false,
  operatorData: null,
  fleetAgeData: [],
  lastPortCalls: [],
  oprUpcomingPortCallsData: []
};

export default OperatorActivitySection;
