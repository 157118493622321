import React, { useState, useEffect } from 'react';
import {
  Grid,
  PageHeader,
  Typography,
  CircularProgress,
  Box,
  AssuredWorkloadOutlinedIcon
} from '@esgian/esgianui';
import VoyageAnalyticsFilters from '@components/Sections/ShipAnalytics/VoyageAnalyticsSection/VoyageAnalyticsFilters';
import CompletedVoyageSection from '@components/Sections/VoyageAnalytics/CompletedVoyageSection';
import { useSelector } from 'react-redux';
import { getCompletedVoyagesFilters } from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import { getVoyageSearchDetails } from '@api';
import AccreditationStatementModal from '@components/Modals/AccreditationStatementModal';
import VoyageGroupsPopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';
import { useSegment } from '@hooks';
import moment from 'moment';
import { useVoyageGroups } from '../hooks/useVoyageGroups';

function CompletedVoyagePage() {
  const [showStatement, setShowStatement] = useState(false);
  const [completedVoyagesData, setCompletedVoyagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [noData, setNoData] = useState(true);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [init, setInit] = useState(false);
  const reduxFilters = useSelector(getCompletedVoyagesFilters);
  const { segment } = useSegment();
  const { completedVoyagesGroups, prefetchTransitDetails } = useVoyageGroups();

  useEffect(() => {
    if (!completedVoyagesGroups || !completedVoyagesGroups.length) return;
    prefetchTransitDetails(
      completedVoyagesGroups.filter((g) => g.isFavorite && g.voyages.length > 0).map((g) => g.id)
    );
  }, [completedVoyagesGroups]);

  const checkValidFilters = (filters) => {
    return !!(
      filters &&
      filters.toId &&
      filters.fromId &&
      moment(filters.startDate).isValid() &&
      moment(filters.endDate).isValid()
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      if (!checkValidFilters(reduxFilters)) {
        setCompletedVoyagesData([]);
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        let body = {
          ...reduxFilters,
          toId: reduxFilters.toId.id ?? null,
          fromId: reduxFilters.fromId.id ?? null,
          segmentTypeId: segment.id
        };

        const response = await getVoyageSearchDetails(body, signal);
        const voyageSearchResults = response.voyageSearchResults || [];
        setCompletedVoyagesData(voyageSearchResults);
      } catch (error) {
        console.error('Error fetching voyage search results:', error);
        setCompletedVoyagesData([]);
      } finally {
        setLoading(false);
      }
    };

    if (shouldFetchData) {
      fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [shouldFetchData, reduxFilters, segment.id]);

  useEffect(() => {
    if (!init && checkValidFilters(reduxFilters)) {
      setShouldFetchData(true);
      setInit(true);
    }
  }, [init, reduxFilters]);

  return (
    <Box sx={{ minHeight: '90vh', width: '100%' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <PageHeader
            id={'lbl-voyage-analytics'}
            title={'Voyage analytics'}
            icon={
              <AssuredWorkloadOutlinedIcon
                fontSize={'small'}
                onClick={() => {
                  setShowStatement(true);
                }}
                style={{ cursor: 'pointer' }}
              />
            }
          />
        </Grid>
        <Grid item container xs={6} spacing={1} justifyContent="flex-end" alignItems="center">
          <VoyageGroupsPopup mode="search" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body2'}>Completed voyage</Typography>
        </Grid>
        <VoyageAnalyticsFilters
          checkValidFilters={checkValidFilters}
          handleApplyFilters={() => {
            setShouldFetchData(true);
          }}
        />

        {loading && (
          <Grid
            item
            xs={12}
            style={{
              minHeight: '400px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}>
            <CircularProgress
              sx={{
                position: 'absolute',
                top: 'calc(50% - 50px)',
                left: 'calc(50% - 50px)'
              }}
              size={100}
            />
          </Grid>
        )}

        {!loading && (
          <Grid
            item
            xs={12}
            style={{
              minHeight: '400px'
            }}>
            <CompletedVoyageSection loading={loading} completedVoyagesData={completedVoyagesData} />
          </Grid>
        )}

        <AccreditationStatementModal
          handleClose={() => setShowStatement(false)}
          open={showStatement}
        />
      </Grid>
    </Box>
  );
}

export default CompletedVoyagePage;
