import {
  Box,
  Button,
  Divider,
  EditIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  Grid,
  HelpOutlineIcon,
  IconButton,
  InfoOutlineIcon,
  Paper,
  Stack,
  Table,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpdateDataModal from './UpdateDataModal';
import { useTheme } from '@hooks';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  removeSpecialCharacters,
  removeWhitespace
} from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';

const TableWithSummary = ({
  columns,
  data,
  title,
  onUpdate,
  summary,
  tableOptions,
  detailsTableColumns,
  detailsTableData,
  detailsTableOptions,
  detailsTableTitle,
  secondaryDetailsTableColumns,
  secondaryDetailsTableData,
  secondaryDetailsTableOptions,
  secondaryDetailsTableTitle,
  isLoading,
  maxTableHeight,
  minTableHeight
}) => {
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const [hoveredRowColumn, setHoveredRowColumn] = useState();
  const [dataToUpdate, setDataToUpdate] = useState();
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const getTableColumns = (tableColumns, tableData) =>
    tableColumns.map((c) => ({
      name: c.name,
      label: c.label,
      options: {
        customBodyRender: (dataIndex, row) => {
          const cellData = tableData[row.rowIndex][c.name];
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onMouseEnter={() =>
                cellData?.isEditable && setHoveredRowColumn({ r: row.rowIndex, c: c.name })
              }
              onMouseLeave={() => cellData?.isEditable && setHoveredRowColumn(undefined)}>
              <Box display="flex" alignItems="center" height={'24px'}>
                {cellData?.value !== undefined && cellData?.value !== null ? (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">
                      {c.customCellValue ? c.customCellValue(cellData.value) : cellData.value}
                    </Typography>
                    {cellData?.tooltip && (
                      <ToolTip title={cellData.tooltip}>
                        <IconButton>
                          <InfoOutlineIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </ToolTip>
                    )}
                  </Box>
                ) : (
                  <Typography variant="body2">
                    {c.customCellValue ? c.customCellValue('--') : '--'}
                  </Typography>
                )}
                {cellData?.isEditable && (
                  <Box width="16px">
                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={() => {
                        setDataToUpdate({
                          rowIndex: row.rowIndex,
                          column: c.name,
                          value: cellData.value,
                          label: row.rowData[0] ?? ''
                        });
                      }}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          );
        },
        customHeadLabelRender: () => (
          <Box display="flex" alignItems="center" my={2}>
            <Typography>{c.label}</Typography>
            {c.tooltip ? (
              <ToolTip title={c.tooltip}>
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </ToolTip>
            ) : null}
          </Box>
        )
      }
    }));

  const defaultTableColumns = getTableColumns(columns, data);

  const primaryExpandedTableColumns =
    detailsTableColumns && detailsTableData
      ? getTableColumns(detailsTableColumns, detailsTableData)
      : undefined;
  const secondaryExpandedTableColumns =
    secondaryDetailsTableColumns && secondaryDetailsTableData
      ? getTableColumns(secondaryDetailsTableColumns, secondaryDetailsTableData)
      : undefined;

  const getTableData = (data) =>
    data.map((d) => Object.keys(d).reduce((acc, curr) => ({ ...acc, [curr]: d[curr].value }), {}));

  const defaultTableData = getTableData(data);

  const primaryExpandedTableData = detailsTableData ? getTableData(detailsTableData) : undefined;
  const secondaryExpandedTableData = secondaryDetailsTableData
    ? getTableData(secondaryDetailsTableData)
    : undefined;

  const defaultTableOptions = {
    search: false,
    print: false,
    filter: false,
    draggable: false,
    sort: false,
    viewColumns: false,
    selectableRows: false,
    customFooter: () => null,
    elevation: 0,
    responsive: 'standard',
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `${removeSpecialCharacters(removeWhitespace(title))}.csv`
    },
    ...(tableOptions ?? {})
  };

  const primaryExpandedTableOptions = { ...defaultTableOptions, ...detailsTableOptions };
  const secondaryExpandedTableOptions = { ...defaultTableOptions, ...secondaryDetailsTableOptions };

  const defaultTableStyle = {
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    }
  };

  const primaryTableCustomStyles = {
    ...defaultTableStyle,
    MUIDataTable: {
      styleOverrides: {
        responsiveBase: {
          minHeight: minTableHeight,
          maxHeight: maxTableHeight
        }
      }
    },
    ...(summary && summary.length > 0
      ? {
          MUIDataTableToolbar: {
            styleOverrides: {
              root: {
                minHeight: '0px',
                height: '0px'
              },
              actions: {
                '&:last-child': {
                  position: 'absolute',
                  top: '-120px',
                  right: '0px'
                }
              }
            }
          },
          MUIDataTableFilterList: {
            styleOverrides: {
              root: {
                minHeight: '0px',
                height: '0px'
              }
            }
          }
        }
      : {})
  };

  return (
    <>
      <Paper
        sx={{
          padding: 2,
          height: '100%'
        }}>
        <Stack spacing={2}>
          {summary && summary.length > 0 && (
            <Stack sx={{ mt: 2 }}>
              <Typography variant={'subtitle1'}>{title}</Typography>
            </Stack>
          )}
          <Stack spacing={2}>
            {summary && summary.length > 0 && (
              <Grid container sx={{ textAlign: 'center' }}>
                {summary.map((s, i) => (
                  <>
                    <Grid item xs>
                      <Typography bold>{s.value ?? '--'}</Typography>
                      <Typography variant={'caption'}>{s.label}</Typography>
                    </Grid>
                    {i < summary.length - 1 && (
                      <div>
                        <Divider orientation={'vertical'} />
                      </div>
                    )}
                  </>
                ))}
              </Grid>
            )}

            <Table
              columns={defaultTableColumns}
              data={defaultTableData}
              mode={theme.mode}
              options={defaultTableOptions}
              title={summary?.length ? null : title}
              customStyle={primaryTableCustomStyles}
              loading={isLoading}
            />
          </Stack>
        </Stack>
      </Paper>

      {isTableExpanded && primaryExpandedTableColumns && primaryExpandedTableData && (
        <Paper
          sx={{
            padding: 2,
            height: '100%',
            marginY: 2
          }}>
          <Table
            columns={primaryExpandedTableColumns}
            data={primaryExpandedTableData}
            mode={theme.mode}
            options={primaryExpandedTableOptions}
            title={<Typography variant={'body1'}>{detailsTableTitle ?? 'Details'}</Typography>}
            customStyle={defaultTableStyle}
            loading={isLoading}
          />
        </Paper>
      )}
      {isTableExpanded && secondaryExpandedTableColumns && secondaryExpandedTableData && (
        <Paper
          sx={{
            padding: 2,
            height: '100%',
            marginY: 2
          }}>
          <Table
            columns={secondaryExpandedTableColumns}
            data={secondaryExpandedTableData}
            mode={theme.mode}
            options={secondaryExpandedTableOptions}
            title={
              <Typography variant={'body1'}>{secondaryDetailsTableTitle ?? 'Details'}</Typography>
            }
            customStyle={defaultTableStyle}
            loading={isLoading}
          />
        </Paper>
      )}
      {detailsTableColumns && detailsTableColumns.length > 0 && detailsTableData && (
        <Box display="flex" justifyContent="center" py={2}>
          <Button
            sx={{ width: '180px' }}
            variant="outlined"
            onClick={() => {
              setIsTableExpanded((p) => !p);
            }}
            endIcon={
              isTableExpanded ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )
            }>
            {isTableExpanded ? 'Hide' : 'Show'} Details
          </Button>
        </Box>
      )}

      {dataToUpdate && (
        <UpdateDataModal
          content={{ value: dataToUpdate.value, label: dataToUpdate.label }}
          onClose={() => {
            setDataToUpdate(undefined);
          }}
          onSubmit={(newValue) => {
            onUpdate && onUpdate(dataToUpdate.rowIndex, dataToUpdate.column, newValue);
            setDataToUpdate(undefined);
          }}
        />
      )}
    </>
  );
};

const tableColumnPropsShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  customCellValue: PropTypes.func
});

const tableDataPropsShape = PropTypes.shape(
  PropTypes.objectOf({
    label: PropTypes.string.isRequired,
    value: PropTypes.number,
    tooltip: PropTypes.string,
    isEditable: PropTypes.bool
  })
);

TableWithSummary.propTypes = {
  columns: PropTypes.arrayOf(tableColumnPropsShape).isRequired,
  data: PropTypes.arrayOf(tableDataPropsShape).isRequired,
  title: PropTypes.string,
  onUpdate: PropTypes.func,
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  tableOptions: PropTypes.object,
  detailsTableColumns: PropTypes.arrayOf(tableColumnPropsShape),
  detailsTableData: PropTypes.arrayOf(tableDataPropsShape),
  detailsTableOptions: PropTypes.object,
  detailsTableTitle: PropTypes.string,
  secondaryDetailsTableTitle: PropTypes.string,
  secondaryDetailsTableColumns: PropTypes.arrayOf(tableColumnPropsShape),
  secondaryDetailsTableData: PropTypes.arrayOf(tableDataPropsShape),
  secondaryDetailsTableOptions: PropTypes.object,
  isLoading: PropTypes.bool,
  minTableHeight: PropTypes.string,
  maxTableHeight: PropTypes.string
};

export default TableWithSummary;
