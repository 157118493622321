import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useSegment } from '@hooks';
import moment from 'moment';
import { getVoyageSearchDetails } from '@api';

const ONGOING_VOYAGE_FILTER_QUERY_KEY = ['ongoingVoyageFilter'];

export const useOngoingVoyageFilter = () => {
  const { segment } = useSegment();
  const searchParamsRef = useRef();

  const {
    data: searchResults = [],
    isFetching: isLoading,
    isFetched,
    refetch
  } = useQuery({
    queryKey: [ONGOING_VOYAGE_FILTER_QUERY_KEY],
    queryFn: async ({ signal }) => {
      const response = await getVoyageSearchDetails(searchParamsRef.current, signal);
      const voyageSearchResults = response.voyageSearchResults || [];
      return voyageSearchResults;
    },
    enabled: false
  });

  const trigger = (reduxFilters) => {
    let body = {
      ...reduxFilters,
      segmentTypeId: segment.id,
      fromId: reduxFilters.fromId ? reduxFilters.fromId.id : null,
      toId: null,
      fromType: null,
      toType: null,
      startDate: moment(reduxFilters.startDate || moment()).format('YYYY-MM-DD HH:mm:ss'),
      endDate: null,
      minTransitTime: null,
      maxTransitTime: null,
      operatorIds: null
    };
    searchParamsRef.current = body;
    refetch();
  };

  return { trigger, isLoading, searchResults, isFetched };
};
