import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { formatNumberReadable } from '@helpers';

const getFuelData = (fuelType, value) => ({
  fuelType: {
    value: fuelType
  },
  consumption: {
    value: formatNumberReadable(value.toFixed(0))
  }
});

const FuelConsumptionTable = ({ fuelDetails, isLoading }) => {
  const columns = [
    {
      label: 'Fuel type',
      name: 'fuelType'
    },
    {
      label: 'Consumption(t)',
      name: 'consumption'
    }
  ];

  const getTableData = () => {
    if (!fuelDetails) {
      return [];
    }
    const { fuelConsumption, fuelTypeIdsFuelNames } = fuelDetails;
    const data = Object.entries(fuelConsumption).map(([key, value]) =>
      getFuelData(fuelTypeIdsFuelNames[key], value)
    );

    return data;
  };

  return (
    <TableWithSummary
      title="Fuel consumption"
      columns={columns}
      data={getTableData()}
      tableOptions={{
        search: true,
        filter: true
      }}
      isLoading={isLoading}
    />
  );
};

FuelConsumptionTable.propTypes = {
  fuelDetails: PropTypes.object,
  isLoading: PropTypes.bool
};

export default FuelConsumptionTable;
