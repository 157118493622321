import React, { useState } from 'react';
import { Button, DateRangeMobi, Grid, TextField, Stack } from '@esgian/esgianui';
import moment from 'moment';
import LocationLoadAndDischargeSelect from '@components/Inputs/LocationLoadAndDischargeSelect/LocationLoadAndDischargeSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  getServiceLevelFilters,
  updateServiceLevelFilters
} from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import { getDateFormat, getLookupOperators } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSegment } from '@hooks';
import OperatorAdvancedMultiSelect from '@components/Inputs/OperatorAdvancedSelect';
import { TextWithTooltipIcon } from '@components';
import { tooltipServicesContents } from '@tooltipContents/filterTooltipContents/MinNoServicesTooltip/MinNoServicesTooltip';
import { tooltipTransitContents } from '@tooltipContents/filterTooltipContents/MaxTransitTimeTooltip';

function PortServiceLevelFilters() {
  const dispatch = useDispatch();
  const serviceLevelFilters = useSelector(getServiceLevelFilters);
  const [tempFilters, setTempFilters] = useState(serviceLevelFilters);
  const dateFormat = useSelector(getDateFormat);
  const { segmentMinDate } = useSegment();
  const lookupOperators = useSelector(getLookupOperators);

  const updateFilterValue = (payload) => {
    setTempFilters((prevFilters) => ({ ...prevFilters, ...payload }));
  };

  const handleApply = () => {
    dispatch(updateServiceLevelFilters(tempFilters));
  };

  const {
    startDate,
    endDate,
    selectedLocationLoad,
    selectedLocationDischarge,
    selectedOperators,
    maxTransitTime,
    minServices,
    selectType
  } = tempFilters;

  const checkValidFilters = selectedLocationLoad && startDate && endDate && maxTransitTime;

  return (
    <Grid item xs={12}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <LocationLoadAndDischargeSelect
            selectedLocationOfDischarge={selectedLocationDischarge}
            setSelectedLocationOfDischarge={(val) =>
              updateFilterValue({ selectedLocationDischarge: val })
            }
            selectedLocationOfLoad={selectedLocationLoad}
            setSelectedLocationOfLoad={(val) => updateFilterValue({ selectedLocationLoad: val })}
            handleSwap={() => {
              updateFilterValue({
                selectedLocationLoad: selectedLocationDischarge,
                selectedLocationDischarge: selectedLocationLoad
              });
            }}
          />
          <Stack className={'section-filter'}>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              label={'Period*'}
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <OperatorAdvancedMultiSelect
              selected={selectedOperators}
              autoEnabledText={'Displaying the top 5 operators given the selected period'}
              handleClose={(values) => {
                updateFilterValue({
                  selectType: values.operatorType,
                  selectedOperators: values.selectedOperators
                });
              }}
              loading={!lookupOperators}
              autoButtonTitle={'Top 5'}
              operators={lookupOperators}
              selectType={selectType}
              label={'Operators*'}
              showAdvanceButton={true}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <TextField
              label={
                <TextWithTooltipIcon
                  label="Max Transit Time (Days)"
                  tooltipText={tooltipTransitContents}
                  labelVariant={'inherit'}
                  labelBold={false}
                  color={'inherit'}
                />
              }
              sx={{ width: '175px' }}
              placeholder={'Max transit time...'}
              type={'number'}
              value={maxTransitTime || ' '}
              onChange={({ target }) => updateFilterValue({ maxTransitTime: target.value })}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <TextField
              label={
                <TextWithTooltipIcon
                  label="Min no. of Services"
                  tooltipText={tooltipServicesContents}
                  labelVariant={'inherit'}
                  labelBold={false}
                  color={'inherit'}
                />
              }
              sx={{ width: '185px' }}
              placeholder={'Min no. of services...'}
              type={'number'}
              value={minServices || ' '}
              onChange={({ target }) => updateFilterValue({ minServices: target.value })}
            />
          </Stack>

          <Button
            onClick={handleApply}
            variant={'contained'}
            color={'primary'}
            disabled={!checkValidFilters}
            sx={{ height: 'min-content' }}>
            Apply Filters
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}

export default PortServiceLevelFilters;
