import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { TextWithTooltipIcon } from '@components';
import {
  ESTIMATED_AVG_EU_ETS_COST_TEXT,
  OVERALL_VOYAGE_PHASE_IN_FACTOR,
  OVERALL_VOYAGE_EU_ALLOWANCES
} from '@constants/tooltips';
import { getValueUptoOneDecimalPlace } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';
import { formatNumberReadable } from '@helpers';

const VoyagesEstimatedEtsCostTable = ({
  overallVoyageEuEtsAllowanceSummary,
  overallVoyageEuEtsAllowanceCost,
  isLoading
}) => {
  const [summaryValue, setSummaryValue] = useState({
    voyageEtsCost: '--',
    estimatedEtsCostPerTonCargo: '--'
  });
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);

  const columns = [
    { name: 'items', label: 'Items' },
    {
      name: 'amount',
      label: 'Amount'
    }
  ];

  const detailsColumns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'co2Emission',
      label: 'CO₂ Emission'
    },
    {
      name: 'applicability',
      label: 'Applicability',
      tooltip: OVERALL_VOYAGE_EU_ALLOWANCES
    },
    {
      name: 'phaseInFactor',
      label: 'Phase in factor',
      tooltip: OVERALL_VOYAGE_PHASE_IN_FACTOR
    },
    {
      name: 'etsAllowance',
      label: 'EU Allowances (EUA)',
      tooltip:
        'EU Allowances (EUAs) are a type of carbon allowance that allows companies covered by the EU ETS to emit a certain amount of  CO₂e. EUAs can be bought and sold on the market, and the variable market price of EUAs reflects the cost of reducing emissions.'
    },
    {
      name: 'etsCostPerTCo2Euro',
      label: 'EU ETS Cost per Tonne CO₂ (€)'
    },
    {
      name: 'estimatedEtsCostEuro',
      label: 'EU ETS Cost (€)'
    }
  ];
  useEffect(() => {
    if (!overallVoyageEuEtsAllowanceCost) {
      return;
    }
    setDetailsData(
      overallVoyageEuEtsAllowanceCost.voyageLegEuEtsAllowanceCosts.map((d, i) => ({
        voyageLeg: { value: `#${i + 1}` },
        origin: { value: d.fromPortName },
        destination: { value: d.toPortName },
        co2Emission: { value: formatNumberReadable(d.co2Tn?.toFixed(0)) },
        applicability: { value: `${d.applicabilityFactor ?? '--'}%` },
        phaseInFactor: { value: d.phaseInFactor },
        etsAllowance: { value: formatNumberReadable(d.euEtsAllowanceTn?.toFixed(1)) },
        etsCostPerTCo2Euro: { value: d.etsCostPerTonCo2?.toFixed(2) },
        estimatedEtsCostEuro: { value: formatNumberReadable(d.estimatedEtsCost?.toFixed(0)) }
      }))
    );
  }, [overallVoyageEuEtsAllowanceCost]);

  useEffect(() => {
    if (!overallVoyageEuEtsAllowanceSummary) return;
    setSummaryValue({
      voyageEtsCost: overallVoyageEuEtsAllowanceSummary.voyageEtsCost,
      estimatedEtsCostPerTonCargo: overallVoyageEuEtsAllowanceSummary.estimatedEtsCostPerTonCargo
    });

    setData([
      {
        items: { value: 'Average Cargo in the voyage  (T)' },
        amount: {
          value: formatNumberReadable(
            overallVoyageEuEtsAllowanceSummary?.avgCargoWeightTn?.toFixed(0)
          )
        }
      },
      {
        items: { value: 'Total EU ETS cost for voyage (€)' },
        amount: {
          value: formatNumberReadable(overallVoyageEuEtsAllowanceSummary?.voyageEtsCost?.toFixed(0))
        }
      },
      {
        items: { value: 'Total EU ETS Allowances for the voyage (T)' },
        amount: {
          value: formatNumberReadable(overallVoyageEuEtsAllowanceSummary?.totalCo2Tn?.toFixed(0))
        }
      },
      {
        items: {
          value: 'EU ETS cost per tonne cargo (€)',
          tooltip: ESTIMATED_AVG_EU_ETS_COST_TEXT
        },
        amount: {
          value: Number(overallVoyageEuEtsAllowanceSummary?.estimatedEtsCostPerTonCargo).toFixed(1)
        }
      },
      {
        items: { value: 'EU Allowances for the voyage' },
        amount: {
          value: formatNumberReadable(
            overallVoyageEuEtsAllowanceSummary?.totalEuEtsAllowanceTn?.toFixed(1)
          )
        }
      }
    ]);
  }, [overallVoyageEuEtsAllowanceSummary]);

  const totalRow = {
    voyageLeg: { value: `Total` },
    origin: { value: '-' },
    destination: { value: '-' },
    co2Emission: {
      value: formatNumberReadable(overallVoyageEuEtsAllowanceCost?.totalCo2Tn?.toFixed(0)) ?? '-'
    },
    applicability: { value: '-' },
    phaseInFactor: { value: '-' },
    etsAllowance: {
      value:
        formatNumberReadable(overallVoyageEuEtsAllowanceCost?.totalEtsAllowanceTn?.toFixed(1)) ??
        '-'
    },
    etsCostPerTCo2Euro: { value: '-' },
    estimatedEtsCostEuro: {
      value:
        formatNumberReadable(overallVoyageEuEtsAllowanceCost?.totalEstimatedEtsCost?.toFixed(0)) ??
        '--'
    }
  };

  const summary = [
    {
      label: 'EU ETS cost for the overall voyage (€)',
      value: formatNumberReadable(Number(summaryValue?.voyageEtsCost).toFixed(0))
    },
    {
      label: (
        <TextWithTooltipIcon
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.1em'
          }}
          label="EU ETS cost per tonne cargo (€)"
          tooltipText={ESTIMATED_AVG_EU_ETS_COST_TEXT}
        />
      ),
      value: getValueUptoOneDecimalPlace(summaryValue?.estimatedEtsCostPerTonCargo)
    }
  ];

  return (
    <TableWithSummary
      title="Overall voyage"
      columns={columns}
      data={data}
      onUpdate={(rowIndex, columnName, newValue) => {
        const newData = [...data];
        newData[rowIndex][columnName].value = +newValue;
        setData(newData);
      }}
      summary={summary}
      detailsTableTitle=""
      detailsTableColumns={detailsColumns}
      detailsTableData={[...detailsData, totalRow]}
      isLoading={isLoading}
    />
  );
};

VoyagesEstimatedEtsCostTable.propTypes = {
  overallVoyageEuEtsAllowanceSummary: PropTypes.object,
  overallVoyageEuEtsAllowanceCost: PropTypes.object,
  isLoading: PropTypes.bool
};

export default VoyagesEstimatedEtsCostTable;
