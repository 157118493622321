export const mockCanalsMapData = [
  {
    id: 14,
    name: 'Bian Canal',

    shortName: 'AFREC',
    canalId: 60,
    polygon:
      'POLYGON ((54.857429 11.7750654, 51.4997604 12.4559124, 47.9910197 11.8932514, 44.2651502 11.2399493, 43.3132246 12.5459383, 40.6987172 15.4614313, 39.3907097 17.6101852, 34.1813672 26.740458, 31.69916 29.4558584, 29.0798047 -26.5165597, 32.715905 -27.6285074, 37.70550792037402 -28.64697112854939, 42.25238581594628 -10.27895982767911, 54.857429 11.7750654))',
    lat: -0.86516434,
    long: 37.824535
  },
  {
    id: 16,
    name: 'Grand Canal',
    shortName: 'AFRS',
    canalId: 62,
    polygon:
      'POLYGON ((5.5378828 -29.4698076, 12.6442578 -41.5799249, 24.9380273 -42.9545261, 43.2301953 -42.8169775, 45.866914 -30.6064952, 37.70550792037402 -28.64697112854939, 32.715905 -27.6285074, 23.3229687 -27.6317296, 20.1418785 -28.128566, 5.5378828 -29.4698076))',
    lat: -35.208183,
    long: 26.936436
  },
  {
    id: 3,
    name: 'Hongqi Canal',
    shortName: 'AFRWC',
    canalId: 49,
    polygon:
      'POLYGON ((-6.239461 34.3692124, -5.8055493 35.8030199, -13.1828203 36.9397243, -25.9221469 26.9696163, -25.1325869 12.5427917, -17.7806056 0.4459098, -1.8449297 -6.2405805, 5.5378828 -29.4698076, 20.1418785 -28.128566, 29.4441328 -19.0741417, -6.239461 34.3692124))',
    lat: 3.5428174,
    long: -0.30850774
  }
];

export const mockCanalData = [
  {
    id: 82,
    portName: 'Grand Canal',
    unlocode: 'ZAELS',
    active: true,
    cpointlat: 51.321465,
    cpointlon: 3.2136517,
    country: {
      id: 131,
      name: 'South Africa',
      isoAlpha3: 'BE',
      isoAlpha2: 'BEL',
      m49: 56
    },
    terminals: [
      {
        id: 1468,
        terminalName: 'Zeebrugge 5',
        terminalLat: 51.29678,
        terminalLong: 3.24142,
        radius: 1000,
        portId: 82
      },
      {
        id: 1465,
        terminalName: 'Zeebrugge 2',
        terminalLat: 51.32277,
        terminalLong: 3.21045,
        radius: 1000,
        portId: 82
      },
      {
        id: 1466,
        terminalName: 'Zeebrugge 3',
        terminalLat: 51.31637,
        terminalLong: 3.22542,
        radius: 1000,
        portId: 82
      },
      {
        id: 1467,
        terminalName: 'Zeebrugge 4',
        terminalLat: 51.30378,
        terminalLong: 3.22955,
        radius: 1000,
        portId: 82
      },
      {
        id: 3057,
        terminalName: 'Zeebrugge 7',
        terminalLat: 51.35234,
        terminalLong: 3.18059,
        radius: 1000,
        portId: 82
      },
      {
        id: 3056,
        terminalName: 'Zeebrugge 6',
        terminalLat: 51.33674,
        terminalLong: 3.19448,
        radius: 1200,
        portId: 82
      },
      {
        id: 3246,
        terminalName: 'Zeebrugge 8 (w)',
        terminalLat: 51.34485,
        terminalLong: 3.21934,
        radius: 1000,
        portId: 82
      },
      {
        id: 100267,
        terminalName: 'Zeebrugge 1 (g)',
        terminalLat: 51.322536,
        terminalLong: 3.193776,
        radius: 1000,
        portId: 82
      },
      {
        id: 100268,
        terminalName: 'Zeebrugge 2 (g)',
        terminalLat: 51.333427,
        terminalLong: 3.2177424,
        radius: 1000,
        portId: 82
      }
    ],
    countryName: 'South Africa',
    countryId: 316,
    isoAlpha2: null
  }
];

export const mockCanalPortCalls = [
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-07-24T04:25:28Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 86.8687,
    departureDraughtDateTime: '2024-07-24T09:11:51Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.6,
    percentDepartureDraught: 86.8687,
    imoDraught: 9.9,
    id: 29444760,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SUNLIGHT ACE',
    startDate: '2024-07-24T04:25:28',
    endDate: '2024-07-24T09:11:51',
    timeInPort: 0.1989,
    imo: 9338864,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-07-17T07:21:02Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.1,
    percentArrivalDraught: 81.00001,
    departureDraughtDateTime: '2024-07-17T14:52:12Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.1,
    percentDepartureDraught: 81.00001,
    imoDraught: 10,
    id: 29333357,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC AMERICA',
    startDate: '2024-07-17T07:21:02',
    endDate: '2024-07-17T14:52:12',
    timeInPort: 0.3133,
    imo: 9277802,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-07-16T04:50:24Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.4,
    percentArrivalDraught: 98.113205,
    departureDraughtDateTime: '2024-07-17T05:42:45Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.3,
    percentDepartureDraught: 97.16981,
    imoDraught: 10.6,
    id: 29324622,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'THEBEN',
    startDate: '2024-07-16T04:50:24',
    endDate: '2024-07-17T05:42:45',
    timeInPort: 1.0364,
    imo: 9722302,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-07-07T03:56:26Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8,
    percentArrivalDraught: 88.888885,
    departureDraughtDateTime: '2024-07-07T07:23:00Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8,
    percentDepartureDraught: 88.888885,
    imoDraught: 9,
    id: 29153240,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SILVER MOON',
    startDate: '2024-07-07T03:56:26',
    endDate: '2024-07-07T07:23:00',
    timeInPort: 0.1434,
    imo: 9448138,
    portId: 586,
    operatorId: 2804,
    operatorShortName: 'SALLAU',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-07-05T05:00:27Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8,
    percentArrivalDraught: 90.90909,
    departureDraughtDateTime: '2024-07-06T09:52:59Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.5,
    percentDepartureDraught: 96.590904,
    imoDraught: 8.8,
    id: 29137123,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'NEPTUNE BARCELONA',
    startDate: '2024-07-05T05:00:27',
    endDate: '2024-07-06T09:52:59',
    timeInPort: 1.2031,
    imo: 9762546,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-29T04:55:39Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 90.909096,
    departureDraughtDateTime: '2024-06-29T11:37:05Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.9,
    percentDepartureDraught: 89.89899,
    imoDraught: 9.9,
    id: 29030711,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'BRILLIANT ACE',
    startDate: '2024-06-29T04:55:39',
    endDate: '2024-06-29T11:37:05',
    timeInPort: 0.2788,
    imo: 9598012,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-28T05:15:11Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.5,
    percentArrivalDraught: 84.15841,
    departureDraughtDateTime: '2024-06-29T03:36:58Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 29023786,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TRIUMPH ACE',
    startDate: '2024-06-28T05:15:11',
    endDate: '2024-06-29T03:36:58',
    timeInPort: 0.9318,
    imo: 9209506,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-27T05:10:51Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 92.9293,
    departureDraughtDateTime: '2024-06-27T14:35:11Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.5,
    percentDepartureDraught: 95.9596,
    imoDraught: 9.9,
    id: 28997739,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TRANQUIL ACE',
    startDate: '2024-06-27T05:10:51',
    endDate: '2024-06-27T14:35:11',
    timeInPort: 0.3919,
    imo: 9561253,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-25T06:42:07Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 92,
    departureDraughtDateTime: '2024-06-26T15:55:54Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.4,
    percentDepartureDraught: 93.99999,
    imoDraught: 10,
    id: 28978993,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DIGNITY LEADER',
    startDate: '2024-06-25T06:42:07',
    endDate: '2024-06-26T15:55:54',
    timeInPort: 1.3846,
    imo: 9441506,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-25T04:50:14Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2024-06-26T04:07:03Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 89.10891,
    imoDraught: 10.1,
    id: 28970680,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUN',
    startDate: '2024-06-25T04:50:14',
    endDate: '2024-06-26T04:07:03',
    timeInPort: 0.97,
    imo: 9749568,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-21T14:25:24Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.3,
    percentArrivalDraught: 94.89796,
    departureDraughtDateTime: '2024-06-22T03:48:48Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.3,
    percentDepartureDraught: 94.89796,
    imoDraught: 9.8,
    id: 28905746,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'WISTERIA ACE',
    startDate: '2024-06-21T14:25:24',
    endDate: '2024-06-22T03:48:48',
    timeInPort: 0.5579,
    imo: 9355197,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-15T13:30:34Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10,
    percentArrivalDraught: 90.90909,
    departureDraughtDateTime: '2024-06-16T04:55:42Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 28798262,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'NABUCCO',
    startDate: '2024-06-15T13:30:34',
    endDate: '2024-06-16T04:55:42',
    timeInPort: 0.6425,
    imo: 9731652,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-14T05:55:58Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 7.7,
    percentArrivalDraught: 78.57143,
    departureDraughtDateTime: '2024-06-15T12:07:04Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 7.7,
    percentDepartureDraught: 78.57143,
    imoDraught: 9.8,
    id: 28789691,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE GABON',
    startDate: '2024-06-14T05:55:58',
    endDate: '2024-06-15T12:07:04',
    timeInPort: 1.2577,
    imo: 9437933,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-13T04:41:06Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 87.755104,
    departureDraughtDateTime: '2024-06-13T16:30:31Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.6,
    percentDepartureDraught: 87.755104,
    imoDraught: 9.8,
    id: 28756634,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CRYSTAL ACE',
    startDate: '2024-06-13T04:41:06',
    endDate: '2024-06-13T16:30:31',
    timeInPort: 0.4927,
    imo: 9539224,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-06-11T05:50:35Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 90,
    departureDraughtDateTime: '2024-06-12T03:57:14Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 90,
    imoDraught: 10,
    id: 28731812,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DONG-A GLAUCOS',
    startDate: '2024-06-11T05:50:35',
    endDate: '2024-06-12T03:57:14',
    timeInPort: 0.9213,
    imo: 9441867,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-28T13:36:21Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8,
    percentArrivalDraught: 90.90909,
    departureDraughtDateTime: '2024-05-29T04:40:41Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.3,
    percentDepartureDraught: 94.31818,
    imoDraught: 8.8,
    id: 28461193,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE SPAGNA',
    startDate: '2024-05-28T13:36:21',
    endDate: '2024-05-29T04:40:41',
    timeInPort: 0.628,
    imo: 9227924,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-27T11:26:58Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.8,
    percentArrivalDraught: 90.72165,
    departureDraughtDateTime: '2024-05-28T04:13:43Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 94.84536,
    imoDraught: 9.7,
    id: 28444469,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND EAGLE',
    startDate: '2024-05-27T11:26:58',
    endDate: '2024-05-28T04:13:43',
    timeInPort: 0.6991,
    imo: 9267663,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-25T05:25:13Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 7.9,
    percentArrivalDraught: 89.77273,
    departureDraughtDateTime: '2024-05-25T10:17:40Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 7.7,
    percentDepartureDraught: 87.5,
    imoDraught: 8.8,
    id: 28404746,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SILVER GLORY',
    startDate: '2024-05-25T05:25:13',
    endDate: '2024-05-25T10:17:40',
    timeInPort: 0.2031,
    imo: 9070474,
    portId: 586,
    operatorId: 2804,
    operatorShortName: 'SALLAU',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-23T07:50:26Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.1,
    percentArrivalDraught: 92.85715,
    departureDraughtDateTime: '2024-05-24T04:18:44Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.5,
    percentDepartureDraught: 96.938774,
    imoDraught: 9.8,
    id: 28379708,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LAVENDER ACE',
    startDate: '2024-05-23T07:50:26',
    endDate: '2024-05-24T04:18:44',
    timeInPort: 0.853,
    imo: 9363948,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-23T04:51:15Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2024-05-25T03:55:56Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 89.10891,
    imoDraught: 10.1,
    id: 28396523,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ORION LEADER',
    startDate: '2024-05-23T04:51:15',
    endDate: '2024-05-25T03:55:56',
    timeInPort: 1.9616,
    imo: 9182289,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-22T04:15:24Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.3,
    percentArrivalDraught: 93.9394,
    departureDraughtDateTime: '2024-05-22T15:58:33Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.3,
    percentDepartureDraught: 93.9394,
    imoDraught: 9.9,
    id: 28354472,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLORIOUS ACE',
    startDate: '2024-05-22T04:15:24',
    endDate: '2024-05-22T15:58:33',
    timeInPort: 0.4883,
    imo: 9561277,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-12T07:53:56Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.3,
    percentArrivalDraught: 93.63636,
    departureDraughtDateTime: '2024-05-12T12:39:57Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.3,
    percentDepartureDraught: 93.63636,
    imoDraught: 11,
    id: 28261143,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORONTO',
    startDate: '2024-05-12T07:53:56',
    endDate: '2024-05-12T12:39:57',
    timeInPort: 0.1986,
    imo: 9302205,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-05-03T04:51:08Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10,
    percentArrivalDraught: 90.90909,
    departureDraughtDateTime: '2024-05-04T03:55:49Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 27970142,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MANON',
    startDate: '2024-05-03T04:51:08',
    endDate: '2024-05-04T03:55:49',
    timeInPort: 0.9616,
    imo: 9179725,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-29T04:25:21Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.9,
    percentArrivalDraught: 87.61061,
    departureDraughtDateTime: '2024-04-30T06:36:12Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.9,
    percentDepartureDraught: 87.61061,
    imoDraught: 11.3,
    id: 27905168,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COUGAR',
    startDate: '2024-04-29T04:25:21',
    endDate: '2024-04-30T06:36:12',
    timeInPort: 1.0909,
    imo: 9451898,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-27T04:40:08Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2024-04-27T14:23:28Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 27864877,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUPERIOR',
    startDate: '2024-04-27T04:40:08',
    endDate: '2024-04-27T14:23:28',
    timeInPort: 0.4051,
    imo: 9674189,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-25T05:15:39Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.4,
    percentArrivalDraught: 83.168304,
    departureDraughtDateTime: '2024-04-26T04:40:13Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.4,
    percentDepartureDraught: 83.168304,
    imoDraught: 10.1,
    id: 27840618,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CHALLENGE',
    startDate: '2024-04-25T05:15:39',
    endDate: '2024-04-26T04:40:13',
    timeInPort: 0.9754,
    imo: 9590591,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-23T04:50:17Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.4,
    percentArrivalDraught: 83.99999,
    departureDraughtDateTime: '2024-04-23T17:22:35Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.4,
    percentDepartureDraught: 83.99999,
    imoDraught: 10,
    id: 27799295,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GOLIATH LEADER',
    startDate: '2024-04-23T04:50:17',
    endDate: '2024-04-23T17:22:35',
    timeInPort: 0.5224,
    imo: 9357315,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-21T04:40:30Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.1,
    percentArrivalDraught: 92.045456,
    departureDraughtDateTime: '2024-04-21T16:53:28Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.1,
    percentDepartureDraught: 92.045456,
    imoDraught: 8.8,
    id: 27767251,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'IRIS ACE',
    startDate: '2024-04-21T04:40:30',
    endDate: '2024-04-21T16:53:28',
    timeInPort: 0.509,
    imo: 9515474,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-16T04:55:43Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.3,
    percentArrivalDraught: 90.21739,
    departureDraughtDateTime: '2024-04-16T09:18:55Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.3,
    percentDepartureDraught: 90.21739,
    imoDraught: 9.2,
    id: 27693715,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SILVER RAY',
    startDate: '2024-04-16T04:55:43',
    endDate: '2024-04-16T09:18:55',
    timeInPort: 0.1828,
    imo: 9181376,
    portId: 586,
    operatorId: 2804,
    operatorShortName: 'SALLAU',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-15T07:55:50Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.5,
    percentArrivalDraught: 95.9596,
    departureDraughtDateTime: '2024-04-16T03:52:23Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.5,
    percentDepartureDraught: 95.9596,
    imoDraught: 9.9,
    id: 27684519,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ELEGANT ACE',
    startDate: '2024-04-15T07:55:50',
    endDate: '2024-04-16T03:52:23',
    timeInPort: 0.8309,
    imo: 9561265,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-06T10:56:05Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.4,
    percentArrivalDraught: 93.0693,
    departureDraughtDateTime: '2024-04-07T04:07:21Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.5,
    percentDepartureDraught: 94.0594,
    imoDraught: 10.1,
    id: 26841200,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SIRIUS',
    startDate: '2024-04-06T10:56:05',
    endDate: '2024-04-07T04:07:21',
    timeInPort: 0.7162,
    imo: 9749582,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-05T13:20:38Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.3,
    percentArrivalDraught: 97.16981,
    departureDraughtDateTime: '2024-04-06T10:15:06Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.1,
    percentDepartureDraught: 95.28302,
    imoDraught: 10.6,
    id: 27112911,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING PROSPERITY',
    startDate: '2024-04-05T13:20:38',
    endDate: '2024-04-06T10:15:06',
    timeInPort: 0.8712,
    imo: 9780639,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-04-01T03:51:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.6,
    percentArrivalDraught: 95.04951,
    departureDraughtDateTime: '2024-04-02T07:08:16Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.7,
    percentDepartureDraught: 96.0396,
    imoDraught: 10.1,
    id: 26860046,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUN',
    startDate: '2024-04-01T03:51:01',
    endDate: '2024-04-02T07:08:16',
    timeInPort: 1.137,
    imo: 9749568,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-03-31T03:40:16Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2024-04-01T03:33:10Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.3,
    percentDepartureDraught: 92.0792,
    imoDraught: 10.1,
    id: 27137604,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PRECIOUS ACE',
    startDate: '2024-03-31T03:40:16',
    endDate: '2024-04-01T03:33:10',
    timeInPort: 0.9951,
    imo: 9554200,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-03-27T05:35:33Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 92.9293,
    departureDraughtDateTime: '2024-03-27T19:40:16Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 90.909096,
    imoDraught: 9.9,
    id: 27313229,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TRANQUIL ACE',
    startDate: '2024-03-27T05:35:33',
    endDate: '2024-03-27T19:40:16',
    timeInPort: 0.5866,
    imo: 9561253,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-03-26T04:47:31Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.3,
    percentArrivalDraught: 82.178215,
    departureDraughtDateTime: '2024-03-27T04:20:58Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.3,
    percentDepartureDraught: 82.178215,
    imoDraught: 10.1,
    id: 26997664,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LEO LEADER',
    startDate: '2024-03-26T04:47:31',
    endDate: '2024-03-27T04:20:58',
    timeInPort: 0.9816,
    imo: 9181558,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-03-13T04:30:52Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2024-03-14T13:45:23Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 89.10891,
    imoDraught: 10.1,
    id: 26851278,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SPRING',
    startDate: '2024-03-13T04:30:52',
    endDate: '2024-03-14T13:45:23',
    timeInPort: 1.3851,
    imo: 9749594,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-03-04T04:59:12Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 86.8687,
    departureDraughtDateTime: '2024-03-06T03:30:12Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.8,
    percentDepartureDraught: 88.88889,
    imoDraught: 9.9,
    id: 27246466,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SWIFT ACE',
    startDate: '2024-03-04T04:59:12',
    endDate: '2024-03-06T03:30:12',
    timeInPort: 1.9382,
    imo: 9338838,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-28T05:35:40Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.4,
    percentArrivalDraught: 94.545456,
    departureDraughtDateTime: '2024-02-29T04:43:58Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.3,
    percentDepartureDraught: 93.63636,
    imoDraught: 11,
    id: 27264932,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TOREADOR',
    startDate: '2024-02-28T05:35:40',
    endDate: '2024-02-29T04:43:58',
    timeInPort: 0.9641,
    imo: 9375288,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-27T06:17:26Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.99999,
    departureDraughtDateTime: '2024-02-28T03:48:53Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.1,
    percentDepartureDraught: 91.00001,
    imoDraught: 10,
    id: 27295410,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SPLENDID ACE',
    startDate: '2024-02-27T06:17:26',
    endDate: '2024-02-28T03:48:53',
    timeInPort: 0.8968,
    imo: 9252228,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-26T05:14:33Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.5,
    percentArrivalDraught: 85,
    departureDraughtDateTime: '2024-02-27T03:38:24Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.5,
    percentDepartureDraught: 85,
    imoDraught: 10,
    id: 26826637,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GARNET LEADER',
    startDate: '2024-02-26T05:14:33',
    endDate: '2024-02-27T03:38:24',
    timeInPort: 0.9332,
    imo: 9357327,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-22T11:56:00Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 7.8,
    percentArrivalDraught: 79.591835,
    departureDraughtDateTime: '2024-02-23T04:05:23Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 7.8,
    percentDepartureDraught: 79.591835,
    imoDraught: 9.8,
    id: 26899026,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE GABON',
    startDate: '2024-02-22T11:56:00',
    endDate: '2024-02-23T04:05:23',
    timeInPort: 0.6732,
    imo: 9437933,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-21T04:42:41Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.1,
    percentArrivalDraught: 90.099014,
    departureDraughtDateTime: '2024-02-22T08:37:45Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 26864795,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUPREME',
    startDate: '2024-02-21T04:42:41',
    endDate: '2024-02-22T08:37:45',
    timeInPort: 1.1632,
    imo: 9674177,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-18T05:39:57Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.9,
    percentArrivalDraught: 89.89899,
    departureDraughtDateTime: '2024-02-18T13:44:13Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 90.909096,
    imoDraught: 9.9,
    id: 26830698,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GENUINE ACE',
    startDate: '2024-02-18T05:39:57',
    endDate: '2024-02-18T13:44:13',
    timeInPort: 0.3363,
    imo: 9610418,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-11T03:51:12Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.5,
    percentArrivalDraught: 94.0594,
    departureDraughtDateTime: '2024-02-11T12:10:41Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.8,
    percentDepartureDraught: 97.0297,
    imoDraught: 10.1,
    id: 26853816,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COSMOS',
    startDate: '2024-02-11T03:51:12',
    endDate: '2024-02-11T12:10:41',
    timeInPort: 0.3469,
    imo: 9707027,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-05T07:08:33Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2024-02-05T11:36:37Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 27045509,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING CHAMPION',
    startDate: '2024-02-05T07:08:33',
    endDate: '2024-02-05T11:36:37',
    timeInPort: 0.1862,
    imo: 9285627,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-02-01T05:01:14Z',
    arrivalDraughtMinsDiff: 70.3,
    arrivalDraught: 9.8,
    percentArrivalDraught: 86.72566,
    departureDraughtDateTime: '2024-02-01T18:51:43Z',
    departureDraughtMinsDiff: 96.6833333333334,
    departureDraught: 9.8,
    percentDepartureDraught: 86.72566,
    imoDraught: 11.3,
    id: 26846558,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CONDOR',
    startDate: '2024-02-01T06:11:32',
    endDate: '2024-02-01T17:15:02',
    timeInPort: 0.4608,
    imo: 9414876,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-01-29T04:12:05Z',
    arrivalDraughtMinsDiff: 61.5166666666667,
    arrivalDraught: 10.1,
    percentArrivalDraught: 95.28302,
    departureDraughtDateTime: '2024-01-30T13:29:43Z',
    departureDraughtMinsDiff: 17.5166666666667,
    departureDraught: 10.1,
    percentDepartureDraught: 95.28302,
    imoDraught: 10.6,
    id: 27273362,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'THALATTA',
    startDate: '2024-01-29T05:13:36',
    endDate: '2024-01-30T13:12:12',
    timeInPort: 1.3324,
    imo: 9702455,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-01-15T05:08:36Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.9,
    percentArrivalDraught: 90.81632,
    departureDraughtDateTime: '2024-01-15T16:39:45Z',
    departureDraughtMinsDiff: 26.9666666666667,
    departureDraught: 8.6,
    percentDepartureDraught: 87.755104,
    imoDraught: 9.8,
    id: 27079885,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MARGUERITE ACE',
    startDate: '2024-01-15T05:08:36',
    endDate: '2024-01-15T16:12:47',
    timeInPort: 0.4612,
    imo: 9426386,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-01-10T04:10:09Z',
    arrivalDraughtMinsDiff: 62.7333333333333,
    arrivalDraught: 10.4,
    percentArrivalDraught: 98.113205,
    departureDraughtDateTime: '2024-01-11T05:26:18Z',
    departureDraughtMinsDiff: 10.0666666666667,
    departureDraught: 10.3,
    percentDepartureDraught: 97.16981,
    imoDraught: 10.6,
    id: 27275051,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'THEMIS',
    startDate: '2024-01-10T05:12:53',
    endDate: '2024-01-11T05:16:14',
    timeInPort: 1.0023,
    imo: 9722314,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-01-03T04:25:05Z',
    arrivalDraughtMinsDiff: 50.5333333333333,
    arrivalDraught: 9.3,
    percentArrivalDraught: 92.0792,
    departureDraughtDateTime: '2024-01-03T21:45:08Z',
    departureDraughtMinsDiff: 30.2,
    departureDraught: 9.4,
    percentDepartureDraught: 93.0693,
    imoDraught: 10.1,
    id: 26870970,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CHAMPION',
    startDate: '2024-01-03T05:15:37',
    endDate: '2024-01-03T21:14:56',
    timeInPort: 0.6662,
    imo: 9651113,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2024-01-02T05:24:32Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.4,
    percentArrivalDraught: 98.113205,
    departureDraughtDateTime: '2024-01-03T05:09:00Z',
    departureDraughtMinsDiff: 49.5833333333333,
    departureDraught: 10.4,
    percentDepartureDraught: 98.113205,
    imoDraught: 10.6,
    id: 27273786,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'THEBEN',
    startDate: '2024-01-02T05:24:32',
    endDate: '2024-01-03T04:19:25',
    timeInPort: 0.9548,
    imo: 9722302,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-28T04:44:19Z',
    arrivalDraughtMinsDiff: 34.3666666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 90.81632,
    departureDraughtDateTime: '2023-12-30T06:29:43Z',
    departureDraughtMinsDiff: 72.2333333333333,
    departureDraught: 9,
    percentDepartureDraught: 91.83673,
    imoDraught: 9.8,
    id: 26891935,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE COTONOU',
    startDate: '2023-12-28T05:18:41',
    endDate: '2023-12-30T05:17:29',
    timeInPort: 1.9992,
    imo: 9672105,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-26T04:18:57Z',
    arrivalDraughtMinsDiff: 55.65,
    arrivalDraught: 9,
    percentArrivalDraught: 91.83673,
    departureDraughtDateTime: '2023-12-26T21:39:15Z',
    departureDraughtMinsDiff: 23.3,
    departureDraught: 9.2,
    percentDepartureDraught: 93.87755,
    imoDraught: 9.8,
    id: 26783578,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CARNATION ACE',
    startDate: '2023-12-26T05:14:36',
    endDate: '2023-12-26T21:15:57',
    timeInPort: 0.6676,
    imo: 9544920,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-23T06:13:21Z',
    arrivalDraughtMinsDiff: 60.1166666666667,
    arrivalDraught: 8.6,
    percentArrivalDraught: 87.755104,
    departureDraughtDateTime: '2023-12-24T05:51:14Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 93.87755,
    imoDraught: 9.8,
    id: 26723642,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'APOLLON LEADER',
    startDate: '2023-12-23T07:13:28',
    endDate: '2023-12-24T05:51:14',
    timeInPort: 0.9429,
    imo: 9402706,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-22T04:26:58Z',
    arrivalDraughtMinsDiff: 48.35,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2023-12-23T05:47:52Z',
    departureDraughtMinsDiff: 33.45,
    departureDraught: 9.6,
    percentDepartureDraught: 95.04951,
    imoDraught: 10.1,
    id: 26840439,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SIRIUS',
    startDate: '2023-12-22T05:15:19',
    endDate: '2023-12-23T05:14:25',
    timeInPort: 0.9994,
    imo: 9749582,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-12T04:07:22Z',
    arrivalDraughtMinsDiff: 65.3333333333333,
    arrivalDraught: 8.5,
    percentArrivalDraught: 86.734695,
    departureDraughtDateTime: '2023-12-13T18:51:26Z',
    departureDraughtMinsDiff: 37.9333333333333,
    departureDraught: 8.7,
    percentDepartureDraught: 88.775505,
    imoDraught: 9.8,
    id: 26723664,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'AQUAMARINE ACE',
    startDate: '2023-12-12T05:12:42',
    endDate: '2023-12-13T18:13:30',
    timeInPort: 1.5422,
    imo: 9397987,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-07T04:27:03Z',
    arrivalDraughtMinsDiff: 48.2833333333333,
    arrivalDraught: 7.4,
    percentArrivalDraught: 84.090904,
    departureDraughtDateTime: '2023-12-08T04:47:17Z',
    departureDraughtMinsDiff: 35.3166666666667,
    departureDraught: 7.7,
    percentDepartureDraught: 87.5,
    imoDraught: 8.8,
    id: 27539182,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'NEPTUNE BARCELONA',
    startDate: '2023-12-07T05:15:20',
    endDate: '2023-12-08T04:11:58',
    timeInPort: 0.956,
    imo: 9762546,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-04T04:33:22Z',
    arrivalDraughtMinsDiff: 43.6333333333333,
    arrivalDraught: 9.1,
    percentArrivalDraught: 88.34952,
    departureDraughtDateTime: '2023-12-05T07:11:44Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.8,
    percentDepartureDraught: 95.14563,
    imoDraught: 10.3,
    id: 26859283,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUNLIGHT',
    startDate: '2023-12-04T05:17:00',
    endDate: '2023-12-05T07:11:44',
    timeInPort: 1.0797,
    imo: 9798416,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-12-03T04:05:39Z',
    arrivalDraughtMinsDiff: 66.4166666666667,
    arrivalDraught: 9.6,
    percentArrivalDraught: 95.04951,
    departureDraughtDateTime: '2023-12-03T20:11:25Z',
    departureDraughtMinsDiff: 52.9166666666667,
    departureDraught: 9.6,
    percentDepartureDraught: 95.04951,
    imoDraught: 10.1,
    id: 27055861,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING COMPOSER',
    startDate: '2023-12-03T05:12:04',
    endDate: '2023-12-03T19:18:30',
    timeInPort: 0.5878,
    imo: 9336074,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-25T08:02:47Z',
    arrivalDraughtMinsDiff: 68.9,
    arrivalDraught: 8.6,
    percentArrivalDraught: 87.755104,
    departureDraughtDateTime: '2023-11-26T10:23:30Z',
    departureDraughtMinsDiff: 8.51666666666667,
    departureDraught: 9.3,
    percentDepartureDraught: 94.89796,
    imoDraught: 9.8,
    id: 26786310,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CATTLEYA ACE',
    startDate: '2023-11-25T09:11:41',
    endDate: '2023-11-26T10:14:59',
    timeInPort: 1.044,
    imo: 9544918,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-23T07:24:46Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.4,
    percentArrivalDraught: 94.94949,
    departureDraughtDateTime: '2023-11-24T04:31:19.34Z',
    departureDraughtMinsDiff: 0.00566666666666667,
    departureDraught: 9.3,
    percentDepartureDraught: 93.9394,
    imoDraught: 9.9,
    id: 26830401,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GENUINE ACE',
    startDate: '2023-11-23T07:24:46',
    endDate: '2023-11-24T04:31:19',
    timeInPort: 0.8795,
    imo: 9610418,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-22T11:14:47Z',
    arrivalDraughtMinsDiff: 58.6333333333333,
    arrivalDraught: 8.5,
    percentArrivalDraught: 84.15841,
    departureDraughtDateTime: '2023-11-23T06:31:08Z',
    departureDraughtMinsDiff: 18.6666666666667,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 26996277,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LEO LEADER',
    startDate: '2023-11-22T12:13:25',
    endDate: '2023-11-23T06:12:28',
    timeInPort: 0.7493,
    imo: 9181558,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-18T10:03:45Z',
    arrivalDraughtMinsDiff: 68.1333333333334,
    arrivalDraught: 9.3,
    percentArrivalDraught: 92.0792,
    departureDraughtDateTime: '2023-11-19T14:30:52Z',
    departureDraughtMinsDiff: 17.2333333333333,
    departureDraught: 9.9,
    percentDepartureDraught: 98.01979,
    imoDraught: 10.1,
    id: 26859320,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUN',
    startDate: '2023-11-18T11:11:53',
    endDate: '2023-11-19T14:13:38',
    timeInPort: 1.1262,
    imo: 9749568,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-12T04:30:26Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.3,
    percentArrivalDraught: 92.0792,
    departureDraughtDateTime: '2023-11-12T18:29:05Z',
    departureDraughtMinsDiff: 15.7333333333333,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 26852867,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COSMOS',
    startDate: '2023-11-12T04:30:26',
    endDate: '2023-11-12T18:13:21',
    timeInPort: 0.5715,
    imo: 9707027,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-08T05:00:36Z',
    arrivalDraughtMinsDiff: 70.7833333333333,
    arrivalDraught: 10,
    percentArrivalDraught: 100,
    departureDraughtDateTime: '2023-11-09T08:14:32Z',
    departureDraughtMinsDiff: 3.3,
    departureDraught: 9.9,
    percentDepartureDraught: 98.99999,
    imoDraught: 10,
    id: 27040703,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING CELESTA',
    startDate: '2023-11-08T06:11:23',
    endDate: '2023-11-09T08:11:14',
    timeInPort: 1.0832,
    imo: 9336062,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-11-06T08:22:07Z',
    arrivalDraughtMinsDiff: 52.7666666666667,
    arrivalDraught: 9,
    percentArrivalDraught: 91.83673,
    departureDraughtDateTime: '2023-11-07T14:55:07Z',
    departureDraughtMinsDiff: 37.75,
    departureDraught: 9.1,
    percentDepartureDraught: 92.85715,
    imoDraught: 9.8,
    id: 27135074,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ORCHID ACE',
    startDate: '2023-11-06T09:14:53',
    endDate: '2023-11-07T14:17:22',
    timeInPort: 1.2101,
    imo: 9381677,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-25T05:00:02Z',
    arrivalDraughtMinsDiff: 71.1,
    arrivalDraught: 8.8,
    percentArrivalDraught: 88.88889,
    departureDraughtDateTime: '2023-10-25T17:42:50Z',
    departureDraughtMinsDiff: 28.5333333333333,
    departureDraught: 9.4,
    percentDepartureDraught: 94.94949,
    imoDraught: 9.9,
    id: 27227148,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SUNLIGHT ACE',
    startDate: '2023-10-25T06:11:08',
    endDate: '2023-10-25T17:14:18',
    timeInPort: 0.4605,
    imo: 9338864,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-24T04:28:11Z',
    arrivalDraughtMinsDiff: 47.8166666666667,
    arrivalDraught: 9.5,
    percentArrivalDraught: 84.82143,
    departureDraughtDateTime: '2023-10-26T04:54:55Z',
    departureDraughtMinsDiff: 35.5833333333333,
    departureDraught: 9.7,
    percentDepartureDraught: 86.60715,
    imoDraught: 11.2,
    id: 26801737,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DONG-A METIS',
    startDate: '2023-10-24T05:16:00',
    endDate: '2023-10-26T04:19:20',
    timeInPort: 1.9606,
    imo: 9419747,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-23T04:05:51Z',
    arrivalDraughtMinsDiff: 66.5,
    arrivalDraught: 9.6,
    percentArrivalDraught: 90.56604,
    departureDraughtDateTime: '2023-10-24T03:53:49Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.7,
    percentDepartureDraught: 91.50943,
    imoDraught: 10.6,
    id: 27112686,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING PRIDE',
    startDate: '2023-10-23T05:12:21',
    endDate: '2023-10-24T03:53:49',
    timeInPort: 0.9455,
    imo: 9681431,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-21T04:36:55Z',
    arrivalDraughtMinsDiff: 40.8333333333333,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2023-10-21T16:33:23Z',
    departureDraughtMinsDiff: 21.7666666666667,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 27136862,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ORION LEADER',
    startDate: '2023-10-21T05:17:45',
    endDate: '2023-10-21T16:11:37',
    timeInPort: 0.4541,
    imo: 9182289,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-18T05:14:56Z',
    arrivalDraughtMinsDiff: 58.4666666666667,
    arrivalDraught: 8.3,
    percentArrivalDraught: 85.56701,
    departureDraughtDateTime: '2023-10-19T05:51:06Z',
    departureDraughtMinsDiff: 33.2166666666667,
    departureDraught: 9.2,
    percentDepartureDraught: 94.84536,
    imoDraught: 9.7,
    id: 26840639,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND CHAMPION',
    startDate: '2023-10-18T06:13:24',
    endDate: '2023-10-19T05:17:53',
    timeInPort: 0.9614,
    imo: 9340570,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-15T04:34:22Z',
    arrivalDraughtMinsDiff: 42.5,
    arrivalDraught: 9.1,
    percentArrivalDraught: 90.099014,
    departureDraughtDateTime: '2023-10-15T14:20:16Z',
    departureDraughtMinsDiff: 7.2,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 27325475,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TREASURE',
    startDate: '2023-10-15T05:16:52',
    endDate: '2023-10-15T14:13:04',
    timeInPort: 0.3724,
    imo: 9184859,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-12T12:21:29Z',
    arrivalDraughtMinsDiff: 53.5166666666667,
    arrivalDraught: 9,
    percentArrivalDraught: 90,
    departureDraughtDateTime: '2023-10-12T20:49:47Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 92,
    imoDraught: 10,
    id: 27174038,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC CLASSIC',
    startDate: '2023-10-12T13:15:00',
    endDate: '2023-10-12T20:49:47',
    timeInPort: 0.3158,
    imo: 9441609,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-03T05:07:37Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 7.9,
    percentArrivalDraught: 85.86957,
    departureDraughtDateTime: '2023-10-03T14:13:37Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.3,
    percentDepartureDraught: 90.21739,
    imoDraught: 9.2,
    id: 26923991,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND PACE',
    startDate: '2023-10-03T05:07:37',
    endDate: '2023-10-03T14:13:37',
    timeInPort: 0.3792,
    imo: 9169328,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-10-01T04:35:11Z',
    arrivalDraughtMinsDiff: 41.5833333333333,
    arrivalDraught: 8.9,
    percentArrivalDraught: 90.81632,
    departureDraughtDateTime: '2023-10-01T15:57:58Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 93.87755,
    imoDraught: 9.8,
    id: 26708654,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'AMETHYST ACE',
    startDate: '2023-10-01T05:16:46',
    endDate: '2023-10-01T15:57:58',
    timeInPort: 0.4453,
    imo: 9397999,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-09-28T14:05:14Z',
    arrivalDraughtMinsDiff: 66.5166666666667,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2023-09-30T04:47:57Z',
    departureDraughtMinsDiff: 31.3,
    departureDraught: 9.8,
    percentDepartureDraught: 97.0297,
    imoDraught: 10.1,
    id: 26859175,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUNRISE',
    startDate: '2023-09-28T15:11:45',
    endDate: '2023-09-30T04:16:39',
    timeInPort: 1.5451,
    imo: 9702405,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-09-28T04:41:16Z',
    arrivalDraughtMinsDiff: 36.6,
    arrivalDraught: 9.3,
    percentArrivalDraught: 92.0792,
    departureDraughtDateTime: '2023-09-28T12:33:12Z',
    departureDraughtMinsDiff: 20.55,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 26839879,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SIRIUS',
    startDate: '2023-09-28T05:17:52',
    endDate: '2023-09-28T12:12:39',
    timeInPort: 0.288,
    imo: 9749582,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-09-20T04:20:21Z',
    arrivalDraughtMinsDiff: 54.5833333333333,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2023-09-21T05:33:50Z',
    departureDraughtMinsDiff: 20.5666666666667,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 26824656,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GALAXY ACE',
    startDate: '2023-09-20T05:14:56',
    endDate: '2023-09-21T05:13:16',
    timeInPort: 0.9988,
    imo: 9624237,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-09-15T07:11:18Z',
    arrivalDraughtMinsDiff: 64.7333333333333,
    arrivalDraught: 8.5,
    percentArrivalDraught: 84.15841,
    departureDraughtDateTime: '2023-09-19T08:48:51Z',
    departureDraughtMinsDiff: 35.2833333333333,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 26785354,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CASSIOPEIA LEADER',
    startDate: '2023-09-15T08:16:02',
    endDate: '2023-09-19T08:13:34',
    timeInPort: 3.9983,
    imo: 9182277,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-09-12T03:18:46Z',
    arrivalDraughtMinsDiff: 63.4833333333333,
    arrivalDraught: 9.9,
    percentArrivalDraught: 98.01979,
    departureDraughtDateTime: '2023-09-12T14:41:20Z',
    departureDraughtMinsDiff: 28.5666666666667,
    departureDraught: 9.9,
    percentDepartureDraught: 98.01979,
    imoDraught: 10.1,
    id: 27047712,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING LENA',
    startDate: '2023-09-12T04:22:15',
    endDate: '2023-09-12T14:12:46',
    timeInPort: 0.4101,
    imo: 9446001,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-09-08T06:07:36Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.4,
    percentArrivalDraught: 93.0693,
    departureDraughtDateTime: '2023-09-09T06:22:54Z',
    departureDraughtMinsDiff: 11.0666666666667,
    departureDraught: 9.7,
    percentDepartureDraught: 96.0396,
    imoDraught: 10.1,
    id: 26854508,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COURAGE',
    startDate: '2023-09-08T06:07:36',
    endDate: '2023-09-09T06:11:50',
    timeInPort: 1.0029,
    imo: 9651101,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-29T04:00:46Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.2,
    percentArrivalDraught: 92.72727,
    departureDraughtDateTime: '2023-08-30T04:59:24Z',
    departureDraughtMinsDiff: 47.5,
    departureDraught: 10.1,
    percentDepartureDraught: 91.818184,
    imoDraught: 11,
    id: 27258563,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TITUS',
    startDate: '2023-08-29T04:00:46',
    endDate: '2023-08-30T04:11:54',
    timeInPort: 1.0077,
    imo: 9700512,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-27T12:03:36Z',
    arrivalDraughtMinsDiff: 68.2666666666667,
    arrivalDraught: 8.3,
    percentArrivalDraught: 84.69388,
    departureDraughtDateTime: '2023-08-28T03:17:05Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.3,
    percentDepartureDraught: 84.69388,
    imoDraught: 9.8,
    id: 26817606,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'FIRMAMENT ACE',
    startDate: '2023-08-27T13:11:52',
    endDate: '2023-08-28T03:17:05',
    timeInPort: 0.587,
    imo: 9293894,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-25T09:00:14Z',
    arrivalDraughtMinsDiff: 71.5,
    arrivalDraught: 8.1,
    percentArrivalDraught: 80.19802,
    departureDraughtDateTime: '2023-08-27T04:58:28Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.1,
    percentDepartureDraught: 80.19802,
    imoDraught: 10.1,
    id: 26724680,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'AQUARIUS LEADER',
    startDate: '2023-08-25T10:11:44',
    endDate: '2023-08-27T04:58:28',
    timeInPort: 1.7825,
    imo: 9158276,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-25T04:33:34Z',
    arrivalDraughtMinsDiff: 38.2166666666667,
    arrivalDraught: 8.5,
    percentArrivalDraught: 85.85859,
    departureDraughtDateTime: '2023-08-25T17:55:05Z',
    departureDraughtMinsDiff: 43.4,
    departureDraught: 8.5,
    percentDepartureDraught: 85.85859,
    imoDraught: 9.9,
    id: 27223571,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SANDERLING ACE',
    startDate: '2023-08-25T05:11:47',
    endDate: '2023-08-25T17:11:41',
    timeInPort: 0.4999,
    imo: 9409481,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-21T05:05:13Z',
    arrivalDraughtMinsDiff: 66.5833333333333,
    arrivalDraught: 10.2,
    percentArrivalDraught: 90.26549,
    departureDraughtDateTime: '2023-08-22T05:21:52Z',
    departureDraughtMinsDiff: 10.0333333333333,
    departureDraught: 10.2,
    percentDepartureDraught: 90.26549,
    imoDraught: 11.3,
    id: 26865594,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CARDINAL',
    startDate: '2023-08-21T06:11:48',
    endDate: '2023-08-22T05:11:50',
    timeInPort: 0.9584,
    imo: 9419759,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-19T14:38:32Z',
    arrivalDraughtMinsDiff: 33.2833333333333,
    arrivalDraught: 8.7,
    percentArrivalDraught: 88.775505,
    departureDraughtDateTime: '2023-08-20T05:33:59Z',
    departureDraughtMinsDiff: 22,
    departureDraught: 8.9,
    percentDepartureDraught: 90.81632,
    imoDraught: 9.8,
    id: 26785795,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CATTLEYA ACE',
    startDate: '2023-08-19T15:11:49',
    endDate: '2023-08-20T05:11:59',
    timeInPort: 0.5834,
    imo: 9544918,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-19T04:54:04Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.1,
    percentArrivalDraught: 83.505165,
    departureDraughtDateTime: '2023-08-19T15:21:58Z',
    departureDraughtMinsDiff: 10.1666666666667,
    departureDraught: 8.1,
    percentDepartureDraught: 83.505165,
    imoDraught: 9.7,
    id: 26929693,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND RUBY',
    startDate: '2023-08-19T04:54:04',
    endDate: '2023-08-19T15:11:48',
    timeInPort: 0.429,
    imo: 9325221,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-05T06:42:05Z',
    arrivalDraughtMinsDiff: 29.8666666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 89.89899,
    departureDraughtDateTime: '2023-08-05T14:52:15Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 90.909096,
    imoDraught: 9.9,
    id: 26808094,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ETERNAL ACE',
    startDate: '2023-08-05T07:11:57',
    endDate: '2023-08-05T14:52:15',
    timeInPort: 0.3197,
    imo: 9606479,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-04T06:03:39Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 87.755104,
    departureDraughtDateTime: '2023-08-04T17:29:31Z',
    departureDraughtMinsDiff: 17.4166666666667,
    departureDraught: 9.1,
    percentDepartureDraught: 92.85715,
    imoDraught: 9.8,
    id: 27211572,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RUBY ACE',
    startDate: '2023-08-04T06:03:39',
    endDate: '2023-08-04T17:12:06',
    timeInPort: 0.4642,
    imo: 9476757,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-03T05:35:43Z',
    arrivalDraughtMinsDiff: 45.7666666666667,
    arrivalDraught: 9.3,
    percentArrivalDraught: 90.29126,
    departureDraughtDateTime: '2023-08-04T05:26:45Z',
    departureDraughtMinsDiff: 74.0833333333333,
    departureDraught: 9.4,
    percentDepartureDraught: 91.26213,
    imoDraught: 10.3,
    id: 26842817,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SONIC',
    startDate: '2023-08-03T06:21:29',
    endDate: '2023-08-04T04:12:40',
    timeInPort: 0.9105,
    imo: 9798387,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-08-02T05:25:58Z',
    arrivalDraughtMinsDiff: 66.6166666666666,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.99999,
    departureDraughtDateTime: '2023-08-03T05:35:25Z',
    departureDraughtMinsDiff: 2.03333333333333,
    departureDraught: 9.2,
    percentDepartureDraught: 92,
    imoDraught: 10,
    id: 27173292,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC CLASSIC',
    startDate: '2023-08-02T06:32:35',
    endDate: '2023-08-03T05:33:23',
    timeInPort: 0.9589,
    imo: 9441609,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-27T07:21:35Z',
    arrivalDraughtMinsDiff: 59.4333333333333,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2023-07-28T05:30:30Z',
    departureDraughtMinsDiff: 9.48333333333333,
    departureDraught: 9.4,
    percentDepartureDraught: 93.0693,
    imoDraught: 10.1,
    id: 26842045,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SOLOMON',
    startDate: '2023-07-27T08:21:01',
    endDate: '2023-07-28T05:21:01',
    timeInPort: 0.875,
    imo: 9445409,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-26T04:20:48Z',
    arrivalDraughtMinsDiff: 54.3833333333333,
    arrivalDraught: 10.2,
    percentArrivalDraught: 92.72727,
    departureDraughtDateTime: '2023-07-27T05:30:36Z',
    departureDraughtMinsDiff: 15.5,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 26728640,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'BOHEME',
    startDate: '2023-07-26T05:15:11',
    endDate: '2023-07-27T05:15:06',
    timeInPort: 0.9999,
    imo: 9176565,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-23T06:13:38Z',
    arrivalDraughtMinsDiff: 62.0666666666667,
    arrivalDraught: 8.2,
    percentArrivalDraught: 83.67347,
    departureDraughtDateTime: '2023-07-23T15:31:44Z',
    departureDraughtMinsDiff: 15.9,
    departureDraught: 8.6,
    percentDepartureDraught: 87.755104,
    imoDraught: 9.8,
    id: 26782490,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CARNATION ACE',
    startDate: '2023-07-23T07:15:42',
    endDate: '2023-07-23T15:15:50',
    timeInPort: 0.3334,
    imo: 9544920,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-21T03:18:06Z',
    arrivalDraughtMinsDiff: 68.75,
    arrivalDraught: 8.1,
    percentArrivalDraught: 80.19802,
    departureDraughtDateTime: '2023-07-21T21:42:41Z',
    departureDraughtMinsDiff: 17.2,
    departureDraught: 8.1,
    percentDepartureDraught: 80.19802,
    imoDraught: 10.1,
    id: 26994679,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LEO LEADER',
    startDate: '2023-07-21T04:26:51',
    endDate: '2023-07-21T21:25:29',
    timeInPort: 0.7074,
    imo: 9181558,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-11T12:23:52Z',
    arrivalDraughtMinsDiff: 57.8333333333333,
    arrivalDraught: 7.6,
    percentArrivalDraught: 78.35052,
    departureDraughtDateTime: '2023-07-12T05:51:06Z',
    departureDraughtMinsDiff: 28.9666666666667,
    departureDraught: 8.9,
    percentDepartureDraught: 91.75257,
    imoDraught: 9.7,
    id: 26880959,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND MERCURY',
    startDate: '2023-07-11T13:21:42',
    endDate: '2023-07-12T05:22:08',
    timeInPort: 0.667,
    imo: 9247584,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-06T06:40:46Z',
    arrivalDraughtMinsDiff: 49.5166666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.118805,
    departureDraughtDateTime: '2023-07-06T20:36:50Z',
    departureDraughtMinsDiff: 6.7,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 27146409,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'NOBLE ACE',
    startDate: '2023-07-06T07:30:17',
    endDate: '2023-07-06T20:30:08',
    timeInPort: 0.5416,
    imo: 9493365,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-04T04:18:54Z',
    arrivalDraughtMinsDiff: 75.3666666666666,
    arrivalDraught: 9.9,
    percentArrivalDraught: 93.39622,
    departureDraughtDateTime: '2023-07-05T05:21:00Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.9,
    percentDepartureDraught: 93.39622,
    imoDraught: 10.6,
    id: 27092330,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING PEACE',
    startDate: '2023-07-04T05:34:16',
    endDate: '2023-07-05T05:21:00',
    timeInPort: 0.9908,
    imo: 9780627,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-07-01T12:11:17Z',
    arrivalDraughtMinsDiff: 75.8,
    arrivalDraught: 8.7,
    percentArrivalDraught: 88.775505,
    departureDraughtDateTime: '2023-07-02T05:25:11Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.8,
    percentDepartureDraught: 89.79591,
    imoDraught: 9.8,
    id: 26979053,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LIBERTY ACE',
    startDate: '2023-07-01T13:27:05',
    endDate: '2023-07-02T05:25:11',
    timeInPort: 0.6653,
    imo: 9293650,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-06-27T05:02:14Z',
    arrivalDraughtMinsDiff: 78.2,
    arrivalDraught: 8.3,
    percentArrivalDraught: 82.178215,
    departureDraughtDateTime: '2023-06-30T04:51:33Z',
    departureDraughtMinsDiff: 30.7,
    departureDraught: 8.3,
    percentDepartureDraught: 82.178215,
    imoDraught: 10.1,
    id: 27331208,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GEMINI LEADER',
    startDate: '2023-06-27T06:20:26',
    endDate: '2023-06-30T04:20:51',
    timeInPort: 2.917,
    imo: 9519121,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-06-24T10:11:37Z',
    arrivalDraughtMinsDiff: 66.8833333333334,
    arrivalDraught: 8.9,
    percentArrivalDraught: 89.89899,
    departureDraughtDateTime: '2023-06-25T04:54:22Z',
    departureDraughtMinsDiff: 81.2166666666667,
    departureDraught: 8.9,
    percentDepartureDraught: 89.89899,
    imoDraught: 9.9,
    id: 26808117,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'EUPHONY ACE',
    startDate: '2023-06-24T11:18:30',
    endDate: '2023-06-25T03:33:09',
    timeInPort: 0.6768,
    imo: 9293595,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-06-19T06:09:15Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.5,
    percentArrivalDraught: 84.15841,
    departureDraughtDateTime: '2023-06-21T04:59:20Z',
    departureDraughtMinsDiff: 26.3833333333333,
    departureDraught: 9.4,
    percentDepartureDraught: 93.0693,
    imoDraught: 10.1,
    id: 26839145,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SIRIUS',
    startDate: '2023-06-19T06:09:15',
    endDate: '2023-06-21T04:32:57',
    timeInPort: 1.9331,
    imo: 9749582,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-06-18T04:25:30Z',
    arrivalDraughtMinsDiff: 70.3833333333334,
    arrivalDraught: 10.4,
    percentArrivalDraught: 94.545456,
    departureDraughtDateTime: '2023-06-19T04:56:25Z',
    departureDraughtMinsDiff: 7.43333333333333,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 27295029,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORTUGAS',
    startDate: '2023-06-18T05:35:53',
    endDate: '2023-06-19T04:48:59',
    timeInPort: 0.9674,
    imo: 9319765,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-06-14T03:20:14Z',
    arrivalDraughtMinsDiff: 62.05,
    arrivalDraught: 8.8,
    percentArrivalDraught: 88,
    departureDraughtDateTime: '2023-06-15T04:31:14Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 90,
    imoDraught: 10,
    id: 26788678,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DIGNITY LEADER',
    startDate: '2023-06-14T04:22:17',
    endDate: '2023-06-15T04:31:14',
    timeInPort: 1.0062,
    imo: 9441506,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-06-07T11:37:59Z',
    arrivalDraughtMinsDiff: 51.4,
    arrivalDraught: 8.3,
    percentArrivalDraught: 94.31818,
    departureDraughtDateTime: '2023-06-08T07:49:37Z',
    departureDraughtMinsDiff: 20,
    departureDraught: 8.7,
    percentDepartureDraught: 98.86363,
    imoDraught: 8.8,
    id: 27537747,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'NEPTUNE BARCELONA',
    startDate: '2023-06-07T12:29:23',
    endDate: '2023-06-08T07:29:37',
    timeInPort: 0.7918,
    imo: 9762546,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-28T06:09:54Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.8,
    percentArrivalDraught: 88.88889,
    departureDraughtDateTime: '2023-05-28T17:48:05Z',
    departureDraughtMinsDiff: 15.2,
    departureDraught: 9.3,
    percentDepartureDraught: 93.9394,
    imoDraught: 9.9,
    id: 27137622,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PRIME ACE',
    startDate: '2023-05-28T06:09:54',
    endDate: '2023-05-28T17:32:53',
    timeInPort: 0.4743,
    imo: 9610444,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-27T05:24:35Z',
    arrivalDraughtMinsDiff: 76.0333333333333,
    arrivalDraught: 9,
    percentArrivalDraught: 90.909096,
    departureDraughtDateTime: '2023-05-27T16:45:41Z',
    departureDraughtMinsDiff: 11.7333333333333,
    departureDraught: 8.9,
    percentDepartureDraught: 89.89899,
    imoDraught: 9.9,
    id: 27232787,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SWALLOW ACE',
    startDate: '2023-05-27T06:40:37',
    endDate: '2023-05-27T16:33:57',
    timeInPort: 0.412,
    imo: 9338620,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-21T04:17:02Z',
    arrivalDraughtMinsDiff: 4.61666666666667,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2023-05-22T05:37:23Z',
    departureDraughtMinsDiff: 20.2666666666667,
    departureDraught: 8.7,
    percentDepartureDraught: 86.13861,
    imoDraught: 10.1,
    id: 26783933,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CASSIOPEIA LEADER',
    startDate: '2023-05-21T04:21:39',
    endDate: '2023-05-22T05:17:07',
    timeInPort: 1.0385,
    imo: 9182277,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-20T05:16:27Z',
    arrivalDraughtMinsDiff: 68.4833333333333,
    arrivalDraught: 8.7,
    percentArrivalDraught: 89.69072,
    departureDraughtDateTime: '2023-05-20T15:48:52Z',
    departureDraughtMinsDiff: 27.45,
    departureDraught: 8.7,
    percentDepartureDraught: 89.69072,
    imoDraught: 9.7,
    id: 26875997,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND HERO',
    startDate: '2023-05-20T06:24:56',
    endDate: '2023-05-20T15:21:25',
    timeInPort: 0.3726,
    imo: 9339806,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-18T05:05:19Z',
    arrivalDraughtMinsDiff: 80.8166666666666,
    arrivalDraught: 8.6,
    percentArrivalDraught: 88.659805,
    departureDraughtDateTime: '2023-05-19T05:53:47Z',
    departureDraughtMinsDiff: 28.9166666666667,
    departureDraught: 8.6,
    percentDepartureDraught: 88.659805,
    imoDraught: 9.7,
    id: 26929037,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND RUBY',
    startDate: '2023-05-18T06:26:08',
    endDate: '2023-05-19T05:24:52',
    timeInPort: 0.9575,
    imo: 9325221,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-10T04:56:16Z',
    arrivalDraughtMinsDiff: 27.1666666666667,
    arrivalDraught: 8.7,
    percentArrivalDraught: 86.13861,
    departureDraughtDateTime: '2023-05-11T04:13:42Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 26876567,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COMET',
    startDate: '2023-05-10T05:23:26',
    endDate: '2023-05-11T04:13:42',
    timeInPort: 0.9516,
    imo: 9122942,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-04T05:00:48Z',
    arrivalDraughtMinsDiff: 34.3166666666667,
    arrivalDraught: 10.1,
    percentArrivalDraught: 91.818184,
    departureDraughtDateTime: '2023-05-04T14:53:20Z',
    departureDraughtMinsDiff: 25.4833333333333,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 26977643,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LIBERTY',
    startDate: '2023-05-04T05:35:07',
    endDate: '2023-05-04T14:27:51',
    timeInPort: 0.37,
    imo: 9310109,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-05-02T05:11:00Z',
    arrivalDraughtMinsDiff: 61.7666666666667,
    arrivalDraught: 9,
    percentArrivalDraught: 90.909096,
    departureDraughtDateTime: '2023-05-02T18:01:00Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 90.909096,
    imoDraught: 9.9,
    id: 27353887,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'VICTORIOUS ACE',
    startDate: '2023-05-02T06:12:46',
    endDate: '2023-05-02T18:01:00',
    timeInPort: 0.4918,
    imo: 9610406,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-28T04:18:45Z',
    arrivalDraughtMinsDiff: 53.9,
    arrivalDraught: 9.4,
    percentArrivalDraught: 93.0693,
    departureDraughtDateTime: '2023-04-28T16:23:54Z',
    departureDraughtMinsDiff: 11.15,
    departureDraught: 9.4,
    percentDepartureDraught: 93.0693,
    imoDraught: 10.1,
    id: 26851315,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS STELLA',
    startDate: '2023-04-28T05:12:39',
    endDate: '2023-04-28T16:12:45',
    timeInPort: 0.4584,
    imo: 9749570,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-23T13:20:34Z',
    arrivalDraughtMinsDiff: 51.8666666666667,
    arrivalDraught: 9.2,
    percentArrivalDraught: 93.87755,
    departureDraughtDateTime: '2023-04-24T04:43:43Z',
    departureDraughtMinsDiff: 53.5833333333333,
    departureDraught: 9.2,
    percentDepartureDraught: 93.87755,
    imoDraught: 9.8,
    id: 27135692,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PRIMROSE ACE',
    startDate: '2023-04-23T14:12:26',
    endDate: '2023-04-24T03:50:08',
    timeInPort: 0.5678,
    imo: 9355185,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-22T04:45:39Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2023-04-23T01:48:56Z',
    departureDraughtMinsDiff: 5.65,
    departureDraught: 9.6,
    percentDepartureDraught: 95.04951,
    imoDraught: 10.1,
    id: 26845298,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SPIRIT',
    startDate: '2023-04-22T04:45:39',
    endDate: '2023-04-23T01:43:17',
    timeInPort: 0.8734,
    imo: 9674165,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-19T03:47:23Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.1,
    percentArrivalDraught: 80.19802,
    departureDraughtDateTime: '2023-04-19T16:19:11Z',
    departureDraughtMinsDiff: 6.65,
    departureDraught: 8.5,
    percentDepartureDraught: 84.15841,
    imoDraught: 10.1,
    id: 26723946,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'AQUARIUS LEADER',
    startDate: '2023-04-19T03:47:23',
    endDate: '2023-04-19T16:12:32',
    timeInPort: 0.5175,
    imo: 9158276,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-17T04:53:43Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10,
    percentArrivalDraught: 88.495575,
    departureDraughtDateTime: '2023-04-17T15:36:25Z',
    departureDraughtMinsDiff: 23.6,
    departureDraught: 9.8,
    percentDepartureDraught: 86.72566,
    imoDraught: 11.3,
    id: 27301805,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TIJUCA',
    startDate: '2023-04-17T04:53:43',
    endDate: '2023-04-17T15:12:49',
    timeInPort: 0.4299,
    imo: 9377511,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-12T07:45:24Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2023-04-13T05:37:58Z',
    departureDraughtMinsDiff: 103.216666666667,
    departureDraught: 9.3,
    percentDepartureDraught: 92.0792,
    imoDraught: 10.1,
    id: 26861695,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUPERIOR',
    startDate: '2023-04-12T07:45:24',
    endDate: '2023-04-13T03:54:45',
    timeInPort: 0.8398,
    imo: 9674189,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-05T10:19:34Z',
    arrivalDraughtMinsDiff: 53.0166666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 91.75257,
    departureDraughtDateTime: '2023-04-06T04:48:43Z',
    departureDraughtMinsDiff: 58.05,
    departureDraught: 9.3,
    percentDepartureDraught: 95.87629,
    imoDraught: 9.7,
    id: 26838427,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND AURORA',
    startDate: '2023-04-05T11:12:35',
    endDate: '2023-04-06T03:50:40',
    timeInPort: 0.6931,
    imo: 9279331,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-04-01T05:10:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.2,
    percentArrivalDraught: 82,
    departureDraughtDateTime: '2023-04-01T15:23:35Z',
    departureDraughtMinsDiff: 11.7666666666667,
    departureDraught: 8.2,
    percentDepartureDraught: 82,
    imoDraught: 10,
    id: 27228524,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SERENITY HIGHWAY',
    startDate: '2023-04-01T05:10:01',
    endDate: '2023-04-01T15:11:49',
    timeInPort: 0.4179,
    imo: 9391579,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-31T04:56:59Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.2,
    percentArrivalDraught: 92.72727,
    departureDraughtDateTime: '2023-04-01T04:49:23Z',
    departureDraughtMinsDiff: 37.2833333333333,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 27265362,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORONTO',
    startDate: '2023-03-31T04:56:59',
    endDate: '2023-04-01T04:12:06',
    timeInPort: 0.9688,
    imo: 9302205,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-28T04:04:33Z',
    arrivalDraughtMinsDiff: 67.4,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2023-03-29T05:14:59Z',
    departureDraughtMinsDiff: 2.91666666666667,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 26993232,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LEO LEADER',
    startDate: '2023-03-28T05:11:57',
    endDate: '2023-03-29T05:12:04',
    timeInPort: 1.0001,
    imo: 9181558,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-26T04:23:05Z',
    arrivalDraughtMinsDiff: 49.0333333333333,
    arrivalDraught: 8.2,
    percentArrivalDraught: 84.53609,
    departureDraughtDateTime: '2023-03-26T19:51:49Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.2,
    percentDepartureDraught: 84.53609,
    imoDraught: 9.7,
    id: 26925331,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND PIONEER',
    startDate: '2023-03-26T05:12:07',
    endDate: '2023-03-26T19:51:49',
    timeInPort: 0.6109,
    imo: 9247572,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-25T06:11:30Z',
    arrivalDraughtMinsDiff: 60.4,
    arrivalDraught: 9,
    percentArrivalDraught: 91.83673,
    departureDraughtDateTime: '2023-03-25T18:50:53Z',
    departureDraughtMinsDiff: 39.1666666666667,
    departureDraught: 9,
    percentDepartureDraught: 91.83673,
    imoDraught: 9.8,
    id: 26784538,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CATTLEYA ACE',
    startDate: '2023-03-25T07:11:54',
    endDate: '2023-03-25T18:11:43',
    timeInPort: 0.4582,
    imo: 9544918,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-25T04:54:53Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.4,
    percentArrivalDraught: 83.99999,
    departureDraughtDateTime: '2023-03-25T14:17:49Z',
    departureDraughtMinsDiff: 6,
    departureDraught: 8.6,
    percentDepartureDraught: 86.00001,
    imoDraught: 10,
    id: 27194141,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC AMERICA',
    startDate: '2023-03-25T04:54:53',
    endDate: '2023-03-25T14:11:49',
    timeInPort: 0.3868,
    imo: 9277802,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-23T05:15:15Z',
    arrivalDraughtMinsDiff: 56.5166666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 90.81632,
    departureDraughtDateTime: '2023-03-24T04:40:10Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.9,
    percentDepartureDraught: 90.81632,
    imoDraught: 9.8,
    id: 26853921,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COUNTESS',
    startDate: '2023-03-23T06:11:46',
    endDate: '2023-03-24T04:40:10',
    timeInPort: 0.9364,
    imo: 9476721,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-19T05:00:25Z',
    arrivalDraughtMinsDiff: 71.5166666666667,
    arrivalDraught: 9.3,
    percentArrivalDraught: 84.545456,
    departureDraughtDateTime: '2023-03-19T16:47:24Z',
    departureDraughtMinsDiff: 35.7833333333333,
    departureDraught: 9.2,
    percentDepartureDraught: 83.63636,
    imoDraught: 11,
    id: 26793636,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DON CARLOS',
    startDate: '2023-03-19T06:11:56',
    endDate: '2023-03-19T16:11:37',
    timeInPort: 0.4164,
    imo: 9122655,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-14T16:03:15Z',
    arrivalDraughtMinsDiff: 68.55,
    arrivalDraught: 9.9,
    percentArrivalDraught: 98.01979,
    departureDraughtDateTime: '2023-03-15T04:41:34Z',
    departureDraughtMinsDiff: 29.5,
    departureDraught: 10.1,
    percentDepartureDraught: 100,
    imoDraught: 10.1,
    id: 26841110,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SOLOMON',
    startDate: '2023-03-14T17:11:48',
    endDate: '2023-03-15T04:12:04',
    timeInPort: 0.4585,
    imo: 9445409,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-03-07T03:46:24Z',
    arrivalDraughtMinsDiff: 41.6333333333333,
    arrivalDraught: 9.5,
    percentArrivalDraught: 95,
    departureDraughtDateTime: '2023-03-07T16:31:42Z',
    departureDraughtMinsDiff: 19.8666666666667,
    departureDraught: 9.5,
    percentDepartureDraught: 95,
    imoDraught: 10,
    id: 27273073,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TARIFA',
    startDate: '2023-03-07T04:28:02',
    endDate: '2023-03-07T16:11:50',
    timeInPort: 0.4888,
    imo: 9327748,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-28T08:09:37Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2023-03-01T04:15:18Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.6,
    percentDepartureDraught: 85.14852,
    imoDraught: 10.1,
    id: 27144223,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PHOENIX LEADER',
    startDate: '2023-02-28T08:09:37',
    endDate: '2023-03-01T04:15:18',
    timeInPort: 0.8373,
    imo: 9283875,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-27T10:19:29Z',
    arrivalDraughtMinsDiff: 51.6,
    arrivalDraught: 8.9,
    percentArrivalDraught: 89.89899,
    departureDraughtDateTime: '2023-02-27T21:20:13Z',
    departureDraughtMinsDiff: 9.13333333333333,
    departureDraught: 8.9,
    percentDepartureDraught: 89.89899,
    imoDraught: 9.9,
    id: 27227007,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SUNRISE ACE',
    startDate: '2023-02-27T11:11:05',
    endDate: '2023-02-27T21:11:05',
    timeInPort: 0.4167,
    imo: 9338840,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-26T04:10:57Z',
    arrivalDraughtMinsDiff: 77.1166666666666,
    arrivalDraught: 8.2,
    percentArrivalDraught: 82.828285,
    departureDraughtDateTime: '2023-02-26T16:20:19Z',
    departureDraughtMinsDiff: 9.28333333333333,
    departureDraught: 8.1,
    percentDepartureDraught: 81.81819,
    imoDraught: 9.9,
    id: 26832523,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLORIOUS ACE',
    startDate: '2023-02-26T05:28:04',
    endDate: '2023-02-26T16:11:02',
    timeInPort: 0.4465,
    imo: 9561277,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-25T04:19:27Z',
    arrivalDraughtMinsDiff: 68.1833333333333,
    arrivalDraught: 9.1,
    percentArrivalDraught: 90.099014,
    departureDraughtDateTime: '2023-02-25T12:54:28Z',
    departureDraughtMinsDiff: 43.2,
    departureDraught: 9.3,
    percentDepartureDraught: 92.0792,
    imoDraught: 10.1,
    id: 26869439,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CHAMPION',
    startDate: '2023-02-25T05:27:38',
    endDate: '2023-02-25T12:11:16',
    timeInPort: 0.2803,
    imo: 9651113,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-24T04:56:37Z',
    arrivalDraughtMinsDiff: 31.9833333333333,
    arrivalDraught: 8.6,
    percentArrivalDraught: 87.755104,
    departureDraughtDateTime: '2023-02-24T19:23:48Z',
    departureDraughtMinsDiff: 12.5166666666667,
    departureDraught: 8.6,
    percentDepartureDraught: 87.755104,
    imoDraught: 9.8,
    id: 27175999,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PARADISE ACE',
    startDate: '2023-02-24T05:28:36',
    endDate: '2023-02-24T19:11:17',
    timeInPort: 0.5713,
    imo: 9293648,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-23T06:17:23Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.8,
    percentArrivalDraught: 89.09091,
    departureDraughtDateTime: '2023-02-23T16:20:40Z',
    departureDraughtMinsDiff: 9.55,
    departureDraught: 9.8,
    percentDepartureDraught: 89.09091,
    imoDraught: 11,
    id: 27094929,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MIGNON',
    startDate: '2023-02-23T06:17:23',
    endDate: '2023-02-23T16:11:07',
    timeInPort: 0.4123,
    imo: 9189251,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-22T05:08:27Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.5,
    percentArrivalDraught: 94.0594,
    departureDraughtDateTime: '2023-02-23T03:44:29Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.5,
    percentDepartureDraught: 94.0594,
    imoDraught: 10.1,
    id: 26863650,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SYMPHONY',
    startDate: '2023-02-22T05:08:27',
    endDate: '2023-02-23T03:44:29',
    timeInPort: 0.9417,
    imo: 9702429,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-12T04:43:36Z',
    arrivalDraughtMinsDiff: 31.55,
    arrivalDraught: 8.4,
    percentArrivalDraught: 83.168304,
    departureDraughtDateTime: '2023-02-13T04:17:08Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.8,
    percentDepartureDraught: 87.12871,
    imoDraught: 10.1,
    id: 26851119,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COSMOS',
    startDate: '2023-02-12T05:15:09',
    endDate: '2023-02-13T04:17:08',
    timeInPort: 0.9597,
    imo: 9707027,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-02-04T04:05:05Z',
    arrivalDraughtMinsDiff: 74.4,
    arrivalDraught: 9.5,
    percentArrivalDraught: 94.0594,
    departureDraughtDateTime: '2023-02-05T05:43:48Z',
    departureDraughtMinsDiff: 23.6166666666667,
    departureDraught: 9.3,
    percentDepartureDraught: 92.0792,
    imoDraught: 10.1,
    id: 27080911,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING LUCY',
    startDate: '2023-02-04T05:19:29',
    endDate: '2023-02-05T05:20:11',
    timeInPort: 1.0005,
    imo: 9383431,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-01-27T04:28:41Z',
    arrivalDraughtMinsDiff: 50.65,
    arrivalDraught: 9,
    percentArrivalDraught: 90.909096,
    departureDraughtDateTime: '2023-01-27T16:48:55Z',
    departureDraughtMinsDiff: 28.2333333333333,
    departureDraught: 9.3,
    percentDepartureDraught: 93.9394,
    imoDraught: 9.9,
    id: 27122257,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'NEPTUNE ACE',
    startDate: '2023-01-27T05:19:20',
    endDate: '2023-01-27T16:20:41',
    timeInPort: 0.4593,
    imo: 9584059,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2023-01-26T05:02:12Z',
    arrivalDraughtMinsDiff: 68.4,
    arrivalDraught: 10,
    percentArrivalDraught: 88.495575,
    departureDraughtDateTime: '2023-01-27T05:41:59Z',
    departureDraughtMinsDiff: 31.4333333333333,
    departureDraught: 9.7,
    percentDepartureDraught: 85.840706,
    imoDraught: 11.3,
    id: 26718697,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ANIARA',
    startDate: '2023-01-26T06:10:36',
    endDate: '2023-01-27T05:10:33',
    timeInPort: 0.9583,
    imo: 9377494,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-31T04:08:47Z',
    arrivalDraughtMinsDiff: 68.9166666666667,
    arrivalDraught: 9,
    percentArrivalDraught: 90.909096,
    departureDraughtDateTime: '2022-12-31T14:39:19Z',
    departureDraughtMinsDiff: 19.2666666666667,
    departureDraught: 9.2,
    percentDepartureDraught: 92.9293,
    imoDraught: 9.9,
    id: 27219577,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SANDERLING ACE',
    startDate: '2022-12-31T05:17:42',
    endDate: '2022-12-31T14:20:03',
    timeInPort: 0.3766,
    imo: 9409481,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-27T08:18:23Z',
    arrivalDraughtMinsDiff: 56.9333333333333,
    arrivalDraught: 7.5,
    percentArrivalDraught: 76.53061,
    departureDraughtDateTime: '2022-12-29T11:54:02Z',
    departureDraughtMinsDiff: 41.9333333333333,
    departureDraught: 7.5,
    percentDepartureDraught: 76.53061,
    imoDraught: 9.8,
    id: 26890950,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE DAKAR',
    startDate: '2022-12-27T09:15:19',
    endDate: '2022-12-29T11:12:06',
    timeInPort: 2.0811,
    imo: 9680724,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-27T05:52:25Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2022-12-28T09:56:23Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 89.10891,
    imoDraught: 10.1,
    id: 26861016,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUPERIOR',
    startDate: '2022-12-27T05:52:25',
    endDate: '2022-12-28T09:56:23',
    timeInPort: 1.1694,
    imo: 9674189,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-26T05:05:42Z',
    arrivalDraughtMinsDiff: 73.2666666666667,
    arrivalDraught: 10.3,
    percentArrivalDraught: 88.7931,
    departureDraughtDateTime: '2022-12-27T08:47:06Z',
    departureDraughtMinsDiff: 26.0166666666667,
    departureDraught: 10.3,
    percentDepartureDraught: 88.7931,
    imoDraught: 11.6,
    id: 27283560,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TITANIA',
    startDate: '2022-12-26T06:18:58',
    endDate: '2022-12-27T08:21:05',
    timeInPort: 1.0848,
    imo: 9505053,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-26T04:19:38Z',
    arrivalDraughtMinsDiff: 52.8166666666667,
    arrivalDraught: 8.4,
    percentArrivalDraught: 85.71428,
    departureDraughtDateTime: '2022-12-27T05:36:55Z',
    departureDraughtMinsDiff: 19.3,
    departureDraught: 8.7,
    percentDepartureDraught: 88.775505,
    imoDraught: 9.8,
    id: 27075363,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MARGUERITE ACE',
    startDate: '2022-12-26T05:12:27',
    endDate: '2022-12-27T05:17:37',
    timeInPort: 1.0036,
    imo: 9426386,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-24T06:11:28Z',
    arrivalDraughtMinsDiff: 65.8833333333334,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.99999,
    departureDraughtDateTime: '2022-12-24T15:52:29Z',
    departureDraughtMinsDiff: 36.9333333333333,
    departureDraught: 9.1,
    percentDepartureDraught: 91.00001,
    imoDraught: 10,
    id: 26938430,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GUARDIAN LEADER',
    startDate: '2022-12-24T07:17:21',
    endDate: '2022-12-24T15:15:33',
    timeInPort: 0.3321,
    imo: 9388716,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-20T05:01:19Z',
    arrivalDraughtMinsDiff: 74.2666666666667,
    arrivalDraught: 9.5,
    percentArrivalDraught: 94.0594,
    departureDraughtDateTime: '2022-12-20T15:49:21Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.5,
    percentDepartureDraught: 94.0594,
    imoDraught: 10.1,
    id: 26855915,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUMMIT',
    startDate: '2022-12-20T06:15:35',
    endDate: '2022-12-20T15:49:21',
    timeInPort: 0.3984,
    imo: 9702417,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-15T10:25:05Z',
    arrivalDraughtMinsDiff: 49.0833333333333,
    arrivalDraught: 8.1,
    percentArrivalDraught: 82.65307,
    departureDraughtDateTime: '2022-12-16T05:34:42Z',
    departureDraughtMinsDiff: 18.75,
    departureDraught: 7.9,
    percentDepartureDraught: 80.61224,
    imoDraught: 9.8,
    id: 26885914,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE MAROCCO',
    startDate: '2022-12-15T11:14:10',
    endDate: '2022-12-16T05:15:57',
    timeInPort: 0.7512,
    imo: 9437907,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-10T06:12:24Z',
    arrivalDraughtMinsDiff: 67.3333333333333,
    arrivalDraught: 8.1,
    percentArrivalDraught: 82.65307,
    departureDraughtDateTime: '2022-12-11T09:34:51Z',
    departureDraughtMinsDiff: 17.65,
    departureDraught: 8.7,
    percentDepartureDraught: 88.775505,
    imoDraught: 9.8,
    id: 27155400,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PROGRESS ACE',
    startDate: '2022-12-10T07:19:44',
    endDate: '2022-12-11T09:17:12',
    timeInPort: 1.0816,
    imo: 9267687,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-12-04T05:03:40Z',
    arrivalDraughtMinsDiff: 68.4,
    arrivalDraught: 10.2,
    percentArrivalDraught: 92.72727,
    departureDraughtDateTime: '2022-12-06T04:45:33Z',
    departureDraughtMinsDiff: 33.4333333333333,
    departureDraught: 10.1,
    percentDepartureDraught: 91.818184,
    imoDraught: 11,
    id: 27261760,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORINO',
    startDate: '2022-12-04T06:12:04',
    endDate: '2022-12-06T04:12:07',
    timeInPort: 1.9167,
    imo: 9398321,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-28T04:50:08Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.118805,
    departureDraughtDateTime: '2022-11-28T15:20:09Z',
    departureDraughtMinsDiff: 9.28333333333333,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 26846684,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SPLENDOR',
    startDate: '2022-11-28T04:50:08',
    endDate: '2022-11-28T15:10:52',
    timeInPort: 0.4311,
    imo: 9702431,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-27T04:47:12Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.2,
    percentArrivalDraught: 84.53609,
    departureDraughtDateTime: '2022-11-27T22:34:55Z',
    departureDraughtMinsDiff: 23.2333333333333,
    departureDraught: 8.7,
    percentDepartureDraught: 89.69072,
    imoDraught: 9.7,
    id: 26873580,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND EAGLE',
    startDate: '2022-11-27T04:47:12',
    endDate: '2022-11-27T22:11:41',
    timeInPort: 0.7253,
    imo: 9267663,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-26T08:08:37Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 90.909096,
    departureDraughtDateTime: '2022-11-27T04:45:13Z',
    departureDraughtMinsDiff: 33.2166666666667,
    departureDraught: 9,
    percentDepartureDraught: 90.909096,
    imoDraught: 9.9,
    id: 27343320,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'VALIANT ACE',
    startDate: '2022-11-26T08:08:37',
    endDate: '2022-11-27T04:12:00',
    timeInPort: 0.8357,
    imo: 9610420,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-25T06:03:05Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.8,
    percentArrivalDraught: 90.72165,
    departureDraughtDateTime: '2022-11-26T07:18:44Z',
    departureDraughtMinsDiff: 6.96666666666667,
    departureDraught: 8.8,
    percentDepartureDraught: 90.72165,
    imoDraught: 9.7,
    id: 26924651,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND PIONEER',
    startDate: '2022-11-25T06:03:05',
    endDate: '2022-11-26T07:11:46',
    timeInPort: 1.0477,
    imo: 9247572,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-23T04:30:34Z',
    arrivalDraughtMinsDiff: 40.2333333333333,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2022-11-23T17:58:43Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 27301665,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'WISDOM ACE',
    startDate: '2022-11-23T05:10:48',
    endDate: '2022-11-23T17:58:43',
    timeInPort: 0.5333,
    imo: 9641833,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-20T13:46:46Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 7.9,
    percentArrivalDraught: 83.1579,
    departureDraughtDateTime: '2022-11-21T05:33:39Z',
    departureDraughtMinsDiff: 22.6666666666667,
    departureDraught: 8.3,
    percentDepartureDraught: 87.36842,
    imoDraught: 9.5,
    id: 26822364,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GALAXY LEADER',
    startDate: '2022-11-20T13:46:46',
    endDate: '2022-11-21T05:10:59',
    timeInPort: 0.6418,
    imo: 9237307,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-18T05:14:57Z',
    arrivalDraughtMinsDiff: 56.1333333333333,
    arrivalDraught: 10.5,
    percentArrivalDraught: 95.454544,
    departureDraughtDateTime: '2022-11-18T15:41:33Z',
    departureDraughtMinsDiff: 29.6666666666667,
    departureDraught: 10.2,
    percentDepartureDraught: 92.72727,
    imoDraught: 11,
    id: 27267275,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORRENS',
    startDate: '2022-11-18T06:11:05',
    endDate: '2022-11-18T15:11:53',
    timeInPort: 0.3756,
    imo: 9293612,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-11-06T04:20:48Z',
    arrivalDraughtMinsDiff: 50.05,
    arrivalDraught: 8,
    percentArrivalDraught: 81.63265,
    departureDraughtDateTime: '2022-11-08T05:30:11Z',
    departureDraughtMinsDiff: 19.35,
    departureDraught: 7.9,
    percentDepartureDraught: 80.61224,
    imoDraught: 9.8,
    id: 26889090,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE COTONOU',
    startDate: '2022-11-06T05:10:51',
    endDate: '2022-11-08T05:10:50',
    timeInPort: 2,
    imo: 9672105,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-31T04:20:06Z',
    arrivalDraughtMinsDiff: 43.35,
    arrivalDraught: 9.3,
    percentArrivalDraught: 92.0792,
    departureDraughtDateTime: '2022-10-31T19:07:06Z',
    departureDraughtMinsDiff: 3,
    departureDraught: 9.3,
    percentDepartureDraught: 92.0792,
    imoDraught: 10.1,
    id: 27050415,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MERCURY ACE',
    startDate: '2022-10-31T05:03:27',
    endDate: '2022-10-31T19:04:06',
    timeInPort: 0.5838,
    imo: 9591052,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-27T04:04:58Z',
    arrivalDraughtMinsDiff: 58.1,
    arrivalDraught: 8.8,
    percentArrivalDraught: 84.61539,
    departureDraughtDateTime: '2022-10-28T05:48:21Z',
    departureDraughtMinsDiff: 45.4833333333333,
    departureDraught: 9,
    percentDepartureDraught: 86.53847,
    imoDraught: 10.4,
    id: 26771195,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DAEDALUS LEADER',
    startDate: '2022-10-27T05:03:04',
    endDate: '2022-10-28T05:02:52',
    timeInPort: 0.9999,
    imo: 9426362,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-25T04:32:29Z',
    arrivalDraughtMinsDiff: 31.4166666666667,
    arrivalDraught: 10,
    percentArrivalDraught: 90.90909,
    departureDraughtDateTime: '2022-10-26T05:27:43Z',
    departureDraughtMinsDiff: 23.7333333333333,
    departureDraught: 9.8,
    percentDepartureDraught: 89.09091,
    imoDraught: 11,
    id: 26727146,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'BOHEME',
    startDate: '2022-10-25T05:03:54',
    endDate: '2022-10-26T05:03:59',
    timeInPort: 1.0001,
    imo: 9176565,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-22T09:39:33Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 87.755104,
    departureDraughtDateTime: '2022-10-23T04:39:38Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.9,
    percentDepartureDraught: 90.81632,
    imoDraught: 9.8,
    id: 26824591,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GARNET ACE',
    startDate: '2022-10-22T09:39:33',
    endDate: '2022-10-23T04:39:38',
    timeInPort: 0.7917,
    imo: 9476769,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-21T04:34:14Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.4,
    percentArrivalDraught: 93.0693,
    departureDraughtDateTime: '2022-10-22T08:57:07Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.4,
    percentDepartureDraught: 93.0693,
    imoDraught: 10.1,
    id: 26860169,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CAPTAIN',
    startDate: '2022-10-21T04:34:14',
    endDate: '2022-10-22T08:57:07',
    timeInPort: 1.1826,
    imo: 9707015,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-11T04:17:38Z',
    arrivalDraughtMinsDiff: 46.0333333333333,
    arrivalDraught: 10,
    percentArrivalDraught: 90.90909,
    departureDraughtDateTime: '2022-10-11T15:45:20Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 27323144,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TRAVIATA',
    startDate: '2022-10-11T05:03:40',
    endDate: '2022-10-11T15:45:20',
    timeInPort: 0.4456,
    imo: 9700524,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-10-04T04:20:20Z',
    arrivalDraughtMinsDiff: 42.75,
    arrivalDraught: 8,
    percentArrivalDraught: 80,
    departureDraughtDateTime: '2022-10-04T19:43:49Z',
    departureDraughtMinsDiff: 40.7,
    departureDraught: 8.5,
    percentDepartureDraught: 85,
    imoDraught: 10,
    id: 27226806,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SERENITY HIGHWAY',
    startDate: '2022-10-04T05:03:05',
    endDate: '2022-10-04T19:03:07',
    timeInPort: 0.5834,
    imo: 9391579,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-30T04:32:39Z',
    arrivalDraughtMinsDiff: 90.2833333333333,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2022-09-30T15:37:29Z',
    departureDraughtMinsDiff: 34.6333333333333,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 26850299,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS STELLA',
    startDate: '2022-09-30T06:02:56',
    endDate: '2022-09-30T15:02:51',
    timeInPort: 0.3749,
    imo: 9749570,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-26T06:04:15Z',
    arrivalDraughtMinsDiff: 60.3666666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 89.89899,
    departureDraughtDateTime: '2022-09-28T09:26:57Z',
    departureDraughtMinsDiff: 22.6833333333333,
    departureDraught: 9.1,
    percentDepartureDraught: 91.919205,
    imoDraught: 9.9,
    id: 26831696,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLORIOUS ACE',
    startDate: '2022-09-26T07:04:37',
    endDate: '2022-09-28T09:04:16',
    timeInPort: 2.0831,
    imo: 9561277,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-25T05:19:05Z',
    arrivalDraughtMinsDiff: 43.15,
    arrivalDraught: 8.7,
    percentArrivalDraught: 88.775505,
    departureDraughtDateTime: '2022-09-26T07:43:41Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.7,
    percentDepartureDraught: 88.775505,
    imoDraught: 9.8,
    id: 26986515,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LAVENDER ACE',
    startDate: '2022-09-25T06:02:14',
    endDate: '2022-09-26T07:43:41',
    timeInPort: 1.0705,
    imo: 9363948,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-25T04:58:14Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2022-09-26T01:30:25Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.4,
    percentDepartureDraught: 83.168304,
    imoDraught: 10.1,
    id: 26782865,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CASSIOPEIA LEADER',
    startDate: '2022-09-25T04:58:14',
    endDate: '2022-09-26T01:30:25',
    timeInPort: 0.8557,
    imo: 9182277,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-21T03:29:58Z',
    arrivalDraughtMinsDiff: 75.8166666666666,
    arrivalDraught: 8.1,
    percentArrivalDraught: 81.00001,
    departureDraughtDateTime: '2022-09-21T17:29:09Z',
    departureDraughtMinsDiff: 24.6833333333333,
    departureDraught: 9.3,
    percentDepartureDraught: 93,
    imoDraught: 10,
    id: 27167606,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC ANTWERP',
    startDate: '2022-09-21T04:45:47',
    endDate: '2022-09-21T17:04:28',
    timeInPort: 0.513,
    imo: 9441623,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-17T05:28:31Z',
    arrivalDraughtMinsDiff: 33.9666666666667,
    arrivalDraught: 8.4,
    percentArrivalDraught: 83.168304,
    departureDraughtDateTime: '2022-09-17T15:40:01Z',
    departureDraughtMinsDiff: 37.45,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 26875368,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COMET',
    startDate: '2022-09-17T06:02:29',
    endDate: '2022-09-17T15:02:34',
    timeInPort: 0.3751,
    imo: 9122942,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-13T14:03:57Z',
    arrivalDraughtMinsDiff: 118.4,
    arrivalDraught: 9.5,
    percentArrivalDraught: 84.82143,
    departureDraughtDateTime: '2022-09-14T06:17:04Z',
    departureDraughtMinsDiff: 14.7333333333333,
    departureDraught: 9.7,
    percentDepartureDraught: 86.60715,
    imoDraught: 11.2,
    id: 27113082,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GOLD KOCHAV',
    startDate: '2022-09-13T16:02:21',
    endDate: '2022-09-14T06:02:20',
    timeInPort: 0.5833,
    imo: 9463205,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-12T02:22:42Z',
    arrivalDraughtMinsDiff: 160.783333333333,
    arrivalDraught: 10.1,
    percentArrivalDraught: 89.38053,
    departureDraughtDateTime: '2022-09-12T19:53:08Z',
    departureDraughtMinsDiff: 409.466666666667,
    departureDraught: 8.3,
    percentDepartureDraught: 73.451324,
    imoDraught: 11.3,
    id: 26718153,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ANIARA',
    startDate: '2022-09-12T05:03:29',
    endDate: '2022-09-12T13:03:40',
    timeInPort: 0.3335,
    imo: 9377494,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-07T05:07:30Z',
    arrivalDraughtMinsDiff: 55.2,
    arrivalDraught: 10,
    percentArrivalDraught: 88.495575,
    departureDraughtDateTime: '2022-09-07T16:00:21Z',
    departureDraughtMinsDiff: 57.7833333333333,
    departureDraught: 10,
    percentDepartureDraught: 88.495575,
    imoDraught: 11.3,
    id: 26710979,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ARC COMMITMENT',
    startDate: '2022-09-07T06:02:42',
    endDate: '2022-09-07T15:02:34',
    timeInPort: 0.3749,
    imo: 9505039,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-09-04T04:32:28Z',
    arrivalDraughtMinsDiff: 30.4833333333333,
    arrivalDraught: 8.1,
    percentArrivalDraught: 82.65307,
    departureDraughtDateTime: '2022-09-04T19:08:34Z',
    departureDraughtMinsDiff: 5.65,
    departureDraught: 8.1,
    percentDepartureDraught: 82.65307,
    imoDraught: 9.8,
    id: 26721157,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'AQUAMARINE ACE',
    startDate: '2022-09-04T05:02:57',
    endDate: '2022-09-04T19:02:55',
    timeInPort: 0.5833,
    imo: 9397987,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-08-18T05:30:24Z',
    arrivalDraughtMinsDiff: 33.8333333333333,
    arrivalDraught: 9.7,
    percentArrivalDraught: 96.0396,
    departureDraughtDateTime: '2022-08-19T04:40:39Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.7,
    percentDepartureDraught: 96.0396,
    imoDraught: 10.1,
    id: 27028998,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING CHANT',
    startDate: '2022-08-18T06:04:14',
    endDate: '2022-08-19T04:40:39',
    timeInPort: 0.942,
    imo: 9663300,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-08-17T13:34:57Z',
    arrivalDraughtMinsDiff: 29.3666666666667,
    arrivalDraught: 8.4,
    percentArrivalDraught: 86.59793,
    departureDraughtDateTime: '2022-08-18T05:42:29Z',
    departureDraughtMinsDiff: 38.05,
    departureDraught: 8.9,
    percentDepartureDraught: 91.75257,
    imoDraught: 9.7,
    id: 26843813,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND COSMO',
    startDate: '2022-08-17T14:04:19',
    endDate: '2022-08-18T05:04:26',
    timeInPort: 0.6251,
    imo: 9303182,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-08-17T04:16:21Z',
    arrivalDraughtMinsDiff: 46.2833333333333,
    arrivalDraught: 8.9,
    percentArrivalDraught: 91.75257,
    departureDraughtDateTime: '2022-08-17T13:35:53Z',
    departureDraughtMinsDiff: 33.2333333333333,
    departureDraught: 8.9,
    percentDepartureDraught: 91.75257,
    imoDraught: 9.7,
    id: 26868331,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND DIAMOND',
    startDate: '2022-08-17T05:02:38',
    endDate: '2022-08-17T13:02:39',
    timeInPort: 0.3333,
    imo: 9303223,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-08-16T10:41:04Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9,
    percentArrivalDraught: 89.10891,
    departureDraughtDateTime: '2022-08-17T04:53:52.2Z',
    departureDraughtMinsDiff: 50.72,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 26860164,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUPERIOR',
    startDate: '2022-08-16T10:41:04',
    endDate: '2022-08-17T04:03:09',
    timeInPort: 0.7237,
    imo: 9674189,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-08-11T04:53:35Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 86.00001,
    departureDraughtDateTime: '2022-08-11T17:15:54Z',
    departureDraughtMinsDiff: 12.8166666666667,
    departureDraught: 8.9,
    percentDepartureDraught: 88.99999,
    imoDraught: 10,
    id: 27166300,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC AMSTERDAM',
    startDate: '2022-08-11T04:53:35',
    endDate: '2022-08-11T17:03:05',
    timeInPort: 0.5066,
    imo: 9660798,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-08-05T04:50:35Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.4,
    percentArrivalDraught: 94.94949,
    departureDraughtDateTime: '2022-08-05T17:51:48Z',
    departureDraughtMinsDiff: 47.8,
    departureDraught: 9.4,
    percentDepartureDraught: 94.94949,
    imoDraught: 9.9,
    id: 26728654,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'BRILLIANT ACE',
    startDate: '2022-08-05T04:50:35',
    endDate: '2022-08-05T17:04:00',
    timeInPort: 0.5093,
    imo: 9598012,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-26T06:48:37Z',
    arrivalDraughtMinsDiff: 14.65,
    arrivalDraught: 7.5,
    percentArrivalDraught: 75,
    departureDraughtDateTime: '2022-07-27T00:57:00Z',
    departureDraughtMinsDiff: 51.15,
    departureDraught: 8.2,
    percentDepartureDraught: 82,
    imoDraught: 10,
    id: 20795019,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'HEROIC ACE',
    startDate: '2022-07-26T07:03:16',
    endDate: '2022-07-27T00:05:51',
    timeInPort: 0.7101,
    imo: 9252216,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-23T04:22:18Z',
    arrivalDraughtMinsDiff: 40,
    arrivalDraught: 8.8,
    percentArrivalDraught: 89.79591,
    departureDraughtDateTime: '2022-07-24T15:42:57Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 91.83673,
    imoDraught: 9.8,
    id: 23929667,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CATTLEYA ACE',
    startDate: '2022-07-23T05:02:18',
    endDate: '2022-07-24T15:42:57',
    timeInPort: 1.4449,
    imo: 9544918,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-22T04:48:29Z',
    arrivalDraughtMinsDiff: 75,
    arrivalDraught: 8,
    percentArrivalDraught: 79.20792,
    departureDraughtDateTime: '2022-07-22T20:53:23Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.4,
    percentDepartureDraught: 83.168304,
    imoDraught: 10.1,
    id: 23883308,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'AQUARIUS LEADER',
    startDate: '2022-07-22T06:03:29',
    endDate: '2022-07-22T20:53:23',
    timeInPort: 0.618,
    imo: 9158276,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-21T13:46:28Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.3,
    percentArrivalDraught: 85.56701,
    departureDraughtDateTime: '2022-07-22T04:49:02Z',
    departureDraughtMinsDiff: 46.4,
    departureDraught: 8.3,
    percentDepartureDraught: 85.56701,
    imoDraught: 9.7,
    id: 24016016,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND HERO',
    startDate: '2022-07-21T13:46:28',
    endDate: '2022-07-22T04:02:38',
    timeInPort: 0.5946,
    imo: 9339806,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-21T05:35:36Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.6,
    percentArrivalDraught: 98.96908,
    departureDraughtDateTime: '2022-07-21T15:19:12Z',
    departureDraughtMinsDiff: 15.6,
    departureDraught: 9.4,
    percentDepartureDraught: 96.90721,
    imoDraught: 9.7,
    id: 23886850,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'BESS',
    startDate: '2022-07-21T05:35:36',
    endDate: '2022-07-21T15:03:36',
    timeInPort: 0.3944,
    imo: 9531715,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-20T07:11:51Z',
    arrivalDraughtMinsDiff: 51.1,
    arrivalDraught: 10.1,
    percentArrivalDraught: 91.818184,
    departureDraughtDateTime: '2022-07-21T07:32:49Z',
    departureDraughtMinsDiff: 29.7166666666667,
    departureDraught: 10,
    percentDepartureDraught: 90.90909,
    imoDraught: 11,
    id: 21247538,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TONGALA',
    startDate: '2022-07-20T08:02:57',
    endDate: '2022-07-21T07:03:06',
    timeInPort: 0.9584,
    imo: 9605786,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-18T07:40:23Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.7,
    percentArrivalDraught: 96.0396,
    departureDraughtDateTime: '2022-07-19T04:40:49Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.6,
    percentDepartureDraught: 95.04951,
    imoDraught: 10.1,
    id: 24003218,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS STELLA',
    startDate: '2022-07-18T07:40:23',
    endDate: '2022-07-19T04:40:49',
    timeInPort: 0.8753,
    imo: 9749570,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-16T06:43:34Z',
    arrivalDraughtMinsDiff: 19.6666666666667,
    arrivalDraught: 9.8,
    percentArrivalDraught: 98,
    departureDraughtDateTime: '2022-07-16T16:51:19Z',
    departureDraughtMinsDiff: 48.1666666666667,
    departureDraught: 9.7,
    percentDepartureDraught: 97,
    imoDraught: 10,
    id: 20960235,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING CELESTA',
    startDate: '2022-07-16T07:03:14',
    endDate: '2022-07-16T16:03:09',
    timeInPort: 0.3749,
    imo: 9336062,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-07T07:03:07Z',
    arrivalDraughtMinsDiff: 59.55,
    arrivalDraught: 8.8,
    percentArrivalDraught: 88.88889,
    departureDraughtDateTime: '2022-07-07T20:36:05Z',
    departureDraughtMinsDiff: 33.5166666666667,
    departureDraught: 8.9,
    percentDepartureDraught: 89.89899,
    imoDraught: 9.9,
    id: 20581085,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DIVINE ACE',
    startDate: '2022-07-07T08:02:40',
    endDate: '2022-07-07T20:02:34',
    timeInPort: 0.4999,
    imo: 9610432,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-04T05:33:43Z',
    arrivalDraughtMinsDiff: 89.8666666666666,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.118805,
    departureDraughtDateTime: '2022-07-04T17:30:50Z',
    departureDraughtMinsDiff: 27.4,
    departureDraught: 9.3,
    percentDepartureDraught: 92.0792,
    imoDraught: 10.1,
    id: 20977813,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING COMPOSER',
    startDate: '2022-07-04T07:03:35',
    endDate: '2022-07-04T17:03:26',
    timeInPort: 0.4166,
    imo: 9336074,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-07-03T04:45:43Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.5,
    percentArrivalDraught: 89.622635,
    departureDraughtDateTime: '2022-07-03T18:43:47Z',
    departureDraughtMinsDiff: 160.333333333333,
    departureDraught: 9.6,
    percentDepartureDraught: 90.56604,
    imoDraught: 10.6,
    id: 24626570,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SILVER',
    startDate: '2022-07-03T04:45:43',
    endDate: '2022-07-03T16:03:27',
    timeInPort: 0.4706,
    imo: 9775828,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-25T05:20:02Z',
    arrivalDraughtMinsDiff: 43.5333333333333,
    arrivalDraught: 9.8,
    percentArrivalDraught: 89.09091,
    departureDraughtDateTime: '2022-06-25T13:46:50Z',
    departureDraughtMinsDiff: 43.6166666666667,
    departureDraught: 9.6,
    percentDepartureDraught: 87.272736,
    imoDraught: 11,
    id: 24088064,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MIGNON',
    startDate: '2022-06-25T06:03:34',
    endDate: '2022-06-25T13:03:13',
    timeInPort: 0.2914,
    imo: 9189251,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-23T14:37:46Z',
    arrivalDraughtMinsDiff: 24.8833333333333,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2022-06-25T07:55:17Z',
    departureDraughtMinsDiff: 52.65,
    departureDraught: 8.8,
    percentDepartureDraught: 87.12871,
    imoDraught: 10.1,
    id: 21108462,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PRECIOUS ACE',
    startDate: '2022-06-23T15:02:39',
    endDate: '2022-06-25T07:02:38',
    timeInPort: 1.6667,
    imo: 9554200,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-23T06:41:10Z',
    arrivalDraughtMinsDiff: 21.2666666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.118805,
    departureDraughtDateTime: '2022-06-23T19:41:19Z',
    departureDraughtMinsDiff: 38.65,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 20631238,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COURAGE',
    startDate: '2022-06-23T07:02:26',
    endDate: '2022-06-23T19:02:40',
    timeInPort: 0.5002,
    imo: 9651101,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-20T07:39:26Z',
    arrivalDraughtMinsDiff: 23.4666666666667,
    arrivalDraught: 8.1,
    percentArrivalDraught: 81.00001,
    departureDraughtDateTime: '2022-06-20T22:55:54Z',
    departureDraughtMinsDiff: 53.0166666666667,
    departureDraught: 8.2,
    percentDepartureDraught: 82,
    imoDraught: 10,
    id: 20690502,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRACEFUL LEADER',
    startDate: '2022-06-20T08:02:54',
    endDate: '2022-06-20T22:02:53',
    timeInPort: 0.5833,
    imo: 9357303,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-17T10:42:46Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.118805,
    departureDraughtDateTime: '2022-06-18T06:53:22Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.9,
    percentDepartureDraught: 88.118805,
    imoDraught: 10.1,
    id: 24574571,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CHAMPION',
    startDate: '2022-06-17T10:42:46',
    endDate: '2022-06-18T06:53:22',
    timeInPort: 0.8407,
    imo: 9651113,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-09T05:31:30Z',
    arrivalDraughtMinsDiff: 91.4,
    arrivalDraught: 8.4,
    percentArrivalDraught: 83.99999,
    departureDraughtDateTime: '2022-06-09T18:40:44Z',
    departureDraughtMinsDiff: 97.8333333333333,
    departureDraught: 8.4,
    percentDepartureDraught: 83.99999,
    imoDraught: 10,
    id: 21142486,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SERENITY HIGHWAY',
    startDate: '2022-06-09T07:02:54',
    endDate: '2022-06-09T17:02:54',
    timeInPort: 0.4167,
    imo: 9391579,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-08T06:54:06Z',
    arrivalDraughtMinsDiff: 8.86666666666667,
    arrivalDraught: 9.3,
    percentArrivalDraught: 84.545456,
    departureDraughtDateTime: '2022-06-08T14:44:04Z',
    departureDraughtMinsDiff: 41.2166666666667,
    departureDraught: 9.3,
    percentDepartureDraught: 84.545456,
    imoDraught: 11,
    id: 20916032,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MANON',
    startDate: '2022-06-08T07:02:58',
    endDate: '2022-06-08T14:02:51',
    timeInPort: 0.2916,
    imo: 9179725,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-04T06:42:33Z',
    arrivalDraughtMinsDiff: 20.0333333333333,
    arrivalDraught: 9.3,
    percentArrivalDraught: 92.0792,
    departureDraughtDateTime: '2022-06-04T14:20:01Z',
    departureDraughtMinsDiff: 17.4833333333333,
    departureDraught: 9.5,
    percentDepartureDraught: 94.0594,
    imoDraught: 10.1,
    id: 24574227,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CAPTAIN',
    startDate: '2022-06-04T07:02:35',
    endDate: '2022-06-04T14:02:32',
    timeInPort: 0.2916,
    imo: 9707015,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-06-03T04:56:38Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2022-06-03T17:58:22Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9,
    percentDepartureDraught: 89.10891,
    imoDraught: 10.1,
    id: 24077655,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MARVELOUS ACE',
    startDate: '2022-06-03T04:56:38',
    endDate: '2022-06-03T17:58:22',
    timeInPort: 0.5429,
    imo: 9293519,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-29T07:04:44Z',
    arrivalDraughtMinsDiff: 58.7,
    arrivalDraught: 8.8,
    percentArrivalDraught: 88.88889,
    departureDraughtDateTime: '2022-05-30T07:31:07Z',
    departureDraughtMinsDiff: 87.7,
    departureDraught: 8.8,
    percentDepartureDraught: 88.88889,
    imoDraught: 9.9,
    id: 21278742,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'VALIANT ACE',
    startDate: '2022-05-29T08:03:26',
    endDate: '2022-05-30T06:03:25',
    timeInPort: 0.9167,
    imo: 9610420,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-28T05:07:29Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10,
    percentArrivalDraught: 94.33962,
    departureDraughtDateTime: '2022-05-29T06:53:09Z',
    departureDraughtMinsDiff: 41.1666666666667,
    departureDraught: 10,
    percentDepartureDraught: 94.33962,
    imoDraught: 10.6,
    id: 24105909,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING PRIDE',
    startDate: '2022-05-28T05:07:29',
    endDate: '2022-05-29T06:11:59',
    timeInPort: 1.0448,
    imo: 9681431,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-27T06:53:16Z',
    arrivalDraughtMinsDiff: 11.8333333333333,
    arrivalDraught: 8.7,
    percentArrivalDraught: 86.13861,
    departureDraughtDateTime: '2022-05-27T14:53:38Z',
    departureDraughtMinsDiff: 48.45,
    departureDraught: 8.7,
    percentDepartureDraught: 86.13861,
    imoDraught: 10.1,
    id: 24574057,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CAPTAIN',
    startDate: '2022-05-27T07:05:06',
    endDate: '2022-05-27T14:05:11',
    timeInPort: 0.2917,
    imo: 9707015,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-20T06:43:09Z',
    arrivalDraughtMinsDiff: 20.3333333333333,
    arrivalDraught: 8,
    percentArrivalDraught: 79.20792,
    departureDraughtDateTime: '2022-05-20T20:54:40Z',
    departureDraughtMinsDiff: 49.6166666666667,
    departureDraught: 8.4,
    percentDepartureDraught: 83.168304,
    imoDraught: 10.1,
    id: 21099566,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'ORION LEADER',
    startDate: '2022-05-20T07:03:29',
    endDate: '2022-05-20T20:05:03',
    timeInPort: 0.5428,
    imo: 9182289,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-18T09:28:45Z',
    arrivalDraughtMinsDiff: 34.6666666666667,
    arrivalDraught: 8.9,
    percentArrivalDraught: 88.99999,
    departureDraughtDateTime: '2022-05-18T18:51:05Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.9,
    percentDepartureDraught: 88.99999,
    imoDraught: 10,
    id: 24608289,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'RCC ANTWERP',
    startDate: '2022-05-18T10:03:25',
    endDate: '2022-05-18T18:51:05',
    timeInPort: 0.3664,
    imo: 9441623,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-17T03:54:42.19Z',
    arrivalDraughtMinsDiff: 52.8135,
    arrivalDraught: 9.3,
    percentArrivalDraught: 93,
    departureDraughtDateTime: '2022-05-18T05:42:49.72Z',
    departureDraughtMinsDiff: 60.3286666666667,
    departureDraught: 9.3,
    percentDepartureDraught: 93,
    imoDraught: 10,
    id: 23975393,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'G POSEIDON',
    startDate: '2022-05-17T04:47:31',
    endDate: '2022-05-18T04:42:30',
    timeInPort: 0.9965,
    imo: 9445394,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-12T13:07:29Z',
    arrivalDraughtMinsDiff: 61.2666666666667,
    arrivalDraught: 7.9,
    percentArrivalDraught: 80.61224,
    departureDraughtDateTime: '2022-05-12T21:53:27Z',
    departureDraughtMinsDiff: 44.6166666666667,
    departureDraught: 8.2,
    percentDepartureDraught: 83.67347,
    imoDraught: 9.8,
    id: 21064423,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PROGRESS ACE',
    startDate: '2022-05-12T14:08:45',
    endDate: '2022-05-12T21:08:50',
    timeInPort: 0.2917,
    imo: 9267687,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-09T06:03:37Z',
    arrivalDraughtMinsDiff: 66.6833333333333,
    arrivalDraught: 8.6,
    percentArrivalDraught: 85.14852,
    departureDraughtDateTime: '2022-05-10T07:05:06Z',
    departureDraughtMinsDiff: 54.5666666666667,
    departureDraught: 8.7,
    percentDepartureDraught: 86.13861,
    imoDraught: 10.1,
    id: 20690531,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SYMPHONY',
    startDate: '2022-05-09T07:10:18',
    endDate: '2022-05-10T06:10:32',
    timeInPort: 0.9585,
    imo: 9702429,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-05-02T07:01:27Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.1,
    percentArrivalDraught: 91.818184,
    departureDraughtDateTime: '2022-05-03T06:01:25Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.1,
    percentDepartureDraught: 91.818184,
    imoDraught: 11,
    id: 21255290,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TRAVIATA',
    startDate: '2022-05-02T07:01:27',
    endDate: '2022-05-03T06:01:25',
    timeInPort: 0.9583,
    imo: 9700524,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-30T07:01:27Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.2,
    percentArrivalDraught: 92.72727,
    departureDraughtDateTime: '2022-05-02T07:01:27Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.2,
    percentDepartureDraught: 92.72727,
    imoDraught: 11,
    id: 21221469,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORTUGAS',
    startDate: '2022-04-30T07:01:27',
    endDate: '2022-05-02T07:01:27',
    timeInPort: 2,
    imo: 9319765,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-27T05:00:09.143Z',
    arrivalDraughtMinsDiff: 38.48095,
    arrivalDraught: 8.8,
    percentArrivalDraught: 87.12871,
    departureDraughtDateTime: '2022-04-27T20:05:35Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.1,
    percentDepartureDraught: 90.099014,
    imoDraught: 10.1,
    id: 24078268,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MERCURY ACE',
    startDate: '2022-04-27T05:38:38',
    endDate: '2022-04-27T20:05:35',
    timeInPort: 0.602,
    imo: 9591052,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-26T03:00:40.903Z',
    arrivalDraughtMinsDiff: 109.48495,
    arrivalDraught: 8.3,
    percentArrivalDraught: 85.56701,
    departureDraughtDateTime: '2022-04-27T03:59:08.883Z',
    departureDraughtMinsDiff: 0.0147166666666667,
    departureDraught: 8.6,
    percentDepartureDraught: 88.659805,
    imoDraught: 9.7,
    id: 20661508,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND COSMO',
    startDate: '2022-04-26T04:50:10',
    endDate: '2022-04-27T03:59:08',
    timeInPort: 0.9646,
    imo: 9303182,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-23T01:59:13.576Z',
    arrivalDraughtMinsDiff: 184.973733333333,
    arrivalDraught: 8.3,
    percentArrivalDraught: 84.69388,
    departureDraughtDateTime: '2022-04-23T21:06:46.527Z',
    departureDraughtMinsDiff: 0.00878333333333334,
    departureDraught: 8.3,
    percentDepartureDraught: 84.69388,
    imoDraught: 9.8,
    id: 21047249,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'OPAL ACE',
    startDate: '2022-04-23T05:04:12',
    endDate: '2022-04-23T21:06:46',
    timeInPort: 0.6684,
    imo: 9539183,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-22T04:13:06.611Z',
    arrivalDraughtMinsDiff: 62.1898166666667,
    arrivalDraught: 7.9,
    percentArrivalDraught: 80.61224,
    departureDraughtDateTime: '2022-04-23T06:19:54.917Z',
    departureDraughtMinsDiff: 0.0152833333333333,
    departureDraught: 7.9,
    percentDepartureDraught: 80.61224,
    imoDraught: 9.8,
    id: 20668053,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND MARK',
    startDate: '2022-04-22T05:15:18',
    endDate: '2022-04-23T06:19:54',
    timeInPort: 1.0449,
    imo: 9228306,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-21T04:04:37.606Z',
    arrivalDraughtMinsDiff: 43.7565666666667,
    arrivalDraught: 8.8,
    percentArrivalDraught: 90.72165,
    departureDraughtDateTime: '2022-04-21T20:59:02.938Z',
    departureDraughtMinsDiff: 3.4823,
    departureDraught: 9,
    percentDepartureDraught: 92.78351,
    imoDraught: 9.7,
    id: 24015484,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND HERO',
    startDate: '2022-04-21T04:48:23',
    endDate: '2022-04-21T20:55:34',
    timeInPort: 0.6717,
    imo: 9339806,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-17T03:47:06.265Z',
    arrivalDraughtMinsDiff: 61.71225,
    arrivalDraught: 4.8,
    percentArrivalDraught: 47.524754,
    departureDraughtDateTime: '2022-04-17T14:57:45.238Z',
    departureDraughtMinsDiff: 0.00396666666666667,
    departureDraught: 4.8,
    percentDepartureDraught: 47.524754,
    imoDraught: 10.1,
    id: 24579005,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SIRIUS',
    startDate: '2022-04-17T04:48:49',
    endDate: '2022-04-17T14:57:45',
    timeInPort: 0.4229,
    imo: 9749582,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-06T04:47:31.111Z',
    arrivalDraughtMinsDiff: 47.84815,
    arrivalDraught: 7.7,
    percentArrivalDraught: 78.57143,
    departureDraughtDateTime: '2022-04-06T11:45:33.961Z',
    departureDraughtMinsDiff: 0.0160166666666667,
    departureDraught: 7.7,
    percentDepartureDraught: 78.57143,
    imoDraught: 9.8,
    id: 20746673,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRANDE MAROCCO',
    startDate: '2022-04-06T05:35:22',
    endDate: '2022-04-06T11:45:33',
    timeInPort: 0.2571,
    imo: 9437907,
    portId: 586,
    operatorId: 2781,
    operatorShortName: 'GRIM',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-03T12:59:28.018Z',
    arrivalDraughtMinsDiff: 57.6830333333333,
    arrivalDraught: 10,
    percentArrivalDraught: 94.33962,
    departureDraughtDateTime: '2022-04-04T04:50:55.52Z',
    departureDraughtMinsDiff: 0.00866666666666666,
    departureDraught: 10,
    percentDepartureDraught: 94.33962,
    imoDraught: 10.6,
    id: 21233431,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'THEMIS',
    startDate: '2022-04-03T13:57:09',
    endDate: '2022-04-04T04:50:55',
    timeInPort: 0.6207,
    imo: 9722314,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-04-02T03:06:51.645Z',
    arrivalDraughtMinsDiff: 101.53925,
    arrivalDraught: 8.5,
    percentArrivalDraught: 85,
    departureDraughtDateTime: '2022-04-02T14:52:30.287Z',
    departureDraughtMinsDiff: 0.00478333333333333,
    departureDraught: 8.5,
    percentDepartureDraught: 85,
    imoDraught: 10,
    id: 24574596,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CARAVEL',
    startDate: '2022-04-02T04:48:24',
    endDate: '2022-04-02T14:52:30',
    timeInPort: 0.4195,
    imo: 9441594,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-30T04:48:43.557Z',
    arrivalDraughtMinsDiff: 51.1907166666667,
    arrivalDraught: 10.2,
    percentArrivalDraught: 90.26549,
    departureDraughtDateTime: '2022-03-30T12:30:16Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.2,
    percentDepartureDraught: 90.26549,
    imoDraught: 11.3,
    id: 24176548,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TIRRANNA',
    startDate: '2022-03-30T05:39:55',
    endDate: '2022-03-30T12:30:16',
    timeInPort: 0.285,
    imo: 9377523,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-29T03:05:30.22Z',
    arrivalDraughtMinsDiff: 60.6796666666667,
    arrivalDraught: 8.1,
    percentArrivalDraught: 81.81819,
    departureDraughtDateTime: '2022-03-30T04:57:00.968Z',
    departureDraughtMinsDiff: 0.0161333333333333,
    departureDraught: 8.6,
    percentDepartureDraught: 86.8687,
    imoDraught: 9.9,
    id: 21197193,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SWALLOW ACE',
    startDate: '2022-03-29T04:06:11',
    endDate: '2022-03-30T04:57:00',
    timeInPort: 1.0353,
    imo: 9338620,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-27T02:57:54.844Z',
    arrivalDraughtMinsDiff: 65.0192666666667,
    arrivalDraught: 8,
    percentArrivalDraught: 79.20792,
    departureDraughtDateTime: '2022-03-28T04:04:10.498Z',
    departureDraughtMinsDiff: 0.0083,
    departureDraught: 8.3,
    percentDepartureDraught: 82.178215,
    imoDraught: 10.1,
    id: 20893235,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'LEO LEADER',
    startDate: '2022-03-27T04:02:56',
    endDate: '2022-03-28T04:04:10',
    timeInPort: 1.0009,
    imo: 9181558,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-26T03:00:02.09Z',
    arrivalDraughtMinsDiff: 61.9818333333333,
    arrivalDraught: 9.3,
    percentArrivalDraught: 93.9394,
    departureDraughtDateTime: '2022-03-26T16:02:42.943Z',
    departureDraughtMinsDiff: 0.0157166666666667,
    departureDraught: 9.4,
    percentDepartureDraught: 94.94949,
    imoDraught: 9.9,
    id: 21060919,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PRIME ACE',
    startDate: '2022-03-26T04:02:01',
    endDate: '2022-03-26T16:02:42',
    timeInPort: 0.5005,
    imo: 9610444,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-25T03:41:49.781Z',
    arrivalDraughtMinsDiff: 61.20365,
    arrivalDraught: 8.7,
    percentArrivalDraught: 86.13861,
    departureDraughtDateTime: '2022-03-25T11:35:48.1Z',
    departureDraughtMinsDiff: 0.00166666666666667,
    departureDraught: 8.7,
    percentDepartureDraught: 86.13861,
    imoDraught: 10.1,
    id: 21107606,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PRECIOUS ACE',
    startDate: '2022-03-25T04:43:02',
    endDate: '2022-03-25T11:35:48',
    timeInPort: 0.2866,
    imo: 9554200,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-23T04:01:40.86Z',
    arrivalDraughtMinsDiff: 61.669,
    arrivalDraught: 10.2,
    percentArrivalDraught: 92.72727,
    departureDraughtDateTime: '2022-03-23T13:15:58.153Z',
    departureDraughtMinsDiff: 0.00255,
    departureDraught: 10.2,
    percentDepartureDraught: 92.72727,
    imoDraught: 11,
    id: 21220931,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TORRENS',
    startDate: '2022-03-23T05:03:21',
    endDate: '2022-03-23T13:15:58',
    timeInPort: 0.3421,
    imo: 9293612,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-22T05:10:12.484Z',
    arrivalDraughtMinsDiff: 102.875266666667,
    arrivalDraught: 8.3,
    percentArrivalDraught: 82.178215,
    departureDraughtDateTime: '2022-03-22T17:07:30.456Z',
    departureDraughtMinsDiff: 0.0076,
    departureDraught: 8.2,
    percentDepartureDraught: 81.18812,
    imoDraught: 10.1,
    id: 20647852,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CORONA',
    startDate: '2022-03-22T06:53:05',
    endDate: '2022-03-22T17:07:30',
    timeInPort: 0.4267,
    imo: 9122930,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-22T03:46:51.747Z',
    arrivalDraughtMinsDiff: 53.7042166666667,
    arrivalDraught: 9.4,
    percentArrivalDraught: 93.0693,
    departureDraughtDateTime: '2022-03-23T04:42:49.987Z',
    departureDraughtMinsDiff: 17.6997833333333,
    departureDraught: 9.5,
    percentDepartureDraught: 94.0594,
    imoDraught: 10.1,
    id: 24002525,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS STELLA',
    startDate: '2022-03-22T04:40:34',
    endDate: '2022-03-23T04:25:08',
    timeInPort: 0.9893,
    imo: 9749570,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-21T06:41:06Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10.4,
    percentArrivalDraught: 92.0354,
    departureDraughtDateTime: '2022-03-22T03:49:20.419Z',
    departureDraughtMinsDiff: 3.60698333333333,
    departureDraught: 10.3,
    percentDepartureDraught: 91.150444,
    imoDraught: 11.3,
    id: 23967050,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'FIGARO',
    startDate: '2022-03-21T06:41:06',
    endDate: '2022-03-22T03:45:44',
    timeInPort: 0.8782,
    imo: 9505041,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-21T04:00:30.443Z',
    arrivalDraughtMinsDiff: 64.3092833333334,
    arrivalDraught: 9.2,
    percentArrivalDraught: 91.0891,
    departureDraughtDateTime: '2022-03-21T16:06:08.276Z',
    departureDraughtMinsDiff: 0.0046,
    departureDraught: 9.2,
    percentDepartureDraught: 91.0891,
    imoDraught: 10.1,
    id: 20638624,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS CHALLENGE',
    startDate: '2022-03-21T05:04:49',
    endDate: '2022-03-21T16:06:08',
    timeInPort: 0.4592,
    imo: 9590591,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-03-11T14:35:42Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.2,
    percentArrivalDraught: 92,
    departureDraughtDateTime: '2022-03-11T21:00:01Z',
    departureDraughtMinsDiff: 1.15,
    departureDraught: 9.2,
    percentDepartureDraught: 92,
    imoDraught: 10,
    id: 24093532,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MORNING CAROL',
    startDate: '2022-03-11T14:35:42',
    endDate: '2022-03-11T20:58:52',
    timeInPort: 0.2661,
    imo: 9336086,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-28T06:00:01Z',
    arrivalDraughtMinsDiff: 50.3333333333333,
    arrivalDraught: 8.9,
    percentArrivalDraught: 90.81632,
    departureDraughtDateTime: '2022-02-28T18:00:01Z',
    departureDraughtMinsDiff: 2.4,
    departureDraught: 8.9,
    percentDepartureDraught: 90.81632,
    imoDraught: 9.8,
    id: 20612693,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'FREEDOM ACE',
    startDate: '2022-02-28T06:50:21',
    endDate: '2022-02-28T17:57:37',
    timeInPort: 0.4634,
    imo: 9293662,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-27T04:00:01Z',
    arrivalDraughtMinsDiff: 46.4666666666667,
    arrivalDraught: 7.8,
    percentArrivalDraught: 77.22772,
    departureDraughtDateTime: '2022-02-28T05:00:01Z',
    departureDraughtMinsDiff: 14.5666666666667,
    departureDraught: 7.8,
    percentDepartureDraught: 77.22772,
    imoDraught: 10.1,
    id: 20765701,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GREEN COVE',
    startDate: '2022-02-27T04:46:29',
    endDate: '2022-02-28T04:45:27',
    timeInPort: 0.9993,
    imo: 9181560,
    portId: 586,
    operatorId: 2799,
    operatorShortName: 'NYK',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-25T05:00:01.25Z',
    arrivalDraughtMinsDiff: 55.4625,
    arrivalDraught: 8.7,
    percentArrivalDraught: 86.13861,
    departureDraughtDateTime: '2022-02-26T04:24:34Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.7,
    percentDepartureDraught: 86.13861,
    imoDraught: 10.1,
    id: 24201394,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'WISDOM ACE',
    startDate: '2022-02-25T05:55:29',
    endDate: '2022-02-26T04:24:34',
    timeInPort: 0.9369,
    imo: 9641833,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-24T04:00:01Z',
    arrivalDraughtMinsDiff: 35.35,
    arrivalDraught: 9.8,
    percentArrivalDraught: 89.09091,
    departureDraughtDateTime: '2022-02-24T13:00:01Z',
    departureDraughtMinsDiff: 19.3666666666667,
    departureDraught: 9.6,
    percentDepartureDraught: 87.272736,
    imoDraught: 11,
    id: 24187821,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'UNDINE',
    startDate: '2022-02-24T04:35:22',
    endDate: '2022-02-24T12:40:39',
    timeInPort: 0.337,
    imo: 9240160,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-21T04:00:01Z',
    arrivalDraughtMinsDiff: 39.3166666666667,
    arrivalDraught: 8.7,
    percentArrivalDraught: 87,
    departureDraughtDateTime: '2022-02-22T00:00:01Z',
    departureDraughtMinsDiff: 22.35,
    departureDraught: 8.7,
    percentDepartureDraught: 87,
    imoDraught: 10,
    id: 20840696,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'HORIZON RAY',
    startDate: '2022-02-21T04:39:20',
    endDate: '2022-02-21T23:37:40',
    timeInPort: 0.7905,
    imo: 9441520,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-19T05:32:38Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.8,
    percentArrivalDraught: 87.12871,
    departureDraughtDateTime: '2022-02-19T20:16:41Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.8,
    percentDepartureDraught: 87.12871,
    imoDraught: 10.1,
    id: 24584138,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS COSMOS',
    startDate: '2022-02-19T05:32:38',
    endDate: '2022-02-19T20:16:41',
    timeInPort: 0.6139,
    imo: 9707027,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-17T05:00:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.7,
    percentArrivalDraught: 79.09091,
    departureDraughtDateTime: '2022-02-17T16:00:01Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.7,
    percentDepartureDraught: 79.09091,
    imoDraught: 11,
    id: 23945172,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DON CARLOS',
    startDate: '2022-02-17T05:00:01',
    endDate: '2022-02-17T16:00:01',
    timeInPort: 0.4583,
    imo: 9122655,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-16T05:00:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 10,
    percentArrivalDraught: 86.206894,
    departureDraughtDateTime: '2022-02-17T05:00:01Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10,
    percentDepartureDraught: 86.206894,
    imoDraught: 11.6,
    id: 24178324,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'TITANIA',
    startDate: '2022-02-16T05:00:01',
    endDate: '2022-02-17T05:00:01',
    timeInPort: 1,
    imo: 9505053,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-14T04:00:01Z',
    arrivalDraughtMinsDiff: 7.38333333333333,
    arrivalDraught: 8.5,
    percentArrivalDraught: 96.590904,
    departureDraughtDateTime: '2022-02-14T16:33:34Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.7,
    percentDepartureDraught: 98.86363,
    imoDraught: 8.8,
    id: 24153683,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'SILVER GLORY',
    startDate: '2022-02-14T04:07:24',
    endDate: '2022-02-14T16:33:34',
    timeInPort: 0.5182,
    imo: 9070474,
    portId: 586,
    operatorId: 2804,
    operatorShortName: 'SALLAU',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-05T05:00:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 9.3,
    percentArrivalDraught: 95.87629,
    departureDraughtDateTime: '2022-02-05T16:00:01Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.3,
    percentDepartureDraught: 95.87629,
    imoDraught: 9.7,
    id: 20674132,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GRAND PAVO',
    startDate: '2022-02-05T05:00:01',
    endDate: '2022-02-05T16:00:01',
    timeInPort: 0.4583,
    imo: 9284776,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-02-01T06:00:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.8,
    percentArrivalDraught: 87.12871,
    departureDraughtDateTime: '2022-02-01T16:00:01Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 8.8,
    percentDepartureDraught: 87.12871,
    imoDraught: 10.1,
    id: 24003012,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'GLOVIS SUPERIOR',
    startDate: '2022-02-01T06:00:01',
    endDate: '2022-02-01T16:00:01',
    timeInPort: 0.4167,
    imo: 9674189,
    portId: 586,
    operatorId: 2779,
    operatorShortName: 'GLOVIS',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-01-31T04:00:01Z',
    arrivalDraughtMinsDiff: 45.7333333333333,
    arrivalDraught: 10.3,
    percentArrivalDraught: 91.150444,
    departureDraughtDateTime: '2022-02-01T11:00:02Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 10.3,
    percentDepartureDraught: 91.150444,
    imoDraught: 11.3,
    id: 23924939,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'CARMEN',
    startDate: '2022-01-31T04:45:45',
    endDate: '2022-02-01T11:00:02',
    timeInPort: 1.2599,
    imo: 9505027,
    portId: 586,
    operatorId: 2820,
    operatorShortName: 'WW',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-01-29T05:00:01Z',
    arrivalDraughtMinsDiff: 32.45,
    arrivalDraught: 8.8,
    percentArrivalDraught: 88.88889,
    departureDraughtDateTime: '2022-01-30T04:00:01Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 9.2,
    percentDepartureDraught: 92.9293,
    imoDraught: 9.9,
    id: 20943166,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'MERMAID ACE',
    startDate: '2022-01-29T05:32:28',
    endDate: '2022-01-30T04:00:01',
    timeInPort: 0.9358,
    imo: 9561289,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-01-24T04:40:54Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.4,
    percentArrivalDraught: 84.84848,
    departureDraughtDateTime: '2022-01-24T17:00:01Z',
    departureDraughtMinsDiff: 2.43333333333333,
    departureDraught: 8.4,
    percentDepartureDraught: 84.84848,
    imoDraught: 9.9,
    id: 23918483,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'BRILLIANT ACE',
    startDate: '2022-01-24T04:40:54',
    endDate: '2022-01-24T16:57:35',
    timeInPort: 0.5116,
    imo: 9598012,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2022-01-05T06:00:01Z',
    arrivalDraughtMinsDiff: 0,
    arrivalDraught: 8.3,
    percentArrivalDraught: 83.83839,
    departureDraughtDateTime: '2022-01-05T17:00:01Z',
    departureDraughtMinsDiff: 12.8166666666667,
    departureDraught: 8.3,
    percentDepartureDraught: 83.83839,
    imoDraught: 9.9,
    id: 20579857,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'DIVINE ACE',
    startDate: '2022-01-05T06:00:01',
    endDate: '2022-01-05T16:47:12',
    timeInPort: 0.4494,
    imo: 9610432,
    portId: 586,
    operatorId: 438,
    operatorShortName: 'MOL ACE',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  },
  {
    origin: 'san Juan',
    directionTo: 'eastBound',
    arrivalDraughtDateTime: '2021-12-26T14:41:00.97Z',
    arrivalDraughtMinsDiff: 0.0005,
    arrivalDraught: 6.6,
    percentArrivalDraught: 75,
    departureDraughtDateTime: '2021-12-27T11:00:01Z',
    departureDraughtMinsDiff: 0,
    departureDraught: 6.6,
    percentDepartureDraught: 75,
    imoDraught: 8.8,
    id: 24122742,
    portCode: 'ZAELS',
    portName: 'East London',
    vesselName: 'PELAGIC PIRANHA',
    startDate: '2021-12-26T14:41:01',
    endDate: '2021-12-27T11:00:01',
    timeInPort: 0.8465,
    imo: 9088249,
    portId: 586,
    operatorId: 2804,
    operatorShortName: 'SALLAU',
    countryId: 316,
    countryName: 'South Africa',
    regionId: 62,
    regionName: 'Africa - South'
  }
];

export const mockCanalStats = [
  {
    weeklyCanalStats: [
      {
        week: 1,
        weekStart: '2021-01-04T00:00:00',
        numoftransits: 160,
        eastBound: 80,
        westBound: 80,
        year: 2021
      },
      {
        week: 2,
        weekStart: '2021-01-11T00:00:00',
        numoftransits: 163,
        eastBound: 81,
        westBound: 82,
        year: 2021
      },
      {
        week: 3,
        weekStart: '2021-01-18T00:00:00',
        numoftransits: 168,
        eastBound: 85,
        westBound: 83,
        year: 2021
      },
      {
        week: 4,
        weekStart: '2021-01-25T00:00:00',
        numoftransits: 171,
        eastBound: 86,
        westBound: 85,
        year: 2021
      },
      {
        week: 5,
        weekStart: '2021-02-01T00:00:00',
        numoftransits: 162,
        eastBound: 81,
        westBound: 81,
        year: 2021
      },
      {
        week: 6,
        weekStart: '2021-02-08T00:00:00',
        numoftransits: 155,
        eastBound: 78,
        westBound: 77,
        year: 2021
      },
      {
        week: 7,
        weekStart: '2021-02-15T00:00:00',
        numoftransits: 163,
        eastBound: 82,
        westBound: 81,
        year: 2021
      },
      {
        week: 8,
        weekStart: '2021-02-22T00:00:00',
        numoftransits: 164,
        eastBound: 82,
        westBound: 82,
        year: 2021
      },
      {
        week: 9,
        weekStart: '2021-03-01T00:00:00',
        numoftransits: 166,
        eastBound: 83,
        westBound: 83,
        year: 2021
      },
      {
        week: 10,
        weekStart: '2021-03-08T00:00:00',
        numoftransits: 168,
        eastBound: 84,
        westBound: 84,
        year: 2021
      },
      {
        week: 11,
        weekStart: '2021-03-15T00:00:00',
        numoftransits: 159,
        eastBound: 80,
        westBound: 79,
        year: 2021
      },
      {
        week: 12,
        weekStart: '2021-03-22T00:00:00',
        numoftransits: 160,
        eastBound: 80,
        westBound: 80,
        year: 2021
      },
      {
        week: 13,
        weekStart: '2021-03-29T00:00:00',
        numoftransits: 171,
        eastBound: 86,
        westBound: 85,
        year: 2021
      },
      {
        week: 14,
        weekStart: '2021-04-05T00:00:00',
        numoftransits: 169,
        eastBound: 85,
        westBound: 84,
        year: 2021
      },
      {
        week: 15,
        weekStart: '2021-04-12T00:00:00',
        numoftransits: 172,
        eastBound: 87,
        westBound: 85,
        year: 2021
      },
      {
        week: 16,
        weekStart: '2021-04-19T00:00:00',
        numoftransits: 158,
        eastBound: 79,
        westBound: 79,
        year: 2021
      },
      {
        week: 17,
        weekStart: '2021-04-26T00:00:00',
        numoftransits: 166,
        eastBound: 83,
        westBound: 83,
        year: 2021
      },
      {
        week: 18,
        weekStart: '2021-05-03T00:00:00',
        numoftransits: 167,
        eastBound: 84,
        westBound: 83,
        year: 2021
      },
      {
        week: 19,
        weekStart: '2021-05-10T00:00:00',
        numoftransits: 155,
        eastBound: 77,
        westBound: 78,
        year: 2021
      },
      {
        week: 20,
        weekStart: '2021-05-17T00:00:00',
        numoftransits: 162,
        eastBound: 81,
        westBound: 81,
        year: 2021
      },
      {
        week: 21,
        weekStart: '2021-05-24T00:00:00',
        numoftransits: 167,
        eastBound: 84,
        westBound: 83,
        year: 2021
      },
      {
        week: 22,
        weekStart: '2021-05-31T00:00:00',
        numoftransits: 152,
        eastBound: 76,
        westBound: 76,
        year: 2021
      },
      {
        week: 23,
        weekStart: '2021-06-07T00:00:00',
        numoftransits: 155,
        eastBound: 78,
        westBound: 77,
        year: 2021
      },
      {
        week: 24,
        weekStart: '2021-06-14T00:00:00',
        numoftransits: 173,
        eastBound: 87,
        westBound: 86,
        year: 2021
      },
      {
        week: 25,
        weekStart: '2021-06-21T00:00:00',
        numoftransits: 153,
        eastBound: 76,
        westBound: 77,
        year: 2021
      },
      {
        week: 26,
        weekStart: '2021-06-28T00:00:00',
        numoftransits: 170,
        eastBound: 85,
        westBound: 85,
        year: 2021
      },
      {
        week: 27,
        weekStart: '2021-07-05T00:00:00',
        numoftransits: 160,
        eastBound: 80,
        westBound: 80,
        year: 2021
      },
      {
        week: 28,
        weekStart: '2021-07-12T00:00:00',
        numoftransits: 177,
        eastBound: 89,
        westBound: 88,
        year: 2021
      },
      {
        week: 29,
        weekStart: '2021-07-19T00:00:00',
        numoftransits: 163,
        eastBound: 82,
        westBound: 81,
        year: 2021
      },
      {
        week: 30,
        weekStart: '2021-07-26T00:00:00',
        numoftransits: 161,
        eastBound: 81,
        westBound: 80,
        year: 2021
      },
      {
        week: 31,
        weekStart: '2021-08-02T00:00:00',
        numoftransits: 148,
        eastBound: 74,
        westBound: 74,
        year: 2021
      },
      {
        week: 32,
        weekStart: '2021-08-09T00:00:00',
        numoftransits: 149,
        eastBound: 75,
        westBound: 74,
        year: 2021
      },
      {
        week: 33,
        weekStart: '2021-08-16T00:00:00',
        numoftransits: 156,
        eastBound: 78,
        westBound: 78,
        year: 2021
      },
      {
        week: 34,
        weekStart: '2021-08-23T00:00:00',
        numoftransits: 162,
        eastBound: 81,
        westBound: 81,
        year: 2021
      },
      {
        week: 35,
        weekStart: '2021-08-30T00:00:00',
        numoftransits: 169,
        eastBound: 85,
        westBound: 84,
        year: 2021
      },
      {
        week: 36,
        weekStart: '2021-09-06T00:00:00',
        numoftransits: 163,
        eastBound: 82,
        westBound: 81,
        year: 2021
      },
      {
        week: 37,
        weekStart: '2021-09-13T00:00:00',
        numoftransits: 168,
        eastBound: 84,
        westBound: 84,
        year: 2021
      },
      {
        week: 38,
        weekStart: '2021-09-20T00:00:00',
        numoftransits: 169,
        eastBound: 85,
        westBound: 84,
        year: 2021
      },
      {
        week: 39,
        weekStart: '2021-09-27T00:00:00',
        numoftransits: 168,
        eastBound: 84,
        westBound: 84,
        year: 2021
      },
      {
        week: 40,
        weekStart: '2021-10-04T00:00:00',
        numoftransits: 160,
        eastBound: 80,
        westBound: 80,
        year: 2021
      },
      {
        week: 41,
        weekStart: '2021-10-11T00:00:00',
        numoftransits: 160,
        eastBound: 80,
        westBound: 80,
        year: 2021
      },
      {
        week: 42,
        weekStart: '2021-10-18T00:00:00',
        numoftransits: 153,
        eastBound: 77,
        westBound: 76,
        year: 2021
      },
      {
        week: 43,
        weekStart: '2021-10-25T00:00:00',
        numoftransits: 170,
        eastBound: 85,
        westBound: 85,
        year: 2021
      },
      {
        week: 44,
        weekStart: '2021-11-01T00:00:00',
        numoftransits: 154,
        eastBound: 77,
        westBound: 77,
        year: 2021
      },
      {
        week: 45,
        weekStart: '2021-11-08T00:00:00',
        numoftransits: 145,
        eastBound: 73,
        westBound: 72,
        year: 2021
      },
      {
        week: 46,
        weekStart: '2021-11-15T00:00:00',
        numoftransits: 162,
        eastBound: 81,
        westBound: 81,
        year: 2021
      },
      {
        week: 47,
        weekStart: '2021-11-22T00:00:00',
        numoftransits: 177,
        eastBound: 89,
        westBound: 88,
        year: 2021
      },
      {
        week: 48,
        weekStart: '2021-11-29T00:00:00',
        numoftransits: 160,
        eastBound: 80,
        westBound: 80,
        year: 2021
      },
      {
        week: 49,
        weekStart: '2021-12-06T00:00:00',
        numoftransits: 157,
        eastBound: 79,
        westBound: 78,
        year: 2021
      },
      {
        week: 50,
        weekStart: '2021-12-13T00:00:00',
        numoftransits: 151,
        eastBound: 75,
        westBound: 76,
        year: 2021
      },
      {
        week: 51,
        weekStart: '2021-12-20T00:00:00',
        numoftransits: 170,
        eastBound: 85,
        westBound: 85,
        year: 2021
      },
      {
        week: 52,
        weekStart: '2021-12-27T00:00:00',
        numoftransits: 159,
        eastBound: 80,
        westBound: 79,
        year: 2021
      }
    ],
    monthlyCanalStats: [
      {
        month: '2021-01-01T00:00:00',
        numoftransits: 500,
        eastBound: 250,
        westBound: 250
      },
      {
        month: '2021-02-01T00:00:00',
        numoftransits: 420,
        eastBound: 200,
        westBound: 220
      },
      {
        month: '2021-03-01T00:00:00',
        numoftransits: 520,
        eastBound: 300,
        westBound: 220
      },
      {
        month: '2021-04-01T00:00:00',
        numoftransits: 600,
        eastBound: 330,
        westBound: 270
      },
      {
        month: '2021-05-01T00:00:00',
        numoftransits: 550,
        eastBound: 275,
        westBound: 275
      },
      {
        month: '2021-06-01T00:00:00',
        numoftransits: 580,
        eastBound: 290,
        westBound: 290
      },
      {
        month: '2021-07-01T00:00:00',
        numoftransits: 450,
        eastBound: 220,
        westBound: 230
      },
      {
        month: '2021-08-01T00:00:00',
        numoftransits: 610,
        eastBound: 310,
        westBound: 300
      },
      {
        month: '2021-09-01T00:00:00',
        numoftransits: 430,
        eastBound: 210,
        westBound: 220
      },
      {
        month: '2021-10-01T00:00:00',
        numoftransits: 475,
        eastBound: 240,
        westBound: 235
      },
      {
        month: '2021-11-01T00:00:00',
        numoftransits: 510,
        eastBound: 260,
        westBound: 250
      },
      {
        month: '2021-12-01T00:00:00',
        numoftransits: 530,
        eastBound: 280,
        westBound: 250
      }
    ]
  }
];

export const mockOprTransits = [
  {
    oprWeeklyResults: [
      { transitStats: 20, week: 1, eastBound: 11, westBound: 9 },
      { transitStats: 14, week: 2, eastBound: 8, westBound: 6 },
      { transitStats: 7, week: 3, eastBound: 3, westBound: 4 },
      { transitStats: 19, week: 4, eastBound: 10, westBound: 9 },
      { transitStats: 12, week: 5, eastBound: 7, westBound: 5 },
      { transitStats: 3, week: 6, eastBound: 2, westBound: 1 },
      { transitStats: 18, week: 7, eastBound: 9, westBound: 9 },
      { transitStats: 6, week: 8, eastBound: 3, westBound: 3 },
      { transitStats: 2, week: 9, eastBound: 1, westBound: 1 },
      { transitStats: 17, week: 10, eastBound: 9, westBound: 8 },
      { transitStats: 5, week: 11, eastBound: 2, westBound: 3 },
      { transitStats: 13, week: 12, eastBound: 7, westBound: 6 },
      { transitStats: 4, week: 13, eastBound: 2, westBound: 2 },
      { transitStats: 15, week: 14, eastBound: 8, westBound: 7 },
      { transitStats: 8, week: 15, eastBound: 4, westBound: 4 },
      { transitStats: 11, week: 16, eastBound: 6, westBound: 5 },
      { transitStats: 1, week: 17, eastBound: 1, westBound: 0 },
      { transitStats: 16, week: 18, eastBound: 8, westBound: 8 },
      { transitStats: 9, week: 19, eastBound: 5, westBound: 4 },
      { transitStats: 10, week: 20, eastBound: 5, westBound: 5 },
      { transitStats: 3, week: 21, eastBound: 1, westBound: 2 },
      { transitStats: 20, week: 22, eastBound: 10, westBound: 10 },
      { transitStats: 2, week: 23, eastBound: 1, westBound: 1 },
      { transitStats: 19, week: 24, eastBound: 10, westBound: 9 },
      { transitStats: 7, week: 25, eastBound: 4, westBound: 3 },
      { transitStats: 14, week: 26, eastBound: 7, westBound: 7 },
      { transitStats: 6, week: 27, eastBound: 3, westBound: 3 },
      { transitStats: 13, week: 28, eastBound: 6, westBound: 7 },
      { transitStats: 5, week: 29, eastBound: 3, westBound: 2 },
      { transitStats: 17, week: 30, eastBound: 9, westBound: 8 },
      { transitStats: 4, week: 31, eastBound: 2, westBound: 2 },
      { transitStats: 12, week: 32, eastBound: 6, westBound: 6 },
      { transitStats: 11, week: 33, eastBound: 5, westBound: 6 },
      { transitStats: 8, week: 34, eastBound: 4, westBound: 4 },
      { transitStats: 18, week: 35, eastBound: 9, westBound: 9 },
      { transitStats: 9, week: 36, eastBound: 4, westBound: 5 },
      { transitStats: 15, week: 37, eastBound: 8, westBound: 7 },
      { transitStats: 1, week: 38, eastBound: 0, westBound: 1 },
      { transitStats: 16, week: 39, eastBound: 8, westBound: 8 },
      { transitStats: 10, week: 40, eastBound: 5, westBound: 5 },
      { transitStats: 3, week: 41, eastBound: 2, westBound: 1 },
      { transitStats: 20, week: 42, eastBound: 10, westBound: 10 },
      { transitStats: 2, week: 43, eastBound: 1, westBound: 1 },
      { transitStats: 19, week: 44, eastBound: 9, westBound: 10 },
      { transitStats: 7, week: 45, eastBound: 4, westBound: 3 },
      { transitStats: 14, week: 46, eastBound: 6, westBound: 8 },
      { transitStats: 6, week: 47, eastBound: 3, westBound: 3 },
      { transitStats: 13, week: 48, eastBound: 6, westBound: 7 },
      { transitStats: 5, week: 49, eastBound: 3, westBound: 2 },
      { transitStats: 17, week: 50, eastBound: 8, westBound: 9 },
      { transitStats: 4, week: 51, eastBound: 2, westBound: 2 },
      { transitStats: 12, week: 52, eastBound: 7, westBound: 5 }
    ],
    oprMonthlyResults: [
      {
        year: 2021,
        months: [
          {
            start: '2021-01-01T00:00:00',
            transitStats: 500,
            eastBound: 250,
            westBound: 250
          },
          {
            start: '2021-02-01T00:00:00',
            transitStats: 420,
            eastBound: 200,
            westBound: 220
          },
          {
            start: '2021-03-01T00:00:00',
            transitStats: 520,
            eastBound: 300,
            westBound: 220
          },
          {
            start: '2021-04-01T00:00:00',
            transitStats: 600,
            eastBound: 330,
            westBound: 270
          },
          {
            start: '2021-05-01T00:00:00',
            transitStats: 550,
            eastBound: 275,
            westBound: 275
          },
          {
            start: '2021-06-01T00:00:00',
            transitStats: 580,
            eastBound: 290,
            westBound: 290
          },
          {
            start: '2021-07-01T00:00:00',
            transitStats: 450,
            eastBound: 220,
            westBound: 230
          },
          {
            start: '2021-08-01T00:00:00',
            transitStats: 610,
            eastBound: 310,
            westBound: 300
          },
          {
            start: '2021-09-01T00:00:00',
            transitStats: 430,
            eastBound: 210,
            westBound: 220
          },
          {
            start: '2021-10-01T00:00:00',
            transitStats: 475,
            eastBound: 240,
            westBound: 235
          },
          {
            start: '2021-11-01T00:00:00',
            transitStats: 510,
            eastBound: 260,
            westBound: 250
          },
          {
            start: '2021-12-01T00:00:00',
            transitStats: 530,
            eastBound: 280,
            westBound: 250
          }
        ]
      }
    ],
    operatorLongName: 'CLdN Ro-Ro & COBELFRET Ferries',
    operatorShortName: 'CLDN'
  },
  {
    oprWeeklyResults: [
      { transitStats: 9, week: 1, eastBound: 5, westBound: 4 },
      { transitStats: 15, week: 2, eastBound: 8, westBound: 7 },
      { transitStats: 4, week: 3, eastBound: 2, westBound: 2 },
      { transitStats: 13, week: 4, eastBound: 7, westBound: 6 },
      { transitStats: 18, week: 5, eastBound: 10, westBound: 8 },
      { transitStats: 5, week: 6, eastBound: 3, westBound: 2 },
      { transitStats: 17, week: 7, eastBound: 9, westBound: 8 },
      { transitStats: 8, week: 8, eastBound: 4, westBound: 4 },
      { transitStats: 1, week: 9, eastBound: 1, westBound: 0 },
      { transitStats: 14, week: 10, eastBound: 7, westBound: 7 },
      { transitStats: 7, week: 11, eastBound: 4, westBound: 3 },
      { transitStats: 19, week: 12, eastBound: 10, westBound: 9 },
      { transitStats: 6, week: 13, eastBound: 3, westBound: 3 },
      { transitStats: 12, week: 14, eastBound: 6, westBound: 6 },
      { transitStats: 2, week: 15, eastBound: 1, westBound: 1 },
      { transitStats: 20, week: 16, eastBound: 11, westBound: 9 },
      { transitStats: 10, week: 17, eastBound: 5, westBound: 5 },
      { transitStats: 3, week: 18, eastBound: 2, westBound: 1 },
      { transitStats: 11, week: 19, eastBound: 6, westBound: 5 },
      { transitStats: 16, week: 20, eastBound: 8, westBound: 8 },
      { transitStats: 8, week: 21, eastBound: 4, westBound: 4 },
      { transitStats: 7, week: 22, eastBound: 3, westBound: 4 },
      { transitStats: 14, week: 23, eastBound: 7, westBound: 7 },
      { transitStats: 5, week: 24, eastBound: 2, westBound: 3 },
      { transitStats: 18, week: 25, eastBound: 9, westBound: 9 },
      { transitStats: 4, week: 26, eastBound: 2, westBound: 2 },
      { transitStats: 13, week: 27, eastBound: 7, westBound: 6 },
      { transitStats: 1, week: 28, eastBound: 0, westBound: 1 },
      { transitStats: 17, week: 29, eastBound: 9, westBound: 8 },
      { transitStats: 9, week: 30, eastBound: 4, westBound: 5 },
      { transitStats: 10, week: 31, eastBound: 6, westBound: 4 },
      { transitStats: 19, week: 32, eastBound: 10, westBound: 9 },
      { transitStats: 3, week: 33, eastBound: 2, westBound: 1 },
      { transitStats: 15, week: 34, eastBound: 7, westBound: 8 },
      { transitStats: 2, week: 35, eastBound: 1, westBound: 1 },
      { transitStats: 20, week: 36, eastBound: 10, westBound: 10 },
      { transitStats: 6, week: 37, eastBound: 3, westBound: 3 },
      { transitStats: 12, week: 38, eastBound: 6, westBound: 6 },
      { transitStats: 11, week: 39, eastBound: 5, westBound: 6 },
      { transitStats: 4, week: 40, eastBound: 2, westBound: 2 },
      { transitStats: 16, week: 41, eastBound: 8, westBound: 8 },
      { transitStats: 5, week: 42, eastBound: 3, westBound: 2 },
      { transitStats: 14, week: 43, eastBound: 7, westBound: 7 },
      { transitStats: 7, week: 44, eastBound: 4, westBound: 3 },
      { transitStats: 9, week: 45, eastBound: 5, westBound: 4 },
      { transitStats: 18, week: 46, eastBound: 9, westBound: 9 },
      { transitStats: 3, week: 47, eastBound: 1, westBound: 2 },
      { transitStats: 19, week: 48, eastBound: 10, westBound: 9 },
      { transitStats: 1, week: 49, eastBound: 0, westBound: 1 },
      { transitStats: 13, week: 50, eastBound: 7, westBound: 6 },
      { transitStats: 6, week: 51, eastBound: 4, westBound: 2 },
      { transitStats: 15, week: 52, eastBound: 7, westBound: 8 }
    ],
    oprMonthlyResults: [
      {
        year: 2021,
        months: [
          {
            start: '2021-01-01T00:00:00',
            transitStats: 500,
            eastBound: 250,
            westBound: 250
          },
          {
            start: '2021-02-01T00:00:00',
            transitStats: 420,
            eastBound: 200,
            westBound: 220
          },
          {
            start: '2021-03-01T00:00:00',
            transitStats: 520,
            eastBound: 300,
            westBound: 220
          },
          {
            start: '2021-04-01T00:00:00',
            transitStats: 600,
            eastBound: 330,
            westBound: 270
          },
          {
            start: '2021-05-01T00:00:00',
            transitStats: 550,
            eastBound: 275,
            westBound: 275
          },
          {
            start: '2021-06-01T00:00:00',
            transitStats: 580,
            eastBound: 290,
            westBound: 290
          },
          {
            start: '2021-07-01T00:00:00',
            transitStats: 450,
            eastBound: 220,
            westBound: 230
          },
          {
            start: '2021-08-01T00:00:00',
            transitStats: 610,
            eastBound: 310,
            westBound: 300
          },
          {
            start: '2021-09-01T00:00:00',
            transitStats: 430,
            eastBound: 210,
            westBound: 220
          },
          {
            start: '2021-10-01T00:00:00',
            transitStats: 475,
            eastBound: 240,
            westBound: 235
          },
          {
            start: '2021-11-01T00:00:00',
            transitStats: 510,
            eastBound: 260,
            westBound: 250
          },
          {
            start: '2021-12-01T00:00:00',
            transitStats: 530,
            eastBound: 280,
            westBound: 250
          }
        ]
      }
    ],
    operatorLongName: 'Finnlines',
    operatorShortName: 'FINLI'
  },
  {
    oprWeeklyResults: [
      { transitStats: 8, week: 1, eastBound: 5, westBound: 3 },
      { transitStats: 16, week: 2, eastBound: 8, westBound: 8 },
      { transitStats: 10, week: 3, eastBound: 5, westBound: 5 },
      { transitStats: 5, week: 4, eastBound: 2, westBound: 3 },
      { transitStats: 17, week: 5, eastBound: 9, westBound: 8 },
      { transitStats: 12, week: 6, eastBound: 6, westBound: 6 },
      { transitStats: 6, week: 7, eastBound: 4, westBound: 2 },
      { transitStats: 19, week: 8, eastBound: 10, westBound: 9 },
      { transitStats: 7, week: 9, eastBound: 4, westBound: 3 },
      { transitStats: 4, week: 10, eastBound: 2, westBound: 2 },
      { transitStats: 14, week: 11, eastBound: 8, westBound: 6 },
      { transitStats: 9, week: 12, eastBound: 4, westBound: 5 },
      { transitStats: 3, week: 13, eastBound: 1, westBound: 2 },
      { transitStats: 11, week: 14, eastBound: 6, westBound: 5 },
      { transitStats: 2, week: 15, eastBound: 1, westBound: 1 },
      { transitStats: 15, week: 16, eastBound: 8, westBound: 7 },
      { transitStats: 13, week: 17, eastBound: 7, westBound: 6 },
      { transitStats: 1, week: 18, eastBound: 0, westBound: 1 },
      { transitStats: 18, week: 19, eastBound: 9, westBound: 9 },
      { transitStats: 6, week: 20, eastBound: 4, westBound: 2 },
      { transitStats: 10, week: 21, eastBound: 5, westBound: 5 },
      { transitStats: 8, week: 22, eastBound: 4, westBound: 4 },
      { transitStats: 4, week: 23, eastBound: 2, westBound: 2 },
      { transitStats: 12, week: 24, eastBound: 6, westBound: 6 },
      { transitStats: 5, week: 25, eastBound: 3, westBound: 2 },
      { transitStats: 20, week: 26, eastBound: 10, westBound: 10 },
      { transitStats: 9, week: 27, eastBound: 5, westBound: 4 },
      { transitStats: 3, week: 28, eastBound: 2, westBound: 1 },
      { transitStats: 17, week: 29, eastBound: 9, westBound: 8 },
      { transitStats: 7, week: 30, eastBound: 4, westBound: 3 },
      { transitStats: 11, week: 31, eastBound: 6, westBound: 5 },
      { transitStats: 2, week: 32, eastBound: 1, westBound: 1 },
      { transitStats: 14, week: 33, eastBound: 7, westBound: 7 },
      { transitStats: 1, week: 34, eastBound: 0, westBound: 1 },
      { transitStats: 16, week: 35, eastBound: 8, westBound: 8 },
      { transitStats: 19, week: 36, eastBound: 9, westBound: 10 },
      { transitStats: 4, week: 37, eastBound: 2, westBound: 2 },
      { transitStats: 18, week: 38, eastBound: 9, westBound: 9 },
      { transitStats: 5, week: 39, eastBound: 3, westBound: 2 },
      { transitStats: 13, week: 40, eastBound: 7, westBound: 6 },
      { transitStats: 6, week: 41, eastBound: 3, westBound: 3 },
      { transitStats: 15, week: 42, eastBound: 7, westBound: 8 },
      { transitStats: 8, week: 43, eastBound: 4, westBound: 4 },
      { transitStats: 10, week: 44, eastBound: 5, westBound: 5 },
      { transitStats: 3, week: 45, eastBound: 1, westBound: 2 },
      { transitStats: 17, week: 46, eastBound: 9, westBound: 8 },
      { transitStats: 12, week: 47, eastBound: 6, westBound: 6 },
      { transitStats: 2, week: 48, eastBound: 1, westBound: 1 },
      { transitStats: 9, week: 49, eastBound: 4, westBound: 5 },
      { transitStats: 7, week: 50, eastBound: 3, westBound: 4 },
      { transitStats: 20, week: 51, eastBound: 11, westBound: 9 },
      { transitStats: 11, week: 52, eastBound: 6, westBound: 5 }
    ],
    oprMonthlyResults: [
      {
        year: 2021,
        months: [
          {
            start: '2021-01-01T00:00:00',
            transitStats: 500,
            eastBound: 250,
            westBound: 250
          },
          {
            start: '2021-02-01T00:00:00',
            transitStats: 420,
            eastBound: 200,
            westBound: 220
          },
          {
            start: '2021-03-01T00:00:00',
            transitStats: 520,
            eastBound: 300,
            westBound: 220
          },
          {
            start: '2021-04-01T00:00:00',
            transitStats: 600,
            eastBound: 330,
            westBound: 270
          },
          {
            start: '2021-05-01T00:00:00',
            transitStats: 550,
            eastBound: 275,
            westBound: 275
          },
          {
            start: '2021-06-01T00:00:00',
            transitStats: 580,
            eastBound: 290,
            westBound: 290
          },
          {
            start: '2021-07-01T00:00:00',
            transitStats: 450,
            eastBound: 220,
            westBound: 230
          },
          {
            start: '2021-08-01T00:00:00',
            transitStats: 610,
            eastBound: 310,
            westBound: 300
          },
          {
            start: '2021-09-01T00:00:00',
            transitStats: 430,
            eastBound: 210,
            westBound: 220
          },
          {
            start: '2021-10-01T00:00:00',
            transitStats: 475,
            eastBound: 240,
            westBound: 235
          },
          {
            start: '2021-11-01T00:00:00',
            transitStats: 510,
            eastBound: 260,
            westBound: 250
          },
          {
            start: '2021-12-01T00:00:00',
            transitStats: 530,
            eastBound: 280,
            westBound: 250
          }
        ]
      }
    ],

    operatorLongName: 'United European Car Carriers',
    operatorShortName: 'UECC'
  },
  {
    oprWeeklyResults: [
      { transitStats: 7, week: 1, eastBound: 4, westBound: 3 },
      { transitStats: 18, week: 2, eastBound: 9, westBound: 9 },
      { transitStats: 3, week: 3, eastBound: 2, westBound: 1 },
      { transitStats: 12, week: 4, eastBound: 6, westBound: 6 },
      { transitStats: 5, week: 5, eastBound: 3, westBound: 2 },
      { transitStats: 14, week: 6, eastBound: 8, westBound: 6 },
      { transitStats: 9, week: 7, eastBound: 5, westBound: 4 },
      { transitStats: 2, week: 8, eastBound: 1, westBound: 1 },
      { transitStats: 16, week: 9, eastBound: 9, westBound: 7 },
      { transitStats: 6, week: 10, eastBound: 3, westBound: 3 },
      { transitStats: 13, week: 11, eastBound: 7, westBound: 6 },
      { transitStats: 1, week: 12, eastBound: 0, westBound: 1 },
      { transitStats: 15, week: 13, eastBound: 8, westBound: 7 },
      { transitStats: 8, week: 14, eastBound: 4, westBound: 4 },
      { transitStats: 4, week: 15, eastBound: 2, westBound: 2 },
      { transitStats: 11, week: 16, eastBound: 6, westBound: 5 },
      { transitStats: 10, week: 17, eastBound: 5, westBound: 5 },
      { transitStats: 17, week: 18, eastBound: 9, westBound: 8 },
      { transitStats: 5, week: 19, eastBound: 3, westBound: 2 },
      { transitStats: 2, week: 20, eastBound: 1, westBound: 1 },
      { transitStats: 14, week: 21, eastBound: 7, westBound: 7 },
      { transitStats: 6, week: 22, eastBound: 3, westBound: 3 },
      { transitStats: 19, week: 23, eastBound: 9, westBound: 10 },
      { transitStats: 3, week: 24, eastBound: 1, westBound: 2 },
      { transitStats: 12, week: 25, eastBound: 7, westBound: 5 },
      { transitStats: 8, week: 26, eastBound: 4, westBound: 4 },
      { transitStats: 11, week: 27, eastBound: 6, westBound: 5 },
      { transitStats: 10, week: 28, eastBound: 5, westBound: 5 },
      { transitStats: 1, week: 29, eastBound: 0, westBound: 1 },
      { transitStats: 18, week: 30, eastBound: 9, westBound: 9 },
      { transitStats: 9, week: 31, eastBound: 4, westBound: 5 },
      { transitStats: 7, week: 32, eastBound: 3, westBound: 4 },
      { transitStats: 15, week: 33, eastBound: 8, westBound: 7 },
      { transitStats: 4, week: 34, eastBound: 2, westBound: 2 },
      { transitStats: 17, week: 35, eastBound: 9, westBound: 8 },
      { transitStats: 2, week: 36, eastBound: 1, westBound: 1 },
      { transitStats: 13, week: 37, eastBound: 7, westBound: 6 },
      { transitStats: 10, week: 38, eastBound: 5, westBound: 5 },
      { transitStats: 5, week: 39, eastBound: 2, westBound: 3 },
      { transitStats: 19, week: 40, eastBound: 9, westBound: 10 },
      { transitStats: 6, week: 41, eastBound: 3, westBound: 3 },
      { transitStats: 14, week: 42, eastBound: 7, westBound: 7 },
      { transitStats: 11, week: 43, eastBound: 6, westBound: 5 },
      { transitStats: 9, week: 44, eastBound: 4, westBound: 5 },
      { transitStats: 3, week: 45, eastBound: 1, westBound: 2 },
      { transitStats: 16, week: 46, eastBound: 8, westBound: 8 },
      { transitStats: 8, week: 47, eastBound: 4, westBound: 4 },
      { transitStats: 12, week: 48, eastBound: 6, westBound: 6 },
      { transitStats: 4, week: 49, eastBound: 2, westBound: 2 },
      { transitStats: 15, week: 50, eastBound: 8, westBound: 7 },
      { transitStats: 10, week: 51, eastBound: 5, westBound: 5 },
      { transitStats: 1, week: 52, eastBound: 0, westBound: 1 }
    ],
    oprMonthlyResults: [
      {
        year: 2021,
        months: [
          {
            start: '2021-01-01T00:00:00',
            transitStats: 500,
            eastBound: 250,
            westBound: 250
          },
          {
            start: '2021-02-01T00:00:00',
            transitStats: 420,
            eastBound: 200,
            westBound: 220
          },
          {
            start: '2021-03-01T00:00:00',
            transitStats: 520,
            eastBound: 300,
            westBound: 220
          },
          {
            start: '2021-04-01T00:00:00',
            transitStats: 600,
            eastBound: 330,
            westBound: 270
          },
          {
            start: '2021-05-01T00:00:00',
            transitStats: 550,
            eastBound: 275,
            westBound: 275
          },
          {
            start: '2021-06-01T00:00:00',
            transitStats: 580,
            eastBound: 290,
            westBound: 290
          },
          {
            start: '2021-07-01T00:00:00',
            transitStats: 450,
            eastBound: 220,
            westBound: 230
          },
          {
            start: '2021-08-01T00:00:00',
            transitStats: 610,
            eastBound: 310,
            westBound: 300
          },
          {
            start: '2021-09-01T00:00:00',
            transitStats: 430,
            eastBound: 210,
            westBound: 220
          },
          {
            start: '2021-10-01T00:00:00',
            transitStats: 475,
            eastBound: 240,
            westBound: 235
          },
          {
            start: '2021-11-01T00:00:00',
            transitStats: 510,
            eastBound: 260,
            westBound: 250
          },
          {
            start: '2021-12-01T00:00:00',
            transitStats: 530,
            eastBound: 280,
            westBound: 250
          }
        ]
      }
    ],

    operatorLongName: 'K-line European Sea Highway Services GmbH',
    operatorShortName: 'KESS'
  },
  {
    oprWeeklyResults: [
      { transitStats: 10, week: 1, eastBound: 5, westBound: 5 },
      { transitStats: 14, week: 2, eastBound: 7, westBound: 7 },
      { transitStats: 6, week: 3, eastBound: 3, westBound: 3 },
      { transitStats: 13, week: 4, eastBound: 7, westBound: 6 },
      { transitStats: 5, week: 5, eastBound: 3, westBound: 2 },
      { transitStats: 19, week: 6, eastBound: 9, westBound: 10 },
      { transitStats: 7, week: 7, eastBound: 4, westBound: 3 },
      { transitStats: 2, week: 8, eastBound: 1, westBound: 1 },
      { transitStats: 16, week: 9, eastBound: 8, westBound: 8 },
      { transitStats: 4, week: 10, eastBound: 2, westBound: 2 },
      { transitStats: 12, week: 11, eastBound: 6, westBound: 6 },
      { transitStats: 3, week: 12, eastBound: 1, westBound: 2 },
      { transitStats: 15, week: 13, eastBound: 8, westBound: 7 },
      { transitStats: 9, week: 14, eastBound: 4, westBound: 5 },
      { transitStats: 1, week: 15, eastBound: 0, westBound: 1 },
      { transitStats: 18, week: 16, eastBound: 9, westBound: 9 },
      { transitStats: 8, week: 17, eastBound: 4, westBound: 4 },
      { transitStats: 11, week: 18, eastBound: 6, westBound: 5 },
      { transitStats: 6, week: 19, eastBound: 3, westBound: 3 },
      { transitStats: 17, week: 20, eastBound: 8, westBound: 9 },
      { transitStats: 5, week: 21, eastBound: 2, westBound: 3 },
      { transitStats: 20, week: 22, eastBound: 10, westBound: 10 },
      { transitStats: 3, week: 23, eastBound: 1, westBound: 2 },
      { transitStats: 12, week: 24, eastBound: 6, westBound: 6 },
      { transitStats: 7, week: 25, eastBound: 4, westBound: 3 },
      { transitStats: 14, week: 26, eastBound: 7, westBound: 7 },
      { transitStats: 2, week: 27, eastBound: 1, westBound: 1 },
      { transitStats: 9, week: 28, eastBound: 4, westBound: 5 },
      { transitStats: 4, week: 29, eastBound: 2, westBound: 2 },
      { transitStats: 16, week: 30, eastBound: 8, westBound: 8 },
      { transitStats: 8, week: 31, eastBound: 4, westBound: 4 },
      { transitStats: 15, week: 32, eastBound: 8, westBound: 7 },
      { transitStats: 11, week: 33, eastBound: 6, westBound: 5 },
      { transitStats: 1, week: 34, eastBound: 0, westBound: 1 },
      { transitStats: 17, week: 35, eastBound: 8, westBound: 9 },
      { transitStats: 10, week: 36, eastBound: 5, westBound: 5 },
      { transitStats: 6, week: 37, eastBound: 3, westBound: 3 },
      { transitStats: 13, week: 38, eastBound: 7, westBound: 6 },
      { transitStats: 5, week: 39, eastBound: 2, westBound: 3 },
      { transitStats: 18, week: 40, eastBound: 9, westBound: 9 },
      { transitStats: 4, week: 41, eastBound: 2, westBound: 2 },
      { transitStats: 19, week: 42, eastBound: 9, westBound: 10 },
      { transitStats: 3, week: 43, eastBound: 1, westBound: 2 },
      { transitStats: 12, week: 44, eastBound: 6, westBound: 6 },
      { transitStats: 7, week: 45, eastBound: 3, westBound: 4 },
      { transitStats: 15, week: 46, eastBound: 8, westBound: 7 },
      { transitStats: 9, week: 47, eastBound: 4, westBound: 5 },
      { transitStats: 2, week: 48, eastBound: 1, westBound: 1 },
      { transitStats: 16, week: 49, eastBound: 8, westBound: 8 },
      { transitStats: 8, week: 50, eastBound: 4, westBound: 4 },
      { transitStats: 11, week: 51, eastBound: 5, westBound: 6 },
      { transitStats: 1, week: 52, eastBound: 0, westBound: 1 }
    ],
    oprMonthlyResults: [
      {
        year: 2021,
        months: [
          {
            start: '2021-01-01T00:00:00',
            transitStats: 500,
            eastBound: 250,
            westBound: 250
          },
          {
            start: '2021-02-01T00:00:00',
            transitStats: 420,
            eastBound: 200,
            westBound: 220
          },
          {
            start: '2021-03-01T00:00:00',
            transitStats: 520,
            eastBound: 300,
            westBound: 220
          },
          {
            start: '2021-04-01T00:00:00',
            transitStats: 600,
            eastBound: 330,
            westBound: 270
          },
          {
            start: '2021-05-01T00:00:00',
            transitStats: 550,
            eastBound: 275,
            westBound: 275
          },
          {
            start: '2021-06-01T00:00:00',
            transitStats: 580,
            eastBound: 290,
            westBound: 290
          },
          {
            start: '2021-07-01T00:00:00',
            transitStats: 450,
            eastBound: 220,
            westBound: 230
          },
          {
            start: '2021-08-01T00:00:00',
            transitStats: 610,
            eastBound: 310,
            westBound: 300
          },
          {
            start: '2021-09-01T00:00:00',
            transitStats: 430,
            eastBound: 210,
            westBound: 220
          },
          {
            start: '2021-10-01T00:00:00',
            transitStats: 475,
            eastBound: 240,
            westBound: 235
          },
          {
            start: '2021-11-01T00:00:00',
            transitStats: 510,
            eastBound: 260,
            westBound: 250
          },
          {
            start: '2021-12-01T00:00:00',
            transitStats: 530,
            eastBound: 280,
            westBound: 250
          }
        ]
      }
    ],

    operatorLongName: 'Wallenius Wilhelmsen',
    operatorShortName: 'WW'
  }
];

export const mockCanalWaitingTime = {
  start: '2021-01-01T00:00:00',
  end: '2024-08-13T00:00:00',
  geoType: 1,
  geoId: 82,
  years: [
    {
      year: 2021,
      months: [
        {
          start: '2021-01-01T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.931731481481481,
          totalWaitingTimeDays: 9.658657407407405,
          ceuCapacityInWaiting: 21200,
          dwtInWaiting: 0
        },
        {
          start: '2021-02-01T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.2518229166666667,
          totalWaitingTimeDays: 1.0072916666666667,
          ceuCapacityInWaiting: 24900,
          dwtInWaiting: 0
        },
        {
          start: '2021-03-01T00:00:00',
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.4945664983164983,
          totalWaitingTimeDays: 5.440231481481481,
          ceuCapacityInWaiting: 75550,
          dwtInWaiting: 0
        },
        {
          start: '2021-04-01T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 2.0988333333333333,
          totalWaitingTimeDays: 20.988333333333333,
          ceuCapacityInWaiting: 62047,
          dwtInWaiting: 0
        },
        {
          start: '2021-05-01T00:00:00',
          numWaitingEvents: 15,
          averageWaitingTimeDays: 1.5462391975308643,
          totalWaitingTimeDays: 23.193587962962965,
          ceuCapacityInWaiting: 92025,
          dwtInWaiting: 0
        },
        {
          start: '2021-06-01T00:00:00',
          numWaitingEvents: 18,
          averageWaitingTimeDays: 1.3413316615226336,
          totalWaitingTimeDays: 24.143969907407406,
          ceuCapacityInWaiting: 102024,
          dwtInWaiting: 0
        },
        {
          start: '2021-07-01T00:00:00',
          numWaitingEvents: 9,
          averageWaitingTimeDays: 3.8423482510288065,
          totalWaitingTimeDays: 34.58113425925926,
          ceuCapacityInWaiting: 53803,
          dwtInWaiting: 0
        },
        {
          start: '2021-08-01T00:00:00',
          numWaitingEvents: 8,
          averageWaitingTimeDays: 2.1067824074074073,
          totalWaitingTimeDays: 16.85425925925926,
          ceuCapacityInWaiting: 26200,
          dwtInWaiting: 0
        },
        {
          start: '2021-09-01T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.42887152777777776,
          totalWaitingTimeDays: 2.5732291666666667,
          ceuCapacityInWaiting: 33294,
          dwtInWaiting: 0
        },
        {
          start: '2021-10-01T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.6770578703703704,
          totalWaitingTimeDays: 3.385289351851852,
          ceuCapacityInWaiting: 21921,
          dwtInWaiting: 0
        },
        {
          start: '2021-11-01T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.6467959104938271,
          totalWaitingTimeDays: 3.8807754629629625,
          ceuCapacityInWaiting: 33450,
          dwtInWaiting: 0
        },
        {
          start: '2021-12-01T00:00:00',
          numWaitingEvents: 12,
          averageWaitingTimeDays: 1.9683661265432104,
          totalWaitingTimeDays: 23.620393518518526,
          ceuCapacityInWaiting: 61114,
          dwtInWaiting: 0
        }
      ],
      weeks: [
        {
          week: 1,
          weekStart: '2021-01-04T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 2.097372685185185,
          totalWaitingTimeDays: 6.292118055555555,
          ceuCapacityInWaiting: 11000,
          dwtInWaiting: 0
        },
        {
          week: 2,
          weekStart: '2021-01-11T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.5726967592592592,
          totalWaitingTimeDays: 0.5726967592592592,
          ceuCapacityInWaiting: 4310,
          dwtInWaiting: 0
        },
        {
          week: 3,
          weekStart: '2021-01-18T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 4,
          weekStart: '2021-01-25T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.200023148148148,
          totalWaitingTimeDays: 1.200023148148148,
          ceuCapacityInWaiting: 4000,
          dwtInWaiting: 0
        },
        {
          week: 5,
          weekStart: '2021-02-01T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.125,
          totalWaitingTimeDays: 0.125,
          ceuCapacityInWaiting: 6500,
          dwtInWaiting: 0
        },
        {
          week: 6,
          weekStart: '2021-02-08T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 7,
          weekStart: '2021-02-15T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.39928240740740745,
          totalWaitingTimeDays: 0.7985648148148149,
          ceuCapacityInWaiting: 11000,
          dwtInWaiting: 0
        },
        {
          week: 8,
          weekStart: '2021-02-22T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.04186342592592592,
          totalWaitingTimeDays: 0.08372685185185184,
          ceuCapacityInWaiting: 12200,
          dwtInWaiting: 0
        },
        {
          week: 9,
          weekStart: '2021-03-01T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.1704513888888889,
          totalWaitingTimeDays: 0.1704513888888889,
          ceuCapacityInWaiting: 7500,
          dwtInWaiting: 0
        },
        {
          week: 10,
          weekStart: '2021-03-08T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.2708391203703704,
          totalWaitingTimeDays: 2.541678240740741,
          ceuCapacityInWaiting: 14400,
          dwtInWaiting: 0
        },
        {
          week: 11,
          weekStart: '2021-03-15T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.2913194444444444,
          totalWaitingTimeDays: 0.8739583333333333,
          ceuCapacityInWaiting: 20830,
          dwtInWaiting: 0
        },
        {
          week: 12,
          weekStart: '2021-03-22T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.4097145061728395,
          totalWaitingTimeDays: 1.2291435185185184,
          ceuCapacityInWaiting: 20120,
          dwtInWaiting: 0
        },
        {
          week: 13,
          weekStart: '2021-03-29T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.3125,
          totalWaitingTimeDays: 0.625,
          ceuCapacityInWaiting: 12700,
          dwtInWaiting: 0
        },
        {
          week: 14,
          weekStart: '2021-04-05T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 15,
          weekStart: '2021-04-12T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.0945949074074074,
          totalWaitingTimeDays: 0.0945949074074074,
          ceuCapacityInWaiting: 6400,
          dwtInWaiting: 0
        },
        {
          week: 16,
          weekStart: '2021-04-19T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 2.060015432098765,
          totalWaitingTimeDays: 12.360092592592592,
          ceuCapacityInWaiting: 34959,
          dwtInWaiting: 0
        },
        {
          week: 17,
          weekStart: '2021-04-26T00:00:00',
          numWaitingEvents: 7,
          averageWaitingTimeDays: 1.5226669973544973,
          totalWaitingTimeDays: 10.658668981481481,
          ceuCapacityInWaiting: 48033,
          dwtInWaiting: 0
        },
        {
          week: 18,
          weekStart: '2021-05-03T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.7968807870370371,
          totalWaitingTimeDays: 1.5937615740740743,
          ceuCapacityInWaiting: 10450,
          dwtInWaiting: 0
        },
        {
          week: 19,
          weekStart: '2021-05-10T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.2729953703703705,
          totalWaitingTimeDays: 6.364976851851853,
          ceuCapacityInWaiting: 31519,
          dwtInWaiting: 0
        },
        {
          week: 20,
          weekStart: '2021-05-17T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.666670524691358,
          totalWaitingTimeDays: 5.000011574074074,
          ceuCapacityInWaiting: 20611,
          dwtInWaiting: 0
        },
        {
          week: 21,
          weekStart: '2021-05-24T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.4577800925925926,
          totalWaitingTimeDays: 7.288900462962963,
          ceuCapacityInWaiting: 28777,
          dwtInWaiting: 0
        },
        {
          week: 22,
          weekStart: '2021-05-31T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.7983472222222223,
          totalWaitingTimeDays: 3.9917361111111114,
          ceuCapacityInWaiting: 22223,
          dwtInWaiting: 0
        },
        {
          week: 23,
          weekStart: '2021-06-07T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.9827986111111111,
          totalWaitingTimeDays: 4.913993055555555,
          ceuCapacityInWaiting: 31921,
          dwtInWaiting: 0
        },
        {
          week: 24,
          weekStart: '2021-06-14T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 1.2448321759259258,
          totalWaitingTimeDays: 4.979328703703703,
          ceuCapacityInWaiting: 27254,
          dwtInWaiting: 0
        },
        {
          week: 25,
          weekStart: '2021-06-21T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.3049729938271606,
          totalWaitingTimeDays: 7.829837962962963,
          ceuCapacityInWaiting: 34889,
          dwtInWaiting: 0
        },
        {
          week: 26,
          weekStart: '2021-06-28T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 3.624994212962963,
          totalWaitingTimeDays: 7.249988425925926,
          ceuCapacityInWaiting: 5530,
          dwtInWaiting: 0
        },
        {
          week: 27,
          weekStart: '2021-07-05T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 4.062494212962963,
          totalWaitingTimeDays: 8.124988425925926,
          ceuCapacityInWaiting: 9100,
          dwtInWaiting: 0
        },
        {
          week: 28,
          weekStart: '2021-07-12T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 4.156252893518518,
          totalWaitingTimeDays: 16.625011574074072,
          ceuCapacityInWaiting: 21945,
          dwtInWaiting: 0
        },
        {
          week: 29,
          weekStart: '2021-07-19T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.141226851851852,
          totalWaitingTimeDays: 5.70613425925926,
          ceuCapacityInWaiting: 25838,
          dwtInWaiting: 0
        },
        {
          week: 30,
          weekStart: '2021-07-26T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.08660300925925926,
          totalWaitingTimeDays: 0.17320601851851852,
          ceuCapacityInWaiting: 8870,
          dwtInWaiting: 0
        },
        {
          week: 31,
          weekStart: '2021-08-02T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 1.4578616898148147,
          totalWaitingTimeDays: 5.831446759259259,
          ceuCapacityInWaiting: 13350,
          dwtInWaiting: 0
        },
        {
          week: 32,
          weekStart: '2021-08-09T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 3.334521604938272,
          totalWaitingTimeDays: 10.003564814814816,
          ceuCapacityInWaiting: 6650,
          dwtInWaiting: 0
        },
        {
          week: 33,
          weekStart: '2021-08-16T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.478125,
          totalWaitingTimeDays: 0.95625,
          ceuCapacityInWaiting: 7600,
          dwtInWaiting: 0
        },
        {
          week: 34,
          weekStart: '2021-08-23T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 35,
          weekStart: '2021-08-30T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.4198032407407407,
          totalWaitingTimeDays: 0.8396064814814814,
          ceuCapacityInWaiting: 7600,
          dwtInWaiting: 0
        },
        {
          week: 36,
          weekStart: '2021-09-06T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 37,
          weekStart: '2021-09-13T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.2825289351851852,
          totalWaitingTimeDays: 0.5650578703703704,
          ceuCapacityInWaiting: 10994,
          dwtInWaiting: 0
        },
        {
          week: 38,
          weekStart: '2021-09-20T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.5916782407407407,
          totalWaitingTimeDays: 1.1833564814814814,
          ceuCapacityInWaiting: 14700,
          dwtInWaiting: 0
        },
        {
          week: 39,
          weekStart: '2021-09-27T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 40,
          weekStart: '2021-10-04T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.8923225308641974,
          totalWaitingTimeDays: 2.676967592592592,
          ceuCapacityInWaiting: 8376,
          dwtInWaiting: 0
        },
        {
          week: 41,
          weekStart: '2021-10-11T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 42,
          weekStart: '2021-10-18T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.20833333333333334,
          totalWaitingTimeDays: 0.20833333333333334,
          ceuCapacityInWaiting: 7145,
          dwtInWaiting: 0
        },
        {
          week: 43,
          weekStart: '2021-10-25T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.49998842592592596,
          totalWaitingTimeDays: 0.49998842592592596,
          ceuCapacityInWaiting: 6400,
          dwtInWaiting: 0
        },
        {
          week: 44,
          weekStart: '2021-11-01T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.12497685185185185,
          totalWaitingTimeDays: 0.12497685185185185,
          ceuCapacityInWaiting: 3800,
          dwtInWaiting: 0
        },
        {
          week: 45,
          weekStart: '2021-11-08T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 46,
          weekStart: '2021-11-15T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.6875,
          totalWaitingTimeDays: 3.375,
          ceuCapacityInWaiting: 14400,
          dwtInWaiting: 0
        },
        {
          week: 47,
          weekStart: '2021-11-22T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.12693287037037038,
          totalWaitingTimeDays: 0.38079861111111113,
          ceuCapacityInWaiting: 15250,
          dwtInWaiting: 0
        },
        {
          week: 48,
          weekStart: '2021-11-29T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 49,
          weekStart: '2021-12-06T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.875011574074074,
          totalWaitingTimeDays: 0.875011574074074,
          ceuCapacityInWaiting: 6200,
          dwtInWaiting: 0
        },
        {
          week: 50,
          weekStart: '2021-12-13T00:00:00',
          numWaitingEvents: 7,
          averageWaitingTimeDays: 1.5498462301587301,
          totalWaitingTimeDays: 10.848923611111111,
          ceuCapacityInWaiting: 39094,
          dwtInWaiting: 0
        },
        {
          week: 51,
          weekStart: '2021-12-20T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.3369077932098765,
          totalWaitingTimeDays: 8.021446759259259,
          ceuCapacityInWaiting: 28220,
          dwtInWaiting: 0
        },
        {
          week: 52,
          weekStart: '2021-12-27T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 2.00764274691358,
          totalWaitingTimeDays: 6.0229282407407405,
          ceuCapacityInWaiting: 19140,
          dwtInWaiting: 0
        }
      ],
      numWaitingEvents: 106,
      averageWaitingTimeDays: 1.5974259696016782,
      totalWaitingTimeDays: 169.32715277777788,
      ceuCapacityInWaiting: 593994,
      dwtInWaiting: 0
    },
    {
      year: 2022,
      months: [
        {
          start: '2022-01-01T00:00:00',
          numWaitingEvents: 22,
          averageWaitingTimeDays: 1.102224852693603,
          totalWaitingTimeDays: 24.248946759259265,
          ceuCapacityInWaiting: 104821,
          dwtInWaiting: 0
        },
        {
          start: '2022-02-01T00:00:00',
          numWaitingEvents: 16,
          averageWaitingTimeDays: 1.1085749421296296,
          totalWaitingTimeDays: 17.737199074074073,
          ceuCapacityInWaiting: 79514,
          dwtInWaiting: 0
        },
        {
          start: '2022-03-01T00:00:00',
          numWaitingEvents: 60,
          averageWaitingTimeDays: 2.0249361496913583,
          totalWaitingTimeDays: 121.49616898148149,
          ceuCapacityInWaiting: 275523,
          dwtInWaiting: 0
        },
        {
          start: '2022-04-01T00:00:00',
          numWaitingEvents: 15,
          averageWaitingTimeDays: 0.7148132716049382,
          totalWaitingTimeDays: 10.722199074074073,
          ceuCapacityInWaiting: 51750,
          dwtInWaiting: 0
        },
        {
          start: '2022-05-01T00:00:00',
          numWaitingEvents: 13,
          averageWaitingTimeDays: 1.3882095797720797,
          totalWaitingTimeDays: 18.046724537037036,
          ceuCapacityInWaiting: 62987,
          dwtInWaiting: 0
        },
        {
          start: '2022-06-01T00:00:00',
          numWaitingEvents: 15,
          averageWaitingTimeDays: 1.4611342592592593,
          totalWaitingTimeDays: 21.91701388888889,
          ceuCapacityInWaiting: 61566,
          dwtInWaiting: 0
        },
        {
          start: '2022-07-01T00:00:00',
          numWaitingEvents: 33,
          averageWaitingTimeDays: 1.6678833473625143,
          totalWaitingTimeDays: 55.04015046296297,
          ceuCapacityInWaiting: 168072,
          dwtInWaiting: 0
        },
        {
          start: '2022-08-01T00:00:00',
          numWaitingEvents: 24,
          averageWaitingTimeDays: 1.661743827160494,
          totalWaitingTimeDays: 39.881851851851856,
          ceuCapacityInWaiting: 138423,
          dwtInWaiting: 0
        },
        {
          start: '2022-09-01T00:00:00',
          numWaitingEvents: 13,
          averageWaitingTimeDays: 1.0758849715099712,
          totalWaitingTimeDays: 13.986504629629627,
          ceuCapacityInWaiting: 62698,
          dwtInWaiting: 0
        },
        {
          start: '2022-10-01T00:00:00',
          numWaitingEvents: 22,
          averageWaitingTimeDays: 1.2977051767676773,
          totalWaitingTimeDays: 28.5495138888889,
          ceuCapacityInWaiting: 97682,
          dwtInWaiting: 0
        },
        {
          start: '2022-11-01T00:00:00',
          numWaitingEvents: 19,
          averageWaitingTimeDays: 0.770379507797271,
          totalWaitingTimeDays: 14.63721064814815,
          ceuCapacityInWaiting: 94201,
          dwtInWaiting: 0
        },
        {
          start: '2022-12-01T00:00:00',
          numWaitingEvents: 27,
          averageWaitingTimeDays: 2.622929098079561,
          totalWaitingTimeDays: 70.81908564814815,
          ceuCapacityInWaiting: 144070,
          dwtInWaiting: 0
        }
      ],
      weeks: [
        {
          week: 1,
          weekStart: '2022-01-03T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 1.2145833333333333,
          totalWaitingTimeDays: 4.858333333333333,
          ceuCapacityInWaiting: 17220,
          dwtInWaiting: 0
        },
        {
          week: 2,
          weekStart: '2022-01-10T00:00:00',
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.46011679292929286,
          totalWaitingTimeDays: 5.061284722222221,
          ceuCapacityInWaiting: 56380,
          dwtInWaiting: 0
        },
        {
          week: 3,
          weekStart: '2022-01-17T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 1.0056412037037037,
          totalWaitingTimeDays: 10.056412037037036,
          ceuCapacityInWaiting: 42368,
          dwtInWaiting: 0
        },
        {
          week: 4,
          weekStart: '2022-01-24T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 2.125,
          totalWaitingTimeDays: 2.125,
          ceuCapacityInWaiting: 6393,
          dwtInWaiting: 0
        },
        {
          week: 5,
          weekStart: '2022-01-31T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.20503472222222222,
          totalWaitingTimeDays: 0.20503472222222222,
          ceuCapacityInWaiting: 4000,
          dwtInWaiting: 0
        },
        {
          week: 6,
          weekStart: '2022-02-07T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.8333796296296296,
          totalWaitingTimeDays: 1.6667592592592593,
          ceuCapacityInWaiting: 11420,
          dwtInWaiting: 0
        },
        {
          week: 7,
          weekStart: '2022-02-14T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.3541666666666667,
          totalWaitingTimeDays: 0.7083333333333334,
          ceuCapacityInWaiting: 13100,
          dwtInWaiting: 0
        },
        {
          week: 8,
          weekStart: '2022-02-21T00:00:00',
          numWaitingEvents: 9,
          averageWaitingTimeDays: 1.2580028292181071,
          totalWaitingTimeDays: 11.322025462962964,
          ceuCapacityInWaiting: 43944,
          dwtInWaiting: 0
        },
        {
          week: 9,
          weekStart: '2022-02-28T00:00:00',
          numWaitingEvents: 16,
          averageWaitingTimeDays: 2.0130280671296297,
          totalWaitingTimeDays: 32.208449074074075,
          ceuCapacityInWaiting: 89494,
          dwtInWaiting: 0
        },
        {
          week: 10,
          weekStart: '2022-03-07T00:00:00',
          numWaitingEvents: 17,
          averageWaitingTimeDays: 1.1563078703703704,
          totalWaitingTimeDays: 19.657233796296296,
          ceuCapacityInWaiting: 67350,
          dwtInWaiting: 0
        },
        {
          week: 11,
          weekStart: '2022-03-14T00:00:00',
          numWaitingEvents: 16,
          averageWaitingTimeDays: 2.248207465277778,
          totalWaitingTimeDays: 35.97131944444445,
          ceuCapacityInWaiting: 83165,
          dwtInWaiting: 0
        },
        {
          week: 12,
          weekStart: '2022-03-21T00:00:00',
          numWaitingEvents: 13,
          averageWaitingTimeDays: 2.0006864316239312,
          totalWaitingTimeDays: 26.008923611111104,
          ceuCapacityInWaiting: 56854,
          dwtInWaiting: 0
        },
        {
          week: 13,
          weekStart: '2022-03-28T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 1.2779976851851853,
          totalWaitingTimeDays: 12.779976851851853,
          ceuCapacityInWaiting: 43414,
          dwtInWaiting: 0
        },
        {
          week: 14,
          weekStart: '2022-04-04T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.974886574074074,
          totalWaitingTimeDays: 4.87443287037037,
          ceuCapacityInWaiting: 18150,
          dwtInWaiting: 0
        },
        {
          week: 15,
          weekStart: '2022-04-11T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.0029282407407407,
          totalWaitingTimeDays: 2.0058564814814814,
          ceuCapacityInWaiting: 11900,
          dwtInWaiting: 0
        },
        {
          week: 16,
          weekStart: '2022-04-18T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.41854166666666665,
          totalWaitingTimeDays: 1.6741666666666666,
          ceuCapacityInWaiting: 10100,
          dwtInWaiting: 0
        },
        {
          week: 17,
          weekStart: '2022-04-25T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.4365277777777778,
          totalWaitingTimeDays: 0.8730555555555556,
          ceuCapacityInWaiting: 10360,
          dwtInWaiting: 0
        },
        {
          week: 18,
          weekStart: '2022-05-02T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.4791493055555555,
          totalWaitingTimeDays: 0.958298611111111,
          ceuCapacityInWaiting: 2070,
          dwtInWaiting: 0
        },
        {
          week: 19,
          weekStart: '2022-05-09T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.1689081790123454,
          totalWaitingTimeDays: 3.5067245370370363,
          ceuCapacityInWaiting: 20699,
          dwtInWaiting: 0
        },
        {
          week: 20,
          weekStart: '2022-05-16T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.9906076388888888,
          totalWaitingTimeDays: 3.9624305555555552,
          ceuCapacityInWaiting: 21399,
          dwtInWaiting: 0
        },
        {
          week: 21,
          weekStart: '2022-05-23T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.9572800925925927,
          totalWaitingTimeDays: 4.7864004629629635,
          ceuCapacityInWaiting: 25084,
          dwtInWaiting: 0
        },
        {
          week: 22,
          weekStart: '2022-05-30T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.9093653549382719,
          totalWaitingTimeDays: 11.456192129629631,
          ceuCapacityInWaiting: 32318,
          dwtInWaiting: 0
        },
        {
          week: 23,
          weekStart: '2022-06-06T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.1042013888888889,
          totalWaitingTimeDays: 0.2084027777777778,
          ceuCapacityInWaiting: 5269,
          dwtInWaiting: 0
        },
        {
          week: 24,
          weekStart: '2022-06-13T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.9582986111111111,
          totalWaitingTimeDays: 0.9582986111111111,
          ceuCapacityInWaiting: 850,
          dwtInWaiting: 0
        },
        {
          week: 25,
          weekStart: '2022-06-20T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.259400462962963,
          totalWaitingTimeDays: 6.297002314814814,
          ceuCapacityInWaiting: 13384,
          dwtInWaiting: 0
        },
        {
          week: 26,
          weekStart: '2022-06-27T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.5986782407407412,
          totalWaitingTimeDays: 7.993391203703705,
          ceuCapacityInWaiting: 22079,
          dwtInWaiting: 0
        },
        {
          week: 27,
          weekStart: '2022-07-04T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 1.9041886574074074,
          totalWaitingTimeDays: 19.041886574074073,
          ceuCapacityInWaiting: 54290,
          dwtInWaiting: 0
        },
        {
          week: 28,
          weekStart: '2022-07-11T00:00:00',
          numWaitingEvents: 12,
          averageWaitingTimeDays: 1.1737750771604942,
          totalWaitingTimeDays: 14.08530092592593,
          ceuCapacityInWaiting: 52170,
          dwtInWaiting: 0
        },
        {
          week: 29,
          weekStart: '2022-07-18T00:00:00',
          numWaitingEvents: 8,
          averageWaitingTimeDays: 1.7389265046296294,
          totalWaitingTimeDays: 13.911412037037035,
          ceuCapacityInWaiting: 38387,
          dwtInWaiting: 0
        },
        {
          week: 30,
          weekStart: '2022-07-25T00:00:00',
          numWaitingEvents: 9,
          averageWaitingTimeDays: 0.8709053497942388,
          totalWaitingTimeDays: 7.838148148148149,
          ceuCapacityInWaiting: 43550,
          dwtInWaiting: 0
        },
        {
          week: 31,
          weekStart: '2022-08-01T00:00:00',
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.9233827861952861,
          totalWaitingTimeDays: 10.157210648148148,
          ceuCapacityInWaiting: 62500,
          dwtInWaiting: 0
        },
        {
          week: 32,
          weekStart: '2022-08-08T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.4260879629629633,
          totalWaitingTimeDays: 8.556527777777779,
          ceuCapacityInWaiting: 37438,
          dwtInWaiting: 0
        },
        {
          week: 33,
          weekStart: '2022-08-15T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.4717110339506174,
          totalWaitingTimeDays: 8.830266203703705,
          ceuCapacityInWaiting: 28549,
          dwtInWaiting: 0
        },
        {
          week: 34,
          weekStart: '2022-08-22T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 3.0702893518518515,
          totalWaitingTimeDays: 9.210868055555554,
          ceuCapacityInWaiting: 19500,
          dwtInWaiting: 0
        },
        {
          week: 35,
          weekStart: '2022-08-29T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.5145115740740738,
          totalWaitingTimeDays: 7.572557870370369,
          ceuCapacityInWaiting: 27870,
          dwtInWaiting: 0
        },
        {
          week: 36,
          weekStart: '2022-09-05T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.0884722222222223,
          totalWaitingTimeDays: 2.1769444444444446,
          ceuCapacityInWaiting: 10400,
          dwtInWaiting: 0
        },
        {
          week: 37,
          weekStart: '2022-09-12T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.24451388888888886,
          totalWaitingTimeDays: 0.48902777777777773,
          ceuCapacityInWaiting: 2450,
          dwtInWaiting: 0
        },
        {
          week: 38,
          weekStart: '2022-09-19T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.8702623456790124,
          totalWaitingTimeDays: 5.221574074074074,
          ceuCapacityInWaiting: 29283,
          dwtInWaiting: 0
        },
        {
          week: 39,
          weekStart: '2022-09-26T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.6533796296296297,
          totalWaitingTimeDays: 1.6533796296296297,
          ceuCapacityInWaiting: 6645,
          dwtInWaiting: 0
        },
        {
          week: 40,
          weekStart: '2022-10-03T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.952800925925926,
          totalWaitingTimeDays: 2.858402777777778,
          ceuCapacityInWaiting: 9600,
          dwtInWaiting: 0
        },
        {
          week: 41,
          weekStart: '2022-10-10T00:00:00',
          numWaitingEvents: 8,
          averageWaitingTimeDays: 0.6053515625,
          totalWaitingTimeDays: 4.8428125,
          ceuCapacityInWaiting: 42114,
          dwtInWaiting: 0
        },
        {
          week: 42,
          weekStart: '2022-10-17T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.844976851851852,
          totalWaitingTimeDays: 11.069861111111111,
          ceuCapacityInWaiting: 26462,
          dwtInWaiting: 0
        },
        {
          week: 43,
          weekStart: '2022-10-24T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.4834780092592592,
          totalWaitingTimeDays: 8.900868055555556,
          ceuCapacityInWaiting: 25906,
          dwtInWaiting: 0
        },
        {
          week: 44,
          weekStart: '2022-10-31T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.9185561342592592,
          totalWaitingTimeDays: 3.6742245370370368,
          ceuCapacityInWaiting: 21054,
          dwtInWaiting: 0
        },
        {
          week: 45,
          weekStart: '2022-11-07T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.8879147376543209,
          totalWaitingTimeDays: 5.327488425925925,
          ceuCapacityInWaiting: 28550,
          dwtInWaiting: 0
        },
        {
          week: 46,
          weekStart: '2022-11-14T00:00:00',
          numWaitingEvents: 7,
          averageWaitingTimeDays: 0.7727265211640211,
          totalWaitingTimeDays: 5.409085648148148,
          ceuCapacityInWaiting: 37717,
          dwtInWaiting: 0
        },
        {
          week: 47,
          weekStart: '2022-11-21T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.2759953703703703,
          totalWaitingTimeDays: 1.1039814814814812,
          ceuCapacityInWaiting: 20734,
          dwtInWaiting: 0
        },
        {
          week: 48,
          weekStart: '2022-11-28T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.0284282407407406,
          totalWaitingTimeDays: 5.142141203703703,
          ceuCapacityInWaiting: 26980,
          dwtInWaiting: 0
        },
        {
          week: 49,
          weekStart: '2022-12-05T00:00:00',
          numWaitingEvents: 13,
          averageWaitingTimeDays: 1.7307380698005697,
          totalWaitingTimeDays: 22.499594907407406,
          ceuCapacityInWaiting: 69429,
          dwtInWaiting: 0
        },
        {
          week: 50,
          weekStart: '2022-12-12T00:00:00',
          numWaitingEvents: 12,
          averageWaitingTimeDays: 1.9609712577160494,
          totalWaitingTimeDays: 23.531655092592594,
          ceuCapacityInWaiting: 63051,
          dwtInWaiting: 0
        },
        {
          week: 51,
          weekStart: '2022-12-19T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 2.8825733024691362,
          totalWaitingTimeDays: 17.295439814814817,
          ceuCapacityInWaiting: 39708,
          dwtInWaiting: 0
        },
        {
          week: 52,
          weekStart: '2022-12-26T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.8022685185185185,
          totalWaitingTimeDays: 2.4068055555555556,
          ceuCapacityInWaiting: 16358,
          dwtInWaiting: 0
        }
      ],
      numWaitingEvents: 266,
      averageWaitingTimeDays: 1.6431675543024236,
      totalWaitingTimeDays: 437.0825694444447,
      ceuCapacityInWaiting: 1263340,
      dwtInWaiting: 0
    },
    {
      year: 2023,
      months: [
        {
          start: '2023-01-01T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 1.6096666666666668,
          totalWaitingTimeDays: 16.096666666666668,
          ceuCapacityInWaiting: 56250,
          dwtInWaiting: 0
        },
        {
          start: '2023-02-01T00:00:00',
          numWaitingEvents: 11,
          averageWaitingTimeDays: 1.3800862794612794,
          totalWaitingTimeDays: 15.180949074074073,
          ceuCapacityInWaiting: 61224,
          dwtInWaiting: 0
        },
        {
          start: '2023-03-01T00:00:00',
          numWaitingEvents: 18,
          averageWaitingTimeDays: 1.1288162294238686,
          totalWaitingTimeDays: 20.318692129629635,
          ceuCapacityInWaiting: 97150,
          dwtInWaiting: 0
        },
        {
          start: '2023-04-01T00:00:00',
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.8724652777777777,
          totalWaitingTimeDays: 9.597118055555555,
          ceuCapacityInWaiting: 55315,
          dwtInWaiting: 0
        },
        {
          start: '2023-05-01T00:00:00',
          numWaitingEvents: 7,
          averageWaitingTimeDays: 0.5095221560846561,
          totalWaitingTimeDays: 3.5666550925925926,
          ceuCapacityInWaiting: 41600,
          dwtInWaiting: 0
        },
        {
          start: '2023-06-01T00:00:00',
          numWaitingEvents: 14,
          averageWaitingTimeDays: 0.6262698412698412,
          totalWaitingTimeDays: 8.767777777777777,
          ceuCapacityInWaiting: 77349,
          dwtInWaiting: 0
        },
        {
          start: '2023-07-01T00:00:00',
          numWaitingEvents: 8,
          averageWaitingTimeDays: 0.5085054976851852,
          totalWaitingTimeDays: 4.068043981481481,
          ceuCapacityInWaiting: 46236,
          dwtInWaiting: 0
        },
        {
          start: '2023-08-01T00:00:00',
          numWaitingEvents: 16,
          averageWaitingTimeDays: 0.6969690393518518,
          totalWaitingTimeDays: 11.15150462962963,
          ceuCapacityInWaiting: 86456,
          dwtInWaiting: 0
        },
        {
          start: '2023-09-01T00:00:00',
          numWaitingEvents: 14,
          averageWaitingTimeDays: 0.8191137566137565,
          totalWaitingTimeDays: 11.467592592592592,
          ceuCapacityInWaiting: 78824,
          dwtInWaiting: 0
        },
        {
          start: '2023-10-01T00:00:00',
          numWaitingEvents: 7,
          averageWaitingTimeDays: 0.35462466931216935,
          totalWaitingTimeDays: 2.4823726851851853,
          ceuCapacityInWaiting: 26799,
          dwtInWaiting: 0
        },
        {
          start: '2023-11-01T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.39185532407407403,
          totalWaitingTimeDays: 3.9185532407407404,
          ceuCapacityInWaiting: 26959,
          dwtInWaiting: 0
        },
        {
          start: '2023-12-01T00:00:00',
          numWaitingEvents: 22,
          averageWaitingTimeDays: 0.46245002104377114,
          totalWaitingTimeDays: 10.173900462962965,
          ceuCapacityInWaiting: 71210,
          dwtInWaiting: 0
        }
      ],
      weeks: [
        {
          week: 1,
          weekStart: '2023-01-02T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.6937442129629628,
          totalWaitingTimeDays: 1.3874884259259257,
          ceuCapacityInWaiting: 14250,
          dwtInWaiting: 0
        },
        {
          week: 2,
          weekStart: '2023-01-09T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.5521875,
          totalWaitingTimeDays: 1.5521875,
          ceuCapacityInWaiting: 7850,
          dwtInWaiting: 0
        },
        {
          week: 3,
          weekStart: '2023-01-16T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 2.2264814814814815,
          totalWaitingTimeDays: 2.2264814814814815,
          ceuCapacityInWaiting: 6400,
          dwtInWaiting: 0
        },
        {
          week: 4,
          weekStart: '2023-01-23T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 1.476090856481481,
          totalWaitingTimeDays: 5.904363425925924,
          ceuCapacityInWaiting: 20000,
          dwtInWaiting: 0
        },
        {
          week: 5,
          weekStart: '2023-01-30T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 2.367098765432099,
          totalWaitingTimeDays: 14.202592592592593,
          ceuCapacityInWaiting: 34800,
          dwtInWaiting: 0
        },
        {
          week: 6,
          weekStart: '2023-02-06T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.1909375000000002,
          totalWaitingTimeDays: 3.5728125000000004,
          ceuCapacityInWaiting: 17900,
          dwtInWaiting: 0
        },
        {
          week: 7,
          weekStart: '2023-02-13T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.33194444444444443,
          totalWaitingTimeDays: 0.33194444444444443,
          ceuCapacityInWaiting: 1800,
          dwtInWaiting: 0
        },
        {
          week: 8,
          weekStart: '2023-02-20T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.5944405864197532,
          totalWaitingTimeDays: 1.7833217592592594,
          ceuCapacityInWaiting: 14870,
          dwtInWaiting: 0
        },
        {
          week: 9,
          weekStart: '2023-02-27T00:00:00',
          numWaitingEvents: 9,
          averageWaitingTimeDays: 0.783000257201646,
          totalWaitingTimeDays: 7.047002314814814,
          ceuCapacityInWaiting: 55094,
          dwtInWaiting: 0
        },
        {
          week: 10,
          weekStart: '2023-03-06T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.6170804398148148,
          totalWaitingTimeDays: 2.468321759259259,
          ceuCapacityInWaiting: 15600,
          dwtInWaiting: 0
        },
        {
          week: 11,
          weekStart: '2023-03-13T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.9004475308641976,
          totalWaitingTimeDays: 2.7013425925925927,
          ceuCapacityInWaiting: 16540,
          dwtInWaiting: 0
        },
        {
          week: 12,
          weekStart: '2023-03-20T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 1.334457947530864,
          totalWaitingTimeDays: 8.006747685185184,
          ceuCapacityInWaiting: 32840,
          dwtInWaiting: 0
        },
        {
          week: 13,
          weekStart: '2023-03-27T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.1775752314814815,
          totalWaitingTimeDays: 0.355150462962963,
          ceuCapacityInWaiting: 10970,
          dwtInWaiting: 0
        },
        {
          week: 14,
          weekStart: '2023-04-03T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.9475578703703704,
          totalWaitingTimeDays: 1.8951157407407409,
          ceuCapacityInWaiting: 5636,
          dwtInWaiting: 0
        },
        {
          week: 15,
          weekStart: '2023-04-10T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.4945439814814815,
          totalWaitingTimeDays: 2.4727199074074075,
          ceuCapacityInWaiting: 21295,
          dwtInWaiting: 0
        },
        {
          week: 16,
          weekStart: '2023-04-17T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 17,
          weekStart: '2023-04-24T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.0458564814814815,
          totalWaitingTimeDays: 5.229282407407407,
          ceuCapacityInWaiting: 28830,
          dwtInWaiting: 0
        },
        {
          week: 18,
          weekStart: '2023-05-01T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.6585619212962963,
          totalWaitingTimeDays: 2.6342476851851853,
          ceuCapacityInWaiting: 25200,
          dwtInWaiting: 0
        },
        {
          week: 19,
          weekStart: '2023-05-08T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 20,
          weekStart: '2023-05-15T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.3670949074074074,
          totalWaitingTimeDays: 0.3670949074074074,
          ceuCapacityInWaiting: 6400,
          dwtInWaiting: 0
        },
        {
          week: 21,
          weekStart: '2023-05-22T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.28265625,
          totalWaitingTimeDays: 0.5653125,
          ceuCapacityInWaiting: 10000,
          dwtInWaiting: 0
        },
        {
          week: 22,
          weekStart: '2023-05-29T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.054085648148148154,
          totalWaitingTimeDays: 0.054085648148148154,
          ceuCapacityInWaiting: 7500,
          dwtInWaiting: 0
        },
        {
          week: 23,
          weekStart: '2023-06-05T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 24,
          weekStart: '2023-06-12T00:00:00',
          numWaitingEvents: 7,
          averageWaitingTimeDays: 0.34226190476190477,
          totalWaitingTimeDays: 2.3958333333333335,
          ceuCapacityInWaiting: 32005,
          dwtInWaiting: 0
        },
        {
          week: 25,
          weekStart: '2023-06-19T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.4194483024691358,
          totalWaitingTimeDays: 4.258344907407407,
          ceuCapacityInWaiting: 16594,
          dwtInWaiting: 0
        },
        {
          week: 26,
          weekStart: '2023-06-26T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.842156635802469,
          totalWaitingTimeDays: 2.526469907407407,
          ceuCapacityInWaiting: 21250,
          dwtInWaiting: 0
        },
        {
          week: 27,
          weekStart: '2023-07-03T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.06461805555555557,
          totalWaitingTimeDays: 0.12923611111111113,
          ceuCapacityInWaiting: 6946,
          dwtInWaiting: 0
        },
        {
          week: 28,
          weekStart: '2023-07-10T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.7197337962962962,
          totalWaitingTimeDays: 1.4394675925925924,
          ceuCapacityInWaiting: 13500,
          dwtInWaiting: 0
        },
        {
          week: 29,
          weekStart: '2023-07-17T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.8470775462962962,
          totalWaitingTimeDays: 1.6941550925925923,
          ceuCapacityInWaiting: 12390,
          dwtInWaiting: 0
        },
        {
          week: 30,
          weekStart: '2023-07-24T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.3382291666666667,
          totalWaitingTimeDays: 0.3382291666666667,
          ceuCapacityInWaiting: 6400,
          dwtInWaiting: 0
        },
        {
          week: 31,
          weekStart: '2023-07-31T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.3694762731481482,
          totalWaitingTimeDays: 1.4779050925925927,
          ceuCapacityInWaiting: 15236,
          dwtInWaiting: 0
        },
        {
          week: 32,
          weekStart: '2023-08-07T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.43363425925925925,
          totalWaitingTimeDays: 1.3009027777777777,
          ceuCapacityInWaiting: 22400,
          dwtInWaiting: 0
        },
        {
          week: 33,
          weekStart: '2023-08-14T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.7632523148148147,
          totalWaitingTimeDays: 3.5265046296296294,
          ceuCapacityInWaiting: 6550,
          dwtInWaiting: 0
        },
        {
          week: 34,
          weekStart: '2023-08-21T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.7640856481481482,
          totalWaitingTimeDays: 4.584513888888889,
          ceuCapacityInWaiting: 34770,
          dwtInWaiting: 0
        },
        {
          week: 35,
          weekStart: '2023-08-28T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.6137229938271606,
          totalWaitingTimeDays: 1.8411689814814816,
          ceuCapacityInWaiting: 19154,
          dwtInWaiting: 0
        },
        {
          week: 36,
          weekStart: '2023-09-04T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.9762314814814814,
          totalWaitingTimeDays: 4.881157407407407,
          ceuCapacityInWaiting: 29574,
          dwtInWaiting: 0
        },
        {
          week: 37,
          weekStart: '2023-09-11T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.37447453703703704,
          totalWaitingTimeDays: 1.8723726851851852,
          ceuCapacityInWaiting: 26450,
          dwtInWaiting: 0
        },
        {
          week: 38,
          weekStart: '2023-09-18T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 39,
          weekStart: '2023-09-25T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.7836429398148147,
          totalWaitingTimeDays: 3.134571759259259,
          ceuCapacityInWaiting: 22800,
          dwtInWaiting: 0
        },
        {
          week: 40,
          weekStart: '2023-10-02T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 41,
          weekStart: '2023-10-09T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.3062962962962963,
          totalWaitingTimeDays: 1.2251851851851852,
          ceuCapacityInWaiting: 17350,
          dwtInWaiting: 0
        },
        {
          week: 42,
          weekStart: '2023-10-16T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.41906249999999995,
          totalWaitingTimeDays: 1.2571875,
          ceuCapacityInWaiting: 9449,
          dwtInWaiting: 0
        },
        {
          week: 43,
          weekStart: '2023-10-23T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 44,
          weekStart: '2023-10-30T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.9066550925925926,
          totalWaitingTimeDays: 0.9066550925925926,
          ceuCapacityInWaiting: 1600,
          dwtInWaiting: 0
        },
        {
          week: 45,
          weekStart: '2023-11-06T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.45862268518518523,
          totalWaitingTimeDays: 0.9172453703703705,
          ceuCapacityInWaiting: 1850,
          dwtInWaiting: 0
        },
        {
          week: 46,
          weekStart: '2023-11-13T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.14238425925925927,
          totalWaitingTimeDays: 0.14238425925925927,
          ceuCapacityInWaiting: 1250,
          dwtInWaiting: 0
        },
        {
          week: 47,
          weekStart: '2023-11-20T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.40701003086419746,
          totalWaitingTimeDays: 1.2210300925925923,
          ceuCapacityInWaiting: 11909,
          dwtInWaiting: 0
        },
        {
          week: 48,
          weekStart: '2023-11-27T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.5209394290123456,
          totalWaitingTimeDays: 3.1256365740740737,
          ceuCapacityInWaiting: 23650,
          dwtInWaiting: 0
        },
        {
          week: 49,
          weekStart: '2023-12-04T00:00:00',
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.5051136363636364,
          totalWaitingTimeDays: 5.55625,
          ceuCapacityInWaiting: 29688,
          dwtInWaiting: 0
        },
        {
          week: 50,
          weekStart: '2023-12-11T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.16688657407407406,
          totalWaitingTimeDays: 0.16688657407407406,
          ceuCapacityInWaiting: 6400,
          dwtInWaiting: 0
        },
        {
          week: 51,
          weekStart: '2023-12-18T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.21757523148148147,
          totalWaitingTimeDays: 0.43515046296296295,
          ceuCapacityInWaiting: 1600,
          dwtInWaiting: 0
        },
        {
          week: 52,
          weekStart: '2023-12-25T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.3242430555555555,
          totalWaitingTimeDays: 1.6212152777777775,
          ceuCapacityInWaiting: 20222,
          dwtInWaiting: 0
        }
      ],
      numWaitingEvents: 145,
      averageWaitingTimeDays: 0.8054470785440608,
      totalWaitingTimeDays: 116.78982638888883,
      ceuCapacityInWaiting: 705572,
      dwtInWaiting: 0
    },
    {
      year: 2024,
      months: [
        {
          start: '2024-01-01T00:00:00',
          numWaitingEvents: 14,
          averageWaitingTimeDays: 0.7769841269841271,
          totalWaitingTimeDays: 10.87777777777778,
          ceuCapacityInWaiting: 50285,
          dwtInWaiting: 0
        },
        {
          start: '2024-02-01T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.5445601851851851,
          totalWaitingTimeDays: 5.445601851851851,
          ceuCapacityInWaiting: 24584,
          dwtInWaiting: 0
        },
        {
          start: '2024-03-01T00:00:00',
          numWaitingEvents: 13,
          averageWaitingTimeDays: 1.5354211182336182,
          totalWaitingTimeDays: 19.960474537037037,
          ceuCapacityInWaiting: 79000,
          dwtInWaiting: 0
        },
        {
          start: '2024-04-01T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.7806377314814814,
          totalWaitingTimeDays: 7.806377314814815,
          ceuCapacityInWaiting: 41410,
          dwtInWaiting: 0
        },
        {
          start: '2024-05-01T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.7181741898148148,
          totalWaitingTimeDays: 2.8726967592592594,
          ceuCapacityInWaiting: 20050,
          dwtInWaiting: 0
        },
        {
          start: '2024-06-01T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.7770439814814815,
          totalWaitingTimeDays: 3.885219907407407,
          ceuCapacityInWaiting: 15650,
          dwtInWaiting: 0
        },
        {
          start: '2024-07-01T00:00:00',
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.7363333333333334,
          totalWaitingTimeDays: 7.363333333333334,
          ceuCapacityInWaiting: 56378,
          dwtInWaiting: 0
        },
        {
          start: '2024-08-01T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.22921296296296295,
          totalWaitingTimeDays: 0.22921296296296295,
          ceuCapacityInWaiting: 1500,
          dwtInWaiting: 0
        }
      ],
      weeks: [
        {
          week: 1,
          weekStart: '2024-01-01T00:00:00',
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.8273553240740741,
          totalWaitingTimeDays: 4.9641319444444445,
          ceuCapacityInWaiting: 15056,
          dwtInWaiting: 0
        },
        {
          week: 2,
          weekStart: '2024-01-08T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.9864120370370371,
          totalWaitingTimeDays: 2.959236111111111,
          ceuCapacityInWaiting: 12552,
          dwtInWaiting: 0
        },
        {
          week: 3,
          weekStart: '2024-01-15T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.5777719907407407,
          totalWaitingTimeDays: 2.311087962962963,
          ceuCapacityInWaiting: 15159,
          dwtInWaiting: 0
        },
        {
          week: 4,
          weekStart: '2024-01-22T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 5,
          weekStart: '2024-01-29T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.29851851851851857,
          totalWaitingTimeDays: 0.8955555555555557,
          ceuCapacityInWaiting: 12570,
          dwtInWaiting: 0
        },
        {
          week: 6,
          weekStart: '2024-02-05T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.8740914351851852,
          totalWaitingTimeDays: 3.4963657407407407,
          ceuCapacityInWaiting: 17379,
          dwtInWaiting: 0
        },
        {
          week: 7,
          weekStart: '2024-02-12T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.25306712962962963,
          totalWaitingTimeDays: 0.5061342592592593,
          ceuCapacityInWaiting: 2350,
          dwtInWaiting: 0
        },
        {
          week: 8,
          weekStart: '2024-02-19T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.3540509259259259,
          totalWaitingTimeDays: 0.3540509259259259,
          ceuCapacityInWaiting: 1500,
          dwtInWaiting: 0
        },
        {
          week: 9,
          weekStart: '2024-02-26T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.41840856481481475,
          totalWaitingTimeDays: 0.8368171296296295,
          ceuCapacityInWaiting: 1855,
          dwtInWaiting: 0
        },
        {
          week: 10,
          weekStart: '2024-03-04T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 1.2445225694444444,
          totalWaitingTimeDays: 4.978090277777778,
          ceuCapacityInWaiting: 27800,
          dwtInWaiting: 0
        },
        {
          week: 11,
          weekStart: '2024-03-11T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 1.0522366898148148,
          totalWaitingTimeDays: 4.208946759259259,
          ceuCapacityInWaiting: 27300,
          dwtInWaiting: 0
        },
        {
          week: 12,
          weekStart: '2024-03-18T00:00:00',
          numWaitingEvents: 5,
          averageWaitingTimeDays: 1.6018796296296298,
          totalWaitingTimeDays: 8.009398148148149,
          ceuCapacityInWaiting: 29300,
          dwtInWaiting: 0
        },
        {
          week: 13,
          weekStart: '2024-03-25T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.382019675925926,
          totalWaitingTimeDays: 2.764039351851852,
          ceuCapacityInWaiting: 9100,
          dwtInWaiting: 0
        },
        {
          week: 14,
          weekStart: '2024-04-01T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 3.4068055555555556,
          totalWaitingTimeDays: 3.4068055555555556,
          ceuCapacityInWaiting: 1600,
          dwtInWaiting: 0
        },
        {
          week: 15,
          weekStart: '2024-04-08T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 16,
          weekStart: '2024-04-15T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.26614583333333336,
          totalWaitingTimeDays: 0.5322916666666667,
          ceuCapacityInWaiting: 9080,
          dwtInWaiting: 0
        },
        {
          week: 17,
          weekStart: '2024-04-22T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.7455179398148148,
          totalWaitingTimeDays: 2.9820717592592594,
          ceuCapacityInWaiting: 16050,
          dwtInWaiting: 0
        },
        {
          week: 18,
          weekStart: '2024-04-29T00:00:00',
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.3742708333333334,
          totalWaitingTimeDays: 1.4970833333333335,
          ceuCapacityInWaiting: 15430,
          dwtInWaiting: 0
        },
        {
          week: 19,
          weekStart: '2024-05-06T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.5105439814814814,
          totalWaitingTimeDays: 0.5105439814814814,
          ceuCapacityInWaiting: 6700,
          dwtInWaiting: 0
        },
        {
          week: 20,
          weekStart: '2024-05-13T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.4005555555555555,
          totalWaitingTimeDays: 0.4005555555555555,
          ceuCapacityInWaiting: 6200,
          dwtInWaiting: 0
        },
        {
          week: 21,
          weekStart: '2024-05-20T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.6748611111111111,
          totalWaitingTimeDays: 1.3497222222222223,
          ceuCapacityInWaiting: 12600,
          dwtInWaiting: 0
        },
        {
          week: 22,
          weekStart: '2024-05-27T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.3396875,
          totalWaitingTimeDays: 0.3396875,
          ceuCapacityInWaiting: 6200,
          dwtInWaiting: 0
        },
        {
          week: 23,
          weekStart: '2024-06-03T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.04159722222222222,
          totalWaitingTimeDays: 0.04159722222222222,
          ceuCapacityInWaiting: 4900,
          dwtInWaiting: 0
        },
        {
          week: 24,
          weekStart: '2024-06-10T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.2334606481481483,
          totalWaitingTimeDays: 1.2334606481481483,
          ceuCapacityInWaiting: 1250,
          dwtInWaiting: 0
        },
        {
          week: 25,
          weekStart: '2024-06-17T00:00:00',
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.1352372685185184,
          totalWaitingTimeDays: 2.2704745370370367,
          ceuCapacityInWaiting: 3300,
          dwtInWaiting: 0
        },
        {
          week: 26,
          weekStart: '2024-06-24T00:00:00',
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          week: 27,
          weekStart: '2024-07-01T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.20321373456790123,
          totalWaitingTimeDays: 0.6096412037037037,
          ceuCapacityInWaiting: 14550,
          dwtInWaiting: 0
        },
        {
          week: 28,
          weekStart: '2024-07-08T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.7608063271604939,
          totalWaitingTimeDays: 2.2824189814814817,
          ceuCapacityInWaiting: 14828,
          dwtInWaiting: 0
        },
        {
          week: 29,
          weekStart: '2024-07-15T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.3001388888888889,
          totalWaitingTimeDays: 0.3001388888888889,
          ceuCapacityInWaiting: 8000,
          dwtInWaiting: 0
        },
        {
          week: 30,
          weekStart: '2024-07-22T00:00:00',
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.1875810185185183,
          totalWaitingTimeDays: 3.562743055555555,
          ceuCapacityInWaiting: 19000,
          dwtInWaiting: 0
        },
        {
          week: 31,
          weekStart: '2024-07-29T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.6083912037037037,
          totalWaitingTimeDays: 0.6083912037037037,
          ceuCapacityInWaiting: 6200,
          dwtInWaiting: 0
        },
        {
          week: 32,
          weekStart: '2024-08-05T00:00:00',
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.22921296296296295,
          totalWaitingTimeDays: 0.22921296296296295,
          ceuCapacityInWaiting: 1500,
          dwtInWaiting: 0
        }
      ],
      numWaitingEvents: 66,
      averageWaitingTimeDays: 0.8854650673400672,
      totalWaitingTimeDays: 58.44069444444444,
      ceuCapacityInWaiting: 287257,
      dwtInWaiting: 0
    }
  ],
  operatorCongestions: [
    {
      operatorId: 2785,
      operator: 'Hoegh Autoliners',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.2375694444444445,
              totalWaitingTimeDays: 3.2375694444444445,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0482523148148148,
              totalWaitingTimeDays: 1.0482523148148148,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6453472222222223,
              totalWaitingTimeDays: 1.6453472222222223,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.4166550925925927,
              totalWaitingTimeDays: 2.4166550925925927,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8209143518518518,
              totalWaitingTimeDays: 0.8209143518518518,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0482523148148148,
              totalWaitingTimeDays: 1.0482523148148148,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6453472222222223,
              totalWaitingTimeDays: 1.6453472222222223,
              ceuCapacityInWaiting: 3693,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.9770563271604937,
          totalWaitingTimeDays: 5.931168981481481,
          ceuCapacityInWaiting: 11079,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6250000000000002,
              totalWaitingTimeDays: 1.6250000000000002,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.5567515432098764,
              totalWaitingTimeDays: 7.670254629629629,
              ceuCapacityInWaiting: 24200,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9998726851851851,
              totalWaitingTimeDays: 0.9998726851851851,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.5827314814814812,
              totalWaitingTimeDays: 2.5827314814814812,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.7315470679012345,
              totalWaitingTimeDays: 2.1946412037037035,
              ceuCapacityInWaiting: 22200,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.9086718750000001,
              totalWaitingTimeDays: 3.6346875000000005,
              ceuCapacityInWaiting: 28700,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5416550925925926,
              totalWaitingTimeDays: 0.5416550925925926,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0833449074074073,
              totalWaitingTimeDays: 1.0833449074074073,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.5000115740740743,
              totalWaitingTimeDays: 2.5000115740740743,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 4.453148148148148,
              totalWaitingTimeDays: 4.453148148148148,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5088888888888888,
              totalWaitingTimeDays: 0.5088888888888888,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20820601851851853,
              totalWaitingTimeDays: 0.20820601851851853,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9998726851851851,
              totalWaitingTimeDays: 0.9998726851851851,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5015625,
              totalWaitingTimeDays: 1.5015625,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0811689814814816,
              totalWaitingTimeDays: 1.0811689814814816,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5052430555555555,
              totalWaitingTimeDays: 0.5052430555555555,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.3436805555555555,
              totalWaitingTimeDays: 1.3436805555555555,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3457175925925926,
              totalWaitingTimeDays: 0.3457175925925926,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14233796296296297,
              totalWaitingTimeDays: 0.14233796296296297,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.187349537037037,
              totalWaitingTimeDays: 1.187349537037037,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.1524999999999999,
              totalWaitingTimeDays: 2.3049999999999997,
              ceuCapacityInWaiting: 14350,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 13,
          averageWaitingTimeDays: 1.439014423076923,
          totalWaitingTimeDays: 18.7071875,
          ceuCapacityInWaiting: 99300,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.0194791666666667,
              totalWaitingTimeDays: 2.0194791666666667,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.19361111111111112,
              totalWaitingTimeDays: 0.19361111111111112,
              ceuCapacityInWaiting: 6000,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.29168402777777774,
              totalWaitingTimeDays: 0.5833680555555555,
              ceuCapacityInWaiting: 16350,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.21511574074074072,
              totalWaitingTimeDays: 0.21511574074074072,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.47087962962962965,
              totalWaitingTimeDays: 0.47087962962962965,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46729166666666666,
              totalWaitingTimeDays: 0.46729166666666666,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5521875,
              totalWaitingTimeDays: 1.5521875,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.19361111111111112,
              totalWaitingTimeDays: 0.19361111111111112,
              ceuCapacityInWaiting: 6000,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.15802083333333333,
              totalWaitingTimeDays: 0.15802083333333333,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4253472222222222,
              totalWaitingTimeDays: 0.4253472222222222,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.21511574074074072,
              totalWaitingTimeDays: 0.21511574074074072,
              ceuCapacityInWaiting: 8500,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2023-12-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.47087962962962965,
              totalWaitingTimeDays: 0.47087962962962965,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.5804089506172839,
          totalWaitingTimeDays: 3.4824537037037038,
          ceuCapacityInWaiting: 45200,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12268518518518517,
              totalWaitingTimeDays: 0.12268518518518517,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12268518518518517,
              totalWaitingTimeDays: 0.12268518518518517,
              ceuCapacityInWaiting: 7850,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.12268518518518517,
          totalWaitingTimeDays: 0.12268518518518517,
          ceuCapacityInWaiting: 7850,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2820,
      operator: 'Wallenius Wilhelmsen',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 0.7416712962962962,
              totalWaitingTimeDays: 3.708356481481481,
              ceuCapacityInWaiting: 36420,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 2.0733935185185186,
              totalWaitingTimeDays: 10.366967592592593,
              ceuCapacityInWaiting: 34707,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 9,
              averageWaitingTimeDays: 2.1288683127572012,
              totalWaitingTimeDays: 19.159814814814812,
              ceuCapacityInWaiting: 60632,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 8,
              averageWaitingTimeDays: 1.1941767939814816,
              totalWaitingTimeDays: 9.553414351851853,
              ceuCapacityInWaiting: 55391,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 3.072427662037037,
              totalWaitingTimeDays: 12.289710648148148,
              ceuCapacityInWaiting: 28410,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7916782407407408,
              totalWaitingTimeDays: 0.7916782407407408,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.625,
              totalWaitingTimeDays: 1.25,
              ceuCapacityInWaiting: 13499,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.3858101851851852,
              totalWaitingTimeDays: 2.7716203703703703,
              ceuCapacityInWaiting: 16000,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.6666666666666667,
              totalWaitingTimeDays: 3.3333333333333335,
              ceuCapacityInWaiting: 13594,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.2708391203703704,
              totalWaitingTimeDays: 2.541678240740741,
              ceuCapacityInWaiting: 14400,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.625011574074074,
              totalWaitingTimeDays: 0.625011574074074,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2708333333333333,
              totalWaitingTimeDays: 0.5416666666666666,
              ceuCapacityInWaiting: 14020,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.069444444444444,
              totalWaitingTimeDays: 6.208333333333333,
              ceuCapacityInWaiting: 20419,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.5292476851851853,
              totalWaitingTimeDays: 6.116990740740741,
              ceuCapacityInWaiting: 29033,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5,
              totalWaitingTimeDays: 1.5,
              ceuCapacityInWaiting: 6450,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.552080439814815,
              totalWaitingTimeDays: 6.20832175925926,
              ceuCapacityInWaiting: 25319,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.666670524691358,
              totalWaitingTimeDays: 5.000011574074074,
              ceuCapacityInWaiting: 20611,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.2465625,
              totalWaitingTimeDays: 4.493125,
              ceuCapacityInWaiting: 13784,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.1496952160493827,
              totalWaitingTimeDays: 3.449085648148148,
              ceuCapacityInWaiting: 21728,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.4375501543209876,
              totalWaitingTimeDays: 4.312650462962963,
              ceuCapacityInWaiting: 20754,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.5972260802469135,
              totalWaitingTimeDays: 1.7916782407407406,
              ceuCapacityInWaiting: 20909,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.124988425925926,
              totalWaitingTimeDays: 1.124988425925926,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 4.666672453703703,
              totalWaitingTimeDays: 9.333344907407406,
              ceuCapacityInWaiting: 14145,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.706377314814815,
              totalWaitingTimeDays: 1.706377314814815,
              ceuCapacityInWaiting: 6645,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.125,
              totalWaitingTimeDays: 0.125,
              ceuCapacityInWaiting: 7620,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7916782407407408,
              totalWaitingTimeDays: 0.7916782407407408,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0416666666666667,
              totalWaitingTimeDays: 1.0416666666666667,
              ceuCapacityInWaiting: 6354,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20833333333333334,
              totalWaitingTimeDays: 0.20833333333333334,
              ceuCapacityInWaiting: 7145,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.75,
              totalWaitingTimeDays: 2.75,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.02162037037037037,
              totalWaitingTimeDays: 0.02162037037037037,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.6666666666666667,
              totalWaitingTimeDays: 3.3333333333333335,
              ceuCapacityInWaiting: 13594,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 37,
          averageWaitingTimeDays: 1.7087809684684683,
          totalWaitingTimeDays: 63.22489583333333,
          ceuCapacityInWaiting: 258719,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 1.7622592592592592,
              totalWaitingTimeDays: 8.811296296296296,
              ceuCapacityInWaiting: 34631,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.9999922839506175,
              totalWaitingTimeDays: 5.999976851851852,
              ceuCapacityInWaiting: 21314,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 8,
              averageWaitingTimeDays: 5.303890335648149,
              totalWaitingTimeDays: 42.431122685185194,
              ceuCapacityInWaiting: 55583,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.7742997685185187,
              totalWaitingTimeDays: 5.548599537037037,
              ceuCapacityInWaiting: 13590,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 2.594638888888889,
              totalWaitingTimeDays: 12.973194444444445,
              ceuCapacityInWaiting: 34917,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 2.9829398148148143,
              totalWaitingTimeDays: 14.914699074074072,
              ceuCapacityInWaiting: 34047,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 9,
              averageWaitingTimeDays: 2.6616833847736623,
              totalWaitingTimeDays: 23.955150462962962,
              ceuCapacityInWaiting: 67385,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 10,
              averageWaitingTimeDays: 2.663356481481481,
              totalWaitingTimeDays: 26.63356481481481,
              ceuCapacityInWaiting: 69953,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.3272916666666668,
              totalWaitingTimeDays: 5.309166666666667,
              ceuCapacityInWaiting: 26648,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.1396103395061727,
              totalWaitingTimeDays: 6.418831018518518,
              ceuCapacityInWaiting: 20258,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.9603279320987655,
              totalWaitingTimeDays: 2.8809837962962965,
              ceuCapacityInWaiting: 23534,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.1133873456790124,
              totalWaitingTimeDays: 6.340162037037038,
              ceuCapacityInWaiting: 21600,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.499988425925926,
              totalWaitingTimeDays: 2.499988425925926,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6973206018518519,
              totalWaitingTimeDays: 1.3946412037037037,
              ceuCapacityInWaiting: 15200,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.3958333333333333,
              totalWaitingTimeDays: 2.7916666666666665,
              ceuCapacityInWaiting: 13038,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.125,
              totalWaitingTimeDays: 2.125,
              ceuCapacityInWaiting: 6393,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9166666666666666,
              totalWaitingTimeDays: 0.9166666666666666,
              ceuCapacityInWaiting: 7620,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.5416550925925927,
              totalWaitingTimeDays: 3.0833101851851854,
              ceuCapacityInWaiting: 13694,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 4.194591049382716,
              totalWaitingTimeDays: 12.583773148148147,
              ceuCapacityInWaiting: 20094,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 3.1981211419753084,
              totalWaitingTimeDays: 9.594363425925925,
              ceuCapacityInWaiting: 23145,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 5.401601080246913,
              totalWaitingTimeDays: 16.20480324074074,
              ceuCapacityInWaiting: 22354,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.4841724537037035,
              totalWaitingTimeDays: 6.968344907407407,
              ceuCapacityInWaiting: 12344,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.7896296296296295,
              totalWaitingTimeDays: 2.7896296296296295,
              ceuCapacityInWaiting: 7600,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.8388078703703703,
              totalWaitingTimeDays: 1.8388078703703703,
              ceuCapacityInWaiting: 7600,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.1689081790123454,
              totalWaitingTimeDays: 3.5067245370370363,
              ceuCapacityInWaiting: 20699,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.6294560185185185,
              totalWaitingTimeDays: 3.258912037037037,
              ceuCapacityInWaiting: 13499,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.996446759259259,
              totalWaitingTimeDays: 3.996446759259259,
              ceuCapacityInWaiting: 6284,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.58295524691358,
              totalWaitingTimeDays: 7.748865740740739,
              ceuCapacityInWaiting: 20618,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 4.2520949074074075,
              totalWaitingTimeDays: 4.2520949074074075,
              ceuCapacityInWaiting: 6284,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.644126157407407,
              totalWaitingTimeDays: 5.288252314814814,
              ceuCapacityInWaiting: 13429,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 2.998940972222222,
              totalWaitingTimeDays: 11.995763888888888,
              ceuCapacityInWaiting: 30220,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.1958912037037033,
              totalWaitingTimeDays: 6.58767361111111,
              ceuCapacityInWaiting: 24000,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.3533912037037037,
              totalWaitingTimeDays: 2.7067824074074074,
              ceuCapacityInWaiting: 14020,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.250763888888889,
              totalWaitingTimeDays: 2.501527777777778,
              ceuCapacityInWaiting: 14400,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.7610802469135807,
              totalWaitingTimeDays: 5.283240740740742,
              ceuCapacityInWaiting: 21900,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.7153298611111114,
              totalWaitingTimeDays: 3.430659722222223,
              ceuCapacityInWaiting: 14288,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.790908564814815,
              totalWaitingTimeDays: 5.58181712962963,
              ceuCapacityInWaiting: 14579,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 3.0702893518518515,
              totalWaitingTimeDays: 9.210868055555554,
              ceuCapacityInWaiting: 19500,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.6401234567901233,
              totalWaitingTimeDays: 4.92037037037037,
              ceuCapacityInWaiting: 20720,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.9311979166666666,
              totalWaitingTimeDays: 1.8623958333333333,
              ceuCapacityInWaiting: 12383,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6533796296296297,
              totalWaitingTimeDays: 1.6533796296296297,
              ceuCapacityInWaiting: 6645,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8334259259259258,
              totalWaitingTimeDays: 0.8334259259259258,
              ceuCapacityInWaiting: 6284,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6666203703703704,
              totalWaitingTimeDays: 1.6666203703703704,
              ceuCapacityInWaiting: 7620,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.041215277777778,
              totalWaitingTimeDays: 3.041215277777778,
              ceuCapacityInWaiting: 6354,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8775694444444445,
              totalWaitingTimeDays: 0.8775694444444445,
              ceuCapacityInWaiting: 6354,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5833333333333334,
              totalWaitingTimeDays: 0.5833333333333334,
              ceuCapacityInWaiting: 7600,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.7341898148148147,
              totalWaitingTimeDays: 1.7341898148148147,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5634606481481481,
              totalWaitingTimeDays: 0.5634606481481481,
              ceuCapacityInWaiting: 7934,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.8465393518518516,
              totalWaitingTimeDays: 3.693078703703703,
              ceuCapacityInWaiting: 13600,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7160821759259259,
              totalWaitingTimeDays: 1.4321643518518519,
              ceuCapacityInWaiting: 13600,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2149189814814816,
              totalWaitingTimeDays: 1.2149189814814816,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 52,
          averageWaitingTimeDays: 3.1195528400997143,
          totalWaitingTimeDays: 162.21674768518514,
          ceuCapacityInWaiting: 366793,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9201967592592593,
              totalWaitingTimeDays: 0.9201967592592593,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08806712962962962,
              totalWaitingTimeDays: 0.08806712962962962,
              ceuCapacityInWaiting: 6354,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.831741898148148,
              totalWaitingTimeDays: 3.663483796296296,
              ceuCapacityInWaiting: 13960,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.1349884259259257,
              totalWaitingTimeDays: 2.2699768518518515,
              ceuCapacityInWaiting: 14400,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.5686766975308643,
              totalWaitingTimeDays: 1.7060300925925926,
              ceuCapacityInWaiting: 20239,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.48291666666666666,
              totalWaitingTimeDays: 0.9658333333333333,
              ceuCapacityInWaiting: 12390,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.7484722222222222,
              totalWaitingTimeDays: 2.993888888888889,
              ceuCapacityInWaiting: 28293,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.4930787037037039,
              totalWaitingTimeDays: 4.479236111111112,
              ceuCapacityInWaiting: 22020,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.048761574074074075,
              totalWaitingTimeDays: 0.048761574074074075,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14903935185185185,
              totalWaitingTimeDays: 0.14903935185185185,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.7920061728395061,
              totalWaitingTimeDays: 2.3760185185185185,
              ceuCapacityInWaiting: 22020,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9201967592592593,
              totalWaitingTimeDays: 0.9201967592592593,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2926793981481482,
              totalWaitingTimeDays: 0.5853587962962964,
              ceuCapacityInWaiting: 13974,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.7720833333333335,
              totalWaitingTimeDays: 1.7720833333333335,
              ceuCapacityInWaiting: 6340,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.3941087962962964,
              totalWaitingTimeDays: 1.3941087962962964,
              ceuCapacityInWaiting: 6340,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.05258101851851852,
              totalWaitingTimeDays: 0.05258101851851852,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.2173958333333332,
              totalWaitingTimeDays: 2.2173958333333332,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.20538773148148148,
              totalWaitingTimeDays: 0.41077546296296297,
              ceuCapacityInWaiting: 13045,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2952546296296295,
              totalWaitingTimeDays: 1.2952546296296295,
              ceuCapacityInWaiting: 7194,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6276041666666666,
              totalWaitingTimeDays: 0.6276041666666666,
              ceuCapacityInWaiting: 5990,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3382291666666667,
              totalWaitingTimeDays: 0.3382291666666667,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5130555555555555,
              totalWaitingTimeDays: 0.5130555555555555,
              ceuCapacityInWaiting: 6393,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0580902777777779,
              totalWaitingTimeDays: 1.0580902777777779,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7113715277777778,
              totalWaitingTimeDays: 1.4227430555555556,
              ceuCapacityInWaiting: 14400,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5574884259259258,
              totalWaitingTimeDays: 1.5574884259259258,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.400775462962963,
              totalWaitingTimeDays: 2.801550925925926,
              ceuCapacityInWaiting: 14020,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12019675925925927,
              totalWaitingTimeDays: 0.12019675925925927,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.048761574074074075,
              totalWaitingTimeDays: 0.048761574074074075,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14903935185185185,
              totalWaitingTimeDays: 0.14903935185185185,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6661805555555558,
              totalWaitingTimeDays: 1.6661805555555558,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5429513888888889,
              totalWaitingTimeDays: 0.5429513888888889,
              ceuCapacityInWaiting: 7620,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16688657407407406,
              totalWaitingTimeDays: 0.16688657407407406,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 23,
          averageWaitingTimeDays: 0.8548057568438004,
          totalWaitingTimeDays: 19.66053240740741,
          ceuCapacityInWaiting: 160476,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.709212962962963,
              totalWaitingTimeDays: 0.709212962962963,
              ceuCapacityInWaiting: 7934,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.6290104166666668,
              totalWaitingTimeDays: 3.2580208333333336,
              ceuCapacityInWaiting: 13900,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1458217592592592,
              totalWaitingTimeDays: 1.1458217592592592,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3396875,
              totalWaitingTimeDays: 0.3396875,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3001388888888889,
              totalWaitingTimeDays: 0.3001388888888889,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.709212962962963,
              totalWaitingTimeDays: 0.709212962962963,
              ceuCapacityInWaiting: 7934,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.5010648148148147,
              totalWaitingTimeDays: 2.5010648148148147,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7569560185185185,
              totalWaitingTimeDays: 0.7569560185185185,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4005555555555555,
              totalWaitingTimeDays: 0.4005555555555555,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7452662037037038,
              totalWaitingTimeDays: 0.7452662037037038,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3396875,
              totalWaitingTimeDays: 0.3396875,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3001388888888889,
              totalWaitingTimeDays: 0.3001388888888889,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.9588136574074073,
          totalWaitingTimeDays: 5.752881944444444,
          ceuCapacityInWaiting: 42234,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2799,
      operator: 'Nippon Yusen Kaisha',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04165509259259259,
              totalWaitingTimeDays: 0.04165509259259259,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2708391203703704,
              totalWaitingTimeDays: 0.5416782407407408,
              ceuCapacityInWaiting: 12480,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.625,
              totalWaitingTimeDays: 0.625,
              ceuCapacityInWaiting: 6000,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.4166666666666667,
              totalWaitingTimeDays: 2.8333333333333335,
              ceuCapacityInWaiting: 12400,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04165509259259259,
              totalWaitingTimeDays: 0.04165509259259259,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.37501157407407404,
              totalWaitingTimeDays: 0.37501157407407404,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16666666666666666,
              totalWaitingTimeDays: 0.16666666666666666,
              ceuCapacityInWaiting: 5980,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.625,
              totalWaitingTimeDays: 0.625,
              ceuCapacityInWaiting: 6000,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.458321759259259,
              totalWaitingTimeDays: 2.458321759259259,
              ceuCapacityInWaiting: 6000,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.18750578703703702,
              totalWaitingTimeDays: 0.37501157407407404,
              ceuCapacityInWaiting: 12400,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2021-12-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.8749884259259262,
              totalWaitingTimeDays: 1.8749884259259262,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 7,
          averageWaitingTimeDays: 0.5833333333333334,
          totalWaitingTimeDays: 4.083333333333334,
          ceuCapacityInWaiting: 43710,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 2.263888888888889,
              totalWaitingTimeDays: 6.791666666666667,
              ceuCapacityInWaiting: 19230,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.9375,
              totalWaitingTimeDays: 1.875,
              ceuCapacityInWaiting: 12900,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.716082175925926,
              totalWaitingTimeDays: 6.864328703703704,
              ceuCapacityInWaiting: 25530,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16674768518518518,
              totalWaitingTimeDays: 0.16674768518518518,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.3333188657407409,
              totalWaitingTimeDays: 5.3332754629629635,
              ceuCapacityInWaiting: 25700,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6666898148148148,
              totalWaitingTimeDays: 0.6666898148148148,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.7023900462962962,
              totalWaitingTimeDays: 3.4047800925925924,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6508564814814815,
              totalWaitingTimeDays: 1.301712962962963,
              ceuCapacityInWaiting: 12180,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.0200405092592595,
              totalWaitingTimeDays: 6.040081018518519,
              ceuCapacityInWaiting: 11530,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5833449074074073,
              totalWaitingTimeDays: 1.5833449074074073,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.624988425925926,
              totalWaitingTimeDays: 0.624988425925926,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.3541724537037036,
              totalWaitingTimeDays: 2.7083449074074073,
              ceuCapacityInWaiting: 12830,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3333333333333333,
              totalWaitingTimeDays: 0.3333333333333333,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5416666666666667,
              totalWaitingTimeDays: 1.5416666666666667,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6673032407407409,
              totalWaitingTimeDays: 1.6673032407407409,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.40888888888888886,
              totalWaitingTimeDays: 0.40888888888888886,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.2536516203703703,
              totalWaitingTimeDays: 4.5073032407407405,
              ceuCapacityInWaiting: 12600,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2808333333333333,
              totalWaitingTimeDays: 0.2808333333333333,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16674768518518518,
              totalWaitingTimeDays: 0.16674768518518518,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7083333333333334,
              totalWaitingTimeDays: 0.7083333333333334,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2916550925925927,
              totalWaitingTimeDays: 1.2916550925925927,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.958287037037037,
              totalWaitingTimeDays: 2.958287037037037,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.375,
              totalWaitingTimeDays: 0.375,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6666898148148148,
              totalWaitingTimeDays: 0.6666898148148148,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.185335648148148,
              totalWaitingTimeDays: 2.185335648148148,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2194444444444446,
              totalWaitingTimeDays: 1.2194444444444446,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.360011574074074,
              totalWaitingTimeDays: 0.360011574074074,
              ceuCapacityInWaiting: 5980,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9417013888888889,
              totalWaitingTimeDays: 0.9417013888888889,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.6064525462962964,
              totalWaitingTimeDays: 3.212905092592593,
              ceuCapacityInWaiting: 11530,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.8271759259259257,
              totalWaitingTimeDays: 2.8271759259259257,
              ceuCapacityInWaiting: 5100,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 21,
          averageWaitingTimeDays: 1.5449658289241626,
          totalWaitingTimeDays: 32.444282407407414,
          ceuCapacityInWaiting: 132700,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.618981481481481,
              totalWaitingTimeDays: 2.618981481481481,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.2997569444444441,
              totalWaitingTimeDays: 3.8992708333333326,
              ceuCapacityInWaiting: 19030,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.26612268518518517,
              totalWaitingTimeDays: 0.26612268518518517,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7463773148148148,
              totalWaitingTimeDays: 0.7463773148148148,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2515277777777778,
              totalWaitingTimeDays: 0.5030555555555556,
              ceuCapacityInWaiting: 13500,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3333101851851852,
              totalWaitingTimeDays: 0.3333101851851852,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.03560185185185185,
              totalWaitingTimeDays: 0.03560185185185185,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12525462962962963,
              totalWaitingTimeDays: 0.12525462962962963,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.618981481481481,
              totalWaitingTimeDays: 2.618981481481481,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.282378472222222,
              totalWaitingTimeDays: 2.564756944444444,
              ceuCapacityInWaiting: 12630,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.334513888888889,
              totalWaitingTimeDays: 1.334513888888889,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.26612268518518517,
              totalWaitingTimeDays: 0.26612268518518517,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2133333333333332,
              totalWaitingTimeDays: 1.2133333333333332,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.03609953703703704,
              totalWaitingTimeDays: 0.03609953703703704,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3333101851851852,
              totalWaitingTimeDays: 0.3333101851851852,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.03560185185185185,
              totalWaitingTimeDays: 0.03560185185185185,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12525462962962963,
              totalWaitingTimeDays: 0.12525462962962963,
              ceuCapacityInWaiting: 6430,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.8527974537037035,
          totalWaitingTimeDays: 8.527974537037036,
          ceuCapacityInWaiting: 65290,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.022337962962962962,
              totalWaitingTimeDays: 0.022337962962962962,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04061342592592593,
              totalWaitingTimeDays: 0.04061342592592593,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.15435185185185185,
              totalWaitingTimeDays: 0.3087037037037037,
              ceuCapacityInWaiting: 13200,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.022337962962962962,
              totalWaitingTimeDays: 0.022337962962962962,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04061342592592593,
              totalWaitingTimeDays: 0.04061342592592593,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1082175925925926,
              totalWaitingTimeDays: 0.1082175925925926,
              ceuCapacityInWaiting: 6800,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20048611111111111,
              totalWaitingTimeDays: 0.20048611111111111,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.09291377314814817,
          totalWaitingTimeDays: 0.37165509259259266,
          ceuCapacityInWaiting: 26400,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2779,
      operator: 'Hyundai Glovis',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.08346064814814814,
              totalWaitingTimeDays: 0.25038194444444445,
              ceuCapacityInWaiting: 18700,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.24155092592592592,
              totalWaitingTimeDays: 0.24155092592592592,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.6041608796296296,
              totalWaitingTimeDays: 3.208321759259259,
              ceuCapacityInWaiting: 13000,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.729363425925926,
              totalWaitingTimeDays: 0.729363425925926,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.39167824074074076,
              totalWaitingTimeDays: 0.39167824074074076,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.4691724537037037,
              totalWaitingTimeDays: 0.9383449074074074,
              ceuCapacityInWaiting: 12900,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.125,
              totalWaitingTimeDays: 0.125,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04165509259259259,
              totalWaitingTimeDays: 0.04165509259259259,
              ceuCapacityInWaiting: 4800,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.04186342592592592,
              totalWaitingTimeDays: 0.08372685185185184,
              ceuCapacityInWaiting: 12200,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.24155092592592592,
              totalWaitingTimeDays: 0.24155092592592592,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.124988425925926,
              totalWaitingTimeDays: 2.124988425925926,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4166550925925926,
              totalWaitingTimeDays: 0.4166550925925926,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6666782407407408,
              totalWaitingTimeDays: 0.6666782407407408,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.729363425925926,
              totalWaitingTimeDays: 0.729363425925926,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.39167824074074076,
              totalWaitingTimeDays: 0.39167824074074076,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.625,
              totalWaitingTimeDays: 0.625,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3133449074074074,
              totalWaitingTimeDays: 0.3133449074074074,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.5273916245791246,
          totalWaitingTimeDays: 5.80130787037037,
          ceuCapacityInWaiting: 71500,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6458391203703704,
              totalWaitingTimeDays: 1.2916782407407408,
              ceuCapacityInWaiting: 14000,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7380092592592593,
              totalWaitingTimeDays: 0.7380092592592593,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 1.6301250000000003,
              totalWaitingTimeDays: 8.150625000000002,
              ceuCapacityInWaiting: 34500,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5834606481481481,
              totalWaitingTimeDays: 1.5834606481481481,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12502314814814813,
              totalWaitingTimeDays: 0.12502314814814813,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.917604166666667,
              totalWaitingTimeDays: 3.917604166666667,
              ceuCapacityInWaiting: 7400,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.9590162037037038,
              totalWaitingTimeDays: 2.9590162037037038,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6458391203703704,
              totalWaitingTimeDays: 1.2916782407407408,
              ceuCapacityInWaiting: 14000,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7380092592592593,
              totalWaitingTimeDays: 0.7380092592592593,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.375,
              totalWaitingTimeDays: 4.125,
              ceuCapacityInWaiting: 21500,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.70275462962963,
              totalWaitingTimeDays: 2.70275462962963,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.3228703703703704,
              totalWaitingTimeDays: 1.3228703703703704,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5834606481481481,
              totalWaitingTimeDays: 1.5834606481481481,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12502314814814813,
              totalWaitingTimeDays: 0.12502314814814813,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.917604166666667,
              totalWaitingTimeDays: 3.917604166666667,
              ceuCapacityInWaiting: 7400,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6577893518518518,
              totalWaitingTimeDays: 1.6577893518518518,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.301226851851852,
              totalWaitingTimeDays: 1.301226851851852,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 12,
          averageWaitingTimeDays: 1.5637847222222223,
          totalWaitingTimeDays: 18.765416666666667,
          ceuCapacityInWaiting: 84800,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.869988425925926,
              totalWaitingTimeDays: 0.869988425925926,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.1095659722222222,
              totalWaitingTimeDays: 2.2191319444444444,
              ceuCapacityInWaiting: 13700,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.9579745370370372,
              totalWaitingTimeDays: 1.9579745370370372,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.05462962962962963,
              totalWaitingTimeDays: 0.05462962962962963,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.25267361111111114,
              totalWaitingTimeDays: 0.25267361111111114,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.4301273148148148,
              totalWaitingTimeDays: 0.8602546296296296,
              ceuCapacityInWaiting: 13700,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.8828587962962963,
              totalWaitingTimeDays: 1.7657175925925925,
              ceuCapacityInWaiting: 14600,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.869988425925926,
              totalWaitingTimeDays: 0.869988425925926,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.294050925925926,
              totalWaitingTimeDays: 1.294050925925926,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9250810185185186,
              totalWaitingTimeDays: 0.9250810185185186,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.9579745370370372,
              totalWaitingTimeDays: 1.9579745370370372,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.05462962962962963,
              totalWaitingTimeDays: 0.05462962962962963,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.25267361111111114,
              totalWaitingTimeDays: 0.25267361111111114,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.027696759259259258,
              totalWaitingTimeDays: 0.027696759259259258,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8325578703703703,
              totalWaitingTimeDays: 0.8325578703703703,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0035532407407408,
              totalWaitingTimeDays: 1.0035532407407408,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7621643518518518,
              totalWaitingTimeDays: 0.7621643518518518,
              ceuCapacityInWaiting: 7300,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.798037037037037,
          totalWaitingTimeDays: 7.98037037037037,
          ceuCapacityInWaiting: 68800,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14994212962962963,
              totalWaitingTimeDays: 0.14994212962962963,
              ceuCapacityInWaiting: 7400,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5105439814814814,
              totalWaitingTimeDays: 0.5105439814814814,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.0852314814814816,
              totalWaitingTimeDays: 3.0852314814814816,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14994212962962963,
              totalWaitingTimeDays: 0.14994212962962963,
              ceuCapacityInWaiting: 7400,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5105439814814814,
              totalWaitingTimeDays: 0.5105439814814814,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.476840277777778,
              totalWaitingTimeDays: 2.476840277777778,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6083912037037037,
              totalWaitingTimeDays: 0.6083912037037037,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 3,
          averageWaitingTimeDays: 1.2485725308641977,
          totalWaitingTimeDays: 3.745717592592593,
          ceuCapacityInWaiting: 20300,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 3138,
      operator: 'K-line European Sea Highway Services GmbH',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.916990740740741,
              totalWaitingTimeDays: 7.833981481481482,
              ceuCapacityInWaiting: 3200,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 19.54171296296296,
              totalWaitingTimeDays: 19.54171296296296,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 2.0015364583333333,
              totalWaitingTimeDays: 8.006145833333333,
              ceuCapacityInWaiting: 5700,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04583333333333333,
              totalWaitingTimeDays: 0.04583333333333333,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04167824074074074,
              totalWaitingTimeDays: 0.04167824074074074,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.33748842592592593,
              totalWaitingTimeDays: 0.33748842592592593,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 4.496493055555556,
              totalWaitingTimeDays: 4.496493055555556,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7,
              totalWaitingTimeDays: 7,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7,
              totalWaitingTimeDays: 7,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7,
              totalWaitingTimeDays: 7,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.541712962962963,
              totalWaitingTimeDays: 1.541712962962963,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04820601851851852,
              totalWaitingTimeDays: 0.04820601851851852,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.686568287037037,
              totalWaitingTimeDays: 1.373136574074074,
              ceuCapacityInWaiting: 2850,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.1267766203703706,
              totalWaitingTimeDays: 6.253553240740741,
              ceuCapacityInWaiting: 2850,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.33125,
              totalWaitingTimeDays: 0.33125,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04583333333333333,
              totalWaitingTimeDays: 0.04583333333333333,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04167824074074074,
              totalWaitingTimeDays: 0.04167824074074074,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 8,
          averageWaitingTimeDays: 4.433668981481481,
          totalWaitingTimeDays: 35.46935185185185,
          ceuCapacityInWaiting: 11250,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.03125,
              totalWaitingTimeDays: 4.125,
              ceuCapacityInWaiting: 4450,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.9583275462962964,
              totalWaitingTimeDays: 1.9166550925925927,
              ceuCapacityInWaiting: 2100,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 13,
              averageWaitingTimeDays: 1.012485754985755,
              totalWaitingTimeDays: 13.162314814814815,
              ceuCapacityInWaiting: 14550,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 6,
              averageWaitingTimeDays: 0.6041493055555555,
              totalWaitingTimeDays: 3.624895833333333,
              ceuCapacityInWaiting: 6800,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16244212962962962,
              totalWaitingTimeDays: 0.16244212962962962,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.9999913194444447,
              totalWaitingTimeDays: 3.999965277777779,
              ceuCapacityInWaiting: 4150,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.907097800925926,
              totalWaitingTimeDays: 3.628391203703704,
              ceuCapacityInWaiting: 4450,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.7492997685185184,
              totalWaitingTimeDays: 2.9971990740740737,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.4382677469135802,
              totalWaitingTimeDays: 1.3148032407407406,
              ceuCapacityInWaiting: 3200,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 6,
              averageWaitingTimeDays: 0.7017380401234568,
              totalWaitingTimeDays: 4.2104282407407405,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.35612847222222216,
              totalWaitingTimeDays: 1.4245138888888886,
              ceuCapacityInWaiting: 3200,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.840443672839506,
              totalWaitingTimeDays: 2.5213310185185183,
              ceuCapacityInWaiting: 4050,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3333333333333333,
              totalWaitingTimeDays: 0.3333333333333333,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.37498842592592596,
              totalWaitingTimeDays: 0.7499768518518519,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.0138966049382716,
              totalWaitingTimeDays: 3.041689814814815,
              ceuCapacityInWaiting: 2850,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5833333333333333,
              totalWaitingTimeDays: 1.5833333333333333,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.8472183641975309,
              totalWaitingTimeDays: 2.5416550925925927,
              ceuCapacityInWaiting: 4050,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.9160378086419753,
              totalWaitingTimeDays: 2.7481134259259257,
              ceuCapacityInWaiting: 3700,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.5277777777777777,
              totalWaitingTimeDays: 4.583333333333333,
              ceuCapacityInWaiting: 3700,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.7213966049382715,
              totalWaitingTimeDays: 2.1641898148148146,
              ceuCapacityInWaiting: 3100,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.45821759259259254,
              totalWaitingTimeDays: 1.8328703703703701,
              ceuCapacityInWaiting: 4450,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.9590046296296296,
              totalWaitingTimeDays: 1.9180092592592592,
              ceuCapacityInWaiting: 2350,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6661805555555556,
              totalWaitingTimeDays: 1.332361111111111,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16244212962962962,
              totalWaitingTimeDays: 0.16244212962962962,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9582986111111111,
              totalWaitingTimeDays: 0.9582986111111111,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.5844521604938271,
              totalWaitingTimeDays: 1.7533564814814813,
              ceuCapacityInWaiting: 3300,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6441550925925926,
              totalWaitingTimeDays: 1.2883101851851853,
              ceuCapacityInWaiting: 2450,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08550925925925926,
              totalWaitingTimeDays: 0.08550925925925926,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2068171296296295,
              totalWaitingTimeDays: 1.2068171296296295,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.7786882716049383,
              totalWaitingTimeDays: 2.3360648148148146,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5194039351851851,
              totalWaitingTimeDays: 1.0388078703703703,
              ceuCapacityInWaiting: 2000,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2933333333333334,
              totalWaitingTimeDays: 1.2933333333333334,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.33252893518518517,
              totalWaitingTimeDays: 0.6650578703703703,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46685185185185185,
              totalWaitingTimeDays: 0.46685185185185185,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.24451388888888886,
              totalWaitingTimeDays: 0.48902777777777773,
              ceuCapacityInWaiting: 2450,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.35892361111111115,
              totalWaitingTimeDays: 0.35892361111111115,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6384837962962963,
              totalWaitingTimeDays: 1.2769675925925925,
              ceuCapacityInWaiting: 3200,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7500231481481481,
              totalWaitingTimeDays: 0.7500231481481481,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1363425925925927,
              totalWaitingTimeDays: 1.1363425925925927,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5235474537037037,
              totalWaitingTimeDays: 1.0470949074074074,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.28541666666666665,
              totalWaitingTimeDays: 0.28541666666666665,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.4424652777777778,
              totalWaitingTimeDays: 0.8849305555555556,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.25416666666666665,
              totalWaitingTimeDays: 0.25416666666666665,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.729525462962963,
              totalWaitingTimeDays: 0.729525462962963,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17969907407407407,
              totalWaitingTimeDays: 0.17969907407407407,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6121064814814816,
              totalWaitingTimeDays: 1.6121064814814816,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 52,
          averageWaitingTimeDays: 0.8286142272079771,
          totalWaitingTimeDays: 43.08793981481481,
          ceuCapacityInWaiting: 55700,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.38762731481481477,
              totalWaitingTimeDays: 0.38762731481481477,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.31444444444444447,
              totalWaitingTimeDays: 0.31444444444444447,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5467361111111111,
              totalWaitingTimeDays: 0.5467361111111111,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.42466435185185186,
              totalWaitingTimeDays: 0.8493287037037037,
              ceuCapacityInWaiting: 2350,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.3867708333333333,
              totalWaitingTimeDays: 1.1603124999999999,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.3507928240740741,
              totalWaitingTimeDays: 1.4031712962962963,
              ceuCapacityInWaiting: 4550,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 0.36033564814814806,
              totalWaitingTimeDays: 1.8016782407407403,
              ceuCapacityInWaiting: 5550,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.38762731481481477,
              totalWaitingTimeDays: 0.38762731481481477,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.31444444444444447,
              totalWaitingTimeDays: 0.31444444444444447,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5467361111111111,
              totalWaitingTimeDays: 0.5467361111111111,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14547453703703703,
              totalWaitingTimeDays: 0.14547453703703703,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7038541666666667,
              totalWaitingTimeDays: 0.7038541666666667,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.03434027777777778,
              totalWaitingTimeDays: 0.06868055555555556,
              ceuCapacityInWaiting: 2850,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0916319444444442,
              totalWaitingTimeDays: 1.0916319444444442,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9066550925925926,
              totalWaitingTimeDays: 0.9066550925925926,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3335763888888889,
              totalWaitingTimeDays: 0.3335763888888889,
              ceuCapacityInWaiting: 850,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14238425925925927,
              totalWaitingTimeDays: 0.14238425925925927,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.11861689814814814,
              totalWaitingTimeDays: 0.2372337962962963,
              ceuCapacityInWaiting: 2450,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5749247685185185,
              totalWaitingTimeDays: 1.149849537037037,
              ceuCapacityInWaiting: 2350,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.21757523148148147,
              totalWaitingTimeDays: 0.43515046296296295,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 17,
          averageWaitingTimeDays: 0.3801940359477124,
          totalWaitingTimeDays: 6.463298611111111,
          ceuCapacityInWaiting: 19650,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.378275462962963,
              totalWaitingTimeDays: 0.378275462962963,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.2930478395061728,
              totalWaitingTimeDays: 0.8791435185185184,
              ceuCapacityInWaiting: 3950,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2702893518518519,
              totalWaitingTimeDays: 1.2702893518518519,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.2350636574074074,
              totalWaitingTimeDays: 4.470127314814815,
              ceuCapacityInWaiting: 2350,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6118750000000001,
              totalWaitingTimeDays: 0.6118750000000001,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2334606481481483,
              totalWaitingTimeDays: 1.2334606481481483,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3722569444444444,
              totalWaitingTimeDays: 0.3722569444444444,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.378275462962963,
              totalWaitingTimeDays: 0.378275462962963,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.25306712962962963,
              totalWaitingTimeDays: 0.5061342592592593,
              ceuCapacityInWaiting: 2350,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.37300925925925926,
              totalWaitingTimeDays: 0.37300925925925926,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2702893518518519,
              totalWaitingTimeDays: 1.2702893518518519,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.4068055555555556,
              totalWaitingTimeDays: 3.4068055555555556,
              ceuCapacityInWaiting: 1600,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0633217592592592,
              totalWaitingTimeDays: 1.0633217592592592,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6118750000000001,
              totalWaitingTimeDays: 0.6118750000000001,
              ceuCapacityInWaiting: 750,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2334606481481483,
              totalWaitingTimeDays: 1.2334606481481483,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3722569444444444,
              totalWaitingTimeDays: 0.3722569444444444,
              ceuCapacityInWaiting: 1250,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 9,
          averageWaitingTimeDays: 1.0239364711934156,
          totalWaitingTimeDays: 9.21542824074074,
          ceuCapacityInWaiting: 10800,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2773,
      operator: 'MOL Short Sea Europe',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.041666666666666664,
          totalWaitingTimeDays: 0.041666666666666664,
          ceuCapacityInWaiting: 3600,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20833333333333334,
              totalWaitingTimeDays: 0.20833333333333334,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.624994212962963,
              totalWaitingTimeDays: 1.249988425925926,
              ceuCapacityInWaiting: 3000,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17112268518518517,
              totalWaitingTimeDays: 0.17112268518518517,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.41543981481481485,
              totalWaitingTimeDays: 0.41543981481481485,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8751041666666667,
              totalWaitingTimeDays: 0.8751041666666667,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.4303742283950616,
              totalWaitingTimeDays: 4.291122685185185,
              ceuCapacityInWaiting: 4500,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.18,
              totalWaitingTimeDays: 0.18,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20833333333333334,
              totalWaitingTimeDays: 0.20833333333333334,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0833217592592592,
              totalWaitingTimeDays: 1.0833217592592592,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16666666666666666,
              totalWaitingTimeDays: 0.16666666666666666,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17112268518518517,
              totalWaitingTimeDays: 0.17112268518518517,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.41543981481481485,
              totalWaitingTimeDays: 0.41543981481481485,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8751041666666667,
              totalWaitingTimeDays: 0.8751041666666667,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.668113425925926,
              totalWaitingTimeDays: 1.668113425925926,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.3195524691358025,
              totalWaitingTimeDays: 0.9586574074074075,
              ceuCapacityInWaiting: 4500,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6643518518518516,
              totalWaitingTimeDays: 1.6643518518518516,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.18,
              totalWaitingTimeDays: 0.18,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 10,
          averageWaitingTimeDays: 0.739111111111111,
          totalWaitingTimeDays: 7.39111111111111,
          ceuCapacityInWaiting: 19640,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.137962962962963,
              totalWaitingTimeDays: 1.137962962962963,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.137962962962963,
              totalWaitingTimeDays: 1.137962962962963,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.137962962962963,
          totalWaitingTimeDays: 1.137962962962963,
          ceuCapacityInWaiting: 1940,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2792,
      operator: 'Mediterranean Car Carrier Line',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7.077395833333332,
              totalWaitingTimeDays: 7.077395833333332,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3601041666666666,
              totalWaitingTimeDays: 0.3601041666666666,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 5.733587962962963,
              totalWaitingTimeDays: 5.733587962962963,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3601041666666666,
              totalWaitingTimeDays: 0.3601041666666666,
              ceuCapacityInWaiting: 1940,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 2,
          averageWaitingTimeDays: 3.71875,
          totalWaitingTimeDays: 7.4375,
          ceuCapacityInWaiting: 3880,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04653935185185185,
              totalWaitingTimeDays: 0.04653935185185185,
              ceuCapacityInWaiting: 3000,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04653935185185185,
              totalWaitingTimeDays: 0.04653935185185185,
              ceuCapacityInWaiting: 3000,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.04653935185185185,
          totalWaitingTimeDays: 0.04653935185185185,
          ceuCapacityInWaiting: 3000,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 438,
      operator: 'Mitsui OSK Lines',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 5.047297453703704,
              totalWaitingTimeDays: 10.094594907407409,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.375,
              totalWaitingTimeDays: 1.375,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.49998842592592596,
              totalWaitingTimeDays: 0.49998842592592596,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 6.958327546296297,
              totalWaitingTimeDays: 13.916655092592594,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.0945949074074074,
              totalWaitingTimeDays: 0.0945949074074074,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 5.666655092592593,
              totalWaitingTimeDays: 5.666655092592593,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 4.333344907407407,
              totalWaitingTimeDays: 4.333344907407407,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.375,
              totalWaitingTimeDays: 1.375,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.49998842592592596,
              totalWaitingTimeDays: 0.49998842592592596,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.9999768518518524,
              totalWaitingTimeDays: 2.9999768518518524,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.5208333333333335,
              totalWaitingTimeDays: 7.041666666666667,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2021-12-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.0739699074074074,
              totalWaitingTimeDays: 4.147939814814815,
              ceuCapacityInWaiting: 12740,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 6,
          averageWaitingTimeDays: 4.314373070987655,
          totalWaitingTimeDays: 25.886238425925928,
          ceuCapacityInWaiting: 38400,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.27292824074074074,
              totalWaitingTimeDays: 0.27292824074074074,
              ceuCapacityInWaiting: 6340,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.749988425925926,
              totalWaitingTimeDays: 2.749988425925926,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 4.076024305555555,
              totalWaitingTimeDays: 16.30409722222222,
              ceuCapacityInWaiting: 25700,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04146990740740741,
              totalWaitingTimeDays: 0.04146990740740741,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.41668402777777774,
              totalWaitingTimeDays: 0.8333680555555555,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5460648148148146,
              totalWaitingTimeDays: 1.5460648148148146,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6345833333333333,
              totalWaitingTimeDays: 0.6345833333333333,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.394764660493827,
              totalWaitingTimeDays: 4.184293981481481,
              ceuCapacityInWaiting: 19800,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5938888888888889,
              totalWaitingTimeDays: 0.5938888888888889,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 2.829299768518519,
              totalWaitingTimeDays: 11.317199074074075,
              ceuCapacityInWaiting: 25309,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.7499884259259262,
              totalWaitingTimeDays: 1.7499884259259262,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.2355555555555555,
              totalWaitingTimeDays: 4.471111111111111,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.9972762345679014,
              totalWaitingTimeDays: 5.991828703703704,
              ceuCapacityInWaiting: 19300,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 6.841157407407407,
              totalWaitingTimeDays: 6.841157407407407,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04146990740740741,
              totalWaitingTimeDays: 0.04146990740740741,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5416550925925926,
              totalWaitingTimeDays: 0.5416550925925926,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.29171296296296295,
              totalWaitingTimeDays: 0.29171296296296295,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5460648148148146,
              totalWaitingTimeDays: 1.5460648148148146,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6345833333333333,
              totalWaitingTimeDays: 0.6345833333333333,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4125347222222222,
              totalWaitingTimeDays: 0.4125347222222222,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.4644444444444438,
              totalWaitingTimeDays: 3.4644444444444438,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3073148148148148,
              totalWaitingTimeDays: 0.3073148148148148,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5938888888888889,
              totalWaitingTimeDays: 0.5938888888888889,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.792216435185185,
              totalWaitingTimeDays: 3.58443287037037,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2733622685185185,
              totalWaitingTimeDays: 0.546724537037037,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 5.759837962962964,
              totalWaitingTimeDays: 5.759837962962964,
              ceuCapacityInWaiting: 6109,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2022-12-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.4262037037037039,
              totalWaitingTimeDays: 1.4262037037037039,
              ceuCapacityInWaiting: 6109,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 18,
          averageWaitingTimeDays: 2.137660108024691,
          totalWaitingTimeDays: 38.477881944444434,
          ceuCapacityInWaiting: 115549,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.4331018518518515,
              totalWaitingTimeDays: 6.866203703703703,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.5569444444444445,
              totalWaitingTimeDays: 7.113888888888889,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.0787442129629627,
              totalWaitingTimeDays: 4.314976851851851,
              ceuCapacityInWaiting: 25200,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.40016203703703707,
              totalWaitingTimeDays: 0.40016203703703707,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14158564814814814,
              totalWaitingTimeDays: 0.14158564814814814,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.902170138888889,
              totalWaitingTimeDays: 3.804340277777778,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.066550925925926,
              totalWaitingTimeDays: 1.066550925925926,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.496064814814815,
              totalWaitingTimeDays: 1.496064814814815,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04362268518518519,
              totalWaitingTimeDays: 0.04362268518518519,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.4331018518518517,
              totalWaitingTimeDays: 2.8662037037037034,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 5.175104166666666,
              totalWaitingTimeDays: 10.350208333333333,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7636805555555556,
              totalWaitingTimeDays: 0.7636805555555556,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2843402777777777,
              totalWaitingTimeDays: 1.2843402777777777,
              ceuCapacityInWaiting: 6000,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7180208333333333,
              totalWaitingTimeDays: 0.7180208333333333,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.1563078703703704,
              totalWaitingTimeDays: 2.312615740740741,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.40016203703703707,
              totalWaitingTimeDays: 0.40016203703703707,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14158564814814814,
              totalWaitingTimeDays: 0.14158564814814814,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.9165509259259257,
              totalWaitingTimeDays: 2.9165509259259257,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8877893518518517,
              totalWaitingTimeDays: 0.8877893518518517,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.066550925925926,
              totalWaitingTimeDays: 1.066550925925926,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.496064814814815,
              totalWaitingTimeDays: 1.496064814814815,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04362268518518519,
              totalWaitingTimeDays: 0.04362268518518519,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 13,
          averageWaitingTimeDays: 1.9421073717948714,
          totalWaitingTimeDays: 25.24739583333333,
          ceuCapacityInWaiting: 82800,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2665046296296296,
              totalWaitingTimeDays: 0.2665046296296296,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 5,
              averageWaitingTimeDays: 1.6717037037037037,
              totalWaitingTimeDays: 8.358518518518519,
              ceuCapacityInWaiting: 32600,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6044560185185185,
              totalWaitingTimeDays: 0.6044560185185185,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8854166666666666,
              totalWaitingTimeDays: 0.8854166666666666,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2665046296296296,
              totalWaitingTimeDays: 0.2665046296296296,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.0031655092592593,
              totalWaitingTimeDays: 2.0063310185185186,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.1459336419753086,
              totalWaitingTimeDays: 3.4378009259259255,
              ceuCapacityInWaiting: 19800,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.9143865740740744,
              totalWaitingTimeDays: 2.9143865740740744,
              ceuCapacityInWaiting: 7000,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6044560185185185,
              totalWaitingTimeDays: 0.6044560185185185,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8854166666666666,
              totalWaitingTimeDays: 0.8854166666666666,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 8,
          averageWaitingTimeDays: 1.2643619791666667,
          totalWaitingTimeDays: 10.114895833333334,
          ceuCapacityInWaiting: 46900,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2781,
      operator: 'Grimaldi',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2916550925925926,
              totalWaitingTimeDays: 0.2916550925925926,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2916550925925926,
              totalWaitingTimeDays: 0.2916550925925926,
              ceuCapacityInWaiting: 6700,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.2916550925925926,
          totalWaitingTimeDays: 0.2916550925925926,
          ceuCapacityInWaiting: 6700,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.375,
              totalWaitingTimeDays: 0.375,
              ceuCapacityInWaiting: 6600,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.4583333333333335,
              totalWaitingTimeDays: 4.916666666666667,
              ceuCapacityInWaiting: 13200,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4581365740740741,
              totalWaitingTimeDays: 0.4581365740740741,
              ceuCapacityInWaiting: 6600,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.134195601851852,
              totalWaitingTimeDays: 4.268391203703704,
              ceuCapacityInWaiting: 7104,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.41625,
              totalWaitingTimeDays: 0.41625,
              ceuCapacityInWaiting: 3500,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.39369212962962963,
              totalWaitingTimeDays: 0.39369212962962963,
              ceuCapacityInWaiting: 7700,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.375,
              totalWaitingTimeDays: 0.375,
              ceuCapacityInWaiting: 6600,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7916608796296297,
              totalWaitingTimeDays: 1.5833217592592594,
              ceuCapacityInWaiting: 13200,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.3333449074074073,
              totalWaitingTimeDays: 3.3333449074074073,
              ceuCapacityInWaiting: 6600,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4581365740740741,
              totalWaitingTimeDays: 0.4581365740740741,
              ceuCapacityInWaiting: 6600,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.680752314814815,
              totalWaitingTimeDays: 3.680752314814815,
              ceuCapacityInWaiting: 3552,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5876388888888889,
              totalWaitingTimeDays: 0.5876388888888889,
              ceuCapacityInWaiting: 3552,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.41625,
              totalWaitingTimeDays: 0.41625,
              ceuCapacityInWaiting: 3500,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.39369212962962963,
              totalWaitingTimeDays: 0.39369212962962963,
              ceuCapacityInWaiting: 7700,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 8,
          averageWaitingTimeDays: 1.3535170717592593,
          totalWaitingTimeDays: 10.828136574074074,
          ceuCapacityInWaiting: 44704,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.31871527777777775,
              totalWaitingTimeDays: 0.31871527777777775,
              ceuCapacityInWaiting: 4093,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.4225231481481482,
              totalWaitingTimeDays: 1.4225231481481482,
              ceuCapacityInWaiting: 5254,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.24074074074074073,
              totalWaitingTimeDays: 0.48148148148148145,
              ceuCapacityInWaiting: 10454,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.27303819444444444,
              totalWaitingTimeDays: 0.5460763888888889,
              ceuCapacityInWaiting: 11400,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.31871527777777775,
              totalWaitingTimeDays: 0.31871527777777775,
              ceuCapacityInWaiting: 4093,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.022002314814814815,
              totalWaitingTimeDays: 0.022002314814814815,
              ceuCapacityInWaiting: 5254,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.4005208333333334,
              totalWaitingTimeDays: 1.4005208333333334,
              ceuCapacityInWaiting: 5254,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14024305555555555,
              totalWaitingTimeDays: 0.14024305555555555,
              ceuCapacityInWaiting: 5254,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.4263888888888889,
              totalWaitingTimeDays: 0.8527777777777777,
              ceuCapacityInWaiting: 8900,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.03453703703703704,
              totalWaitingTimeDays: 0.03453703703703704,
              ceuCapacityInWaiting: 7700,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.46146604938271607,
          totalWaitingTimeDays: 2.7687962962962964,
          ceuCapacityInWaiting: 31201,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.354386574074074,
              totalWaitingTimeDays: 4.708773148148148,
              ceuCapacityInWaiting: 8806,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.8147337962962964,
              totalWaitingTimeDays: 1.8147337962962964,
              ceuCapacityInWaiting: 3500,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.4047916666666667,
              totalWaitingTimeDays: 2.4047916666666667,
              ceuCapacityInWaiting: 3552,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6346064814814816,
              totalWaitingTimeDays: 1.6346064814814816,
              ceuCapacityInWaiting: 3552,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6693749999999999,
              totalWaitingTimeDays: 0.6693749999999999,
              ceuCapacityInWaiting: 5254,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.8147337962962964,
              totalWaitingTimeDays: 1.8147337962962964,
              ceuCapacityInWaiting: 3500,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 3,
          averageWaitingTimeDays: 2.1745023148148146,
          totalWaitingTimeDays: 6.523506944444444,
          ceuCapacityInWaiting: 12306,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2681,
      operator: 'Kawasaki Kisen Kaisha',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.25001157407407404,
              totalWaitingTimeDays: 0.25001157407407404,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7569097222222223,
              totalWaitingTimeDays: 0.7569097222222223,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.41213831018518515,
              totalWaitingTimeDays: 1.6485532407407406,
              ceuCapacityInWaiting: 26200,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.125,
              totalWaitingTimeDays: 0.125,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.16166087962962963,
              totalWaitingTimeDays: 0.32332175925925927,
              ceuCapacityInWaiting: 12400,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.1875,
              totalWaitingTimeDays: 0.375,
              ceuCapacityInWaiting: 13700,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.3541724537037039,
              totalWaitingTimeDays: 2.7083449074074077,
              ceuCapacityInWaiting: 13700,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7569097222222223,
              totalWaitingTimeDays: 0.7569097222222223,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1704513888888889,
              totalWaitingTimeDays: 0.1704513888888889,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20729166666666668,
              totalWaitingTimeDays: 0.20729166666666668,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6874768518518519,
              totalWaitingTimeDays: 0.6874768518518519,
              ceuCapacityInWaiting: 6100,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5833333333333334,
              totalWaitingTimeDays: 0.5833333333333334,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.125,
              totalWaitingTimeDays: 0.125,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16666666666666666,
              totalWaitingTimeDays: 0.16666666666666666,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1566550925925926,
              totalWaitingTimeDays: 0.1566550925925926,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2916666666666667,
              totalWaitingTimeDays: 0.2916666666666667,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08333333333333333,
              totalWaitingTimeDays: 0.08333333333333333,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.875011574074074,
              totalWaitingTimeDays: 0.875011574074074,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.8333333333333333,
              totalWaitingTimeDays: 1.8333333333333333,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 13,
          averageWaitingTimeDays: 0.4759339387464388,
          totalWaitingTimeDays: 6.187141203703704,
          ceuCapacityInWaiting: 84600,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.125,
              totalWaitingTimeDays: 0.125,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.501724537037037,
              totalWaitingTimeDays: 0.501724537037037,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 2.435523726851852,
              totalWaitingTimeDays: 9.742094907407408,
              ceuCapacityInWaiting: 26500,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.3310185185185184,
              totalWaitingTimeDays: 2.6620370370370368,
              ceuCapacityInWaiting: 12700,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.8136458333333333,
              totalWaitingTimeDays: 1.6272916666666666,
              ceuCapacityInWaiting: 12800,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7907233796296296,
              totalWaitingTimeDays: 1.5814467592592591,
              ceuCapacityInWaiting: 13700,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.2629012345679012,
              totalWaitingTimeDays: 3.7887037037037037,
              ceuCapacityInWaiting: 22500,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.7424131944444443,
              totalWaitingTimeDays: 3.4848263888888886,
              ceuCapacityInWaiting: 13900,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 1.6440943287037035,
              totalWaitingTimeDays: 6.576377314814814,
              ceuCapacityInWaiting: 28600,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 9.769305555555556,
              totalWaitingTimeDays: 19.538611111111113,
              ceuCapacityInWaiting: 12449,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.125,
              totalWaitingTimeDays: 0.125,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.2374652777777784,
              totalWaitingTimeDays: 3.2374652777777784,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1,
              totalWaitingTimeDays: 1,
              ceuCapacityInWaiting: 6100,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.003177083333333,
              totalWaitingTimeDays: 6.006354166666666,
              ceuCapacityInWaiting: 14200,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.3310185185185185,
              totalWaitingTimeDays: 0.662037037037037,
              ceuCapacityInWaiting: 12700,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.210462962962963,
              totalWaitingTimeDays: 2.210462962962963,
              ceuCapacityInWaiting: 6600,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.4168287037037035,
              totalWaitingTimeDays: 1.4168287037037035,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6251967592592592,
              totalWaitingTimeDays: 0.6251967592592592,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.95625,
              totalWaitingTimeDays: 0.95625,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04167824074074074,
              totalWaitingTimeDays: 0.04167824074074074,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.205300925925926,
              totalWaitingTimeDays: 1.205300925925926,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.541724537037037,
              totalWaitingTimeDays: 2.541724537037037,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.581435185185185,
              totalWaitingTimeDays: 1.581435185185185,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.9516956018518518,
              totalWaitingTimeDays: 1.9033912037037035,
              ceuCapacityInWaiting: 13900,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.0059953703703703,
              totalWaitingTimeDays: 2.0059953703703703,
              ceuCapacityInWaiting: 6100,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.096747685185185,
              totalWaitingTimeDays: 2.096747685185185,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.2159375000000001,
              totalWaitingTimeDays: 2.4318750000000002,
              ceuCapacityInWaiting: 15000,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04175925925925926,
              totalWaitingTimeDays: 0.04175925925925926,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.951087962962963,
              totalWaitingTimeDays: 3.951087962962963,
              ceuCapacityInWaiting: 6249,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 3.831736111111111,
              totalWaitingTimeDays: 7.663472222222222,
              ceuCapacityInWaiting: 12449,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7,
              totalWaitingTimeDays: 7,
              ceuCapacityInWaiting: 6249,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2022-12-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.924050925925926,
              totalWaitingTimeDays: 0.924050925925926,
              ceuCapacityInWaiting: 6249,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 21,
          averageWaitingTimeDays: 2.3632434964726636,
          totalWaitingTimeDays: 49.62811342592594,
          ceuCapacityInWaiting: 142749,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.970844907407407,
              totalWaitingTimeDays: 2.970844907407407,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17180555555555557,
              totalWaitingTimeDays: 0.17180555555555557,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.367013888888889,
              totalWaitingTimeDays: 1.367013888888889,
              ceuCapacityInWaiting: 6100,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.508287037037037,
              totalWaitingTimeDays: 1.016574074074074,
              ceuCapacityInWaiting: 12649,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.8272685185185185,
              totalWaitingTimeDays: 3.309074074074074,
              ceuCapacityInWaiting: 25000,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.054085648148148154,
              totalWaitingTimeDays: 0.054085648148148154,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1867939814814814,
              totalWaitingTimeDays: 1.1867939814814814,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.26167824074074075,
              totalWaitingTimeDays: 0.26167824074074075,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.4479282407407406,
              totalWaitingTimeDays: 1.4479282407407406,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1209027777777778,
              totalWaitingTimeDays: 1.1209027777777778,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.023067129629629632,
              totalWaitingTimeDays: 0.023067129629629632,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.970844907407407,
              totalWaitingTimeDays: 2.970844907407407,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17180555555555557,
              totalWaitingTimeDays: 0.17180555555555557,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0667708333333334,
              totalWaitingTimeDays: 1.0667708333333334,
              ceuCapacityInWaiting: 6100,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3002430555555556,
              totalWaitingTimeDays: 0.3002430555555556,
              ceuCapacityInWaiting: 6100,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.508287037037037,
              totalWaitingTimeDays: 1.016574074074074,
              ceuCapacityInWaiting: 12649,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.2190162037037036,
              totalWaitingTimeDays: 2.438032407407407,
              ceuCapacityInWaiting: 12400,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3670949074074074,
              totalWaitingTimeDays: 0.3670949074074074,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5039467592592592,
              totalWaitingTimeDays: 0.5039467592592592,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.054085648148148154,
              totalWaitingTimeDays: 0.054085648148148154,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1867939814814814,
              totalWaitingTimeDays: 1.1867939814814814,
              ceuCapacityInWaiting: 6200,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.26167824074074075,
              totalWaitingTimeDays: 0.26167824074074075,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.4479282407407406,
              totalWaitingTimeDays: 1.4479282407407406,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1209027777777778,
              totalWaitingTimeDays: 1.1209027777777778,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2023-12-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.023067129629629632,
              totalWaitingTimeDays: 0.023067129629629632,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 15,
          averageWaitingTimeDays: 0.8619845679012346,
          totalWaitingTimeDays: 12.929768518518518,
          ceuCapacityInWaiting: 101149,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.320324074074074,
              totalWaitingTimeDays: 2.640648148148148,
              ceuCapacityInWaiting: 15000,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.7057368827160493,
              totalWaitingTimeDays: 5.117210648148148,
              ceuCapacityInWaiting: 22500,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2813078703703704,
              totalWaitingTimeDays: 1.2813078703703704,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6145717592592592,
              totalWaitingTimeDays: 1.2291435185185184,
              ceuCapacityInWaiting: 13749,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5825231481481483,
              totalWaitingTimeDays: 1.5825231481481483,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.058125,
              totalWaitingTimeDays: 1.058125,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4706944444444444,
              totalWaitingTimeDays: 0.4706944444444444,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7711458333333334,
              totalWaitingTimeDays: 0.7711458333333334,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.38162037037037,
              totalWaitingTimeDays: 2.38162037037037,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.49375,
              totalWaitingTimeDays: 1.49375,
              ceuCapacityInWaiting: 7500,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2813078703703704,
              totalWaitingTimeDays: 1.2813078703703704,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6145717592592592,
              totalWaitingTimeDays: 1.2291435185185184,
              ceuCapacityInWaiting: 13749,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 8,
          averageWaitingTimeDays: 1.2835387731481482,
          totalWaitingTimeDays: 10.268310185185186,
          ceuCapacityInWaiting: 57649,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 3793,
      operator: 'CLdN Ro-Ro & COBELFRET Ferries',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5233912037037036,
              totalWaitingTimeDays: 0.5233912037037036,
              ceuCapacityInWaiting: 7194,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5233912037037036,
              totalWaitingTimeDays: 0.5233912037037036,
              ceuCapacityInWaiting: 7194,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.5233912037037036,
          totalWaitingTimeDays: 0.5233912037037036,
          ceuCapacityInWaiting: 7194,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.2909027777777777,
              totalWaitingTimeDays: 2.2909027777777777,
              ceuCapacityInWaiting: 446,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.09313657407407408,
              totalWaitingTimeDays: 0.09313657407407408,
              ceuCapacityInWaiting: 446,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.931747685185185,
              totalWaitingTimeDays: 0.931747685185185,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.7929591049382717,
              totalWaitingTimeDays: 2.3788773148148152,
              ceuCapacityInWaiting: 1410,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.3492708333333334,
              totalWaitingTimeDays: 1.3492708333333334,
              ceuCapacityInWaiting: 446,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9416319444444444,
              totalWaitingTimeDays: 0.9416319444444444,
              ceuCapacityInWaiting: 446,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.09313657407407408,
              totalWaitingTimeDays: 0.09313657407407408,
              ceuCapacityInWaiting: 446,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.931747685185185,
              totalWaitingTimeDays: 0.931747685185185,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7518981481481481,
              totalWaitingTimeDays: 1.5037962962962963,
              ceuCapacityInWaiting: 1155,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2023-12-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8750810185185186,
              totalWaitingTimeDays: 0.8750810185185186,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 6,
          averageWaitingTimeDays: 0.9491107253086418,
          totalWaitingTimeDays: 5.694664351851851,
          ceuCapacityInWaiting: 2557,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2936111111111111,
              totalWaitingTimeDays: 0.5872222222222222,
              ceuCapacityInWaiting: 510,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.1267708333333333,
              totalWaitingTimeDays: 2.2535416666666666,
              ceuCapacityInWaiting: 510,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12453703703703704,
              totalWaitingTimeDays: 0.12453703703703704,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4626851851851852,
              totalWaitingTimeDays: 0.4626851851851852,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.7897337962962963,
              totalWaitingTimeDays: 1.7897337962962963,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46380787037037036,
              totalWaitingTimeDays: 0.46380787037037036,
              ceuCapacityInWaiting: 255,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 4,
          averageWaitingTimeDays: 0.7101909722222222,
          totalWaitingTimeDays: 2.8407638888888886,
          ceuCapacityInWaiting: 1020,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2815,
      operator: 'Toyofuji',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1165625,
              totalWaitingTimeDays: 1.1165625,
              ceuCapacityInWaiting: 802,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.1165625,
              totalWaitingTimeDays: 1.1165625,
              ceuCapacityInWaiting: 802,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.1165625,
          totalWaitingTimeDays: 1.1165625,
          ceuCapacityInWaiting: 802,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9166666666666666,
              totalWaitingTimeDays: 0.9166666666666666,
              ceuCapacityInWaiting: 802,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9166666666666666,
              totalWaitingTimeDays: 0.9166666666666666,
              ceuCapacityInWaiting: 802,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.9166666666666666,
          totalWaitingTimeDays: 0.9166666666666666,
          ceuCapacityInWaiting: 802,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2816,
      operator: 'United European Car Carriers',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.6208449074074074,
              totalWaitingTimeDays: 1.2416898148148148,
              ceuCapacityInWaiting: 8750,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.11566550925925927,
              totalWaitingTimeDays: 0.23133101851851853,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.2916608796296296,
              totalWaitingTimeDays: 0.5833217592592592,
              ceuCapacityInWaiting: 7860,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5187384259259259,
              totalWaitingTimeDays: 0.5187384259259259,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14164351851851853,
              totalWaitingTimeDays: 0.14164351851851853,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 4750,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.200023148148148,
              totalWaitingTimeDays: 1.200023148148148,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.09376157407407408,
              totalWaitingTimeDays: 0.09376157407407408,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.13756944444444444,
              totalWaitingTimeDays: 0.13756944444444444,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3333333333333333,
              totalWaitingTimeDays: 0.3333333333333333,
              ceuCapacityInWaiting: 3930,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.24998842592592593,
              totalWaitingTimeDays: 0.24998842592592593,
              ceuCapacityInWaiting: 3930,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5187384259259259,
              totalWaitingTimeDays: 0.5187384259259259,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.14164351851851853,
              totalWaitingTimeDays: 0.14164351851851853,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 9,
          averageWaitingTimeDays: 0.30648791152263377,
          totalWaitingTimeDays: 2.758391203703704,
          ceuCapacityInWaiting: 30850,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.20720679012345677,
              totalWaitingTimeDays: 0.6216203703703703,
              ceuCapacityInWaiting: 8820,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5378530092592593,
              totalWaitingTimeDays: 1.0757060185185185,
              ceuCapacityInWaiting: 8000,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 8,
              averageWaitingTimeDays: 0.7656293402777778,
              totalWaitingTimeDays: 6.125034722222223,
              ceuCapacityInWaiting: 27960,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7063078703703703,
              totalWaitingTimeDays: 0.7063078703703703,
              ceuCapacityInWaiting: 3930,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7088078703703704,
              totalWaitingTimeDays: 1.4176157407407408,
              ceuCapacityInWaiting: 4820,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12508101851851852,
              totalWaitingTimeDays: 0.12508101851851852,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 2.8949479166666663,
              totalWaitingTimeDays: 5.789895833333333,
              ceuCapacityInWaiting: 5020,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.37503472222222217,
              totalWaitingTimeDays: 0.7500694444444443,
              ceuCapacityInWaiting: 8670,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7728530092592593,
              totalWaitingTimeDays: 1.5457060185185185,
              ceuCapacityInWaiting: 8750,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5418518518518518,
              totalWaitingTimeDays: 0.5418518518518518,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.5695871913580247,
              totalWaitingTimeDays: 1.7087615740740743,
              ceuCapacityInWaiting: 11330,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.44166666666666665,
              totalWaitingTimeDays: 0.44166666666666665,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1250115740740741,
              totalWaitingTimeDays: 0.1250115740740741,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.054942129629629625,
              totalWaitingTimeDays: 0.054942129629629625,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.20503472222222222,
              totalWaitingTimeDays: 0.20503472222222222,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8706712962962962,
              totalWaitingTimeDays: 0.8706712962962962,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.6770804398148148,
              totalWaitingTimeDays: 2.708321759259259,
              ceuCapacityInWaiting: 12750,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16723379629629628,
              totalWaitingTimeDays: 0.16723379629629628,
              ceuCapacityInWaiting: 4870,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5413136574074073,
              totalWaitingTimeDays: 1.0826273148148147,
              ceuCapacityInWaiting: 5150,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.1668518518518516,
              totalWaitingTimeDays: 2.1668518518518516,
              ceuCapacityInWaiting: 5190,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7063078703703703,
              totalWaitingTimeDays: 0.7063078703703703,
              ceuCapacityInWaiting: 3930,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7958564814814815,
              totalWaitingTimeDays: 0.7958564814814815,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6217592592592592,
              totalWaitingTimeDays: 0.6217592592592592,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12508101851851852,
              totalWaitingTimeDays: 0.12508101851851852,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.500833333333333,
              totalWaitingTimeDays: 3.500833333333333,
              ceuCapacityInWaiting: 1220,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.1445312499999998,
              totalWaitingTimeDays: 2.2890624999999996,
              ceuCapacityInWaiting: 5020,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7084027777777777,
              totalWaitingTimeDays: 0.7084027777777777,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041666666666666664,
              totalWaitingTimeDays: 0.041666666666666664,
              ceuCapacityInWaiting: 4870,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.9575,
              totalWaitingTimeDays: 0.9575,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5882060185185185,
              totalWaitingTimeDays: 0.5882060185185185,
              ceuCapacityInWaiting: 4750,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5418518518518518,
              totalWaitingTimeDays: 0.5418518518518518,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.577199074074074,
              totalWaitingTimeDays: 0.577199074074074,
              ceuCapacityInWaiting: 3930,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0483217592592593,
              totalWaitingTimeDays: 1.0483217592592593,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08324074074074074,
              totalWaitingTimeDays: 0.08324074074074074,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2022-12-26T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.05655092592592593,
              totalWaitingTimeDays: 0.05655092592592593,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 27,
          averageWaitingTimeDays: 0.7558389060356652,
          totalWaitingTimeDays: 20.40765046296296,
          ceuCapacityInWaiting: 92320,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.05655092592592593,
              totalWaitingTimeDays: 0.05655092592592593,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.8790432098765432,
              totalWaitingTimeDays: 2.6371296296296296,
              ceuCapacityInWaiting: 12670,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.42125289351851847,
              totalWaitingTimeDays: 1.6850115740740739,
              ceuCapacityInWaiting: 17460,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5458449074074074,
              totalWaitingTimeDays: 0.5458449074074074,
              ceuCapacityInWaiting: 5190,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.18731481481481482,
              totalWaitingTimeDays: 0.37462962962962965,
              ceuCapacityInWaiting: 7270,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.29970679012345675,
              totalWaitingTimeDays: 0.8991203703703702,
              ceuCapacityInWaiting: 13620,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.4526504629629629,
              totalWaitingTimeDays: 0.9053009259259258,
              ceuCapacityInWaiting: 7800,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.26380787037037035,
              totalWaitingTimeDays: 0.26380787037037035,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.5150810185185186,
              totalWaitingTimeDays: 1.5150810185185186,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.42912037037037043,
              totalWaitingTimeDays: 0.8582407407407409,
              ceuCapacityInWaiting: 8670,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.585625,
              totalWaitingTimeDays: 0.585625,
              ceuCapacityInWaiting: 5190,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.45248842592592586,
              totalWaitingTimeDays: 0.45248842592592586,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5919907407407408,
              totalWaitingTimeDays: 0.5919907407407408,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.054907407407407405,
              totalWaitingTimeDays: 0.054907407407407405,
              ceuCapacityInWaiting: 4870,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5458449074074074,
              totalWaitingTimeDays: 0.5458449074074074,
              ceuCapacityInWaiting: 5190,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.18731481481481482,
              totalWaitingTimeDays: 0.37462962962962965,
              ceuCapacityInWaiting: 7270,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.09939814814814814,
              totalWaitingTimeDays: 0.09939814814814814,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3005902777777778,
              totalWaitingTimeDays: 0.3005902777777778,
              ceuCapacityInWaiting: 4750,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4991319444444444,
              totalWaitingTimeDays: 0.4991319444444444,
              ceuCapacityInWaiting: 4870,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.34577546296296297,
              totalWaitingTimeDays: 0.34577546296296297,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.559525462962963,
              totalWaitingTimeDays: 0.559525462962963,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 16,
          averageWaitingTimeDays: 0.4439742476851852,
          totalWaitingTimeDays: 7.103587962962963,
          ceuCapacityInWaiting: 68010,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5586921296296297,
              totalWaitingTimeDays: 1.1173842592592593,
              ceuCapacityInWaiting: 9620,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.42902777777777773,
              totalWaitingTimeDays: 1.2870833333333331,
              ceuCapacityInWaiting: 8190,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.43542534722222226,
              totalWaitingTimeDays: 1.741701388888889,
              ceuCapacityInWaiting: 10410,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.33121527777777776,
              totalWaitingTimeDays: 0.33121527777777776,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.22921296296296295,
              totalWaitingTimeDays: 0.22921296296296295,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.496400462962963,
              totalWaitingTimeDays: 0.496400462962963,
              ceuCapacityInWaiting: 4750,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.43660879629629634,
              totalWaitingTimeDays: 0.8732175925925927,
              ceuCapacityInWaiting: 6370,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6807986111111111,
              totalWaitingTimeDays: 0.6807986111111111,
              ceuCapacityInWaiting: 5190,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3540509259259259,
              totalWaitingTimeDays: 0.3540509259259259,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.49167824074074074,
              totalWaitingTimeDays: 0.49167824074074074,
              ceuCapacityInWaiting: 2080,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4875,
              totalWaitingTimeDays: 0.4875,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.38126157407407407,
              totalWaitingTimeDays: 0.7625231481481481,
              ceuCapacityInWaiting: 6830,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.33121527777777776,
              totalWaitingTimeDays: 0.33121527777777776,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.22921296296296295,
              totalWaitingTimeDays: 0.22921296296296295,
              ceuCapacityInWaiting: 1500,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 11,
          averageWaitingTimeDays: 0.4278724747474747,
          totalWaitingTimeDays: 4.706597222222221,
          ceuCapacityInWaiting: 31220,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2762,
      operator: 'SAIC Anji Logistics Co., Ltd',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.2264814814814815,
              totalWaitingTimeDays: 2.2264814814814815,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.220625,
              totalWaitingTimeDays: 0.220625,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.2264814814814815,
              totalWaitingTimeDays: 2.2264814814814815,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.220625,
              totalWaitingTimeDays: 0.220625,
              ceuCapacityInWaiting: 6400,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.223553240740741,
          totalWaitingTimeDays: 2.447106481481482,
          ceuCapacityInWaiting: 12800,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3166203703703704,
              totalWaitingTimeDays: 0.3166203703703704,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3166203703703704,
              totalWaitingTimeDays: 0.3166203703703704,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.3166203703703704,
          totalWaitingTimeDays: 0.3166203703703704,
          ceuCapacityInWaiting: 4000,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2812,
      operator: 'Suardiaz',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.014791666666666667,
              totalWaitingTimeDays: 0.014791666666666667,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7831481481481481,
              totalWaitingTimeDays: 0.7831481481481481,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1822800925925926,
              totalWaitingTimeDays: 0.1822800925925926,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7979398148148148,
              totalWaitingTimeDays: 0.7979398148148148,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1822800925925926,
              totalWaitingTimeDays: 0.1822800925925926,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.4901099537037037,
          totalWaitingTimeDays: 0.9802199074074074,
          ceuCapacityInWaiting: 8000,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6300578703703703,
              totalWaitingTimeDays: 1.6300578703703703,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.7496585648148147,
              totalWaitingTimeDays: 1.4993171296296295,
              ceuCapacityInWaiting: 8300,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1670486111111111,
              totalWaitingTimeDays: 0.1670486111111111,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.29170138888888886,
              totalWaitingTimeDays: 0.29170138888888886,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4187962962962963,
              totalWaitingTimeDays: 0.4187962962962963,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16670138888888889,
              totalWaitingTimeDays: 0.16670138888888889,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6300578703703703,
              totalWaitingTimeDays: 1.6300578703703703,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12487268518518518,
              totalWaitingTimeDays: 0.12487268518518518,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.3744444444444444,
              totalWaitingTimeDays: 1.3744444444444444,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1670486111111111,
              totalWaitingTimeDays: 0.1670486111111111,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2880787037037037,
              totalWaitingTimeDays: 0.2880787037037037,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.0036226851851851854,
              totalWaitingTimeDays: 0.0036226851851851854,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.4187962962962963,
              totalWaitingTimeDays: 0.4187962962962963,
              ceuCapacityInWaiting: 4000,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.16670138888888889,
              totalWaitingTimeDays: 0.16670138888888889,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 7,
          averageWaitingTimeDays: 0.5962318121693121,
          totalWaitingTimeDays: 4.173622685185185,
          ceuCapacityInWaiting: 29200,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.36944444444444446,
              totalWaitingTimeDays: 0.36944444444444446,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.36944444444444446,
              totalWaitingTimeDays: 0.36944444444444446,
              ceuCapacityInWaiting: 4300,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.36944444444444446,
          totalWaitingTimeDays: 0.36944444444444446,
          ceuCapacityInWaiting: 4300,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1417013888888889,
              totalWaitingTimeDays: 0.1417013888888889,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04159722222222222,
              totalWaitingTimeDays: 0.04159722222222222,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.053275462962963,
              totalWaitingTimeDays: 1.053275462962963,
              ceuCapacityInWaiting: 1079,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1417013888888889,
              totalWaitingTimeDays: 0.1417013888888889,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.04159722222222222,
              totalWaitingTimeDays: 0.04159722222222222,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.053275462962963,
              totalWaitingTimeDays: 1.053275462962963,
              ceuCapacityInWaiting: 1079,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 3,
          averageWaitingTimeDays: 0.41219135802469137,
          totalWaitingTimeDays: 1.236574074074074,
          ceuCapacityInWaiting: 10879,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2804,
      operator: 'Sallaum Lines',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0895601851851853,
              totalWaitingTimeDays: 1.0895601851851853,
              ceuCapacityInWaiting: 4310,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5168634259259259,
              totalWaitingTimeDays: 0.5168634259259259,
              ceuCapacityInWaiting: 4310,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5726967592592592,
              totalWaitingTimeDays: 0.5726967592592592,
              ceuCapacityInWaiting: 4310,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 1.0895601851851853,
          totalWaitingTimeDays: 1.0895601851851853,
          ceuCapacityInWaiting: 4310,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.9185474537037038,
              totalWaitingTimeDays: 1.8370949074074077,
              ceuCapacityInWaiting: 9100,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17068287037037036,
              totalWaitingTimeDays: 0.17068287037037036,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08332175925925926,
              totalWaitingTimeDays: 0.08332175925925926,
              ceuCapacityInWaiting: 4049,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.271875,
              totalWaitingTimeDays: 2.54375,
              ceuCapacityInWaiting: 9800,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7918055555555555,
              totalWaitingTimeDays: 0.7918055555555555,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.777465277777778,
              totalWaitingTimeDays: 1.777465277777778,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.5208275462962964,
              totalWaitingTimeDays: 1.0416550925925927,
              ceuCapacityInWaiting: 9100,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7954398148148148,
              totalWaitingTimeDays: 0.7954398148148148,
              ceuCapacityInWaiting: 4200,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.17068287037037036,
              totalWaitingTimeDays: 0.17068287037037036,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08332175925925926,
              totalWaitingTimeDays: 0.08332175925925926,
              ceuCapacityInWaiting: 4049,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.2097800925925928,
              totalWaitingTimeDays: 1.2097800925925928,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.3339699074074074,
              totalWaitingTimeDays: 1.3339699074074074,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7918055555555555,
              totalWaitingTimeDays: 0.7918055555555555,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.777465277777778,
              totalWaitingTimeDays: 1.777465277777778,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 8,
          averageWaitingTimeDays: 0.9005150462962964,
          totalWaitingTimeDays: 7.204120370370371,
          ceuCapacityInWaiting: 37649,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2782,
      operator: 'Gold Star Line',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.09960648148148149,
              totalWaitingTimeDays: 0.09960648148148149,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.09960648148148149,
              totalWaitingTimeDays: 0.09960648148148149,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.09960648148148149,
          totalWaitingTimeDays: 0.09960648148148149,
          ceuCapacityInWaiting: 4900,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6826273148148149,
              totalWaitingTimeDays: 0.6826273148148149,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6826273148148149,
              totalWaitingTimeDays: 0.6826273148148149,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.6826273148148149,
          totalWaitingTimeDays: 0.6826273148148149,
          ceuCapacityInWaiting: 4900,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2389,
      operator: 'Neptune Lines',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7.916666666666667,
              totalWaitingTimeDays: 7.916666666666667,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12497685185185185,
              totalWaitingTimeDays: 0.12497685185185185,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              start: '2021-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46312500000000006,
              totalWaitingTimeDays: 0.46312500000000006,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 4.166655092592593,
              totalWaitingTimeDays: 4.166655092592593,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.750011574074074,
              totalWaitingTimeDays: 3.750011574074074,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12497685185185185,
              totalWaitingTimeDays: 0.12497685185185185,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46312500000000006,
              totalWaitingTimeDays: 0.46312500000000006,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 3,
          averageWaitingTimeDays: 2.834922839506173,
          totalWaitingTimeDays: 8.50476851851852,
          ceuCapacityInWaiting: 9400,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.37642361111111106,
              totalWaitingTimeDays: 0.37642361111111106,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.4375405092592593,
              totalWaitingTimeDays: 0.8750810185185186,
              ceuCapacityInWaiting: 5600,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.5144097222222223,
              totalWaitingTimeDays: 1.5432291666666667,
              ceuCapacityInWaiting: 7400,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1253240740740741,
              totalWaitingTimeDays: 0.1253240740740741,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12429398148148148,
              totalWaitingTimeDays: 0.12429398148148148,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2915509259259259,
              totalWaitingTimeDays: 0.2915509259259259,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8756365740740739,
              totalWaitingTimeDays: 0.8756365740740739,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041574074074074076,
              totalWaitingTimeDays: 0.041574074074074076,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08251157407407407,
              totalWaitingTimeDays: 0.08251157407407407,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7581018518518517,
              totalWaitingTimeDays: 0.7581018518518517,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.37642361111111106,
              totalWaitingTimeDays: 0.37642361111111106,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7500925925925926,
              totalWaitingTimeDays: 0.7500925925925926,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12498842592592592,
              totalWaitingTimeDays: 0.12498842592592592,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.166111111111111,
              totalWaitingTimeDays: 1.166111111111111,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08334490740740741,
              totalWaitingTimeDays: 0.08334490740740741,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.29377314814814814,
              totalWaitingTimeDays: 0.29377314814814814,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.1253240740740741,
              totalWaitingTimeDays: 0.1253240740740741,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12429398148148148,
              totalWaitingTimeDays: 0.12429398148148148,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.2915509259259259,
              totalWaitingTimeDays: 0.2915509259259259,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.8756365740740739,
              totalWaitingTimeDays: 0.8756365740740739,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.041574074074074076,
              totalWaitingTimeDays: 0.041574074074074076,
              ceuCapacityInWaiting: 4900,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.08251157407407407,
              totalWaitingTimeDays: 0.08251157407407407,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.7581018518518517,
              totalWaitingTimeDays: 0.7581018518518517,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 13,
          averageWaitingTimeDays: 0.39182514245014244,
          totalWaitingTimeDays: 5.0937268518518515,
          ceuCapacityInWaiting: 34500,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.33194444444444443,
              totalWaitingTimeDays: 0.33194444444444443,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 1.7317746913580248,
              totalWaitingTimeDays: 5.195324074074074,
              ceuCapacityInWaiting: 9400,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46193287037037034,
              totalWaitingTimeDays: 0.46193287037037034,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.061365740740740735,
              totalWaitingTimeDays: 0.061365740740740735,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 1.779965277777778,
              totalWaitingTimeDays: 3.559930555555556,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6647685185185185,
              totalWaitingTimeDays: 0.6647685185185185,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.33194444444444443,
              totalWaitingTimeDays: 0.33194444444444443,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.6615046296296296,
              totalWaitingTimeDays: 1.6615046296296296,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 2,
              averageWaitingTimeDays: 0.3406597222222222,
              totalWaitingTimeDays: 0.6813194444444444,
              ceuCapacityInWaiting: 5600,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.21123842592592593,
              totalWaitingTimeDays: 0.21123842592592593,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 2.641261574074074,
              totalWaitingTimeDays: 2.641261574074074,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.46193287037037034,
              totalWaitingTimeDays: 0.46193287037037034,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.061365740740740735,
              totalWaitingTimeDays: 0.061365740740740735,
              ceuCapacityInWaiting: 3800,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.2259143518518516,
              totalWaitingTimeDays: 3.2259143518518516,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.3340162037037037,
              totalWaitingTimeDays: 0.3340162037037037,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.6647685185185185,
              totalWaitingTimeDays: 0.6647685185185185,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 9,
          averageWaitingTimeDays: 1.141696244855967,
          totalWaitingTimeDays: 10.275266203703703,
          ceuCapacityInWaiting: 22200,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.24866898148148148,
              totalWaitingTimeDays: 0.24866898148148148,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.9392592592592592,
              totalWaitingTimeDays: 1.9392592592592592,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.24866898148148148,
              totalWaitingTimeDays: 0.24866898148148148,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.9392592592592592,
              totalWaitingTimeDays: 1.9392592592592592,
              ceuCapacityInWaiting: 1800,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.0939641203703705,
          totalWaitingTimeDays: 2.187928240740741,
          ceuCapacityInWaiting: 3600,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 213,
      operator: 'COSCO',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 14.524074074074075,
              totalWaitingTimeDays: 14.524074074074075,
              ceuCapacityInWaiting: 5000,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 6.508391203703704,
              totalWaitingTimeDays: 6.508391203703704,
              ceuCapacityInWaiting: 5000,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 7,
              totalWaitingTimeDays: 7,
              ceuCapacityInWaiting: 5000,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 1.0156828703703704,
              totalWaitingTimeDays: 1.0156828703703704,
              ceuCapacityInWaiting: 5000,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 14.524074074074075,
          totalWaitingTimeDays: 14.524074074074075,
          ceuCapacityInWaiting: 5000,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.18208333333333332,
              totalWaitingTimeDays: 0.18208333333333332,
              ceuCapacityInWaiting: 5000,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2023-12-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.18208333333333332,
              totalWaitingTimeDays: 0.18208333333333332,
              ceuCapacityInWaiting: 5000,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.18208333333333332,
          totalWaitingTimeDays: 0.18208333333333332,
          ceuCapacityInWaiting: 5000,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 4131,
      operator: 'Finnlines',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.1666435185185184,
              totalWaitingTimeDays: 3.1666435185185184,
              ceuCapacityInWaiting: 967,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.049247685185185186,
              totalWaitingTimeDays: 0.049247685185185186,
              ceuCapacityInWaiting: 967,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 3.1666435185185184,
              totalWaitingTimeDays: 3.1666435185185184,
              ceuCapacityInWaiting: 967,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.049247685185185186,
              totalWaitingTimeDays: 0.049247685185185186,
              ceuCapacityInWaiting: 967,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 2,
          averageWaitingTimeDays: 1.6079456018518519,
          totalWaitingTimeDays: 3.2158912037037037,
          ceuCapacityInWaiting: 1934,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.11679398148148148,
              totalWaitingTimeDays: 0.11679398148148148,
              ceuCapacityInWaiting: 699,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-12-01T00:00:00',
              numWaitingEvents: 4,
              averageWaitingTimeDays: 0.4012991898148149,
              totalWaitingTimeDays: 1.6051967592592595,
              ceuCapacityInWaiting: 3600,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.11679398148148148,
              totalWaitingTimeDays: 0.11679398148148148,
              ceuCapacityInWaiting: 699,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 3,
              averageWaitingTimeDays: 0.5116975308641977,
              totalWaitingTimeDays: 1.535092592592593,
              ceuCapacityInWaiting: 2633,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 52,
              weekStart: '2023-12-25T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.07010416666666666,
              totalWaitingTimeDays: 0.07010416666666666,
              ceuCapacityInWaiting: 967,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 5,
          averageWaitingTimeDays: 0.3443981481481482,
          totalWaitingTimeDays: 1.7219907407407409,
          ceuCapacityInWaiting: 4299,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.22533564814814816,
              totalWaitingTimeDays: 0.22533564814814816,
              ceuCapacityInWaiting: 699,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.22533564814814816,
              totalWaitingTimeDays: 0.22533564814814816,
              ceuCapacityInWaiting: 699,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.22533564814814816,
          totalWaitingTimeDays: 0.22533564814814816,
          ceuCapacityInWaiting: 699,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 2818,
      operator: 'Volkswagen Logistics',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12916666666666668,
              totalWaitingTimeDays: 0.12916666666666668,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.12916666666666668,
              totalWaitingTimeDays: 0.12916666666666668,
              ceuCapacityInWaiting: 6500,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 1,
          averageWaitingTimeDays: 0.12916666666666668,
          totalWaitingTimeDays: 0.12916666666666668,
          ceuCapacityInWaiting: 6500,
          dwtInWaiting: 0
        }
      ]
    },
    {
      operatorId: 5157,
      operator: 'Polaris Autoliners Limited',
      years: [
        {
          year: 2021,
          months: [
            {
              start: '2021-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2021-01-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2021-01-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2021-01-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2021-01-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2021-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2021-02-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2021-02-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2021-02-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2021-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2021-03-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2021-03-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2021-03-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2021-03-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2021-04-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2021-04-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2021-04-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2021-04-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2021-05-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2021-05-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2021-05-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2021-05-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2021-05-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2021-06-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2021-06-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2021-06-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2021-06-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2021-07-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2021-07-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2021-07-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2021-07-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2021-08-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2021-08-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2021-08-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2021-08-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2021-08-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2021-09-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2021-09-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2021-09-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2021-09-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2021-10-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2021-10-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2021-10-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2021-10-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2021-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2021-11-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2021-11-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2021-11-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2021-11-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2021-12-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2021-12-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2021-12-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2022,
          months: [
            {
              start: '2022-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2022-11-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2022-01-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2022-01-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2022-01-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2022-01-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2022-01-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2022-02-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2022-02-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2022-02-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2022-02-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2022-03-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2022-03-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2022-03-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2022-03-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2022-04-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2022-04-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2022-04-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2022-04-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2022-05-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2022-05-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2022-05-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2022-05-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2022-05-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2022-06-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2022-06-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2022-06-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2022-06-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2022-07-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2022-07-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2022-07-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2022-07-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2022-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2022-08-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2022-08-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2022-08-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2022-08-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2022-09-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2022-09-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2022-09-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2022-09-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2022-10-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2022-10-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2022-10-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2022-10-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2022-10-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2022-11-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2022-11-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2022-11-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2022-11-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2022-12-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2022-12-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2022-12-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        },
        {
          year: 2023,
          months: [
            {
              start: '2023-01-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.06731481481481481,
              totalWaitingTimeDays: 0.06731481481481481,
              ceuCapacityInWaiting: 1000,
              dwtInWaiting: 0
            },
            {
              start: '2023-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-08-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-09-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-10-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2023-11-01T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5836689814814815,
              totalWaitingTimeDays: 0.5836689814814815,
              ceuCapacityInWaiting: 1000,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2023-01-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2023-01-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2023-01-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2023-01-23T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.06731481481481481,
              totalWaitingTimeDays: 0.06731481481481481,
              ceuCapacityInWaiting: 1000,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2023-01-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2023-02-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2023-02-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2023-02-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2023-02-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2023-03-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2023-03-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2023-03-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2023-03-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2023-04-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2023-04-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2023-04-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2023-04-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2023-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2023-05-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2023-05-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2023-05-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2023-05-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2023-06-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2023-06-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2023-06-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2023-06-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2023-07-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2023-07-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2023-07-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2023-07-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2023-07-31T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2023-08-07T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 33,
              weekStart: '2023-08-14T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 34,
              weekStart: '2023-08-21T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 35,
              weekStart: '2023-08-28T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 36,
              weekStart: '2023-09-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 37,
              weekStart: '2023-09-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 38,
              weekStart: '2023-09-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 39,
              weekStart: '2023-09-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 40,
              weekStart: '2023-10-02T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 41,
              weekStart: '2023-10-09T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 42,
              weekStart: '2023-10-16T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 43,
              weekStart: '2023-10-23T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 44,
              weekStart: '2023-10-30T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 45,
              weekStart: '2023-11-06T00:00:00',
              numWaitingEvents: 1,
              averageWaitingTimeDays: 0.5836689814814815,
              totalWaitingTimeDays: 0.5836689814814815,
              ceuCapacityInWaiting: 1000,
              dwtInWaiting: 0
            },
            {
              week: 46,
              weekStart: '2023-11-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 47,
              weekStart: '2023-11-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 48,
              weekStart: '2023-11-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 49,
              weekStart: '2023-12-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 50,
              weekStart: '2023-12-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 51,
              weekStart: '2023-12-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 2,
          averageWaitingTimeDays: 0.32549189814814816,
          totalWaitingTimeDays: 0.6509837962962963,
          ceuCapacityInWaiting: 2000,
          dwtInWaiting: 0
        },
        {
          year: 2024,
          months: [
            {
              start: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-02-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-03-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-05-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-06-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              start: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          weeks: [
            {
              week: 1,
              weekStart: '2024-01-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 2,
              weekStart: '2024-01-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 3,
              weekStart: '2024-01-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 4,
              weekStart: '2024-01-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 5,
              weekStart: '2024-01-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 6,
              weekStart: '2024-02-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 7,
              weekStart: '2024-02-12T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 8,
              weekStart: '2024-02-19T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 9,
              weekStart: '2024-02-26T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 10,
              weekStart: '2024-03-04T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 11,
              weekStart: '2024-03-11T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 12,
              weekStart: '2024-03-18T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 13,
              weekStart: '2024-03-25T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 14,
              weekStart: '2024-04-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 15,
              weekStart: '2024-04-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 16,
              weekStart: '2024-04-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 17,
              weekStart: '2024-04-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 18,
              weekStart: '2024-04-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 19,
              weekStart: '2024-05-06T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 20,
              weekStart: '2024-05-13T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 21,
              weekStart: '2024-05-20T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 22,
              weekStart: '2024-05-27T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 23,
              weekStart: '2024-06-03T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 24,
              weekStart: '2024-06-10T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 25,
              weekStart: '2024-06-17T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 26,
              weekStart: '2024-06-24T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 27,
              weekStart: '2024-07-01T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 28,
              weekStart: '2024-07-08T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 29,
              weekStart: '2024-07-15T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 30,
              weekStart: '2024-07-22T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 31,
              weekStart: '2024-07-29T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            },
            {
              week: 32,
              weekStart: '2024-08-05T00:00:00',
              numWaitingEvents: 0,
              averageWaitingTimeDays: 0,
              totalWaitingTimeDays: 0,
              ceuCapacityInWaiting: 0,
              dwtInWaiting: 0
            }
          ],
          numWaitingEvents: 0,
          averageWaitingTimeDays: 0,
          totalWaitingTimeDays: 0,
          ceuCapacityInWaiting: 0,
          dwtInWaiting: 0
        }
      ]
    }
  ],
  averageMonthlyPWTDaysPolyfit: [
    1.2442638882670007, 1.3063663901042488, 1.3600542496195733, 1.4056783800559285,
    1.4435896946562687, 1.474139106663549, 1.4976775293207232, 1.5145558758707462,
    1.5251250595565726, 1.5297359936211568, 1.528739591307453, 1.5224867658584162,
    1.5113284305170007, 1.495615498526161, 1.4756988831288513, 1.4519294975680266,
    1.4246582550866411, 1.3942360689276494, 1.3610138523340058, 1.3253425185486651,
    1.2875729808145817, 1.2480561523747102, 1.207142946472005, 1.1651842763494202,
    1.122531055249911, 1.079534196416432, 1.0365446130919367, 0.9939132185193804,
    0.9519909259417177, 0.9111286486019021, 0.8716772997428893, 0.8339877926076334,
    0.798411040439089, 0.7652979564802099, 0.7349994539739514, 0.7078664461632678,
    0.6842498462911135, 0.6645005676004428, 0.6489695233342105, 0.6380076267353711,
    0.6319657910468793, 0.6311949295116889, 0.6360459553727554, 0.646869781873033
  ]
};
