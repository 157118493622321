import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionModal from '../../../Common/ActionModal';
import { Alert, Box, Typography } from '@esgian/esgianui';
import { generateVoyageIdFromSavedVoyage } from '../VoyageUtils';
import { LocationSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getLookupLocations } from '@store/features';
import { getLocationTypeId } from '@constants';

const AddDestinationModal = ({
  open,
  onClose,
  vesselsToAddDestination,
  isAddingDestination,
  onUpdate
}) => {
  const [showReminderInfo, setShowReminderInfo] = useState(true);
  const lookupLocations = useSelector(getLookupLocations);
  const [selectedLocationPerVesselId, setSelectedLocationPerVesselId] = useState({});

  return (
    <ActionModal
      open={open}
      onCancel={onClose}
      agreeButtonLabel="Update"
      cancelButtonLabel="Cancel"
      isDisabledAgree={isAddingDestination || !Object.values(selectedLocationPerVesselId).length}
      onAgree={() => {
        onUpdate(
          vesselsToAddDestination.map((singleVessel) => {
            const selectedLocation =
              selectedLocationPerVesselId[generateVoyageIdFromSavedVoyage(singleVessel)];
            return {
              ...singleVessel,
              toPortId: selectedLocation?.id ?? null,
              toPortType: selectedLocation?.type
                ? getLocationTypeId(selectedLocation.type)
                : undefined
            };
          })
        );
      }}
      content={
        <Box>
          {showReminderInfo ? (
            <Alert
              onClose={() => setShowReminderInfo(false)}
              severity="info"
              color="info"
              sx={{
                my: 2,
                display: 'flex',
                alignItems: 'center',
                background: '#C4C4C4',
                '.MuiAlert-icon': {
                  color: 'black'
                },
                '.MuiAlert-action': {
                  color: 'black'
                }
              }}>
              <Typography sx={{ color: 'black', m: 0, p: 0 }}>
                We will remind you when the status of the voyage changes
              </Typography>
            </Alert>
          ) : null}

          {vesselsToAddDestination.map((singleVessel, i) => {
            return (
              <Box
                key={`${i}~${singleVessel.imo}`}
                my={4}
                display="flex"
                flexDirection="column"
                gap={2}>
                {singleVessel.vesselName && <Typography>{singleVessel.vesselName}:</Typography>}
                <LocationSelect
                  label="Arrival Port"
                  keyPreFix={'arrival-port'}
                  placeholder="Arrival"
                  handleChange={(selectedLocation) => {
                    setSelectedLocationPerVesselId((prev) => ({
                      ...prev,
                      [generateVoyageIdFromSavedVoyage(singleVessel)]: selectedLocation
                    }));
                  }}
                  selectedLocation={
                    selectedLocationPerVesselId[generateVoyageIdFromSavedVoyage(singleVessel)] ??
                    null
                  }
                  locationList={lookupLocations}
                  width="100%"
                  disablePortal={false}
                />
              </Box>
            );
          })}
        </Box>
      }
      title="Add the destination"
    />
  );
};

AddDestinationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  vesselsToAddDestination: PropTypes.array,
  isAddingDestination: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default AddDestinationModal;
