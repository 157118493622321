import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getOptions = (categories, theme, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast, threeColors }
    }
  } = theme;

  return {
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: false
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val?.toFixed(2);
      }
    },
    colors: [twoColorsContrast[1], threeColors[0], twoColorsContrast[0]],
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      title: {},
      labels: { rotateAlways: true }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left'
    },
    yaxis: {
      min: 0,
      max: (max) => max * 1.2,
      title: {
        text: 'Avg. Speed[kn]'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          enabled: false,
          position: 'center'
        }
      }
    },
    stroke: {
      width: [2, 2, 3],
      curve: 'straight',
      dashArray: [0, 0, 5]
    },
    markers: {
      size: [0, 0, 2],
      strokewidth: 2,
      hover: {
        size: 5
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true
    }
  };
};
function OperatorAvgSpeedChart({ loading, avgSpeedData, fileName }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const { series = [], categories = [] } = useMemo(() => {
    if (!avgSpeedData?.operatorAverageMonthlySpeed) return {};

    return {
      categories: avgSpeedData?.operatorAverageMonthlySpeed.map(({ monthStr }) => monthStr),
      series: [
        {
          name: 'Average',
          type: 'bar',
          data: avgSpeedData?.operatorAverageMonthlySpeed.map(
            ({ monthlyAvgSpeed }) => monthlyAvgSpeed
          )
        },
        {
          name: 'Segment Avg Speed',
          type: 'line',
          data: avgSpeedData?.segmentTypeIdAverageMonthlySpeed
            ? avgSpeedData?.segmentTypeIdAverageMonthlySpeed?.map(
                ({ monthlyAvgSpeed }) => monthlyAvgSpeed
              )
            : []
        },
        {
          name: 'Trendline',
          type: 'line',
          data: avgSpeedData?.polyFitAverageMonthSpeed
            ? avgSpeedData?.polyFitAverageMonthSpeed?.map((polyFitAverageMonthSpeed) =>
                polyFitAverageMonthSpeed > 0 ? polyFitAverageMonthSpeed : 0
              )
            : []
        }
      ]
    };
  }, [avgSpeedData]);

  const options = useMemo(() => {
    if (!categories?.length) return {};
    return getOptions(categories, theme, user, fileName);
  }, [theme, categories]);

  return (
    <ApexChart loading={loading} height={480} options={options || {}} type={'bar'} data={series} />
  );
}

OperatorAvgSpeedChart.propTypes = {
  loading: PropTypes.bool,
  avgSpeedData: PropTypes.object,
  fileName: PropTypes.string
};

OperatorAvgSpeedChart.defaultProps = {
  avgSpeedData: null,
  loading: false,
  fileName: ''
};

export default OperatorAvgSpeedChart;
