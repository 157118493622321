import { Box, Grid, Typography, CircularProgress } from '@esgian/esgianui';
import React, { useEffect, useRef } from 'react';
import Summary from './Summary';
import {
  CargoEstimatedEtsCostTable,
  VoyagesEstimatedEtSCostTable,
  Co2eEmissionTable
} from '@components/Tables/EmissionAndETSTables';
import Co2EmissionTable from '@components/Tables/EmissionAndETSTables/Co2EmissionTable';
import FuelConsumptionTable from '@components/Tables/EmissionAndETSTables/FuelConsumptionTable';
import MainEngineFuelTable from '@components/Tables/EmissionAndETSTables/MainEngineFuelTable';
import MainGeneratorFuelTable from '@components/Tables/EmissionAndETSTables/MainGeneratorFuelTable';
import FuelConsumptionOverTimeChart from './FuelConsumptionOverTimeChart';
import EmissionOverTimeChart from './EmissionOverTimeChart';
import OverallVoyage from '@components/Tables/EmissionAndETSTables/OverallVoyage';
import { TextWithTooltipIcon } from '@components';

function EmissionAndEtsSection({
  summary,
  isLoadingData,
  myCargoWeightForCustomizedCalculation,
  fetchEmissionAndEtsData,
  myEtsCostForCustomizedCalculation,
  setMyCargoWeightForCustomizedCalculation,
  setMyEtsCostForCustomizedCalculation,
  loadedData
}) {
  const isInitialLoad = useRef(false);

  useEffect(() => {
    if (
      !isInitialLoad.current &&
      myCargoWeightForCustomizedCalculation &&
      myEtsCostForCustomizedCalculation
    ) {
      // Fetch updated data when customized calculation parameters change, avoiding the initial load
      fetchEmissionAndEtsData(
        myCargoWeightForCustomizedCalculation,
        myEtsCostForCustomizedCalculation
      );
    }
  }, [myCargoWeightForCustomizedCalculation, myEtsCostForCustomizedCalculation]);
  return (
    <Box display="flex" gap={2} flexDirection="column">
      {isLoadingData ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </Box>
      ) : (
        <>
          <Summary summary={summary} isLoading={isLoadingData} />

          <Box>
            <Typography variant="h6" my={2}>
              <TextWithTooltipIcon
                labelVariant={'h6'}
                label="EU ETS Cost"
                tooltipText="Estimated EU ETS Cost"
              />
            </Typography>
            <CargoEstimatedEtsCostTable
              myCargoEuEtsAllowanceSummary={loadedData?.myCargoEuEtsAllowanceSummary}
              myCargoEuEtsAllowanceCost={loadedData?.myCargoEuEtsAllowanceCost}
              isLoading={isLoadingData}
              myCargoWeightForCustomizedCalculation={myCargoWeightForCustomizedCalculation}
              myEtsCostForCustomizedCalculation={myEtsCostForCustomizedCalculation}
              setMyCargoWeightForCustomizedCalculation={setMyCargoWeightForCustomizedCalculation}
              setMyEtsCostForCustomizedCalculation={setMyEtsCostForCustomizedCalculation}
            />
            <VoyagesEstimatedEtSCostTable
              isLoading={isLoadingData}
              overallVoyageEuEtsAllowanceSummary={loadedData?.overallVoyageEuEtsAllowanceSummary}
              overallVoyageEuEtsAllowanceCost={loadedData?.overallVoyageEuEtsAllowanceCost}
            />
          </Box>

          <Box>
            <Typography variant="h6" my={2}>
              CO₂e Emission (GHG Emission)
            </Typography>
            <Co2eEmissionTable
              myCargoToc={loadedData?.myCargoTocEmission ?? {}}
              myCargoHoc={loadedData?.myCargoHocEmission ?? {}}
              emissionSummary={loadedData?.myCargoGhgEmissionSummary ?? {}}
              isLoading={isLoadingData}
            />
            <OverallVoyage
              isLoading={isLoadingData}
              overallVoyage={loadedData?.overallVoyageGhgEmission}
            />
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              CO<sub>2</sub> Emission
            </Typography>
            <Co2EmissionTable
              title={'Overall Voyage'}
              filename={'co2-emission'}
              ciiRating={loadedData?.ciiRating}
              isLoading={isLoadingData}
            />
          </Box>

          <Box>
            <EmissionOverTimeChart
              dailyEmissions={loadedData?.dailyEmissions}
              isLoading={isLoadingData}
            />
          </Box>

          <Box display="flex" gap={2}>
            <Box flex={1}>
              <FuelConsumptionTable
                fuelDetails={loadedData?.fuelDetails}
                isLoading={isLoadingData}
              />
            </Box>
            <Box flex={2}>
              <FuelConsumptionOverTimeChart
                fuelDetails={loadedData?.fuelDetails}
                isLoading={isLoadingData}
              />
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MainEngineFuelTable
                isLoading={isLoadingData}
                fuelTypes={loadedData?.fuelDetails?.fuelTypeIdsFuelNames ?? {}}
                mainEngineFuel={loadedData?.fuelDetails?.mainEngineFuelDurations ?? []}
              />
            </Grid>
            <Grid item xs={6}>
              <MainGeneratorFuelTable
                isLoading={isLoadingData}
                fuelTypes={loadedData?.fuelDetails?.fuelTypeIdsFuelNames ?? {}}
                mainGeneratorFuel={loadedData?.fuelDetails?.mainGeneratorFuelDurations ?? []}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default EmissionAndEtsSection;
