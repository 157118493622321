import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import { getCanalArrivals, getCanalDepartures, getCanalInTransit } from '@api';
import { DEFAULT_NUM_PORT_CALLS } from '@constants';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';
import CanalProfileSection from '@components/Sections/ShipAnalytics/CanalProfileSection';
import {
  mockCanalStats,
  mockOprTransits,
  mockCanalWaitingTime
} from '@components/Sections/ShipAnalytics/CanalsOverviewSection/mock';

function CanalProfilePage() {
  const params = useParams();
  const [loadingDetails, setLoadingDetails] = useState(true);
  const navigate = useNavigate();
  const [operatorStats, setOperatorStats] = useState(null);
  const [selectedYear] = useState(moment().year());
  const { segment, isRoRo } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { segmentMinDate } = useSegment();
  const [canalDeparturesData, setCanalDeparturesData] = useState(null);
  const [canalArrivalsData, setCanalArrivalsData] = useState(null);
  const [canalIntransitData, setCanalIntransitData] = useState(null);

  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getCanalData = async (canalId) => {
      setLoadingDetails(true);
      try {
        await Promise.all([
          getCanalDepartures(
            {
              start: '2023-08-13T10:23:41.337Z', //moment(segmentMinDate).format(DATE_FORMAT),
              end: null, //Keep it null to get results faster
              nMostRecent: DEFAULT_NUM_PORT_CALLS,
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: [canalId]
            },
            signal
          )
            .then((result) => {
              setCanalDeparturesData(result);
            })
            .catch(() => {
              setCanalDeparturesData(null);
            }),
          getCanalArrivals(
            {
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: [canalId]
            },
            signal
          )
            .then((result) => {
              setCanalArrivalsData(result);
            })
            .catch(() => {
              setCanalArrivalsData(null);
            }),
          getCanalInTransit(
            {
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: [canalId]
            },
            signal
          )
            .then((result) => {
              setCanalIntransitData(result);
            })
            .catch(() => {
              setCanalIntransitData(null);
            })
        ]);
      } catch (error) {
        console.error('Error fetching canal data:', error);
      } finally {
        setLoadingDetails(false);
      }
    };

    getCanalData(params.canalId);

    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [operatorType, commercialCategoryType, segment]);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-1)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={loadingDetails}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  routeChange(isRoRo ? '/roro/commercial-analytics' : '/mpp/commercial-analytics')
                }>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() =>
                  routeChange(isRoRo ? '/roro/commercial-analytics' : '/mpp/commercial-analytics')
                }>
                Commercial Analytics
              </Link>
              <Typography color="primary">Canal Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CanalProfileSection
          mockCanalStats={mockCanalStats}
          mockOprTransits={mockOprTransits}
          operatorStats={operatorStats}
          selectedYear={selectedYear}
          canalDeparturesData={canalDeparturesData}
          canalArrivalsData={canalArrivalsData}
          canalIntransitData={canalIntransitData}
          mockCanalWaitingTime={mockCanalWaitingTime}
        />
      </Grid>
    </Grid>
  );
}

CanalProfilePage.propTypes = {};

CanalProfilePage.defaultProps = {};

export default CanalProfilePage;
