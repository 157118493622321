import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangeMobi, Box, Grid, Stack } from '@esgian/esgianui';
import WeeklyCanalTransitsChart from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalStatisticsSection/WeeklyCanalTransitsChart';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import moment from 'moment';
import { OperatorMultiSelect } from '@components/Inputs';
import WeeklyCanalTransitsTable from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalStatisticsSection/WeeklyCanalTransitsTable';
import GroupSelect from '@components/Inputs/GroupSelect';
import { useSelector } from 'react-redux';
import { getDateFormat, getLookupOperators } from '@store/features';
import { useSegment } from '@hooks';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal';

function CanalStatisticsSection({ mockCanalStats, mockOprTransits, loading, portName }) {
  const [selectedGroups, setSelectedGroups] = useState('Direction', 'Operator');
  const dateFormat = useSelector(getDateFormat);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { segmentMinDate } = useSegment();
  const [init, setInit] = useState(false);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const lookupOperators = useSelector(getLookupOperators);

  const groups = [
    { value: 'direction', label: 'Direction' },
    { value: 'operator', label: 'Operator' }
  ];

  const handleGroupChange = (groups) => {
    setSelectedGroups(groups);
  };
  const weeklyCanalStats = mockCanalStats[0]?.weeklyCanalStats;
  const monthlyCanalStats = mockCanalStats[0].monthlyCanalStats;

  const updateFilterValue = (payload) => {
    setStartDate(payload.startDate);
    setEndDate(payload.endDate);
    setInit(true);
  };

  const handleFilterChange = (payload) => {
    setInit(true);
    setSelectedOperators(payload.selectedOperators);
  };

  return (
    <Grid container spacing={2}>
      <Grid item className={'section-filter'}>
        <Stack direction={'row'} spacing={4} justifyContent={'space-between'}>
          <Box sx={{ width: '8em', flex: 1 }}>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
          </Box>
          <Box sx={{ width: '15em', flex: 1 }}>
            <OperatorMultiSelect
              helperText={init && !selectedOperators.length ? 'No operators selected' : ''}
              error={init && !selectedOperators.length}
              placeholder={'Select Operators...'}
              selectedOperators={selectedOperators}
              operatorsList={lookupOperators || []}
              sx={{ width: '100%' }}
              handleChange={(_, values) => {
                handleFilterChange({ selectedOperators: values });
              }}
            />
          </Box>
          <Box sx={{ width: '15em', flex: 1 }}>
            <GroupSelect
              label="Select Group(s)"
              groupList={groups}
              handleChange={handleGroupChange}
              selectedGroup={selectedGroups}
              isRequired={true}
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <WeeklyCanalTransitsChart
          mockOprTransits={mockOprTransits}
          mockCanalStats={mockCanalStats[0]}
          loading={loading}
          selectedGroups={selectedGroups}
          timeFrame={'week'}
        />
      </Grid>
      <Grid item xs={12}>
        <WeeklyCanalTransitsTable
          mockCanalStats={mockCanalStats[0]}
          mockOprTransits={mockOprTransits}
          loading={loading}
          selectedGroups={selectedGroups}
          timeFrame={'week'}
        />
        <UnderlyingCanalStatsModal handleClose={null} />
      </Grid>
      <Grid item xs={12}>
        <WeeklyCanalTransitsChart
          mockOprTransits={mockOprTransits}
          mockCanalStats={mockCanalStats[0]}
          loading={loading}
          selectedGroups={selectedGroups}
          timeFrame={'month'}
        />
      </Grid>
      <Grid item xs={12}>
        <WeeklyCanalTransitsTable
          mockCanalStats={mockCanalStats[0]}
          mockOprTransits={mockOprTransits}
          loading={loading}
          selectedGroups={selectedGroups}
          timeFrame={'month'}
        />
        <UnderlyingCanalStatsModal handleClose={null} />
      </Grid>
    </Grid>
  );
}

export default CanalStatisticsSection;
