import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getOptions = (categories, theme, user, fileName, timeFrame, isStacked) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast, tenColors }
    }
  } = theme;

  return {
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        show: true,
        tools: {
          download: checkUserHasAccess(user, true),
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: isStacked
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val?.toFixed(2);
      }
    },
    colors: [tenColors[0], tenColors[1], tenColors[2], tenColors[3], tenColors[4], tenColors[5]],
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories,
      title: timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left'
    },
    yaxis: {
      max: (max) => max * 1.2,
      title: {
        text: 'No. of Transits'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          enabled: false,
          position: 'center'
        }
      }
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 0]
    },
    markers: {
      size: [0, 0],
      strokewidth: 2,
      hover: {
        size: 5
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      x: {
        show: true,
        formatter: function (value, { dataPointIndex }) {
          return `${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}: ${
            categories[dataPointIndex]
          }`;
        }
      }
    }
  };
};

function CanalTransitsChart({
  loading,
  fileName,
  mockOprTransits,
  mockCanalStats,
  selectedGroups,
  timeFrame
}) {
  console.log('data in chart', mockCanalStats);
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const [series, setSeries] = useState([]);

  const isStacked =
    (selectedGroups[0] === 'Direction' && selectedGroups[1] === 'Operator') ||
    (selectedGroups[0] === 'Operator' && selectedGroups[1] === 'Direction');

  const getMonthlyCategories = (data) => {
    if (data.length === 0) return [];

    // Assuming we're taking data from the first operator and its monthly results
    const monthlyResults = data[0].oprMonthlyResults;

    // Create an array of month-year strings
    return monthlyResults.flatMap((yearlyData) => {
      const year = yearlyData.year;
      return yearlyData.months.map((month, index) => {
        // Format month as "Jan-21", "Feb-21", etc.
        const monthName = new Date(year, index).toLocaleString('default', {
          month: 'short'
        });
        return `${monthName}-${year % 100}`;
      });
    });
  };
  const { categories } = useMemo(() => {
    if (!mockOprTransits?.length) {
      return { categories: [] };
    }

    const timeFrameKey = timeFrame.toLowerCase(); // week, month
    const timeFrameTitle = timeFrameKey.charAt(0).toUpperCase() + timeFrameKey.slice(1);

    const categories =
      timeFrame === 'week'
        ? mockOprTransits[0].oprWeeklyResults
          ? mockOprTransits[0].oprWeeklyResults.map((_, index) => `${timeFrameTitle} ${index + 1}`)
          : []
        : getMonthlyCategories(mockOprTransits);

    if (isStacked) {
      // Create series for each operator's east and west bound data
      setSeries([]);
    } else if (selectedGroups[0] === 'Operator') {
      if (timeFrame === 'week') {
        let updatedSeries = mockOprTransits.map((operator) => ({
          name: operator.operatorLongName,
          type: 'bar',
          data: operator.oprWeeklyResults.map((result) => result.transitStats)
        }));

        setSeries(updatedSeries);
      } else {
        //   const updatedMonthlySeries = mockOprTransits.map((operator) => ({
        //     name: operator.operatorLongName,
        //     type: 'bar',
        //     data: operator.oprMonthlyResults.flatMap((yearData) =>
        //       yearData.months.map((month) => ({
        //         x: new Date(month.start).toLocaleString('default', { month: 'short', year: 'numeric' }),
        //         y: month.transitStats
        //       }))
        //     )
        //   }));
        const updatedMonthlySeries = mockOprTransits.map((operator) => ({
          name: operator.operatorLongName,
          type: 'bar',
          data: operator.oprMonthlyResults.flatMap((yearData) =>
            yearData.months.map((month) => month.transitStats)
          )
        }));
        //   const updatedMonthlySeries = mockOprTransits.map((operator) => ({
        //     name: operator.operatorLongName,
        //     type: 'bar',
        //     data: operator.oprMonthlyResults[0].months.map((month) => month.transitStats)
        //   }));

        setSeries(updatedMonthlySeries);
      }
    } else if (selectedGroups[0] === 'Direction') {
      const { weeklyCanalStats, monthlyCanalStats } = mockCanalStats;

      let updatedSeries = [
        {
          name: 'East Bound',
          type: 'bar',
          data:
            timeFrame === 'month'
              ? monthlyCanalStats.map((result) => result.eastBound)
              : weeklyCanalStats.map((result) => result.eastBound)
        },
        {
          name: 'West Bound',
          type: 'bar',
          data:
            timeFrame === 'month'
              ? monthlyCanalStats.map((result) => result.westBound)
              : weeklyCanalStats.map((result) => result.westBound)
        }
      ];
      setSeries(updatedSeries);
    } else {
      const { weeklyCanalStats, monthlyCanalStats } = mockCanalStats;
      let updatedSeries = [
        {
          name: 'No. of transits',
          type: 'bar',
          data:
            timeFrame === 'month'
              ? monthlyCanalStats.map((result) => result.numoftransits)
              : weeklyCanalStats.map((result) => result.numoftransits)
        }
      ];
      setSeries(updatedSeries);
    }

    return { categories, series };
  }, [mockOprTransits, mockCanalStats, selectedGroups, timeFrame]);

  const options = useMemo(() => {
    if (!categories?.length) return {};
    const isStacked =
      (selectedGroups[0] === 'Direction' && selectedGroups[1] === 'Operator') ||
      (selectedGroups[0] === 'Operator' && selectedGroups[1] === 'Direction');
    return getOptions(categories, theme, user, fileName, timeFrame, isStacked);
  }, [theme, categories, user, fileName, timeFrame, selectedGroups]);

  const convertWeeksToSeries = (data) => {
    return data.flatMap((operator) => {
      const operatorName = operator.operatorLongName;
      const eastBoundData = operator.oprWeeklyResults.map((result) => result.eastBound);
      const westBoundData = operator.oprWeeklyResults.map((result) => result.westBound);

      return [
        {
          name: `${operatorName}-eastBound`,
          type: 'bar',
          group: operatorName,
          data: eastBoundData
        },
        {
          name: `${operatorName}-westBound`,
          type: 'bar',
          group: operatorName,
          data: westBoundData
        }
      ];
    });
  };

  const convertMonthsToSeries = (data) => {
    return data.flatMap((operator) => {
      const operatorName = operator.operatorLongName;
      const monthlyResults = operator.oprMonthlyResults;

      // Iterate through each year in monthlyResults
      return monthlyResults.flatMap((yearlyData) => {
        const year = yearlyData.year;

        const eastBoundData = yearlyData.months.map((month) => month.eastBound);
        const westBoundData = yearlyData.months.map((month) => month.westBound);

        return [
          {
            name: `${operatorName}-${year}-eastBound`,
            type: 'bar',
            group: `${operatorName}-${year}`,
            data: eastBoundData
          },
          {
            name: `${operatorName}-${year}-westBound`,
            type: 'bar',
            group: `${operatorName}-${year}`,
            data: westBoundData
          }
        ];
      });
    });
  };
  console.log(' mockCanalStats111', mockCanalStats);
  return (
    <>
      <Stack>
        <Typography variant={'h6'}>{timeFrame}ly canal transits</Typography>
      </Stack>
      <ApexChart
        loading={loading}
        height={480}
        options={options || {}}
        type={'bar'}
        data={
          isStacked
            ? timeFrame === 'month'
              ? convertMonthsToSeries(mockOprTransits)
              : convertWeeksToSeries(mockOprTransits)
            : series
        }
        title={'Weekly Canal Transits'}
      />
    </>
  );
}

CanalTransitsChart.propTypes = {
  loading: PropTypes.bool,
  mockOprTransits: PropTypes.array,
  fileName: PropTypes.string,
  selectedGroups: PropTypes.array,
  timeFrame: PropTypes.string
};

CanalTransitsChart.defaultProps = {
  mockOprTransits: [],
  loading: false,
  fileName: '',
  selectedGroups: [],
  timeFrame: 'week'
};

export default CanalTransitsChart;
