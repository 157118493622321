import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextWithTooltipIcon } from '@components';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { emissionTableColumns, getDataShape } from './Co2eEmissionTable';
import { formatNumberReadable } from '@helpers';

const Co2EmissionTable = ({ ciiRating, isLoading, title }) => {
  const [data, setData] = useState([]);

  const summary = [
    {
      label: (
        <TextWithTooltipIcon
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.1em'
          }}
          label="Total CO₂ (T) "
          tooltipText={'Total CO₂ emission( Tank to Wake) of the vessel for the selected voyage'}
        />
      ),
      tooltip: 'Total CO₂ emission in tonnes',
      value: formatNumberReadable(ciiRating?.totalCo2Ttw?.toFixed(0)) ?? '--'
    },
    {
      label: (
        <TextWithTooltipIcon
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.1em'
          }}
          label={`CII (${ciiRating?.ciiDisplayString}) `}
          tooltipText={'Carbon Index Intensity of the vessel for the selected voyage.'}
        />
      ),
      value: ciiRating?.ciiValueTtw?.toFixed(2) ?? '--'
    },
    {
      label: (
        <TextWithTooltipIcon
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.1em'
          }}
          label="CII "
          tooltipText={'Carbon Index Intensity Rating of the vessel for the selected voyage. '}
        />
      ),
      value: ciiRating?.ciiRatingTtw ?? '--'
    }
  ];

  useEffect(() => {
    if (!ciiRating) {
      return;
    }

    setData([
      getDataShape(
        'CO₂',
        ciiRating?.totalCo2Wtt,
        formatNumberReadable(ciiRating?.totalCo2Ttw?.toFixed(0)),
        ciiRating?.totalCo2Wtw
      ),
      getDataShape(
        'CII',
        ciiRating?.ciiValueWtt,
        formatNumberReadable(ciiRating?.ciiValueTtw?.toFixed(2)),
        ciiRating?.ciiValueWtw
      )
    ]);
  }, [ciiRating]);

  return (
    <TableWithSummary
      title={title}
      columns={emissionTableColumns}
      data={data}
      summary={summary}
      isLoading={isLoading}
    />
  );
};

Co2EmissionTable.propTypes = {
  ciiRating: PropTypes.object,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

export default Co2EmissionTable;
