import { Grid, Skeleton, Stack, Box, CircularProgress } from '@esgian/esgianui';
import React, { useMemo, useRef } from 'react';
import VoyageSummaryOverview from './VoyageSummaryOverview/VoyageSummaryOverview';
import VoyageSummaryTable from './VoyageSummaryTable';
import VoyageActivityProfile from './VoyageSummaryActivityProfile';
import VoyagePortWaitingTime from './VoyageSummaryPortWaitingTime';
import VoyageSummarySpeed from './VoyageSummarySpeed';
import VoyageSummaryDraught from './VoyageSummaryDraught';
import VoyageSummaryRouteMap from './VoyageSummaryRouteMap';
import { useSearchParams } from 'react-router-dom';
import { VoyageSummaryFilterKeys } from './VoyageSummaryConstants';
import { getValueUptoTwoDecimalPlaces } from './VoyageSummaryRouteMap/utils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import moment from 'moment';
import VoyageSummaryFilter from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryFilter';
import {
  VoyageSummaryOverviewFloatingWindow,
  VoyageSummaryShortestRouteOverviewFloatingWindow,
  VoyageSummarySpeedIndicatorFloatingWindow
} from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryOverview';

const VoyageSummarySection = ({ voyageData }) => {
  const mapContainerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const {
    mapOptions: {
      shortestPath: showShortestPath,
      fullscreen,
      speed: showSpeed,
      voyageInfo: showVoyageInfo,
      draught: showDraught
    }
  } = useSelector(getVoyageProfileFilters);
  const { voyageOverview, voyagePortCalls, voyageTransitDetails, shortestPath } = voyageData ?? {};

  const selectedPeriodFilter = useMemo(() => {
    let startDate = searchParams.get(VoyageSummaryFilterKeys.START_DATE);
    let endDate = searchParams.get(VoyageSummaryFilterKeys.END_DATE);
    startDate = startDate ? +startDate : null;
    endDate = endDate ? +endDate : null;
    return { selectedPeriodStart: startDate, selectedPeriodEnd: endDate };
  }, [searchParams]);

  const filteredTransitDetails = useMemo(() => {
    const { selectedPeriodStart, selectedPeriodEnd } = selectedPeriodFilter;
    let start = moment(selectedPeriodEnd);
    let end = moment(selectedPeriodEnd);
    return {
      ...voyageTransitDetails,
      transitDetails: voyageTransitDetails?.transitDetails?.filter(({ timeStamp }) => {
        const currTimestamp = moment(timeStamp);
        const isMatchStartTimestampCriteria =
          !selectedPeriodStart || currTimestamp.isSameOrAfter(start);
        const isMatchEndTimestampCriteria = !selectedPeriodEnd || currTimestamp.isSameOrBefore(end);
        return isMatchStartTimestampCriteria && isMatchEndTimestampCriteria;
      })
    };
  }, [voyageTransitDetails, selectedPeriodFilter]);

  const shouldShowFullscreenMap = !showSpeed && !showDraught;
  const shouldShowBoth = showSpeed && showDraught;

  if (!voyageData) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 50px)'
          }}
          size={100}
        />
      </Grid>
    );
  }

  if (fullscreen && voyageData) {
    return (
      <Grid container sx={{ position: 'relative' }} spacing={1}>
        <VoyageSummaryFilter
          voyageTransitDetails={voyageTransitDetails}
          voyagePortCalls={voyagePortCalls}
        />
        <Stack position="absolute" left="1rem" top="7rem" spacing={1}>
          {showVoyageInfo && (
            <VoyageSummaryOverviewFloatingWindow
              voyageTransitDetails={voyageTransitDetails}
              summaryOverview={voyageOverview}
              voyagePortCalls={voyagePortCalls}
            />
          )}
          {showShortestPath && (
            <VoyageSummaryShortestRouteOverviewFloatingWindow
              shortestPathDistanceNm={shortestPath?.shortestPathDistanceNm}
              voyageTransitDetails={voyageTransitDetails}
            />
          )}
          {showSpeed && false && <VoyageSummarySpeedIndicatorFloatingWindow />}
        </Stack>
        <VoyageSummaryRouteMap
          voyageTransitDetails={voyageTransitDetails}
          shortestPath={shortestPath ?? {}}
          voyagePortCalls={voyagePortCalls}
          shouldShowFullscreenMap={shouldShowFullscreenMap}
        />
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {showSpeed && (
              <Grid item xs={12} md={shouldShowBoth ? 6 : 12} alignSelf={'center'}>
                <VoyageSummarySpeed
                  isFullScreen
                  voyageTransitDetails={filteredTransitDetails}
                  sx={{ height: '30vh' }}
                />
              </Grid>
            )}
            {showDraught && (
              <Grid item xs={12} md={shouldShowBoth ? 6 : 12} alignSelf={'center'}>
                <VoyageSummaryDraught
                  voyageTransitDetails={filteredTransitDetails}
                  isFullScreen
                  sx={{ height: '30vh' }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Stack spacing={2} sx={{ maxWidth: '100%' }}>
      <Grid container>
        <Grid item xs={12} md={4} alignSelf={'center'}>
          <Box height={'400px'}>
            {!voyageData ? (
              <Skeleton variant={'rectangular'} height={'320px'} />
            ) : (
              <VoyageSummaryOverview
                voyageTransitDetails={voyageTransitDetails}
                summaryOverview={voyageOverview}
                isLoading={!voyageData}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} pl={2} alignSelf={'center'}>
          <Box height={'400px'}>
            {!voyageData ? (
              <Skeleton variant={'rectangular'} height={'320px'} />
            ) : (
              <div ref={mapContainerRef}>
                <VoyageSummaryRouteMap
                  voyageTransitDetails={voyageTransitDetails}
                  shortestPath={shortestPath ?? {}}
                  voyagePortCalls={voyagePortCalls}
                  isLoading={!voyageData}
                />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>

      <VoyageSummaryTable
        voyagePortCalls={voyagePortCalls?.portCalls ?? []}
        loading={!voyageData}
      />

      {voyageData && (
        <>
          <Grid container alignItems="stretch">
            {voyageData && (
              <Grid item xs={12} md={6} pr={1}>
                <VoyageActivityProfile
                  activityData={{
                    atPortDays: voyageTransitDetails.countAtPort,
                    standbyDays: voyageTransitDetails.countStandby,
                    transitDays: voyageTransitDetails.countTransit
                  }}
                  chartData={{
                    atPortDays: voyageTransitDetails.percentAtPort,
                    standbyDays: voyageTransitDetails.percentStandby,
                    transitDays: voyageTransitDetails.percentTransit
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} pl={1}>
              <VoyagePortWaitingTime
                voyagePortCalls={voyagePortCalls.portCalls}
                averageData={{
                  averagePortTime: getValueUptoTwoDecimalPlaces(
                    voyagePortCalls.averagePortTimeDays
                  ),
                  averagePortWaitingTime: getValueUptoTwoDecimalPlaces(
                    voyagePortCalls.averagePortWaitingTimeDays
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6} pr={1} alignSelf={'center'}>
              <VoyageSummarySpeed voyageTransitDetails={filteredTransitDetails} />
            </Grid>
            <Grid item xs={12} md={6} pl={1} alignSelf={'center'}>
              <VoyageSummaryDraught voyageTransitDetails={filteredTransitDetails} />
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

VoyageSummarySection.propTypes = {
  voyageData: PropTypes.object
};
VoyageSummarySection.defaultProps = {
  voyageData: null
};

export default VoyageSummarySection;
