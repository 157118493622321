import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { getValueUptoFourDecimalPlaces } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';
import { formatNumberReadable } from '@helpers';
import { TextWithTooltipIcon } from '@components';

const OverallVoyage = ({ overallVoyage, isLoading }) => {
  const summary = [];

  const columns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'energyProduction',
      label: 'Energy Production',
      tooltip:
        'CO₂e (kg) Emission from energy production (also referred to as Well to Tank (WTT) Emission)  of the vessel for the selected voyage'
    },
    {
      name: 'operational',
      label: 'Operational',
      tooltip:
        'CO₂e (kg) Emission from vessel operation (also referred to as Tank to Wake ( TTW) Emission)  of the vessel for the selected voyage'
    },
    {
      name: 'overall',
      label: 'Overall',
      tooltip:
        'Sum of CO₂e (kg) Emission from energy production and vessel operation (also referred to as Well to Wake (WTW) Emission) of the vessel for the selected voyage'
    }
  ];

  const data = [];

  const totalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    energyProduction: { value: '-' },
    operational: { value: '-' },
    overall: { value: '-' }
  };

  const detailsTableColumns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'estimatedCargoWeightT',
      label: 'Estimated Cargo weight (t)',
      tooltip:
        'Cargo weight is estimated based on the draught of the vessel using the Esgian cargo weight estimation model.'
    },
    {
      name: 'shortestDistanceKm',
      label: 'Distance (km)',
      tooltip:
        'For RORO vessels, ‘Distance’ corresponds to shortest distance between port pairs estimated by Esgian based on positional data of historical voyages of all vessels in the Esgian database. For MPP vessels, ‘Distance’ corresponds to actual distance travelled between port pairs based on the positional data of the vessel carrying the cargo.'
    },
    {
      name: 'transportActivityTKm',
      label: 'Transport Activity (t-km) ',
      tooltip: 'Transport activity is the product of cargo weight (t) and distance (km).'
    },
    {
      name: 'energyProduction',
      label: 'Energy Production',
      tooltip:
        'CO₂e (kg) Emission intensity ( CO₂e (kg) emission per cargo weight carried per kilometer travelled) from energy production (also referred to as Well to Tank (WTT) Emission)  of the vessel for the selected voyage',
      customCellValue: getValueUptoFourDecimalPlaces
    },
    {
      name: 'operational',
      label: 'Operational',
      tooltip:
        'CO₂e (kg) Emission intensity ( CO₂e (kg) emission per cargo weight carried per kilometer travelled) from vessel operation (also referred to as Tank to Wake ( TTW) Emission)  of the vessel for the selected voyage',
      customCellValue: getValueUptoFourDecimalPlaces
    },
    {
      name: 'overall',
      label: 'Overall',
      tooltip:
        'CO₂e (kg) Emission intensity ( CO₂e (kg) emission per cargo weight carried per kilometer travelled) from energy production and vessel operation (also referred to as Well to Wake (WTW) Emission) of the vessel for the selected voyage',
      customCellValue: getValueUptoFourDecimalPlaces
    }
  ];

  const detailsTableData = [];

  const detailsTableTotalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    estimatedCargoWeightT: { value: '-' },
    shortestDistanceKm: { value: '-' },
    transportActivityTKm: { value: '-' },
    energyProduction: { value: null },
    operational: { value: null },
    overall: { value: null }
  };

  if (overallVoyage) {
    summary.push({
      label: (
        <TextWithTooltipIcon
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.1em'
          }}
          label="Total CO₂e (kg)"
          tooltipText={'Total CO₂e emission of the vessel for the selected voyage'}
        />
      ),
      value: formatNumberReadable(overallVoyage.totalGhgEmissionOverallKg.toFixed(0))
    });

    data.push(
      ...overallVoyage.voyageLegEmissionIntensities.map((d) => ({
        voyageLeg: { value: `#${d.voyageLeg}` },
        origin: { value: d.fromPortName },
        destination: { value: d.toPortName },
        energyProduction: {
          value: formatNumberReadable(d.ghgEmissionEneryProductionKg?.toFixed(0))
        },
        operational: { value: formatNumberReadable(d.ghgEmissionOperationalKg?.toFixed(0)) },
        overall: { value: formatNumberReadable(d.ghgEmissionOverallKg?.toFixed(0)) }
      }))
    );

    totalRow.energyProduction.value = formatNumberReadable(
      overallVoyage.totalGhgEmissionEneryProductionKg?.toFixed(0)
    );
    totalRow.operational.value = formatNumberReadable(
      overallVoyage.totalGhgEmissionOperationalKg?.toFixed(0)
    );
    totalRow.overall.value = formatNumberReadable(
      overallVoyage.totalGhgEmissionOverallKg?.toFixed(0)
    );

    detailsTableData.push(
      ...overallVoyage.voyageLegEmissionIntensities.map((d) => ({
        voyageLeg: { value: `#${d.voyageLeg}` },
        origin: { value: d.fromPortName },
        destination: { value: d.toPortName },
        estimatedCargoWeightT: { value: d.cargoWeightTn?.toFixed(0) },
        shortestDistanceKm: { value: d.distanceKm?.toFixed(0) },
        transportActivityTKm: { value: formatNumberReadable(d.transportActivity) },
        energyProduction: { value: d.emissionIntensityEneryProduction },
        operational: { value: d.emissionIntensityOperational },
        overall: { value: d.emissionIntensityOverall }
      }))
    );

    detailsTableTotalRow.shortestDistanceKm.value = overallVoyage.totalDistanceKm?.toFixed(0);
    // detailsTableTotalRow.energyProduction.value = overallVoyage.totalGhgEmissionEneryProductionKg;
  }
  return (
    <TableWithSummary
      title="Overall voyage"
      columns={columns}
      data={[...data, totalRow]}
      summary={summary}
      detailsTableTitle="Overall Voyage Emission intensity"
      detailsTableColumns={detailsTableColumns}
      detailsTableData={[...detailsTableData, detailsTableTotalRow]}
      isLoading={isLoading}
    />
  );
};

OverallVoyage.propTypes = {
  overallVoyage: PropTypes.object,
  isLoading: PropTypes.bool
};

export default OverallVoyage;
