import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import CanalsOverviewSection from '@components/Sections/ShipAnalytics/CanalsOverviewSection';
import { getCanalArrivals, getCanalInTransit } from '@api';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';

function CanalsOverviewPage() {
  const params = useParams();

  const navigate = useNavigate();
  const routeChange = useCallback((path) => {
    navigate(path);
  }, []);
  const [canalArrivalVesselsData, setCanalArrivalVesselsData] = useState(null);
  const [canalInTransitVesselsData, setCanalInTransitVesselsData] = useState(null);
  const operatorType = useSelector(getOperatorType);
  const { segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getCanalsData = async () => {
      try {
        await Promise.all([
          getCanalArrivals(
            {
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: null
            },
            signal
          )
            .then((result) => {
              setCanalArrivalVesselsData(result);
            })
            .catch(() => {
              setCanalArrivalVesselsData(null);
            }),
          getCanalInTransit(
            {
              operatorTypeId: operatorType,
              segmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType,
              canalIds: null
            },
            signal
          )
            .then((result) => {
              setCanalInTransitVesselsData(result);
            })
            .catch(() => {
              setCanalInTransitVesselsData(null);
            })
        ]);
      } catch (error) {
        console.error('Error fetching canal data:', error);
      }
    };
    getCanalsData(params.canalId);

    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [operatorType, commercialCategoryType, segment]);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          title={'Canals Overview'}
          id={'lbl-canal-overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="gps-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange('/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="Canals-overview">
                Canals Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CanalsOverviewSection
          canalArrivalVesselsData={canalArrivalVesselsData}
          canalInTransitVesselsData={canalInTransitVesselsData}
        />
      </Grid>
    </Grid>
  );
}

export default CanalsOverviewPage;
