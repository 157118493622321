import { useLocation } from 'react-router-dom';
import { VoyageType } from '../../../components/Sections/VoyageAnalytics/VoyageUtils';

function getVoyageType(path) {
  if (path.includes('completed-voyages')) {
    return VoyageType.COMPLETED;
  } else if (path.includes('ongoing-voyages')) {
    return VoyageType.ONGOING;
  } else {
    return 'unknown';
  }
}

export const useGetVoyageType = () => {
  const location = useLocation();
  const pathName = location.pathname;

  return getVoyageType(pathName);
};
