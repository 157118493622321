import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Button,
  CircularProgress,
  DatePickerMobi,
  Grid,
  Skeleton,
  TextField,
  Typography
} from '@esgian/esgianui';
import { OperatorMultiSelect } from '@components/Inputs';
import moment from 'moment/moment';
import { DATE_FORMAT } from '@constants';
import { useSearchParams } from 'react-router-dom';
import { activityMapCheckValidFilters } from '@helpers';
import { getRegionActivityByOperators } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTIVITY_MAP_SLICE_KEYS,
  getActivityMapFilters,
  updateActivityMapFilters
} from '@store/features/filters/CommercialAnalyticsPage/ActivityMapSlice/ActivityMapSlice';
import { useSegment } from '@hooks';
import { getLookupOperators } from '@store/features';
import PropTypes from 'prop-types';
import {
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSliceToUri
} from '@helpers/uriHelpers';

const setUrlParams = (newFilters, lookupOperators, searchParams, setSearchParams) => {
  const { selectedOperators } = newFilters;
  searchParams = setSliceToUri(newFilters, ACTIVITY_MAP_SLICE_KEYS, 'am', searchParams);

  if (selectedOperators === 'all') {
    searchParams.set(`amSelectedOperators`, 'all');
  } else if (Array.isArray(selectedOperators)) {
    if (selectedOperators.length === lookupOperators.length) {
      searchParams.set(`amSelectedOperators`, 'all');
    } else {
      searchParams.set(`amSelectedOperators`, selectedOperators?.map(({ id }) => id).join(','));
    }
  }

  setSearchParams(searchParams);
};

const initUrlParams = (setTempSelectedDate, activityMapFilters, lookupOperators, searchParams) => {
  let newFilters = { ...activityMapFilters };

  newFilters = getStandardValuesFromUri(newFilters, ACTIVITY_MAP_SLICE_KEYS, 2, searchParams);
  const oprs = getSelectedOperatorsFromUri(
    'amSelectedOperators',
    null,
    lookupOperators,
    searchParams
  );
  if (oprs) {
    newFilters.selectedOperators = oprs;
  } else {
    if (newFilters.selectedOperators === 'all') {
      newFilters.selectedOperators = lookupOperators;
    }
  }
  setTempSelectedDate(newFilters.selectedDate);
  return newFilters;
};

function SectionFilters({ setLoading, setActivityData, isActive }) {
  const [init, setInit] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    date: null,
    vesselSize: null,
    minMaxLifting: null
  });
  const [updateData, setUpdateData] = useState(true);
  const [tempSelectedDate, setTempSelectedDate] = useState(moment().format(DATE_FORMAT));
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const { segment, isRoRo, segmentMinDate } = useSegment();
  const activityMapFilters = useSelector(getActivityMapFilters);
  const lookupOperators = useSelector(getLookupOperators);
  const [searchParams, setSearchParams] = useSearchParams();
  const abortControllerRef = useRef(null);

  const setFiltersToState = useCallback(() => {
    let newFilters = {
      ...filters
    };
    newFilters.selectedDate = tempSelectedDate;
    newFilters.selectedOperators =
      filters.selectedOperators?.length === lookupOperators.length
        ? 'all'
        : filters.selectedOperators;
    setUrlParams(newFilters, lookupOperators, searchParams, setSearchParams);

    dispatch(updateActivityMapFilters(newFilters));
  }, [filters, tempSelectedDate, lookupOperators, searchParams, setSearchParams, dispatch]);

  const handleFilterChange = useCallback(
    (val) => {
      setFilters({ ...filters, ...val });
    },
    [filters]
  );

  const fetchData = useCallback(async () => {
    if (!isActive) return;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      const {
        vesselSizeMin,
        selectedOperators,
        vesselSizeMax,
        minLiftingCapacity,
        maxLiftingCapacity
      } = filters;
      if (!activityMapCheckValidFilters(filters, tempSelectedDate, inputErrors, setInputErrors)) {
        return;
      }
      if (selectedOperators?.length === 0) {
        setActivityData(null);
        return;
      }
      setLoading(true);

      const result = await getRegionActivityByOperators(
        moment(tempSelectedDate, DATE_FORMAT).subtract(1, 'month').format(DATE_FORMAT),
        moment(tempSelectedDate, DATE_FORMAT).format(DATE_FORMAT),
        selectedOperators.map(({ id }) => id),
        vesselSizeMin,
        vesselSizeMax,
        minLiftingCapacity,
        maxLiftingCapacity,
        segment.id,
        signal
      );

      if (!signal.aborted) {
        setActivityData(result);
        setFiltersToState();
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching data:', error);
        setActivityData(null);
      }
    } finally {
      if (!signal.aborted) {
        setLoading(false);
      }
    }
  }, [
    filters,
    tempSelectedDate,
    segment.id,
    setActivityData,
    setLoading,
    setFiltersToState,
    isActive
  ]);

  useEffect(() => {
    if (init && isActive) {
      fetchData();
    }

    return () => {
      if (abortControllerRef.current) {
        console.log('Unmount cancel request');
        abortControllerRef.current.abort();
      }
    };
  }, [init, fetchData, isActive]);

  useEffect(() => {
    if (lookupOperators?.length) {
      let tempFilters = initUrlParams(
        setTempSelectedDate,
        activityMapFilters,
        lookupOperators,
        searchParams
      );
      setFilters(tempFilters);
      setUrlParams(tempFilters, lookupOperators, searchParams, setSearchParams);

      setInit(true);
      setUpdateData(!updateData);
    }
  }, [lookupOperators]);

  if (!isActive) return null;

  const {
    vesselSizeMin,
    vesselSizeMax,
    minLiftingCapacity,
    maxLiftingCapacity,
    selectedOperators
  } = filters;
  return (
    <Grid
      item
      xs={3}
      sx={{
        pr: 2,
        mt: 2,
        borderRight: ({ palette }) => `3px solid ${palette.primary.main}`,
        zIndex: 2,
        boxShadow: 'rgb(44 121 173 / 46%) 2px 0px 3px 0px'
      }}>
      <Grid container spacing={2} sx={{ pr: 2 }}>
        <Grid item xs={12}>
          <Typography id={'filters-options'} variant={'h6'}>
            Filters / Options
          </Typography>
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <OperatorMultiSelect
            helperText={init && !selectedOperators.length ? 'No operators selected' : ''}
            error={init && !selectedOperators.length}
            placeholder={'Select Operators...'}
            selectedOperators={selectedOperators}
            operatorsList={lookupOperators || []}
            handleChange={(_, values) => {
              handleFilterChange({ selectedOperators: values });
            }}
          />
        </Grid>
        <Grid item container xs={12} spacing={2} className={'section-filter'}>
          <Grid item xs={6}>
            <TextField
              id={'min-vessel-size'}
              value={init ? vesselSizeMin : ''}
              label={
                init ? (
                  `Vessel Size ${isRoRo ? 'Capacity' : 'DWT'} (min)`
                ) : (
                  <CircularProgress size={25} />
                )
              }
              onChange={({ target }) => {
                if (target.value > -1) {
                  handleFilterChange({ vesselSizeMin: target.value });
                }
              }}
              type={'number'}
              fullWidth
              placeholder={'Min size...'}
            />
          </Grid>
          <Grid item xs={6} alignSelf={'flex-end'}>
            <TextField
              id={'max-vessel-size'}
              value={init ? vesselSizeMax : ''}
              label={
                init ? (
                  `Vessel Size ${isRoRo ? 'Capacity' : 'DWT'} (max)`
                ) : (
                  <CircularProgress size={25} />
                )
              }
              onChange={({ target }) => {
                if (target.value > -1) {
                  handleFilterChange({ vesselSizeMax: target.value });
                }
              }}
              type={'number'}
              fullWidth
              placeholder={'Max size...'}></TextField>
          </Grid>
          <Typography sx={{ pl: 2 }} color={'error'} variant={'caption'}>
            {inputErrors.vesselSize}
          </Typography>
        </Grid>
        {!isRoRo && (
          <Grid item container xs={12} spacing={2} className={'section-filter'}>
            <Grid item xs={6}>
              <TextField
                id={'min-vessel-size'}
                value={init ? minLiftingCapacity : ''}
                label={init ? 'Lifting Capacity (min)' : <CircularProgress size={25} />}
                onChange={({ target }) => {
                  handleFilterChange({ minLiftingCapacity: target.value });
                }}
                type={'number'}
                fullWidth
                placeholder={'Min size...'}
              />
            </Grid>
            <Grid item xs={6} alignSelf={'flex-end'}>
              <TextField
                id={'max-vessel-size'}
                value={init ? maxLiftingCapacity : ''}
                label={init ? 'Lifting Capacity (max)' : <CircularProgress size={25} />}
                onChange={({ target }) => {
                  handleFilterChange({ maxLiftingCapacity: target.value });
                }}
                type={'number'}
                fullWidth
                placeholder={'Max size...'}
              />
            </Grid>
            <Typography sx={{ pl: 2 }} color={'error'} variant={'caption'}>
              {inputErrors.minMaxLifting}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} className={'section-filter'}>
          {init ? (
            <DatePickerMobi
              sx={{
                inputProps: {
                  sx: { width: '100%' },
                  InputProps: {},
                  defaultValue: undefined,
                  value: moment(tempSelectedDate, DATE_FORMAT).format(DATE_FORMAT) || '-',
                  placeholder: 'Date...',
                  label: 'Date Up To'
                }
              }}
              selectedDate={moment(tempSelectedDate, DATE_FORMAT)}
              onClose={({ value }) => {
                setTempSelectedDate(moment(value, DATE_FORMAT).format(DATE_FORMAT));
              }}
              dateFormat={DATE_FORMAT}
              placeholder={'Date...'}
              minStartDate={segmentMinDate}
              disableFuture
            />
          ) : (
            <Skeleton width={'100%'} height={'5em'} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            id={'apply-filter-btn'}
            size={'large'}
            onClick={() => setUpdateData((prev) => !prev)}
            disableElevation
            fullWidth
            variant={'contained'}>
            Apply filters
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

SectionFilters.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setActivityData: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

SectionFilters.defaultProps = {};

export default SectionFilters;
