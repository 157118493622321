import React, { useEffect, useState } from 'react';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import { OperatorProfileSection } from '@components/Sections/ShipAnalytics';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getOperatorCiiRatingData,
  getOperatorPercentageCo2Emission,
  getOperatorProfile,
  getVesselsPositions,
  getLatestPortCalls,
  getOperatorIncomingPortCalls
} from '@api';
import { getNumVesselsInAgeRangePerQuarter } from '@api/Emissions';
import { COMMERCIAL_CATEGORY_TYPE, DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType, getSegment } from '@store/features';
import { CommercialCategoryTypeSelect } from '@components/Inputs';
import { useSegment } from '@hooks';

function OperatorProfilePage() {
  const [operatorData, setOperatorData] = useState(null);
  const [fleetAgeData, setFleetAgeData] = useState([]);
  const [vesselsData, setVesselsData] = useState(null);
  const [lastPortCalls, setLastPortCalls] = useState(null);
  const [hasCiiData, setHasCiiData] = useState(false);
  const [hasEmissionsData, setHasEmissionsData] = useState(false);
  const [oprUpcomingPortCallsData, setOprUpcomingPortCallsData] = useState(null);
  const [typeCategoryVesselsCount, setTypeCategoryVesselsCount] = useState({});
  const segment = useSelector(getSegment);
  const navigate = useNavigate();
  const params = useParams();
  const { isRoRo } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);

  const [loading, setLoading] = useState({
    overview: true,
    lastPortCall: true,
    vesselData: true,
    avgSpeedData: true,
    activityStatus: true,
    vesselAge: true
  });

  const updateLoading = (val) => {
    setLoading((prev) => ({ ...prev, ...val }));
  };

  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getOperatorData = async (operatorId) => {
      try {
        let apis = [
          getOperatorProfile(
            {
              OperatorId: operatorId,
              SegmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => {
              setOperatorData(result);
              updateLoading({ overview: false });
              getVesselsPositions(
                result.operatorVessels?.map(({ imo }) => imo),
                signal
              )
                .then((result) => {
                  updateLoading({ vesselData: false });
                  setVesselsData(result);
                })
                .catch(() => {
                  updateLoading({ vesselData: false });
                });
            })
            .catch(() => {
              updateLoading({ overview: false });
            }),
          getOperatorProfile(
            {
              OperatorId: operatorId,
              SegmentTypeId: segment.id,
              CommercialCategoryId: COMMERCIAL_CATEGORY_TYPE.ALL
            },
            signal
          ).then((result) => {
            setTypeCategoryVesselsCount(result?.typeCategoryVesselsCount);
          }),
          getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.ALL,
              segmentTypeId: segment.id,
              CommercialCategoryId: commercialCategoryType,
              lastPortCallType: 0,
              numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
              operators: [operatorId]
            },
            signal
          )
            .then((result) => {
              updateLoading({ lastPortCall: false });
              setLastPortCalls(result);
            })
            .catch(() => {
              updateLoading({ lastPortCall: false });
            }),
          getOperatorIncomingPortCalls(
            {
              OperatorId: operatorId,
              NumberOfIncomingEntries: DEFAULT_NUM_PORT_CALLS,
              SegmentTypeId: segment.id,
              OperatorTypeId: operatorType,
              CommercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => {
              setOprUpcomingPortCallsData(result);
            })
            .catch(() => {
              setOprUpcomingPortCallsData([]);
            }),
          getNumVesselsInAgeRangePerQuarter(
            {
              OperatorId: operatorId,
              SegmentTypeId: segment.id,
              OperatorTypeId: operatorType,
              CommercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => {
              updateLoading({ vesselAge: false });
              setFleetAgeData([...result]);
            })
            .catch(() => {
              updateLoading({ vesselAge: false });
            }),
          getOperatorCiiRatingData(
            {
              OperatorId: operatorId,
              SegmentTypeId: segment.id,
              OperatorTypeId: operatorType,
              CommercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then(() => {
              setHasCiiData(true);
            })
            .catch(() => {
              setHasCiiData(false);
            }),
          getOperatorPercentageCo2Emission(
            {
              OperatorId: operatorId,
              SegmentTypeId: segment.id,
              OperatorTypeId: operatorType,
              CommercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then(() => {
              setHasEmissionsData(true);
            })
            .catch(() => {
              setHasEmissionsData(false);
            })
        ];

        await Promise.all(apis);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getOperatorData(params.id);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, commercialCategoryType]);

  const { operatorLongName, operatorShortName } = operatorData || {};
  return (
    <Grid container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-1)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={!operatorData}
          title={`${operatorLongName} (${operatorShortName})`}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary">Operator Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      {!isRoRo && (
        <Grid item xs={12}>
          <CommercialCategoryTypeSelect typeCategoryVesselsCount={typeCategoryVesselsCount} />
        </Grid>
      )}

      <Grid item xs={12}>
        <OperatorProfileSection
          hasEmissionsData={hasEmissionsData}
          hasCiiData={hasCiiData}
          fleetAgeData={fleetAgeData}
          lastPortCalls={lastPortCalls}
          vesselsData={vesselsData}
          setLastPortCalls={setLastPortCalls}
          operatorData={operatorData}
          oprUpcomingPortCallsData={oprUpcomingPortCallsData}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

OperatorProfilePage.propTypes = {};

OperatorProfilePage.defaultProps = {};

export default OperatorProfilePage;
