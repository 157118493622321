import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@esgian/esgianui';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getDateFormat, getLookupRegions, getUser } from '@store/features';
import { useTheme } from '@hooks';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal';

function CanalTransitsTable({ loading, selectedDate, mockOprTransits, selectedGroups, timeFrame }) {
  const lookupRegions = useSelector(getLookupRegions);
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const dateFormat = useSelector(getDateFormat);

  const [modalData, setModalData] = useState(null);

  const data = useMemo(() => {
    if (!mockOprTransits) return [];

    if (selectedGroups.includes('Direction') && selectedGroups.includes('Operator')) {
      if (timeFrame == 'week') {
        const operatorDirectionData = {};

        mockOprTransits.forEach((operator) => {
          const { operatorLongName, oprWeeklyResults } = operator;
          oprWeeklyResults.forEach(({ eastBound, westBound, week }) => {
            if (!operatorDirectionData[operatorLongName]) {
              operatorDirectionData[operatorLongName] = {};
            }

            if (!operatorDirectionData[operatorLongName]['East Bound']) {
              operatorDirectionData[operatorLongName]['East Bound'] = {
                operator: operatorLongName,
                direction_to: 'East Bound',
                total: 0
              };
            }
            if (!operatorDirectionData[operatorLongName]['West Bound']) {
              operatorDirectionData[operatorLongName]['West Bound'] = {
                operator: operatorLongName,
                direction_to: 'West Bound',
                total: 0
              };
            }

            operatorDirectionData[operatorLongName]['East Bound'][`Week ${week}`] =
              (operatorDirectionData[operatorLongName]['East Bound'][`Week ${week}`] || 0) +
              eastBound;
            operatorDirectionData[operatorLongName]['East Bound'].total += eastBound;

            operatorDirectionData[operatorLongName]['West Bound'][`Week ${week}`] =
              (operatorDirectionData[operatorLongName]['West Bound'][`Week ${week}`] || 0) +
              westBound;
            operatorDirectionData[operatorLongName]['West Bound'].total += westBound;
          });
        });

        return Object.values(operatorDirectionData).flatMap(Object.values);
      } else if (timeFrame == 'month') {
        const operatorDirectionData = {};

        mockOprTransits.forEach((operator) => {
          const { operatorLongName, oprMonthlyResults } = operator;

          oprMonthlyResults.forEach(({ year, months }) => {
            months.forEach(({ start, eastBound, westBound }) => {
              const month = new Date(start).toLocaleString('default', {
                month: 'long',
                year: 'numeric'
              });

              if (!operatorDirectionData[operatorLongName]) {
                operatorDirectionData[operatorLongName] = {};
              }

              if (!operatorDirectionData[operatorLongName]['East Bound']) {
                operatorDirectionData[operatorLongName]['East Bound'] = {
                  operator: operatorLongName,
                  direction_to: 'East Bound',
                  total: 0
                };
              }
              if (!operatorDirectionData[operatorLongName]['West Bound']) {
                operatorDirectionData[operatorLongName]['West Bound'] = {
                  operator: operatorLongName,
                  direction_to: 'West Bound',
                  total: 0
                };
              }

              operatorDirectionData[operatorLongName]['East Bound'][month] =
                (operatorDirectionData[operatorLongName]['East Bound'][month] || 0) + eastBound;
              operatorDirectionData[operatorLongName]['East Bound'].total += eastBound;

              operatorDirectionData[operatorLongName]['West Bound'][month] =
                (operatorDirectionData[operatorLongName]['West Bound'][month] || 0) + westBound;
              operatorDirectionData[operatorLongName]['West Bound'].total += westBound;
            });
          });
        });

        return Object.values(operatorDirectionData).flatMap(Object.values);
      } else return [];
    } else if (selectedGroups[0] === 'Operator') {
      if (timeFrame == 'week') {
        const operatorData = mockOprTransits.reduce((acc, operator) => {
          const { operatorLongName, oprWeeklyResults } = operator;
          if (!acc[operatorLongName]) {
            acc[operatorLongName] = { operator: operatorLongName, total: 0 };
          }
          oprWeeklyResults.forEach(({ transitStats, week }) => {
            acc[operatorLongName][`Week ${week}`] = transitStats;
            acc[operatorLongName].total += transitStats;
          });
          return acc;
        }, {});

        return Object.values(operatorData);
      } else if (timeFrame == 'month') {
        const operatorData = mockOprTransits.reduce((acc, operator) => {
          const { operatorLongName, oprMonthlyResults } = operator;
          if (!acc[operatorLongName]) {
            acc[operatorLongName] = { operator: operatorLongName, total: 0 };
          }

          oprMonthlyResults.forEach(({ months }) => {
            months.forEach(({ start, transitStats }) => {
              const month = new Date(start).toLocaleString('default', {
                month: 'long',
                year: 'numeric'
              });
              if (!acc[operatorLongName][month]) {
                acc[operatorLongName][month] = 0;
              }
              acc[operatorLongName][month] += transitStats;
              acc[operatorLongName].total += transitStats;
            });
          });

          return acc;
        }, {});

        return Object.values(operatorData);
      } else return [];
    } else {
      if (timeFrame == 'week') {
        const weeks = [...new Set(mockOprTransits[0].oprWeeklyResults.map((stat) => stat.week))];
        const eastBoundRow = { direction: 'East Bound', total: 0 };
        const westBoundRow = { direction: 'West Bound', total: 0 };
        const totalRow = { direction: 'Total', total: 0 };
        const transitStatsRow = { direction: 'Transit Stats', total: 0 };

        weeks.forEach((week) => {
          eastBoundRow[`Week ${week}`] = 0;
          westBoundRow[`Week ${week}`] = 0;
          totalRow[`Week ${week}`] = 0;
          transitStatsRow[`Week ${week}`] = 0;
        });

        mockOprTransits[0].oprWeeklyResults.forEach((stat) => {
          eastBoundRow[`Week ${stat.week}`] += stat.eastBound;
          westBoundRow[`Week ${stat.week}`] += stat.westBound;
          totalRow[`Week ${stat.week}`] += stat.transitStats;
          transitStatsRow[`Week ${stat.week}`] += stat.transitStats;

          eastBoundRow.total += stat.eastBound;
          westBoundRow.total += stat.westBound;
          totalRow.total += stat.transitStats;
          transitStatsRow.total += stat.transitStats;
        });

        if (selectedGroups[0] === 'Direction') {
          return [eastBoundRow, westBoundRow];
        } else {
          return [transitStatsRow];
        }
      } else if (timeFrame == 'month') {
        // Extracting months from the data
        const months = [
          ...new Set(
            mockOprTransits[0].oprMonthlyResults.flatMap((result) =>
              result.months.map((monthStat) =>
                new Date(monthStat.start).toLocaleString('default', {
                  month: 'long',
                  year: 'numeric'
                })
              )
            )
          )
        ];

        const eastBoundRow = { direction: 'East Bound', total: 0 };
        const westBoundRow = { direction: 'West Bound', total: 0 };
        const totalRow = { direction: 'Total', total: 0 };
        const transitStatsRow = { direction: 'Transit Stats', total: 0 };

        // Initializing the months
        months.forEach((month) => {
          eastBoundRow[month] = 0;
          westBoundRow[month] = 0;
          totalRow[month] = 0;
          transitStatsRow[month] = 0;
        });

        mockOprTransits[0].oprMonthlyResults.forEach((result) => {
          result.months.forEach((monthStat) => {
            const month = new Date(monthStat.start).toLocaleString('default', {
              month: 'long',
              year: 'numeric'
            });

            eastBoundRow[month] += monthStat.eastBound;
            westBoundRow[month] += monthStat.westBound;
            totalRow[month] += monthStat.transitStats;
            transitStatsRow[month] += monthStat.transitStats;

            eastBoundRow.total += monthStat.eastBound;
            westBoundRow.total += monthStat.westBound;
            totalRow.total += monthStat.transitStats;
            transitStatsRow.total += monthStat.transitStats;
          });
        });

        if (selectedGroups[0] === 'Direction') {
          return [eastBoundRow, westBoundRow];
        } else {
          return [transitStatsRow];
        }
      } else return [];
    }
  }, [mockOprTransits, selectedGroups]);

  const tableBoxShadow = useMemo(() => {
    const {
      palette: {
        tables: {
          boxShadow: { insetRight, insetLeft }
        }
      }
    } = theme;
    return { insetRight, insetLeft };
  }, [theme]);

  const columns = useMemo(() => {
    if (!mockOprTransits?.length) return [];

    const weeks =
      selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
        ? [
            ...new Set(
              mockOprTransits.flatMap((operator) =>
                operator.oprWeeklyResults.map((result) => result.week)
              )
            )
          ]
        : selectedGroups[0] === 'Operator'
        ? [
            ...new Set(
              mockOprTransits.flatMap((operator) =>
                operator.oprWeeklyResults.map((result) => result.week)
              )
            )
          ]
        : [...new Set(mockOprTransits[0].oprWeeklyResults.map((stat) => stat.week))];

    const baseColumns = [
      {
        name:
          selectedGroups.length === 0
            ? 'week'
            : selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
            ? 'operator'
            : selectedGroups.includes('Direction')
            ? 'direction'
            : 'operator',
        label:
          selectedGroups.length === 0
            ? 'Week No'
            : selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
            ? 'Operator'
            : selectedGroups.includes('Direction')
            ? 'Direction'
            : 'Operator',
        options: {
          sort: false,
          sortThirdClickReset: true,
          setCellProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              zIndex: 1000,
              padding: '0px 0px 0px 16px'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              zIndex: 1000
            }
          }),
          customBodyRender: (value, tableMeta) => {
            if (selectedGroups.length === 0) {
              return 'No. of transits';
            }
            if (selectedGroups.includes('Direction') && selectedGroups.includes('Operator')) {
              return value;
            }
            if (selectedGroups.includes('Direction') && tableMeta.rowIndex === 0) {
              return 'Eastbound';
            }
            if (selectedGroups.includes('Direction') && tableMeta.rowIndex === 1) {
              return 'Westbound';
            }
            return value;
          }
        }
      }
    ];

    if (selectedGroups.includes('Direction') && selectedGroups.includes('Operator')) {
      baseColumns.push({
        name: 'direction_to',
        label: 'Direction To',
        options: {
          sort: false,
          sortThirdClickReset: true,
          setCellProps: () => ({
            style: {
              position: 'sticky',
              left: '75px',
              zIndex: 1000,
              padding: '0px 0px 0px 16px'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: '75px',
              zIndex: 1000
            }
          })
        }
      });
    }

    weeks.forEach((week) => {
      baseColumns.push({
        name: `Week ${week}`,
        label: `week ${week}`,
        options: {
          sort: false,
          sortThirdClickReset: true,
          customBodyRender: (value) =>
            value ? (
              <div onClick={() => setModalData(value)}>
                <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
                  {value}
                </Typography>
              </div>
            ) : (
              <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
                -
              </Typography>
            )
        }
      });
    });

    baseColumns.push({
      name: 'total',
      label: 'Total',
      options: {
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (value) => (value !== null ? <b>{value}</b> : '--')
      }
    });

    return baseColumns;
  }, [mockOprTransits, selectedGroups]);

  const columnsMonths = useMemo(() => {
    if (!mockOprTransits?.length) return [];

    const months =
      selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
        ? [
            ...new Set(
              mockOprTransits.flatMap((operator) =>
                operator.oprMonthlyResults.flatMap((result) =>
                  result.months.map((monthStat) =>
                    new Date(monthStat.start).toLocaleString('default', {
                      month: 'long',
                      year: 'numeric'
                    })
                  )
                )
              )
            )
          ]
        : selectedGroups[0] === 'Operator'
        ? [
            ...new Set(
              mockOprTransits.flatMap((operator) =>
                operator.oprMonthlyResults.flatMap((result) =>
                  result.months.map((monthStat) =>
                    new Date(monthStat.start).toLocaleString('default', {
                      month: 'long',
                      year: 'numeric'
                    })
                  )
                )
              )
            )
          ]
        : [
            ...new Set(
              mockOprTransits[0].oprMonthlyResults.flatMap((result) =>
                result.months.map((monthStat) =>
                  new Date(monthStat.start).toLocaleString('default', {
                    month: 'long',
                    year: 'numeric'
                  })
                )
              )
            )
          ];

    const baseColumns = [
      {
        name:
          selectedGroups.length === 0
            ? 'month'
            : selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
            ? 'operator'
            : selectedGroups.includes('Direction')
            ? 'direction'
            : 'operator',
        label:
          selectedGroups.length === 0
            ? 'Month'
            : selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
            ? 'Operator'
            : selectedGroups.includes('Direction')
            ? 'Direction'
            : 'Operator',
        options: {
          sort: false,
          sortThirdClickReset: true,
          setCellProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              zIndex: 1000,
              padding: '0px 0px 0px 16px'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              zIndex: 1000
            }
          }),
          customBodyRender: (value, tableMeta) => {
            if (selectedGroups.length === 0) {
              return 'No. of transits';
            }
            if (selectedGroups.includes('Direction') && selectedGroups.includes('Operator')) {
              return value;
            }
            if (selectedGroups.includes('Direction') && tableMeta.rowIndex === 0) {
              return 'Eastbound';
            }
            if (selectedGroups.includes('Direction') && tableMeta.rowIndex === 1) {
              return 'Westbound';
            }
            return value;
          }
        }
      }
    ];

    if (selectedGroups.includes('Direction') && selectedGroups.includes('Operator')) {
      baseColumns.push({
        name: 'direction_to',
        label: 'Direction To',
        options: {
          sort: false,
          sortThirdClickReset: true,
          setCellProps: () => ({
            style: {
              position: 'sticky',
              left: '75px',
              zIndex: 1000,
              padding: '0px 0px 0px 16px'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: '75px',
              zIndex: 1000
            }
          })
        }
      });
    }

    months.forEach((month) => {
      baseColumns.push({
        name: `${month}`,
        label: `${month}`,
        options: {
          sort: false,
          sortThirdClickReset: true,
          customBodyRender: (value) =>
            value ? (
              <div onClick={() => setModalData(value)}>
                <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
                  {value}
                </Typography>
              </div>
            ) : (
              <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
                -
              </Typography>
            )
        }
      });
    });

    baseColumns.push({
      name: 'total',
      label: 'Total',
      options: {
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (value) => (value !== null ? <b>{value}</b> : '--')
      }
    });

    return baseColumns;
  }, [mockOprTransits, selectedGroups]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: `Weekly-Canal-Transits${moment(selectedDate, dateFormat).format(dateFormat)}.csv`
      },
      setTableProps: () => ({
        id: 'weekly-canal-transits-table'
      }),
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name:
          selectedGroups.includes('Direction') && selectedGroups.includes('Operator')
            ? 'operator'
            : selectedGroups[0] === 'Operator'
            ? 'operator'
            : 'direction',
        direction: 'asc'
      },
      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          columnHeaderTooltip: (column) => {
            if (
              column.name === 'operator' ||
              column.name === 'direction' ||
              column.name === 'direction_to'
            ) {
              return column.label;
            }
            return `${column.label}`;
          }
        }
      }
    };
  }, [user, selectedDate, tableBoxShadow, data, mockOprTransits, selectedGroups, columns]);

  const updatedColumData = timeFrame == 'week' ? columns : columnsMonths;

  return (
    <TableContainer>
      <Table
        skeletonRows={5}
        loading={loading}
        mode={theme.mode}
        title={<Typography id={'vessels-region-opr-title'} variant={'h6'}></Typography>}
        data={data}
        columns={updatedColumData}
        options={options}>
        <TableHead>
          <TableRow>
            <TableCell>Years</TableCell>
            {updatedColumData.slice(1).map((col) => (
              <TableCell key={`year-${col.name}`} colSpan={1}>
                2024
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {updatedColumData.map((col) => (
              <TableCell key={`header-${col.name}`}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={`row-${rowIndex}`}>
              {updatedColumData.map((col, colIndex) => (
                <TableCell key={`cell-${rowIndex}-${colIndex}`}>{row[col.name]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <UnderlyingCanalStatsModal
        modalData={modalData}
        handleClose={() => setModalData(null)}></UnderlyingCanalStatsModal>
    </TableContainer>
  );
}

CanalTransitsTable.propTypes = {
  mockOprTransits: PropTypes.array,
  loading: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedGroups: PropTypes.array
};

CanalTransitsTable.defaultProps = {
  mockOprTransits: null,
  selectedDate: null,
  loading: false,
  selectedGroups: []
};

export default CanalTransitsTable;

// import React, { useMemo, useState } from "react";
// import { useSelector } from "react-redux";
// import { useTheme } from "@hooks";
// import {
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   TableContainer,
// } from "@mui/material";
// import UnderlyingCanalStatsModal from "@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal";
// import moment from "moment";

// function CanalTransitsTable({
//   loading,
//   selectedDate,
//   mockOprTransits,
//   selectedGroups,
//   timeFrame,
// }) {
//   const lookupRegions = useSelector((state) => state.lookupRegions);
//   const { theme } = useTheme();
//   const user = useSelector((state) => state.user);
//   const dateFormat = useSelector((state) => state.dateFormat);

//   const [modalData, setModalData] = useState(null);

//   const data = useMemo(() => {
//     if (!mockOprTransits) return [];

//     const operatorDirectionData = {};
//     const isDirectionOperator = selectedGroups.includes("Direction") && selectedGroups.includes("Operator");
//     const isOperator = selectedGroups.includes("Operator");

//     mockOprTransits.forEach((operator) => {
//       const { operatorLongName, oprWeeklyResults, oprMonthlyResults } = operator;
//       const results = timeFrame === "week" ? oprWeeklyResults : oprMonthlyResults;

//       results.forEach((result) => {
//         const period = timeFrame === "week" ? `Week ${result.week}` : new Date(result.start).toLocaleString("default", {
//           month: "long",
//           year: "numeric",
//         });

//         if (!operatorDirectionData[operatorLongName]) {
//           operatorDirectionData[operatorLongName] = {};
//         }

//         if (isDirectionOperator) {
//           const { eastBound, westBound } = result;

//           if (!operatorDirectionData[operatorLongName]["East Bound"]) {
//             operatorDirectionData[operatorLongName]["East Bound"] = {
//               operator: operatorLongName,
//               direction_to: "East Bound",
//               total: 0,
//             };
//           }

//           if (!operatorDirectionData[operatorLongName]["West Bound"]) {
//             operatorDirectionData[operatorLongName]["West Bound"] = {
//               operator: operatorLongName,
//               direction_to: "West Bound",
//               total: 0,
//             };
//           }

//           operatorDirectionData[operatorLongName]["East Bound"][period] =
//             (operatorDirectionData[operatorLongName]["East Bound"][period] || 0) + eastBound;
//           operatorDirectionData[operatorLongName]["East Bound"].total += eastBound;

//           operatorDirectionData[operatorLongName]["West Bound"][period] =
//             (operatorDirectionData[operatorLongName]["West Bound"][period] || 0) + westBound;
//           operatorDirectionData[operatorLongName]["West Bound"].total += westBound;

//         } else if (isOperator) {
//           if (!operatorDirectionData[operatorLongName][period]) {
//             operatorDirectionData[operatorLongName][period] = 0;
//           }
//           operatorDirectionData[operatorLongName][period] += result.transitStats;
//           operatorDirectionData[operatorLongName].total += result.transitStats;
//         } else {
//           const { eastBound, westBound, transitStats } = result;

//           if (!operatorDirectionData["East Bound"]) {
//             operatorDirectionData["East Bound"] = {
//               direction: "East Bound",
//               total: 0,
//             };
//           }
//           if (!operatorDirectionData["West Bound"]) {
//             operatorDirectionData["West Bound"] = {
//               direction: "West Bound",
//               total: 0,
//             };
//           }
//           if (!operatorDirectionData["Total"]) {
//             operatorDirectionData["Total"] = {
//               direction: "Total",
//               total: 0,
//             };
//           }

//           operatorDirectionData["East Bound"][period] = (operatorDirectionData["East Bound"][period] || 0) + eastBound;
//           operatorDirectionData["West Bound"][period] = (operatorDirectionData["West Bound"][period] || 0) + westBound;
//           operatorDirectionData["Total"][period] = (operatorDirectionData["Total"][period] || 0) + transitStats;

//           operatorDirectionData["East Bound"].total += eastBound;
//           operatorDirectionData["West Bound"].total += westBound;
//           operatorDirectionData["Total"].total += transitStats;
//         }
//       });
//     });

//     return Object.values(operatorDirectionData).flatMap(Object.values);
//   }, [mockOprTransits, selectedGroups, timeFrame]);

//   const tableBoxShadow = useMemo(() => {
//     const {
//       palette: {
//         tables: {
//           boxShadow: { insetRight, insetLeft },
//         },
//       },
//     } = theme;
//     return { insetRight, insetLeft };
//   }, [theme]);

//   const columns = useMemo(() => {
//     if (!mockOprTransits?.length) return [];

//     const baseColumns = [
//       {
//         name:
//           selectedGroups.length === 0
//             ? "week"
//             : selectedGroups.includes("Direction") && selectedGroups.includes("Operator")
//             ? "operator"
//             : selectedGroups.includes("Direction")
//             ? "direction"
//             : "operator",
//         label:
//           selectedGroups.length === 0
//             ? "Week No"
//             : selectedGroups.includes("Direction") && selectedGroups.includes("Operator")
//             ? "Operator"
//             : selectedGroups.includes("Direction")
//             ? "Direction"
//             : "Operator",
//         options: {
//           sort: false,
//           sortThirdClickReset: true,
//           setCellProps: () => ({
//             style: {
//               position: "sticky",
//               left: 0,
//               zIndex: 1000,
//               padding: "0px 0px 0px 16px",
//             },
//           }),
//           setCellHeaderProps: () => ({
//             style: {
//               position: "sticky",
//               left: 0,
//               zIndex: 1000,
//             },
//           }),
//           customBodyRender: (value, tableMeta) => {
//             if (selectedGroups.length === 0) {
//               return "No. of transits";
//             }
//             if (selectedGroups.includes("Direction") && selectedGroups.includes("Operator")) {
//               return value;
//             }
//             if (selectedGroups.includes("Direction") && tableMeta.rowIndex === 0) {
//               return "Eastbound";
//             }
//             if (selectedGroups.includes("Direction") && tableMeta.rowIndex === 1) {
//               return "Westbound";
//             }
//             return value;
//           },
//         },
//       },
//     ];

//     if (timeFrame === "week") {
//       const weeks = [
//         ...new Set(
//           mockOprTransits.flatMap((operator) =>
//             operator.oprWeeklyResults.map((result) => result.week)
//           )
//         ),
//       ];
//       weeks.forEach((week) => {
//         baseColumns.push({
//           name: `Week ${week}`,
//           label: `Week ${week}`,
//           options: {
//             sort: false,
//           },
//         });
//       });
//     } else if (timeFrame === "month") {
//       const months = [
//         ...new Set(
//           mockOprTransits.flatMap((operator) =>
//             operator.oprMonthlyResults.flatMap((result) =>
//               result.months.map((monthStat) =>
//                 new Date(monthStat.start).toLocaleString("default", {
//                   month: "long",
//                   year: "numeric",
//                 })
//               )
//             )
//           )
//         ),
//       ];
//       months.forEach((month) => {
//         baseColumns.push({
//           name: month,
//           label: month,
//           options: {
//             sort: false,
//           },
//         });
//       });
//     }

//     return baseColumns;
//   }, [mockOprTransits, selectedGroups, timeFrame]);

//   return (
//     <TableContainer>
//       <Table>
//         <TableHead>
//           <TableRow>
//             {columns.map((column) => (
//               <TableCell key={column.name}>{column.label}</TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map((row, index) => (
//             <TableRow key={index}>
//               {columns.map((column) => (
//                 <TableCell key={column.name}>{row[column.name]}</TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//       {modalData && <UnderlyingCanalStatsModal data={modalData} onClose={() => setModalData(null)} />}
//     </TableContainer>
//   );
// }

// export default CanalTransitsTable;
