import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DeleteOutlineIcon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Popover,
  Typography
} from '@esgian/esgianui';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useParams, useSearchParams } from 'react-router-dom';
import { useVoyageGroups } from '@pages/VoyageAnalytics/hooks/useVoyageGroups';
import VoyageGroupListPopup from './VoyageGroupListPopup';
import PropTypes from 'prop-types';
import AddToVoyageGroupPopup from './AddToVoyageGroupPopup';
import CreateVoyagePopup from './CreateVoyagePopup';
import { useGetVoyageType } from '../../../../pages/VoyageAnalytics/hooks/useGetVoyageType';
import { VoyageType } from '../VoyageUtils';
import FavoriteIcon from '../../../Icons/FavoriteIcon';

export const NUM_MAX_FAVORITE_GROUP = 1;

const VoyageGroupsPopup = ({ mode }) => {
  const isSearchPage = mode === 'search';

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenManageModal, setIsOpenManageModal] = useState(false);
  const [voyageGroupName, setVoyageGroupName] = useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [voyageGroupToEdit, setVoyageGroupToEdit] = useState();
  const [renamedVoyageGroupName, setRenamedVoyageGroupName] = useState('');

  const params = useParams();

  const {
    ongoingVoyagesGroups,
    completedVoyagesGroups,
    isLoadingVoyageGroups,
    deleteVoyageGroup,
    isDeletingVoyageGroup,
    createVoyageGroup,
    isCreatingVoyageGroup,
    renameVoyageGroup,
    isRenamingVoyageGroup,
    addVoyagesToGroup,
    isAddingVoyagesToGroup,
    updateVoyageGroups,
    isUpdatingVoyageGroups
  } = useVoyageGroups();
  const [searchParams] = useSearchParams();
  const [updatedGroupPerId, setUpdatedGroupPerId] = useState();

  const voyageGroupType = useGetVoyageType();

  const fromPortIdParam = searchParams.get('fromPortId');
  const toPortIdParam = searchParams.get('toPortId');
  const departureDateParam = searchParams.get('departureDate');
  const arrivalDateParam = searchParams.get('arrivalDate');

  const voyageIdentity = {
    imo: params.imo,
    fromPortId: fromPortIdParam === 'null' ? null : fromPortIdParam,
    toPortId: toPortIdParam === 'null' ? null : toPortIdParam,
    departureDate: departureDateParam,
    arrivalDate: arrivalDateParam
  };

  const isDisabledManageModalActions = isDeletingVoyageGroup || isUpdatingVoyageGroups;

  const filteredGroups =
    voyageGroupType === VoyageType.ONGOING ? ongoingVoyagesGroups : completedVoyagesGroups;

  useEffect(() => {
    if (!filteredGroups) return;
    const groupPerId = filteredGroups.reduce((acc, group) => {
      acc[group.id] = group;
      return acc;
    }, {});
    setUpdatedGroupPerId(groupPerId);
  }, [filteredGroups]);

  const isMaxFavoriteGroupSelected =
    !updatedGroupPerId ||
    Object.values(updatedGroupPerId).filter((v) => v.isFavorite).length >= NUM_MAX_FAVORITE_GROUP;

  return (
    <>
      <Box>
        <Button
          onClick={({ currentTarget }) => {
            setAnchorEl(currentTarget);
          }}
          variant="text"
          sx={{
            height: 'fit-content',
            size: '0.875rem',
            textTransform: 'none'
          }}
          startIcon={<FavoriteBorderIcon sx={{ fontSize: 16 }} />}>
          <Typography variant="body2">
            {isSearchPage ? `MY VOYAGE GROUP` : 'SAVE TO GROUP'}
          </Typography>
        </Button>
        <Popover
          onClose={() => {
            setAnchorEl(undefined);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}>
          {isSearchPage ? (
            <VoyageGroupListPopup
              setModalOpen={setModalOpen}
              savedGroups={filteredGroups}
              isSavingVoyageToGroup={isAddingVoyagesToGroup}
              isLoadingVoyageGroups={isLoadingVoyageGroups}
              setIsOpenManageModal={setIsOpenManageModal}
              voyageGroupType={voyageGroupType}
            />
          ) : (
            <AddToVoyageGroupPopup
              onCreateANewGroup={() => setModalOpen(true)}
              savedGroups={filteredGroups}
              onAddVoyageToGroup={(groupId) => {
                addVoyagesToGroup({ voyageGroupId: groupId, voyagesToAdd: [voyageIdentity] });
              }}
              isSavingVoyageToGroup={isAddingVoyagesToGroup}
              isLoadingVoyageGroups={isLoadingVoyageGroups}
              voyageGroupType={voyageGroupType}
            />
          )}
        </Popover>
      </Box>
      <CreateVoyagePopup
        open={modalOpen || voyageGroupToEdit}
        setModalOpen={setModalOpen}
        onChange={voyageGroupToEdit ? setRenamedVoyageGroupName : setVoyageGroupName}
        existingGroups={filteredGroups}
        isSubmitDisabled={isCreatingVoyageGroup || isRenamingVoyageGroup}
        onSubmit={() => {
          if (voyageGroupToEdit) {
            renameVoyageGroup({
              newName: renamedVoyageGroupName,
              voyageGroupToRename: voyageGroupToEdit
            }).then(() => {
              setVoyageGroupToEdit(undefined);
              setRenamedVoyageGroupName('');
            });
          } else {
            createVoyageGroup({ newVoyageGroupName: voyageGroupName, type: voyageGroupType }).then(
              () => {
                setVoyageGroupName('');
                setModalOpen(false);
              }
            );
          }
        }}
        voyageGroupToEdit={voyageGroupToEdit}
        setVoyageGroupToEdit={setVoyageGroupToEdit}
        onClose={() => {
          if (voyageGroupToEdit) setRenamedVoyageGroupName('');
          else setVoyageGroupName('');
        }}
      />
      <Modal
        title="Manage my voyage group"
        open={isOpenManageModal && filteredGroups.length > 0}
        handleClose={() => setIsOpenManageModal(false)}>
        <List dense>
          {filteredGroups.map((v) => {
            const isFavoriteGroup = updatedGroupPerId?.[v.id]?.isFavorite;
            const isDisableAddToFavorite = !isFavoriteGroup && isMaxFavoriteGroupSelected;
            return (
              <ListItem
                disabled={isDisabledManageModalActions}
                key={v.id}
                secondaryAction={
                  <Box display="flex">
                    <Button
                      size={'small'}
                      variant="text"
                      onClick={() => {
                        setRenamedVoyageGroupName(v.name);
                        setVoyageGroupToEdit(v);
                      }}>
                      Edit
                    </Button>
                    <IconButton
                      size={'small'}
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        deleteVoyageGroup(v);
                      }}>
                      <DeleteOutlineIcon fontSize={'small'} />
                    </IconButton>
                  </Box>
                }>
                <Box display="flex" gap={1} alignItems="center">
                  {updatedGroupPerId && (
                    <IconButton sx={{ p: 0 }}>
                      <FavoriteIcon
                        isFavorite={isFavoriteGroup}
                        onToggle={(isFavorite) => {
                          setUpdatedGroupPerId({
                            ...updatedGroupPerId,
                            [v.id]: {
                              ...updatedGroupPerId[v.id],
                              isFavorite
                            }
                          });
                        }}
                        disabled={isDisableAddToFavorite}
                        disabledMessage={`Max ${NUM_MAX_FAVORITE_GROUP} favorite groups allowed`}
                      />
                    </IconButton>
                  )}
                  <ListItemText
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    primary={v.name}
                  />
                </Box>
              </ListItem>
            );
          })}
        </List>
        <Box display="flex" gap={2} justifyContent="center" alignItems="center">
          <Button
            disabled={isDisabledManageModalActions}
            variant="text"
            onClick={() => setIsOpenManageModal(false)}>
            Close
          </Button>
          <Button
            disabled={isDisabledManageModalActions}
            onClick={async () => {
              await updateVoyageGroups({ updatedGroups: Object.values(updatedGroupPerId) });
              setIsOpenManageModal(false);
            }}
            style={{ width: '100px' }}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

VoyageGroupsPopup.defaultProps = {
  mode: 'search'
};

VoyageGroupsPopup.propTypes = {
  mode: PropTypes.string
};

export default VoyageGroupsPopup;
