import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText
} from '@esgian/esgianui';

function GroupSelect({ isRequired, label, placeholder, groupList, handleChange }) {
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleGroupChange = (event) => {
    const value = event.target.value;
    setSelectedGroups(value);
    handleChange(value);
  };

  let displayLabel = isRequired ? `${label}` : label;

  return (
    <FormControl fullWidth>
      <InputLabel>{displayLabel}</InputLabel>
      <Select
        multiple
        value={selectedGroups}
        onChange={handleGroupChange}
        displayEmpty
        renderValue={(selected) =>
          selected.length === 0 ? <em>{placeholder}</em> : selected.join(', ')
        }>
        {groupList.map((group) => (
          <MenuItem key={group.value} value={group.label}>
            <Checkbox checked={selectedGroups.indexOf(group.label) > -1} />
            <ListItemText primary={group.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

GroupSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  groupList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired
};

GroupSelect.defaultProps = {
  placeholder: '',
  label: 'Group*'
};

export default GroupSelect;
