import { Paper, Stack, Typography } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import React from 'react';
import { getGeneralSlice } from '@store/features';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getValueUptoTwoDecimalPlaces } from '../VoyageSummaryRouteMap/utils';

const StatusIndicatorDot = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

StatusIndicatorDot.propTypes = {
  color: PropTypes.string
};

const VoyageSummaryOverviewFloatingWindow = ({
  summaryOverview,
  voyageTransitDetails,
  isOngoingVoyage
}) => {
  const { numPortsVisited, departurePort, arrivalPort } = summaryOverview ?? {};
  const { themeMode } = useSelector(getGeneralSlice);

  const lastReportDate =
    voyageTransitDetails?.transitDetails?.[voyageTransitDetails?.transitDetails?.length - 1]
      ?.timeStamp;

  return (
    <Paper
      sx={{
        zIndex: 20,
        width: '20rem',
        background: themeMode ? 'rgba(255, 255, 255, 0.80)' : 'rgba(0, 0, 0, 0.80)',
        p: '0.5rem 0rem'
      }}>
      <Stack pl={2} pr={2} spacing={1}>
        {!isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'} color="primary.contrastText">
              <StatusIndicatorDot color={'#0E9EFF'} /> Origin:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {departurePort}
            </Typography>
          </Stack>
        )}
        {!isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'} color="primary.contrastText">
              <StatusIndicatorDot color={'#FF9800'} /> Destination:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {arrivalPort}
            </Typography>
          </Stack>
        )}
        {!isOngoingVoyage && (
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'} color="primary.contrastText">
              Transit Time:
            </Typography>
            <Typography variant={'body2'} color="primary.contrastText">
              {getValueUptoTwoDecimalPlaces(voyageTransitDetails?.transitDays)} days
            </Typography>
          </Stack>
        )}
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} color="primary.contrastText">
            {!isOngoingVoyage ? 'Intermediate ' : ''}Port calls:
          </Typography>
          <Typography variant={'body2'} color="primary.contrastText">
            {numPortsVisited}
          </Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} color="primary.contrastText">
            {isOngoingVoyage ? 'Last reported at:' : 'Distance (NM):'}
          </Typography>
          <Typography variant={'body2'} color="primary.contrastText">
            {isOngoingVoyage
              ? lastReportDate
                ? moment.utc(lastReportDate).format('YYYY-MM-DD HH:mm:ss')
                : null
              : getValueUptoTwoDecimalPlaces(voyageTransitDetails?.totalDistance)}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default VoyageSummaryOverviewFloatingWindow;

VoyageSummaryOverviewFloatingWindow.propTypes = {
  summaryOverview: PropTypes.object,
  voyageTransitDetails: PropTypes.object.isRequired,
  voyagePortCalls: PropTypes.object.isRequired,
  isOngoingVoyage: PropTypes.bool
};
