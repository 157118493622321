import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getYaxis = (max, avgColor, maxAverageAge) => {
  let axis = [];
  let first = true;
  for (let i = 0; i < 6; i++) {
    axis.push({
      show: first,
      max: max,
      forceNiceScale: true,
      tickAmount: 0,
      decimalsInFloat: 0,
      title: {
        text: 'Number of vessels'
      }
    });
    first = false;
  }
  axis.push({
    opposite: true,
    tickAmount: 10,
    seriesName: 'Average Age',
    min: 0,
    max: maxAverageAge * 1.25,
    forceNiceScale: true,
    decimalsInFloat: 0,
    labels: {
      style: {
        colors: avgColor
      }
    },
    title: {
      style: {
        color: avgColor
      },
      text: 'Average Age'
    }
  });
  return axis;
};

const getOptions = (
  categories,
  theme,
  user,
  max,
  fileName,
  totals,
  maxAverageAge,
  vesselsAdded,
  vesselsRemoved,
  showForSingleSeries
) => {
  const {
    palette: {
      mode,
      text: { primary },
      charts: { background, rowColors, twoColorsContrast, tenColorsSequential }
    }
  } = theme;
  return {
    chart: {
      type: 'line',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: true
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3, 4, 5]
    },
    stroke: {
      width: [0, 0, 0, 0, 0, 0, 2],
      dashArray: [0, 0, 0, 0, 0, 0, 5]
    },
    colors: [
      tenColorsSequential[8],
      tenColorsSequential[6],
      tenColorsSequential[4],
      tenColorsSequential[3],
      tenColorsSequential[2],
      tenColorsSequential[1],
      twoColorsContrast[0]
    ],
    theme: {
      mode: mode
    },
    annotations: {
      points: totals?.map((item, i) => {
        return {
          x: categories[i],
          y: item,
          marker: {
            size: 0,
            radius: 0
          },
          label: {
            borderColor: 'transparent',
            offsetY: 0,
            style: {
              color: primary,
              background: 'transparent'
            },
            text: `Total: ${item}`
          }
        };
      })
    },

    yaxis: getYaxis(max, twoColorsContrast[0], maxAverageAge),
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'center',
          total: {
            label: { color: 'primary', background: 'transparent' },
            style: {
              fontsize: '10px'
            }
          }
        },
        background: {
          enabled: false
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: ({ dataPointIndex, w }) => {
        const vesselInNames =
          vesselsAdded[dataPointIndex]?.map((vessel) => vessel.vesselName).join('<br />') || '--';

        const vesselOutNames =
          vesselsRemoved[dataPointIndex]?.map((vessel) => vessel.vesselName).join('<br/>') || '--';

        const defaultTooltip = w.globals.series
          .map((s, idx) => {
            return `
            <div style="display: flex; align-items: center;">
              <span style="background-color: ${w.globals.colors[idx]}; width: 10px; height: 10px; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
              <span style="color: ${w.globals.colors[idx]}">${w.globals.seriesNames[idx]}: ${s[dataPointIndex]}</span>
            </div>
          `;
          })
          .join('');

        return `
      <div style="padding: 4px;">
        <strong>${categories[dataPointIndex]}</strong><br/>
        ${defaultTooltip}
        ${
          showForSingleSeries
            ? `
            <hr/>
            <strong>Vessels Added:</strong><br />
            ${vesselInNames}<br />
            <strong>Vessels Removed:</strong><br />
            ${vesselOutNames}
          `
            : ''
        }
      </div>
    `;
      }
    },
    legend: {
      position: 'top',
      showForSingleSeries: true,
      horizontalAlign: 'left'
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};

function OperatorFleetAgeChart({
  loading,
  fleetAgeCeuData,
  fileName,
  totalCountVessel,
  vesselsAdded,
  vesselsRemoved,
  categories,
  showForSingleSeries
}) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [totals, setTotals] = useState([]);
  const [maxAverageAge, setMaxAverageAge] = useState(0);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!fleetAgeCeuData?.length) return;
    setSeries(null);
    let totals = new Array(categories.length - 1).fill(0);
    let maxAvgAge = 0;

    setSeries([
      {
        name: '0-5',
        type: 'bar',
        data:
          fleetAgeCeuData?.slice(1).map(({ vesselsAgeRange0To5 }, i) => {
            totals[i] += vesselsAgeRange0To5 || 0;
            return vesselsAgeRange0To5;
          }) || []
      },
      {
        name: '6-10',
        type: 'bar',
        data:
          fleetAgeCeuData?.slice(1).map(({ vesselsAgeRange6To10 }, i) => {
            totals[i] += vesselsAgeRange6To10 || 0;
            return vesselsAgeRange6To10;
          }) || []
      },
      {
        name: '11-15',
        type: 'bar',
        data:
          fleetAgeCeuData?.slice(1).map(({ vesselsAgeRange11To15 }, i) => {
            totals[i] += vesselsAgeRange11To15 || 0;
            return vesselsAgeRange11To15;
          }) || []
      },
      {
        name: '16-20',
        type: 'bar',
        data:
          fleetAgeCeuData?.slice(1).map(({ vesselsAgeRange16To20 }, i) => {
            totals[i] += vesselsAgeRange16To20 || 0;
            return vesselsAgeRange16To20;
          }) || []
      },
      {
        name: '21-25',
        type: 'bar',
        data:
          fleetAgeCeuData?.slice(1).map(({ vesselsAgeRange21To25 }, i) => {
            totals[i] += vesselsAgeRange21To25 || 0;
            return vesselsAgeRange21To25;
          }) || []
      },
      {
        name: '25+',
        type: 'bar',
        data:
          fleetAgeCeuData?.slice(1).map(({ vesselsAgeRangeGreaterThan25 }, i) => {
            totals[i] += vesselsAgeRangeGreaterThan25 || 0;
            return vesselsAgeRangeGreaterThan25;
          }) || []
      },
      {
        name: 'Average Age',
        type: 'line',
        data:
          fleetAgeCeuData?.slice(1).map(({ averageAge }) => {
            maxAvgAge = Math.max(maxAvgAge, averageAge || 0);
            return averageAge?.toFixed(1);
          }) || []
      }
    ]);

    setTotals(totals);
    setMaxAverageAge(maxAvgAge);
  }, [fleetAgeCeuData]);

  useEffect(() => {
    if (!fleetAgeCeuData?.length) return;
    setOptions(null);
    const maxVesselCount = Math.max(...totalCountVessel.slice(1));
    const max = maxVesselCount * 1.4;

    setOptions(
      getOptions(
        categories.slice(1), //skip first month
        theme,
        user,
        max,
        fileName,
        totals,
        maxAverageAge,
        vesselsAdded.slice(1),
        vesselsRemoved.slice(1),
        showForSingleSeries
      )
    );
  }, [theme, series, categories, maxAverageAge]);

  return (
    <ApexChart
      loading={loading}
      height={480}
      options={
        !options || !series
          ? {}
          : {
              ...options,
              xaxis: {
                categories: categories.slice(1),
                forceNiceScale: true,
                labels: { rotateAlways: true },
                title: {
                  text: ''
                }
              }
            }
      }
      type={'line'}
      data={!options || !series ? [] : series}
    />
  );
}
OperatorFleetAgeChart.propTypes = {
  loading: PropTypes.bool,
  fleetAgeCeuData: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string.isRequired,
  totalCountVessel: PropTypes.array,
  categories: PropTypes.array,
  vesselsAdded: PropTypes.array,
  vesselsRemoved: PropTypes.array
};
OperatorFleetAgeChart.defaultProps = {
  fleetAgeCeuData: [],
  loading: false,
  totalCountVessel: [],
  categories: [],
  vesselsRemoved: [],
  vesselsAdded: []
};
export default OperatorFleetAgeChart;
