import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableRow, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { checkUserHasAccess } from '@helpers';
import { ProfileLink } from '@components/Links';
import { useSelector } from 'react-redux';
import { getDateFormat, getLookupRegions, getUser } from '@store/features';
import { useTheme } from '@hooks';
function VesselsPerRegionTable({ loading, selectedDate, activityData }) {
  const lookupRegions = useSelector(getLookupRegions);
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const dateFormat = useSelector(getDateFormat);
  const data = useMemo(() => {
    if (!activityData) return [];
    let tempData = [];
    const operatorStats = activityData ? activityData.operatorStats : null;
    if (!operatorStats?.length) {
      return [];
    }
    operatorStats.forEach(({ operatorId, regionVisitsOperator, shortName }) => {
      let tot = 0;
      let notInRegionCount = 0;
      let obj = { operatorId: operatorId, operatorName: shortName };
      regionVisitsOperator.forEach(({ geoname, count }) => {
        let key = geoname.split(' ').join('_');
        if (key === 'NotInRegion') {
          notInRegionCount = count;
        } else {
          obj[key] = count;
          tot += count;
        }
      });
      obj['Not_In_Region'] = notInRegionCount;
      obj['total'] = tot + notInRegionCount;
      tempData.push(obj);
    });
    return tempData;
  }, [activityData]);

  const tableBoxShadow = useMemo(() => {
    const {
      palette: {
        tables: {
          boxShadow: { insetRight, insetLeft }
        }
      }
    } = theme;
    return { insetRight, insetLeft };
  }, [theme]);

  let columns = useMemo(() => {
    if (!lookupRegions?.length) return;
    const {
      palette: {
        tables: {
          boxShadow: { insetLeft }
        }
      }
    } = theme;
    const tempColumns = [
      {
        name: 'operatorName',
        label: 'Operator/Regions',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              padding: '0px 0px 0px 16px'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              zIndex: 101
            }
          }),
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} name={dataIndex} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false }
      }
    ];
    lookupRegions.map(({ name, shortName }) => {
      tempColumns.push({
        name: name.split(' ').join('_'),
        label: shortName,
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return (
              <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
                {dataIndex || '-'}
              </Typography>
            );
          }
        }
      });
    });

    tempColumns.push({
      name: 'Not_In_Region',
      label: 'Not In Region',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          return (
            <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
              {dataIndex || '-'}
            </Typography>
          );
        }
      }
    });

    tempColumns.push({
      name: 'total',
      label: 'Total',
      options: {
        sort: true,
        sortThirdClickReset: true,
        setCellProps: () => {
          return {
            style: {
              boxShadow: insetLeft
            }
          };
        },

        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            zIndex: 101
          }
        }),

        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? <b>{dataIndex}</b> : '--';
        }
      }
    });
    return tempColumns;
  }, [lookupRegions, theme]);

  const options = useMemo(() => {
    return {
      customTableBodyFooterRender: () => {
        if (!activityData) return;
        const dayStats = activityData;
        if (!dayStats) return;
        const { regionStats } = dayStats;
        let total = 0;
        let notInRegionTotal = 0;

        data.forEach((row) => {
          notInRegionTotal += row.Not_In_Region || 0;
        });
        return (
          <tbody>
            <TableRow
              key={`total-row-footer`}
              sx={{ backgroundColor: ({ palette }) => palette.background.default }}>
              <TableCell
                style={{
                  boxShadow: tableBoxShadow.insetRight,
                  position: 'sticky',
                  left: 0,
                  zIndex: 100
                }}>
                <b>Total</b>
              </TableCell>
              {lookupRegions?.map(({ regionId }) => {
                let region = regionStats.find(
                  ({ regionId: regionIdStats }) => regionIdStats === regionId
                );
                const { count } = region || {};
                total += count || 0;
                return (
                  <TableCell sx={{ pl: 3 }} key={`${regionId}-total-cell`}>
                    <Typography variant={'caption'} bold>
                      {count || 0}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell
                sx={{
                  boxShadow: ({ palette }) => palette.tables.boxShadow.insetRight,
                  pl: 3
                }}>
                <Typography variant={'caption'} bold>
                  {notInRegionTotal}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  boxShadow: ({ palette }) => palette.tables.boxShadow.insetRight
                }}>
                <Typography variant={'caption'} bold>
                  {total + notInRegionTotal}
                </Typography>
              </TableCell>
            </TableRow>
          </tbody>
        );
      },
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: `regional-activity-${moment(selectedDate, dateFormat).format(dateFormat)}.csv`
      },
      setTableProps: () => {
        return {
          id: 'operators-in-region-table'
        };
      },
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '80vh',
      tableBodyHeight: '80vh',
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'total',
        direction: 'desc'
      },
      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          columnHeaderTooltip: (column) => {
            if (column.name === 'total') {
              return 'Total';
            }
            if (column.name === 'operatorName') {
              return 'Operator Name';
            }
            return `${column.name.split('_').join(' ')}`;
          }
        }
      }
    };
  }, [user, selectedDate, activityData, tableBoxShadow, data, lookupRegions]);

  return (
    <Table
      skeletonRows={5}
      loading={loading}
      mode={theme.mode}
      title={
        <Typography id={'vessels-region-opr-title'} variant={'h6'}>
          Number of vessels in region by operator
        </Typography>
      }
      data={data}
      columns={columns}
      options={options}
    />
  );
}

VesselsPerRegionTable.propTypes = {
  activityData: PropTypes.object,
  loading: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedSlideDate: PropTypes.number
};

VesselsPerRegionTable.defaultProps = {
  activityData: null,
  selectedDate: null,
  loading: false,
  selectedSlideDate: 30
};

export default VesselsPerRegionTable;
