import React from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks/useTheme';
import { useSearchParams } from 'react-router-dom';
import { VoyageSummaryFilterKeys } from '../VoyageSummaryConstants';
import moment from 'moment';

function VoyageSummaryDraughtChart({
  loading,
  voyageTransitDetails,
  expectedDraught,
  isFullScreen,
  isOngoingVoyage
}) {
  const { theme, themeMode } = useTheme();

  const [searchParams] = useSearchParams();

  const selectedDate = searchParams.get(VoyageSummaryFilterKeys.SELECTED_DATE);
  const series = [
    {
      name: 'Draught (m)',
      data: voyageTransitDetails?.transitDetails?.map((item) => ({
        x: new Date(item.timeStamp),
        y: item.draught
      }))
    }
  ];

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      background: isOngoingVoyage ? '0' : theme.palette.charts.background
    },
    colors: ['#80DFEB'],

    stroke: {
      width: [2],
      curve: 'straight'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd'
      }
    },
    yaxis: {
      title: {
        text: ''
      },
      min: 0,
      max: 15
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left'
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return `
          <div style="padding: 8px 16px; width: 240px; background-color: ${
            themeMode ? 'rgba(255,255,255,0.80)' : 'rgba(0,0,0,0.80)'
          }; box-shadow: 0px 4px 4px 0px ${
          themeMode ? 'rgba(0,0,0,0.25)' : 'rgba(255,255,255,0.25)'
        }; color: ${themeMode ? 'black' : 'white'};">
            <div style="font-size: 10px; margin-bottom: 2px;">
              ${moment(data.x).format('YYYY-MM-DD HH:mm')}
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between; font-size: 14px;">
              <div>Draught (m)</div>
              <div>${data.y.toFixed(1)}</div>
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between; font-size: 14px;">
              <div>Desired draught (m)</div>
              <div>${expectedDraught.toFixed(1)}</div>
            </div>
          </div>
        `;
      }
    },
    dataLabels: {
      enabled: false
    },
    theme: {
      mode: theme.mode
    },
    toolbar: {
      show: false
    },
    annotations: {
      yaxis: [
        {
          y: expectedDraught,
          borderColor: '#FF9800',
          strokeDashArray: 5,
          label: {
            borderColor: '#000',
            style: { color: '#fff', background: '#000' },
            text: 'Maximum draught'
          }
        }
      ],
      xaxis: selectedDate
        ? [
            {
              x: selectedDate,
              borderColor: '#EF5350',
              strokeDashArray: 0
            }
          ]
        : []
    }
  };

  return (
    <Box>
      {isOngoingVoyage && <Typography variant={'h6'}>Draught</Typography>}
      <Box ml={isOngoingVoyage ? '-15px' : 0}>
        <ApexChart
          options={options}
          type={'area'}
          loading={loading}
          data={series}
          height={isFullScreen ? 200 : 480}
        />
      </Box>
    </Box>
  );
}

VoyageSummaryDraughtChart.propTypes = {
  loading: PropTypes.bool,
  voyageTransitDetails: PropTypes.object.isRequired,
  expectedDraught: PropTypes.number,
  isFullScreen: PropTypes.bool,
  isOngoingVoyage: PropTypes.bool
};

VoyageSummaryDraughtChart.defaultProps = {
  loading: false
};

export default VoyageSummaryDraughtChart;
