import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DatePickerMobi,
  FilterIcon,
  Grid,
  OperatorTreeSelect,
  Stack
} from '@esgian/esgianui';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupVesselHierarchy } from '@store/features';
import {
  getOngoingVoyagesFilters,
  updateOngoingVoyagesFilters
} from '@store/features/filters/VoyageAnalyticsPage/OngoingVoyage/OngoingVoyagesSlice';
import { DATE_FORMAT } from '@constants';
import AdvancedOngoingVoyageAnalyticsFilters from '@components/Sections/ShipAnalytics/VoyageAnalyticsSection/OngoingVoyageAnalyticsFilters/AdvancedOngoingVoyageAnalyticsFilters';
import { TextWithTooltipIcon } from '@components';

function OngoingVoyageAnalyticsFilters({ checkValidFilters, handleApplyFilters }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const lookupVesselHierarchy = useSelector(getLookupVesselHierarchy);
  const dispatch = useDispatch();
  const reduxFilters = useSelector(getOngoingVoyagesFilters);
  const [tempSelectedFilters, setTempSelectedFilters] = useState({});

  const { startDate } = tempSelectedFilters;
  const yesterday = moment().startOf('day').subtract(1, 'day');

  useEffect(() => {
    setTempSelectedFilters(reduxFilters);
  }, [reduxFilters]);

  useEffect(() => {
    if (!tempSelectedFilters.startDate) {
      setTempSelectedFilters((prev) => ({ ...prev, startDate: yesterday.format(DATE_FORMAT) }));
    }
  }, [tempSelectedFilters]);

  const updateFilterValue = (payload) => {
    setTempSelectedFilters((prev) => ({ ...prev, ...payload }));
  };

  const handleApply = (filters = {}) => {
    const updated = { ...tempSelectedFilters, ...filters };
    dispatch(updateOngoingVoyagesFilters(updated));
    handleApplyFilters(updated);
  };

  const defaultSelectedOperatorVessel = useMemo(() => {
    const selectedImoArray = tempSelectedFilters?.imos;
    if (!lookupVesselHierarchy || !selectedImoArray || selectedImoArray.length === 0) {
      return [];
    }
    const selectedOperatorIds = tempSelectedFilters?.operatorIds ?? [];

    const selected = [];

    selectedImoArray.forEach((imo) => {
      const vessel = lookupVesselHierarchy.vessels.find(({ id }) => id === imo);
      if (!vessel) {
        return;
      }
      selected.push(vessel);
    });

    selectedOperatorIds.forEach((operatorId) => {
      const operator = lookupVesselHierarchy.operatorHierarchy.find(({ id }) => id === operatorId);
      if (!operator) {
        return;
      }
      selected.push(operator);
    });

    return selected;
  }, [lookupVesselHierarchy, tempSelectedFilters]);

  return (
    <Grid item xs={12}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Stack className={'section-filter'}>
            <OperatorTreeSelect
              label={'Select Operators/Vessels*'}
              selected={defaultSelectedOperatorVessel}
              handleClose={(value) => {
                let res = {};
                let selected = value?.selected || [];
                const vesselImos = selected
                  ?.filter(({ type }) => type === 'vessel')
                  .map(({ id }) => id);

                const operatorId = selected
                  ?.filter(({ type }) => type === 'operator')
                  .map(({ id }) => id);

                res.imos = vesselImos.length ? vesselImos : null;
                res.operatorIds = operatorId.length ? operatorId : null;

                updateFilterValue(res);
              }}
              handleSelectTypeChange={() => {}}
              lookupVesselHierarchy={lookupVesselHierarchy}
              loading={Object.values(lookupVesselHierarchy).every((v) => v.length === 0)}
              sx={{ minWidth: '300px' }}
            />
          </Stack>

          <Stack className={'section-filter'}>
            <DatePickerMobi
              sx={{
                inputProps: {
                  InputProps: {},
                  defaultValue: null,
                  value: startDate ? moment(startDate, DATE_FORMAT).format(DATE_FORMAT) : '-',
                  placeholder: 'Period',
                  label: (
                    <TextWithTooltipIcon
                      label={'From'}
                      tooltipText={
                        'How Long back do you want the data from? Currently supports up to 100 days data going back from today'
                      }
                    />
                  ),
                  autoComplete: 'off'
                }
              }}
              selectedDate={startDate ? moment(`${startDate}`) : null}
              onClose={({ value }) => {
                if (moment(value).format(DATE_FORMAT) !== moment(startDate).format(DATE_FORMAT)) {
                  updateFilterValue({ startDate: moment(value).format(DATE_FORMAT) });
                }
              }}
              dateFormat={DATE_FORMAT}
              minStartDate={moment().subtract(100, 'day')}
              dateWheels={DATE_FORMAT}
              maxDate={yesterday}
            />
          </Stack>

          <Button
            disabled={!checkValidFilters(tempSelectedFilters)}
            onClick={() => {
              handleApply();
            }}
            variant={'contained'}
            color={'primary'}
            sx={{ height: 'min-content' }}>
            Apply Filters
          </Button>
        </Stack>

        <Button
          sx={{ height: 'min-content' }}
          startIcon={<FilterIcon />}
          variant={'text'}
          onClick={() => setAdvancedOpen(true)}>
          Advanced Filter
        </Button>
      </Stack>

      <AdvancedOngoingVoyageAnalyticsFilters
        updateFilterValue={updateFilterValue}
        advancedOpen={advancedOpen}
        setAdvancedOpen={setAdvancedOpen}
        handleApplyFilters={handleApply}
        disableApply={!checkValidFilters(tempSelectedFilters)}
      />
    </Grid>
  );
}

OngoingVoyageAnalyticsFilters.propTypes = {
  checkValidFilters: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired
};

OngoingVoyageAnalyticsFilters.defaultProps = {};

export default OngoingVoyageAnalyticsFilters;
