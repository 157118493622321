import React from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks/useTheme';
import { useSearchParams } from 'react-router-dom';
import { VoyageSummaryFilterKeys } from '../VoyageSummaryConstants';
import { DATE_TIME_FORMAT } from '@constants';
import moment from 'moment';

function VoyageSummarySpeedChart({ loading, voyageTransitDetails, isfullScreen, isOngoingVoyage }) {
  const { theme, themeMode } = useTheme();

  const [searchParams] = useSearchParams();
  const selectedDate = searchParams.get(VoyageSummaryFilterKeys.SELECTED_DATE);

  const seriesData = voyageTransitDetails?.transitDetails?.map((route) => {
    return {
      x: new Date(route.timeStamp),
      y: route.speed
    };
  });

  const series = [
    {
      name: 'Speed (kts)',
      data: seriesData
    }
  ];

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      background: isOngoingVoyage ? '0' : theme.palette.charts.background
    },
    colors: ['#80DFEB'],

    stroke: {
      width: [2],
      curve: 'straight'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd'
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left'
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return `
          <div style="padding: 8px 16px; width: 240px; background-color: ${
            themeMode ? 'rgba(255,255,255,0.80)' : 'rgba(0,0,0,0.80)'
          }; box-shadow: 0px 4px 4px 0px ${
          themeMode ? 'rgba(0,0,0,0.25)' : 'rgba(255,255,255,0.25)'
        }; color: ${themeMode ? 'black' : 'white'};">
            <div style="font-size: 10px; margin-bottom: 2px;">
              ${moment(data.x).format(DATE_TIME_FORMAT)}
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between; font-size: 14px;">
              <div>Speed (kts)</div>
              <div>${data.y}</div>
            </div>
          </div>
        `;
      }
    },
    dataLabels: {
      enabled: false
    },
    theme: {
      mode: theme.mode
    },
    annotations: {
      xaxis: [
        {
          x: selectedDate,
          borderColor: '#EF5350',
          strokeDashArray: 0
        }
      ]
    }
  };

  return (
    <Box>
      {isOngoingVoyage && <Typography variant={'h6'}>Speed</Typography>}
      <Box ml={isOngoingVoyage ? '-15px' : 0}>
        <ApexChart
          options={options}
          type={'line'}
          loading={loading}
          data={series}
          height={isfullScreen ? 200 : 480}
        />
      </Box>
    </Box>
  );
}

VoyageSummarySpeedChart.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  isfullScreen: PropTypes.bool,
  isOngoingVoyage: PropTypes.bool
};

VoyageSummarySpeedChart.defaultProps = {
  performanceData: null,
  loading: false
};

export default VoyageSummarySpeedChart;
