import React from 'react';
import { Grid } from '@esgian/esgianui';
import CanalsOverviewMap from '@components/Maps/CanalsOverviewMap/CanalsOverviewMap';
import PropTypes from 'prop-types';

function CanalsOverviewSection({
  canalArrivalVesselsData = null,
  canalInTransitVesselsData = null
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <CanalsOverviewMap
          hideCanalList={false}
          canalArrivalVesselsData={canalArrivalVesselsData}
          canalInTransitVesselsData={canalInTransitVesselsData}
        />
      </Grid>
    </Grid>
  );
}

CanalsOverviewSection.propTypes = {
  canalArrivalVesselsData: PropTypes.arrayOf(PropTypes.object),
  canalInTransitVesselsData: PropTypes.arrayOf(PropTypes.object)
};

export default CanalsOverviewSection;
