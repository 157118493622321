import {
  SAVED_VOYAGE_GROUPS_KEY,
  getSavedVoyageGroups,
  getReducedTransitDetails,
  updateVoyageGroup
} from '@api';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features/auth/AuthSlice.js';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  VoyageType,
  generateVoyageIdFromSavedVoyage
} from '../../../components/Sections/VoyageAnalytics/VoyageUtils';
import { useMemo } from 'react';
import { getChunkedArray } from '../../../components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';

const fetchTransitDetailsQueryFn = async ({ groupId, email, signal }) => {
  const data = await getSavedVoyageGroups(email, signal);
  const existingVoyageGroups = JSON.parse(data.userInformation);
  const foundSingleSavedVoyageGroup = existingVoyageGroups.find(
    (group) => group.id.toString() === groupId.toString()
  );
  if (!foundSingleSavedVoyageGroup) {
    throw new Error('Voyage group not found');
  }

  const voyageGroupType = foundSingleSavedVoyageGroup.type ?? VoyageType.COMPLETED;

  const filterParams = foundSingleSavedVoyageGroup.voyages.map((v) => {
    return {
      imo: v.imo,
      fromPortId: parseInt(v.fromPortId),
      toPortId: v.toPortId && voyageGroupType !== VoyageType.ONGOING ? parseInt(v.toPortId) : null,
      startDate: v.departureDate,
      endDate: v.arrivalDate && voyageGroupType !== VoyageType.ONGOING ? v.arrivalDate : null,
      reductionFactor: 4
    };
  });

  const chunked = getChunkedArray(filterParams, 50);

  const promises = chunked.map((chunk) => getReducedTransitDetails(chunk, signal));
  const responses = await Promise.all(promises);

  const flattened = responses.flat();

  const withOperators = flattened.map((d, i) => ({
    ...d,
    operator: `Operator~${i % 10}`
  }));

  return {
    ...foundSingleSavedVoyageGroup,
    voyages: foundSingleSavedVoyageGroup.voyages.map((singleSavedVoyage) => ({
      ...singleSavedVoyage,
      details: withOperators.find(
        (d) => +d.voyageReducedTransitDetails.imo === +singleSavedVoyage.imo
      )
    }))
  };
};

const VoyageDetailsQueryKey = 'voyageDetails';

const MAX_VOYAGE_PER_GROUP = 150;

const filterAllowedProperties = (voyageGroup) => {
  return {
    id: voyageGroup.id,
    name: voyageGroup.name,
    type: voyageGroup?.type ?? VoyageType.COMPLETED,
    isFavorite: voyageGroup.isFavorite,
    voyages: voyageGroup.voyages.map((v) => ({
      imo: v.imo,
      fromPortId: v.fromPortId,
      toPortId: v.toPortId ?? null,
      departureDate: v.departureDate,
      arrivalDate: v.arrivalDate ?? null,
      toPortType: v.toPortType
    }))
  };
};

export const useVoyageGroups = (props = {}) => {
  const { voyageGroupId } = props;
  const user = useSelector(getUser);
  const email = user?.email;
  const queryClient = useQueryClient();
  const controller = new AbortController();
  const { signal } = controller;

  const existingVoyageGroups = queryClient.getQueryData([SAVED_VOYAGE_GROUPS_KEY]) ?? [];

  const {
    data: savedVoyageGroups = [],
    isPending: isLoadingVoyageGroups,
    isFetching: isFetchingVoyageGroups
  } = useQuery({
    queryKey: [SAVED_VOYAGE_GROUPS_KEY],
    queryFn: async () => {
      const data = await getSavedVoyageGroups(email, signal);
      const parsed = JSON.parse(data.userInformation);
      return parsed.sort((a, b) =>
        a.isFavorite && !b.isFavorite ? -1 : b.isFavorite && !a.isFavorite ? 1 : 0
      );
    }
  });

  const {
    data: voyageGroupWithVoyageDetails,
    isPending: isGettingSavedVoyagesDetails,
    isError: isErrorGettingVoyageGroupWithVoyageDetails
  } = useQuery({
    queryKey: [VoyageDetailsQueryKey, voyageGroupId?.toString()],
    queryFn: async () =>
      fetchTransitDetailsQueryFn({
        groupId: voyageGroupId?.toString(),
        email,
        signal
      }),
    enabled: !!voyageGroupId
  });
  const { mutateAsync: deleteVoyageGroup, isPending: isDeletingVoyageGroup } = useMutation({
    mutationFn: (voyageGroupToDelete) => {
      const updated = existingVoyageGroups.filter((v) => v.id !== voyageGroupToDelete.id);
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, voyageGroupToDelete) => {
      toast.success(`Voyage group "${voyageGroupToDelete.name}" deleted successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    },
    onError: (_err, voyageGroupToDelete) => {
      toast.error(`Could not delete the voyage group: ${voyageGroupToDelete.name}`);
    }
  });

  const { mutateAsync: createVoyageGroup, isPending: isCreatingVoyageGroup } = useMutation({
    mutationFn: ({ newVoyageGroupName, type = VoyageType.COMPLETED }) => {
      const newGroup = {
        id: `${Date.now()}~${type}`,
        name: newVoyageGroupName,
        voyages: [],
        type
      };

      const updated = [...existingVoyageGroups, newGroup];
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, { newVoyageGroupName }) => {
      toast.success(`Voyage group "${newVoyageGroupName}" created successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    },
    onError: (err, { newVoyageGroupName }) => {
      toast.error(`Could not create the voyage group: ${newVoyageGroupName}`);
    }
  });

  const { mutateAsync: renameVoyageGroup, isPending: isRenamingVoyageGroup } = useMutation({
    mutationFn: ({ newName, voyageGroupToRename }) => {
      const updated = existingVoyageGroups.map((v) =>
        v.id === voyageGroupToRename.id ? { ...v, name: newName } : v
      );
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, { newName, voyageGroupToRename }) => {
      toast.success(
        `Voyage group "${voyageGroupToRename.name}" renamed to "${newName}" successfully.`
      );
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, voyageGroupToRename.id?.toString()]
      });
    },
    onError: (_err, { voyageGroupToRename, newName }) => {
      toast.error(
        `Could not rename the voyage group from "${voyageGroupToRename.name}" to "${newName}"`
      );
    }
  });

  const { mutateAsync: updateVoyageGroups, isPending: isUpdatingVoyageGroups } = useMutation({
    mutationFn: ({ updatedGroups }) => {
      const updated = existingVoyageGroups.map((v) => {
        const matched = updatedGroups.find((g) => g.id === v.id);
        if (!matched) return v;
        return matched;
      });
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: () => {
      toast.success(`Voyage groups updated successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    },
    onError: () => {
      toast.error(`Could not update voyage groups.`);
    }
  });

  const { mutateAsync: deleteVoyageFromGroup, isPending: isDeletingVoyageFromGroup } = useMutation({
    mutationFn: ({ voyageGroupId, savedVoyageIdToDelete }) => {
      const updated = existingVoyageGroups.map((v) =>
        v.id === voyageGroupId
          ? {
              ...v,
              voyages: v.voyages.filter(
                (v) => generateVoyageIdFromSavedVoyage(v) !== savedVoyageIdToDelete
              )
            }
          : v
      );
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_, { voyageGroupId, savedVoyageIdToDelete }) => {
      toast.success(`Voyage deleted successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.setQueryData([VoyageDetailsQueryKey, voyageGroupId?.toString()], (old) => ({
        ...old,
        voyages: old.voyages.filter(
          (v) => generateVoyageIdFromSavedVoyage(v) !== savedVoyageIdToDelete
        )
      }));
    },
    onError: () => {
      toast.error(`Could not delete the voyage.`);
    }
  });
  const { mutateAsync: triggerAddVoyagesToGroup, isPending: isAddingVoyagesToGroup } = useMutation({
    mutationFn: async ({ targetGroup, newVoyagesToAdd }) => {
      const updated = existingVoyageGroups
        .map((group) =>
          group.id === targetGroup.id
            ? { ...group, voyages: [...group.voyages, ...newVoyagesToAdd] }
            : group
        )
        .map(filterAllowedProperties);
      const res = await updateVoyageGroup(email, updated, signal);
      const parsedResponse = JSON.parse(res.userInformation);
      return parsedResponse;
    },
    onSuccess: (_data, { targetGroup, newVoyagesToAdd, duplicateVoyages }) => {
      toast.success(
        `Voyage${newVoyagesToAdd.length > 1 ? 's' : ''} saved to group successfully.${
          duplicateVoyages && duplicateVoyages.length > 0
            ? `Voyage${duplicateVoyages.length > 1 ? 's' : ''} ${duplicateVoyages.join(', ')} ${
                duplicateVoyages.length > 1 ? 'have' : 'has'
              } been excluded since ${
                duplicateVoyages.length > 1 ? 'they' : 'it'
              } already exist in the group.`
            : ''
        }`
      );
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, targetGroup.id?.toString()]
      });
    },
    onError: (_, { targetGroup, newVoyagesToAdd }) => {
      toast.error(
        `Unable to save voyage${newVoyagesToAdd.length > 1 ? 's' : ''} to the group "${
          targetGroup.name
        }".`
      );
    }
  });

  const { mutateAsync: updateVoyages, isPending: isUpdatingVoyages } = useMutation({
    mutationFn: async ({ targetGroupId, updatedVoyages }) => {
      const updated = existingVoyageGroups.map((group) => {
        if (group.id === targetGroupId) {
          return {
            ...group,
            voyages: group.voyages.map(
              (v) =>
                updatedVoyages.find(
                  (uV) => generateVoyageIdFromSavedVoyage(uV) === generateVoyageIdFromSavedVoyage(v)
                ) ?? v
            )
          };
        }
        return group;
      });
      return updateVoyageGroup(email, updated, signal);
    },

    onSuccess: (_data, { targetGroupId, updatedVoyages }) => {
      toast.success('Voyages information updated successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.setQueryData([VoyageDetailsQueryKey, targetGroupId?.toString()], (old) => ({
        ...old,
        voyages: old.voyages.map(
          (v) =>
            updatedVoyages.find(
              (uV) => generateVoyageIdFromSavedVoyage(uV) === generateVoyageIdFromSavedVoyage(v)
            ) ?? v
        )
      }));
    },
    onError: () => {
      toast.error('Failed to update voyages information.');
    }
  });

  const { mutateAsync: moveVoyage, isPending: isMovingVoyage } = useMutation({
    mutationFn: async ({ currentGroupId, targetGroupId, voyageIdToMove }) => {
      const currentGroup = existingVoyageGroups.find((g) => g.id === currentGroupId);
      const targetGroup = existingVoyageGroups.find((g) => g.id === targetGroupId);
      if (!currentGroup) {
        throw new Error('Current group not found');
      }
      if (!targetGroup) {
        throw new Error('Target group not found');
      }
      const foundVoyageInCurrentGroup = currentGroup.voyages.find(
        (v) => generateVoyageIdFromSavedVoyage(v) === voyageIdToMove
      );
      if (!foundVoyageInCurrentGroup) {
        throw new Error('Voyage not found in current group');
      }

      const isAlreadyExistInTargetGroup = targetGroup.voyages.some(
        (v) => generateVoyageIdFromSavedVoyage(v) === voyageIdToMove
      );

      if (isAlreadyExistInTargetGroup) {
        const msg = 'Voyage already exist in target group';
        toast.error(msg);
        throw new Error(msg);
      }

      const updated = existingVoyageGroups.map((g) => {
        if (g.id === targetGroupId) {
          return {
            ...g,
            voyages: [...g.voyages, foundVoyageInCurrentGroup]
          };
        }
        if (g.id === currentGroupId) {
          return {
            ...g,
            voyages: g.voyages.filter((v) => generateVoyageIdFromSavedVoyage(v) !== voyageIdToMove)
          };
        }
        return g;
      });

      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, { targetGroupId, currentGroupId }) => {
      toast.success('Voyage moved to target group successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, targetGroupId?.toString()]
      });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, currentGroupId?.toString()]
      });
    },
    onError: () => {
      toast.error('Failed to move voyage.');
    }
  });

  const addVoyagesToGroup = async ({ voyageGroupId, voyagesToAdd }) => {
    const updatedVoyageGroups = [...existingVoyageGroups];

    const targetGroup = updatedVoyageGroups.find((group) => group.id === voyageGroupId);

    if (!targetGroup) {
      const errMessage = `Requested group not found.`;
      toast.error(errMessage);
      return;
    }

    const voyagesOnTargetGroup = targetGroup.voyages;

    const duplicateVoyages = [];

    const voyagesAfterEliminatingDuplicates = voyagesToAdd.filter((voyage) => {
      return !voyagesOnTargetGroup.some((voyageOnTargetGroup) => {
        const isMatch =
          generateVoyageIdFromSavedVoyage(voyage) ===
          generateVoyageIdFromSavedVoyage(voyageOnTargetGroup);
        if (isMatch) {
          duplicateVoyages.push(voyageOnTargetGroup.imo);
        }
        return isMatch;
      });
    });

    const voyagesToSave = [...voyagesOnTargetGroup, ...voyagesAfterEliminatingDuplicates];

    if (duplicateVoyages.length === voyagesToAdd.length) {
      const errMessage = `Voyage${
        duplicateVoyages.length > 1 ? 's' : ''
      } already exists in the group.`;
      toast.info(errMessage);
      return;
    }

    if (voyagesToSave.length > MAX_VOYAGE_PER_GROUP) {
      const errMessage =
        duplicateVoyages.length > 0
          ? `Voyage${duplicateVoyages.length > 1 ? 's' : ''} ${duplicateVoyages.join(
              ', '
            )} already exist in the group. Including ${
              duplicateVoyages.length > 1 ? 'them' : 'it'
            }, a group can have a maximum of ${MAX_VOYAGE_PER_GROUP} voyages.`
          : `A Group can have maximum ${MAX_VOYAGE_PER_GROUP} voyages.`;
      toast.error(errMessage);
      return;
    }

    return triggerAddVoyagesToGroup({
      targetGroup,
      newVoyagesToAdd: voyagesAfterEliminatingDuplicates,
      duplicateVoyages: duplicateVoyages
    });
  };

  const prefetchTransitDetails = async (voyageGroupIdCollection) => {
    if (!voyageGroupIdCollection || voyageGroupIdCollection.length === 0) return;
    for (const voyageGroupId of voyageGroupIdCollection) {
      queryClient.prefetchQuery({
        queryKey: [VoyageDetailsQueryKey, voyageGroupId.toString()],
        queryFn: () =>
          fetchTransitDetailsQueryFn({ groupId: voyageGroupId.toString(), email, signal })
      });
    }
  };

  const completedVoyagesGroups = useMemo(
    () => savedVoyageGroups.filter((v) => !v.type || v.type === VoyageType.COMPLETED),
    [savedVoyageGroups]
  );

  const ongoingVoyagesGroups = useMemo(
    () => savedVoyageGroups.filter((v) => v.type === VoyageType.ONGOING),
    [savedVoyageGroups]
  );

  // updateVoyageGroup(email, [], signal);

  const loadingSavedVoyagesMessage = isGettingSavedVoyagesDetails
    ? 'Fetching voyage details. This may take a moment. Please wait...'
    : null;

  return {
    savedVoyageGroups,
    completedVoyagesGroups,
    ongoingVoyagesGroups,
    isLoadingVoyageGroups,
    isFetchingVoyageGroups,
    deleteVoyageGroup,
    isDeletingVoyageGroup,
    createVoyageGroup,
    isCreatingVoyageGroup,
    renameVoyageGroup,
    isRenamingVoyageGroup,
    deleteVoyageFromGroup,
    isDeletingVoyageFromGroup,
    voyageGroupWithVoyageDetails,
    isGettingSavedVoyagesDetails,
    addVoyagesToGroup,
    isAddingVoyagesToGroup,
    isErrorGettingVoyageGroupWithVoyageDetails,
    updateVoyages,
    isUpdatingVoyages,
    moveVoyage,
    isMovingVoyage,
    loadingSavedVoyagesMessage,
    updateVoyageGroups,
    isUpdatingVoyageGroups,
    prefetchTransitDetails
  };
};
