import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CountryFlag,
  Divider,
  Grid,
  Link,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Box,
  DateRangeMobi,
  NotFound,
  Select,
  MenuItem
} from '@esgian/esgianui';
import { VesselsMap } from '@components/Maps';
import { VesselsTable } from '@components/Tables';
import { OperatorFleetAgeChart, OperatorCEUChart } from './index';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { useSegment, useTheme } from '@hooks';
import { fetchVesselStats } from '@api';
import { DATE_TIME_FORMAT } from '@constants';
import { useParams } from 'react-router-dom';
import moment from 'moment';

function OperatorOverviewSection({ operatorData, vesselsData }) {
  const { isRoRo, segment } = useSegment();
  const { theme } = useTheme();
  const params = useParams();
  const [fleetAgeCeuData, setFleetAgeCeuData] = useState([]);
  const [CEUCapacityData, setCEUCapacityData] = useState([]);
  const [totalCountVessel, setTotalCountVessel] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [vesselsAdded, setVesselsAdded] = useState([]);
  const [vesselsRemoved, setVesselsRemoved] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('ageProfile');

  const filterOptions = [
    { value: 'ceuCapacity', label: isRoRo ? 'CEU Capacity' : 'Total Deadweight' },
    { value: 'ageProfile', label: 'Age Profile' }
  ];

  const [fleetDates, setFleetDates] = useState({
    start: moment().subtract(6, 'months').startOf('month'),
    end: moment()
  });

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchFleetAgeData = async () => {
      try {
        setLoading(true);
        setNoData(false);
        const response = await fetchVesselStats(
          signal,
          [segment.id],
          [],
          [params.id],
          [],
          moment(fleetDates.start).subtract(1, 'month').format(DATE_TIME_FORMAT), // Subtract 1 month to get the previous month data
          moment(fleetDates.end).format(DATE_TIME_FORMAT)
        );
        const operatorFleetData = response.map((item) => item.vesselTimeSeries || {});
        const vesselsAdded = operatorFleetData[0].map((item) => item.vesselsIn || {});
        const vesselsRemoved = operatorFleetData[0].map((item) => item.vesselsOut || {});
        const totalVessels = operatorFleetData[0].map((item) => item.numVessels || {});
        const categories = operatorFleetData[0].map((item) => {
          const date = new Date(item.date);
          return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        });
        const operatorAgeInformation = operatorFleetData[0].map(
          (item) => item.ageInformation || {}
        );
        const ceuCapacity = operatorFleetData[0].map((item) => item.capacity || {});
        setCategories(categories);
        setFleetAgeCeuData(operatorAgeInformation);
        setCEUCapacityData(ceuCapacity);
        setVesselsAdded(vesselsAdded);
        setVesselsRemoved(vesselsRemoved);
        setTotalCountVessel(totalVessels);

        setLoading(false);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          setLoading(false);
          console.error('Error fetching fleet age data:', error);
          setNoData(true);
        }
      }
    };

    fetchFleetAgeData();

    return () => {
      controller.abort();
    };
  }, [fleetDates]);

  const ensureHttpsAndWww = (website) => {
    let site = website;
    if (!website.startsWith('www.') && !website.startsWith('https://')) {
      site = `https://www.${site}`;
    } else if (website.startsWith('www.')) {
      site = `https://${site}`;
    }
    return site;
  };

  const {
    country,
    address,
    website,
    operatorLongName,
    operatorShortName,
    operatorVessels,
    isoAlpha2
  } = operatorData || {};

  let hideColumns = isRoRo
    ? ['operatorShortName', 'maxLiftingCapacity', 'numCranes']
    : ['operatorShortName', 'loadCapacity', 'callSign', 'ownerLongName', 'class', 'grossTonnage'];

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper sx={{ p: 2, height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item alignSelf={'center'}>
                    <Typography variant={'h6'}>Overview</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2.5}>
                      <Typography variant={'body2'} bold>
                        Name:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>{`${operatorLongName}`}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2.5}>
                      <Typography variant={'body2'} bold>
                        Short Name:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>{`${operatorShortName}`}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2.5}>
                      <Typography variant={'body2'} bold>
                        Country:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <CountryFlag size={15} countryCode={isoAlpha2} />
                        <Typography variant={'body2'}>{country}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2.5}>
                      <Typography variant={'body2'} bold>
                        Address:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>{address}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2.5}>
                      <Typography variant={'body2'} bold>
                        Website:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {website ? (
                        <Link
                          variant={'body2'}
                          target={'__blank'}
                          href={ensureHttpsAndWww(website)}>
                          {website}
                        </Link>
                      ) : (
                        <Typography variant={'body2'}>{website}</Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={12}>
                <VesselsMap
                  loading={!vesselsData?.length || loading}
                  showSpeedLegend
                  height={'50vh'}
                  zoom={0}
                  projection={'mercator'}
                  mapStyle={theme.mode === 'dark' ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE}
                  vessels={vesselsData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'h6'}>Fleet Development</Typography>
            </Stack>
            <Stack direction="row" justifyContent={'space-between'}>
              <Box sx={{ width: '10em' }}>
                <DateRangeMobi
                  sx={{
                    inputProps: {
                      InputProps: {
                        sx: { fontSize: '0.875rem' }
                      },
                      variant: 'standard',
                      defaultValue: undefined,
                      value:
                        fleetDates.start || fleetDates.end
                          ? `${
                              fleetDates.start ? moment(fleetDates.start).format('MMM YYYY') : ''
                            } - ${fleetDates.end ? moment(fleetDates.end).format('MMM YYYY') : ''}`
                          : null
                    }
                  }}
                  onClose={({ value }) => {
                    if (value[0].isValid()) {
                      setFleetDates((prevState) => ({
                        ...prevState,
                        start: value[0].startOf('month')
                      }));
                    }
                    if (value[1].isValid()) {
                      setFleetDates((prevState) => ({
                        ...prevState,
                        end: value[1].isSameOrAfter(moment(), 'month')
                          ? moment()
                          : value[1].endOf('month')
                      }));
                    }
                  }}
                  dateFormat={'MMM YYYY'}
                  minStartDate={
                    isRoRo ? moment('07-2022', 'MM-YYYY') : moment('07-2023', 'MM-YYYY')
                  }
                  disableFuture
                  startDate={fleetDates.start}
                  endDate={fleetDates.end}
                  placeholder={'Period'}
                  dateWheels={'MMM YYYY'}
                />
              </Box>
              <Grid item>
                <Box sx={{ width: '10em' }}>
                  <Select
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    displayEmpty
                    variant={'standard'}
                    labelId="operator-overview-filter"
                    inputProps={{ 'aria-label': 'Filter' }}
                    MenuProps={{ PaperProps: { style: { width: '10em' } } }}
                    sx={{ width: '10em' }}>
                    {filterOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Stack>
            <Divider />

            {noData ? (
              <NotFound
                show={true}
                header={'No Matching Result'}
                text={'Please change your search parameters'}
              />
            ) : (
              <>
                {selectedFilter === 'ageProfile' && (
                  <OperatorFleetAgeChart
                    operatorName={operatorShortName}
                    categories={categories}
                    loading={loading}
                    fleetAgeCeuData={fleetAgeCeuData}
                    totalCountVessel={totalCountVessel}
                    vesselsAdded={vesselsAdded}
                    vesselsRemoved={vesselsRemoved}
                    fileName={`${operatorShortName}-fleet-age`}
                    showForSingleSeries={true}
                  />
                )}
                {selectedFilter === 'ceuCapacity' && (
                  <OperatorCEUChart
                    loading={loading}
                    CEUCapacityData={CEUCapacityData}
                    fileName={`${operatorShortName}-CEU-capacity`}
                    categories={categories}
                    vesselsAdded={vesselsAdded}
                    vesselsRemoved={vesselsRemoved}
                  />
                )}
              </>
            )}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <VesselsTable
          loadCapacityUnit={isRoRo ? 'CEU' : 'Deadweight'}
          fileName={`${operatorShortName}-vessel-list`}
          title={'Operator Vessels'}
          loading={loading.vesselData}
          hideColumns={hideColumns}
          vessels={operatorVessels}
        />
      </Grid>
    </>
  );
}

OperatorOverviewSection.propTypes = {
  loading: PropTypes.object.isRequired,
  operatorData: PropTypes.object,
  vesselsData: PropTypes.arrayOf(PropTypes.object),
  fleetAgeData: PropTypes.arrayOf(PropTypes.object)
};

OperatorOverviewSection.defaultProps = {
  operatorData: null,
  vesselsData: [],
  fleetAgeData: []
};

export default OperatorOverviewSection;
