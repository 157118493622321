import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DateRangeMobi,
  FilterIcon,
  Grid,
  OperatorTreeSelect,
  TextField,
  Stack
} from '@esgian/esgianui';
import moment from 'moment';
import { getLocationTypeId } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDateTimeFormat, getLookupVesselHierarchy } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSegment } from '@hooks';
import AdvancedVoyageAnalyticsFilters from '@components/Sections/ShipAnalytics/VoyageAnalyticsSection/VoyageAnalyticsFilters/AdvancedVoyageAnalyticsFilters';
import {
  getCompletedVoyagesFilters,
  updateCompletedVoyagesFilters
} from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import LocationLoadAndDischargeSelect from '@components/Inputs/LocationLoadAndDischargeSelect/LocationLoadAndDischargeSelect';

function VoyageAnalyticsFilters({ checkValidFilters, handleApplyFilters }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const lookupVesselHierarchy = useSelector(getLookupVesselHierarchy);
  const dateFormat = useSelector(getDateTimeFormat);
  const dispatch = useDispatch();
  const { segmentMinDate } = useSegment();
  const reduxFilters = useSelector(getCompletedVoyagesFilters);
  const [tempSelectedFilters, setTempSelectedFilters] = useState({});

  useEffect(() => {
    setTempSelectedFilters(reduxFilters);
  }, [reduxFilters]);

  const updateFilterValue = (payload) => {
    setTempSelectedFilters((prev) => ({ ...prev, ...payload }));
  };

  const handleApply = () => {
    dispatch(updateCompletedVoyagesFilters(tempSelectedFilters));
    handleApplyFilters();
  };

  const { fromId, toId, startDate, endDate, maxTransitTime, operatorVesselSelect } =
    tempSelectedFilters;

  return (
    <Grid item xs={12}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'} className={'section-filter'}>
          <LocationLoadAndDischargeSelect
            selectedLocationOfDischarge={toId}
            setSelectedLocationOfDischarge={(val) =>
              updateFilterValue({
                toId: val,
                toType: getLocationTypeId(val.type)
              })
            }
            selectedLocationOfLoad={fromId}
            setSelectedLocationOfLoad={(val) =>
              updateFilterValue({ fromId: val, fromType: getLocationTypeId(val.type) })
            }
            handleSwap={() => {
              updateFilterValue({ fromId: toId, toId: fromId });
            }}
          />
          <DateRangeMobi
            sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
            onClose={({ value }) => {
              const { start, end } = getStartEndMonth(value, dateFormat);
              updateFilterValue({
                startDate: start,
                endDate: end
              });
            }}
            dateFormat={dateFormat}
            minStartDate={segmentMinDate}
            disableFuture
            startDate={moment(startDate, dateFormat)}
            endDate={moment(endDate, dateFormat)}
            placeholder={'Period'}
            dateWheels={'MMM YYYY'}
          />
          <OperatorTreeSelect
            label={'Select Operators/Vessels*'}
            selected={operatorVesselSelect}
            handleClose={(value) => {
              let res = {};
              let selected = value?.selected || [];
              const vesselImos = selected
                ?.filter(({ type }) => type === 'vessel')
                .map(({ id }) => id);

              const operatorId = selected
                ?.filter(({ type }) => type === 'operator')
                .map(({ id }) => id);

              res.imos = vesselImos.length ? vesselImos : null;
              res.operatorIds = operatorId.length ? operatorId : null;

              updateFilterValue(res);
            }}
            handleSelectTypeChange={() => {}}
            lookupVesselHierarchy={lookupVesselHierarchy}
            loading={!lookupVesselHierarchy}
          />
          <TextField
            label={'Max Transit Time (Days)'}
            sx={{ width: '170px' }}
            placeholder={'Max transit time...'}
            type={'number'}
            value={maxTransitTime || ''}
            onChange={({ target }) => {
              const value = Number(target.value);
              if (value <= 100) {
                updateFilterValue({ maxTransitTime: value });
              }
            }}
            InputProps={{
              inputProps: {
                max: 100
              }
            }}
          />
          <Button
            disabled={!checkValidFilters(tempSelectedFilters)}
            onClick={handleApply}
            variant={'contained'}
            color={'primary'}
            sx={{ height: 'min-content' }}>
            Apply Filters
          </Button>
        </Stack>
        <Button
          sx={{ height: 'min-content' }}
          startIcon={<FilterIcon />}
          variant={'text'}
          onClick={() => setAdvancedOpen(true)}>
          Advanced Filter
        </Button>
      </Stack>
      <AdvancedVoyageAnalyticsFilters
        updateFilterValue={updateFilterValue}
        advancedOpen={advancedOpen}
        setAdvancedOpen={setAdvancedOpen}
        handleApplyFilters={handleApplyFilters}
      />
    </Grid>
  );
}

VoyageAnalyticsFilters.propTypes = {
  checkValidFilters: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired
};

VoyageAnalyticsFilters.defaultProps = {};

export default VoyageAnalyticsFilters;
